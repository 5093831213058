import { http } from "@/vuex-bcore";

const url = "signup";

function create(account) {
  return http.post(`${url}/`, account);
}

export default {
  create,
};
