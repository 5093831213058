import Integrations from "@/integrations/views/Integrations.vue";
import QuickbooksDesktopSettings from "@/integrations/components/quickbooks-desktop/QuickbooksDesktopSettings";
import QuickBooksOnlineSettings from "@/integrations/components/quickbooks-online/QuickbooksOnlineSettings";

const integrationRoutes = [
  {
    path: "/organization/integrations",
    name: "organization-integrations",
    component: Integrations,
    meta: { requiresAuth: true, pageTitle: "Integrations" }
  },
  {
    path: "/organization/integrations/qbd-settings",
    name: "organization-qbd-settings",
    component: QuickbooksDesktopSettings,
    meta: { requiresAuth: true }
  },
  {
    path: "/organization/integrations/qbo-settings",
    name: "organization-qbo-settings",
    component: QuickBooksOnlineSettings,
    meta: { requiresAuth: true }
  },

];

export default integrationRoutes;
