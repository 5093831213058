import debounce from "lodash/debounce";
import { ADD_FILTERS, SET_LIST } from "@/store/mutation-types";

const dataTableMixin = {
  props: { modelName: { type: String, required: true } },
  data() {
    return {
      selected: [],
      defaultItemsPerPage: 10,
      tableFilters: {},
      tableOptions: {
        itemsPerPage: 20
      },
      itemsPerPageOptions: [5, 10, 20, 50, 100]
    };
  },
  computed: {
    totalItems() {
      return this.$store.state[this.modelName].meta.count;
    }
  },
  watch: {
    tableOptions: {
      handler: debounce(function () {
        this.$store.commit(`${this.modelName}/${SET_LIST}`, []);
        const { sortBy, sortDesc, page, itemsPerPage } = this.tableOptions;
        let ordering = "";
        if (sortBy.length > 0) {
          ordering = this.getOrdering(sortBy, sortDesc);
        }
        this.$store.commit(`${this.modelName}/${ADD_FILTERS}`, {
          page: page,
          page_size: itemsPerPage,
          ordering: ordering
        });
        this.fetchItems();
      }, 500)
    },
    tableFilters: {
      handler: debounce(function () {
        this.$store.commit(`${this.modelName}/${SET_LIST}`, []);
        this.$store.commit(`${this.modelName}/${ADD_FILTERS}`, this.tableFilters);
        this.fetchItems();
      }, 500),
      deep: true
    }
  },
  methods: {
    getOrdering(sortBy, sortDesc) {
      const orderingArray = sortBy.map((value, index) => {
        return sortDesc[index] ? `-${value}` : value;
      });
      return orderingArray.join(",").replace(".", "__");
    }
  }
};

export default dataTableMixin;
