<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table-row@2" />

    <v-simple-table v-show="!loading" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Description</th>
            <th class="text-left">Price</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Sub Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="orderItem in items" :key="orderItem.id">
            <td>{{ itemDescription(orderItem) }}</td>
            <td>{{ formatCurrency(itemPrice(orderItem)) }}</td>
            <td>{{ orderItem.quantity }}</td>
            <td>{{ formatCurrency(subtotal(orderItem)) }}</td>
            <td>
              <v-btn text color="error" @click.stop="onClickDeleteButton(orderItem)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <order-item-form
            ref="order_item_form"
            @updated="onItemsUpdated"
            :price-override="determinePriceOverrideToUse"
          />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import OrderItemForm from "@/point-of-sale/components/order/OrderItemForm.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";
import { determineItemPrice } from "@/utilities/price_override";

const getDefaultFieldArray = () => {
  return [];
};

export default {
  name: "OrderItems",

  components: { OrderItemForm, DeleteDialog },

  mixins: [sumColumnMixin, currencyFormatMixin],

  props: {
    loading: { type: Boolean, default: false },
    priceOverrides: { type: Array, default: getDefaultFieldArray }
  },

  data() {
    return {
      items: [],
      isUpdate: false,

      OrderItemFormObject: null,
      OrderItemFormNew: {
        quantity: 0.0
      }
    };
  },

  computed: {
    showTable() {
      return !this.loading && this.items.length > 0;
    },

    grandTotal() {
      return this.sumColumn("total", this.items);
    },

    determinePriceOverrideToUse() {
      // while we determine how QuickBooks manages POs precedence when a customer belongs to multiple POs.
      //  Use the most recent PO for that customer
      return this.priceOverrides.length > 0 ? this.priceOverrides[0] : null;
    }
  },

  watch: {
    priceOverrides: {
      handler(value) {
        this.recalculatePrice();
      }
    }
  },

  methods: {
    onItemsUpdated(item) {
      this.items.push(item);
      this.OrderItemFormObject = null;
      this.$emit("updated", this.items);
    },

    onClickAddItem() {
      this.OrderItemFormObject = {
        ...this.OrderItemFormNew
      };
    },

    update(items, update = false) {
      this.isUpdate = update;
      this.items = items || [];
    },

    onClickDeleteButton(item) {
      this.items = this.items.reduce((acc, curr) => {
        if (JSON.stringify(curr) !== JSON.stringify(item)) acc.push(curr);
        return acc;
      }, []);
      this.$emit("updated", this.items);
    },

    itemDescription(orderItem) {
      return orderItem.item === undefined ? orderItem.description : orderItem.item.description;
    },

    itemPrice(orderItem) {
      if (this.isUpdate) {
        return orderItem.price;
      }
      if (orderItem.custom_price) {
        return orderItem.custom_price;
      }
      return orderItem.item === undefined ? orderItem.total : orderItem.item.price;
    },

    subtotal(orderItem) {
      let price = orderItem.custom_price || orderItem.price || 0;
      let quantity = orderItem.quantity || 0;
      let subtotal = price * quantity;
      subtotal = Math.round((subtotal + Number.EPSILON) * 100) / 100;
      return subtotal;
    },

    recalculatePrice() {
      this.priceOverride = this.determinePriceOverrideToUse;
      if (this.items.length) {
        this.items.forEach(item => {
          item = determineItemPrice(item, this.priceOverride);
          item.total = this.subtotal(item);
        });
        this.$emit("updated", this.items);
      }
    }
  }
};
</script>
