<template>
  <v-dialog v-model="isOpen" max-width="500px" persistent>
    <v-card v-if="employee.id">
      <v-card-title class="headline">Transfer {{ entity.name }}?</v-card-title>
      <v-card-text>
        Warning: You are about to transfer the ownership of {{ entity.name }} to {{ employee.name }}. Once you click
        transfer, you will relinquish all your administrative rights. Are you sure you wish to continue with the
        transfer?
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="close">CANCEL</v-btn>
        <v-btn color="error" text @click="transfer">TRANSFER</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default Vue.extend({
  name: "OrganizationTransfer",
  data() {
    return {
      isOpen: false,
      employee: {}
    };
  },

  computed: {
    entity() {
      return this.$store.state.auth.entity;
    }
  },

  methods: {
    open(employee) {
      this.isOpen = true;
      this.employee = employee;
    },

    async transfer() {
      try {
        await this.$store.dispatch("organization/update", {
          id: this.entity.id,
          data: { user_id: this.employee.user }
        });
        await this.$router.push("bills");
        window.location.reload();
        this.$store.commit(`THEME/${ADD_MESSAGE}`, {
          text: "Ownership transfer successful, you now only have employee permissions for this organization.",
          type: "success"
        });
      } catch (e) {
        this.$store.commit(`THEME/${ADD_MESSAGE}`, {
          text: "Ownership transfer failed.",
          type: "error"
        });
      }
    },
    
    close() {
      this.isOpen = false;
      this.employee = {};
    }
  }
});
</script>
