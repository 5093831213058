const environmentMixin = {
  computed: {
    /**
     * Determine environment by hostname
     */
    environment() {
      const hostname = window.location.hostname;
      switch (hostname) {
        case "staging.invoiceboost.com":
          return "staging";
        case "demo.invoiceboost.com":
          return "staging";
        case "localhost":
          return "development";
        case "localhost.com":
          return "development";
        default:
          return "production";
      }
    }
  }
};

export default environmentMixin;
