<template>
  <BaseLayout>
    <keep-alive>
      <component :is="active_component" :context="context"></component>
    </keep-alive>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/views/layouts/BaseLayout.vue";
import FirstVisitMobile from "./FirstVisitMobile.vue";

import viewport from "@/mixins/viewport";

import api from "@/api";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "FirstVisit",

  components: {
    BaseLayout,
    FirstVisitMobile
  },

  mixins: [viewport],

  data() {
    return {
      isLoading: false,

      form: {
        language: "en-us",
        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    context() {
      return this;
    },

    active_component() {
      return FirstVisitMobile;
    }
  },

  mounted() {},

  methods: {
    submit() {
      this.reset_errors();
      this.isLoading = true;

      let api_call = api.internationalization.update(this.form.language);

      api_call
        .then(() => {
          this.$router.push({ name: "welcome-slides" });
        })
        .catch(exception => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: `Language setup failed: ${exception.data.message}`,
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    reset_errors() {
      this.form.field_errors = {};
      this.form.errors = [];
    }
  }
};
</script>

<style></style>
