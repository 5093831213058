import { is_object } from "./object_type";

export function parseFormErrors(fieldErrors) {
  const reducer = (formErrors, err) => {
    formErrors[err.field] = err.message;
    return formErrors;
  };
  return fieldErrors.reduce(reducer, {});
}

/**
 * Extracts field_errors details from an API Response Exception
 * @param {Object} exception
 * @return Dictionary with any key-value pairs of field errors.
 */
export function get_field_errors(exception) {
  let data = exception && exception.response ? exception.response.data : {};
  let field_errors = is_object(data) && data.field_errors ? data.field_errors : [];
  let mapped_errors = {};

  for (let i = 0; i < field_errors.length; i++) {
    let error = field_errors[i];
    mapped_errors[error.field] = error.message;
  }

  return mapped_errors;
}

/**
 * Extracts non field_errors details from an API Response Exception
 * @param {Object} exception
 * @return Array with a list of generic error objects.
 */
export function get_errors(exception) {
  const data = exception && exception.response ? exception.response.data : {};
  return data.errors || [];
}

export default {
  parseFormErrors,
  get_field_errors,
  get_errors
};
