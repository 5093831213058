<template>
  <v-card></v-card>
</template>

<script>
export default {
  name: "DeliveryIntervalDetails"
};
</script>

<style scoped></style>
