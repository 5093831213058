<template>
  <keep-alive>
    <component :is="active_component" :context="context"></component>
  </keep-alive>
</template>

<script>
import { ADD_FILTERS } from "@/store/mutation-types";

import InvoiceFilters from "@/invoiceboost/components/invoice/InvoiceFilters.vue";

import CurrencyFormatMixin from "@/mixins/currencyFormat";
import InvoiceStatusMixin from "@/mixins/invoiceStatus";
import InvoiceSyncStatusMixin from "@/mixins/invoiceSyncStatus";
import DataTableMixin from "@/mixins/dataTable";
import DateMixin from "@/mixins/date";
import PreviewMixin from "@/mixins/preview";
import InvoiceTermsMixin from "@/mixins/invoiceTerms";
import FilterCountMixin from "@/mixins/filterCount";
import DownloadMixin from "@/mixins/download";
import PermissionMixin from "@/mixins/permission";
import settingsMixin from "@/mixins/settings";

import deep_copy from "@/utilities/deep_copy";
import eventHub from "@/eventHub";
import viewport from "@/mixins/viewport";
import InvoicesDesktop from "@/invoiceboost/views/invoice/InvoicesDesktop";
import InvoicesMobile from "@/invoiceboost/views/invoice/InvoicesMobile";

const modelName = "invoice";

export default {
  name: "Invoices",

  components: {
    InvoiceFilters
  },

  mixins: [
    CurrencyFormatMixin,
    InvoiceStatusMixin,
    InvoiceSyncStatusMixin,
    DataTableMixin,
    DateMixin,
    PreviewMixin,
    InvoiceTermsMixin,
    FilterCountMixin,
    DownloadMixin,
    PermissionMixin,
    settingsMixin,
    viewport
  ],

  data() {
    return {
      displayFilters: false,
      headers: [
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Invoice #", value: "reference_no", sortable: true },
        { text: "Balance due", value: "balance_due", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Created", value: "created_at", sortable: true },
        { text: "Last updated", value: "modified_at", sortable: true },
        { text: "", value: "actions", sortable: false }
      ]
    };
  },

  computed: {
    context() {
      return this;
    },

    active_component() {
      return this.viewport.is_desktop ? InvoicesDesktop : InvoicesMobile;
    },

    pageTitle() {
      return this.$store.state.theme.pageTitle;
    },

    items() {
      return this.$store.state[modelName].list;
    },

    isLoading() {
      return this.$store.getters[`${modelName}/loading`]("list") || this.isLoadingSettings;
    }
  },

  mounted() {
    eventHub.listen("Invoice.CREATED", this.onInvoiceCreated);
    eventHub.listen("Invoice.UPDATED", this.onInvoiceUpdated);
    eventHub.listen("Invoice.DELETED", this.onInvoiceDeleted);
  },

  methods: {
    fetchItems() {
      this.$store.dispatch(`${modelName}/list`);
    },

    updateFilters(filters) {
      this.tableFilters = {};
      this.$store.commit(`${this.modelName}/${ADD_FILTERS}`, filters);
      this.fetchItems();
    },

    displayDetails(invoice) {
      // Send a copy of the invoice object to prevent edit propagation
      eventHub.trigger("InvoiceDetails.open", deep_copy(invoice));
    },

    onInvoiceCreated() {
      // Update the whole list
      this.fetchItems();
    },

    onInvoiceUpdated(invoice) {
      // Update the details from only the affected invoice 
      const invoice_id = invoice.id;
      const found = this.items.find(item => {
        return item.id == invoice_id;
      });

      deep_copy(invoice, found);
    },

    onInvoiceDeleted(invoice) {
      // Remove the invoice from the local array
      this.items.splice(this.items.indexOf(invoice), 1);
    }
  }
};
</script>

<style>
.org-invoices .search-field .v-input__slot {
  margin: 0;
}

.org-invoices .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-invoices table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-invoices table thead tr {
  background-color: #eeeeee;
}

.org-invoices table thead tr th {
  color: #000 !important;
}
</style>
