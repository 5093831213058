function copy_range(html_node) {
  let range = document.createRange();

  return new Promise((resolve, reject) => {
    try {
      range.selectNodeContents(html_node);

      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand("copy");
      selection.removeAllRanges();

      resolve();
    } catch (exception) {
      // console.log(exception);
      reject();
    }
  });
}

function select_range(html_node) {
  let range = document.createRange();
  range.selectNodeContents(html_node);

  let selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

export default {
  copy_range,
  select_range
};