<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table-row@2" />

    <v-simple-table v-show="!loading" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Description</th>
            <th class="text-left">Price</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Sub Total</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="quoteItem in items" :key="quoteItem.id">
            <td>{{ itemDescription(quoteItem) }}</td>
            <td>{{ itemPrice(quoteItem) }}</td>
            <td>{{ quoteItem.quantity }}</td>
            <td>{{ quoteItem.total }}</td>
            <td>
              <v-btn text color="error" @click.stop="onClickDeleteButton(quoteItem)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>

          <quote-item-form ref="quote_item_form" @updated="onItemsUpdated" :price-override="priceOverride" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import QuoteItemForm from "@/point-of-sale/components/quote/QuoteItemForm.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";

import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";

export default {
  name: "QuoteItems",

  components: { QuoteItemForm, DeleteDialog },

  mixins: [sumColumnMixin, currencyFormatMixin],

  props: {
    loading: { type: Boolean, default: false },
    priceOverride: { type: Object, default: null, required: false }
  },

  data() {
    return {
      items: [],
      isUpdate: false,

      QuoteItemFormObject: null,
      QuoteItemFormNew: {
        quantity: 0.0
      }
    };
  },

  computed: {
    showTable() {
      return !this.loading && this.items.length > 0;
    },

    grandTotal() {
      return this.sumColumn("total", this.items);
    }
  },

  methods: {
    onItemsUpdated(item) {
      this.items.push(item);
      this.QuoteItemFormObject = null;
      this.$emit("updated", this.items);
    },

    onClickAddItem() {
      this.QuoteItemFormObject = {
        ...this.QuoteItemFormNew
      };
    },

    update(items, update = false) {
      this.isUpdate = update;
      this.items = items || [];
    },

    onClickDeleteButton(item) {
      this.items = this.items.reduce((acc, curr) => {
        if (JSON.stringify(curr) !== JSON.stringify(item)) acc.push(curr);
        return acc;
      }, []);
      this.$emit("updated", this.items);
    },

    itemDescription(quoteItem) {
      return quoteItem.item === undefined ? quoteItem.description : quoteItem.item.description;
    },

    itemPrice(quoteItem) {
      if (this.isUpdate) {
        return this.formatCurrency(quoteItem.price);
      }
      if (quoteItem.custom_price) {
        return this.formatCurrency(quoteItem.custom_price);
      }
      let price = quoteItem.item === undefined ? quoteItem.total : quoteItem.item.price;
      return this.formatCurrency(price);
    }
  }
};
</script>
