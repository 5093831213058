<template>
  <v-card tile outlined class="QBD-connector ma-5">
    <v-card-text>
      <v-row>
        <v-col cols="6" class="d-flex align-center justify-center flex-wrap">
          <v-img
            height="100px"
            class="white--text align-end"
            :src="require('@/assets/integrations/quickbooks_desktop_logo.png')"
            contain
          />
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-center flex-wrap">
          <v-list dense>
            <!-- MENU ITEM: CONNECT/DISCONNECT FROM QBD -->

            <v-list-item>
              <v-list-item-title>
                <!-- SUBMENU ITEM: CONNECT TO QBD -->

                <v-menu v-if="!connected" bottom offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined color="grey" tile v-on="on" style="width: 100%">
                      NOT CONNECTED |
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click="openWebConnectorForm">
                      <v-icon color="green" right>mdi-power-plug</v-icon>
                      Connect
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!-- SUBMENU ITEM: DISCONNECT FROM QBD -->
                <v-menu v-else bottom offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined color="green" tile v-on="on">
                      CONNECTED |
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click="unregisterWebConnector">
                      <v-icon color="red" right>mdi-power-plug-off</v-icon>
                      Disconnect
                    </v-list-item>
                    <v-list-item text color="info" absolute @click="downloadConnectorFile">
                      <v-icon left>mdi-download</v-icon>
                      QWC File
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item>

            <!-- MENU ITEM: SETTINGS -->

            <v-list-item v-if="connected">
              <v-list-item-title>
                <v-btn class="mt-6" outlined color="danger" @click="openSettings">
                  <v-icon left>mdi-tune</v-icon>
                  Settings
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <!-- MENU ITEM: DOWNLOAD QBWC -->
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  class="mt-6"
                  color="info"
                  href="https://intuit.box.com/shared/static/veoi2d8otmz7i7zm1yogt3wepfhbfuc0.zip"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon left>mdi-download</v-icon>
                  QB Web Connector
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <responsive-dialog
      :display-dialog="formOpened"
      :dialog-title="dialogTitle"
      :show-actions="!isItemUpdate"
      @closed="closeForm"
    >
      <form v-if="!password">
        <v-text-field
          v-if="formItem"
          v-model="formItem.account.qbwc_file_path"
          type="text"
          label="QuickBooks Company File Path"
          outlined
          :messages="formMessages"
          :error-messages="formItemErrors.account.qbwc_file_path"
        />
      </form>

      <div v-if="password" tile>
        <h2 class="black--text">Web Connector Password</h2>

        <p class="mt-2">Please save your web connector password securely. It will not be revealed again.</p>

        <v-text-field v-model="formItem.password" readonly outlined small @click="copyToClipBoard(formItem.password)">
          <template slot="append">
            <v-icon>mdi-clipboard</v-icon>
          </template>
        </v-text-field>
      </div>

      <template v-slot:actions>
        <v-spacer />
        <v-btn
            v-if="!password"
            type="submit"
            :loading="formIsLoading"
            :disabled="!formItem.account || !formItem.account.qbwc_file_path"
            outlined
            color="success"
            @click="registerWebConnector"
        >
          Connect
        </v-btn>
      </template>
    </responsive-dialog>

  </v-card>
</template>

<script>
import { ADD_MESSAGE, SET_DETAIL } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";

import fileMixin from "@/mixins/file";
import formMixin from "@/mixins/form";
import settingsMixin from "@/mixins/settings";
import copyToClipBoardMixin from "@/mixins/copyToClipBoard";

export default {
  name: "QuickBooksDesktop",

  components: {
    ResponsiveDialog,
  },

  mixins: [formMixin, copyToClipBoardMixin, fileMixin, settingsMixin],

  data() {
    const msg = "Please enter the full file path to the QB Company file on your PC e.g " +
        "C:\\Users\\Public\\Documents\\Intuit\\QuickBooks\\Company Files\\CompanyFile.qbw";
    return {
      dialogTitle: "Connect QuickBooks Desktop",
      formMessages: msg,
      formItem: { account: {} },
      formItemErrors: { account: {} },
      formItemCleaned: { account: {} }
    };
  },

  computed: {
    entity() {
      return this.$store.state.auth.entity;
    },

    password() {
      return this.formItem && this.formItem.password;
    },

    settings() {
      return this.$store.state.entitySettings.detail;
    },

    connected() {
      return !!this.settings.accounting_package_connected.QBD;
    }
  },

  methods: {
    openWebConnectorForm() {
      this.$store.commit(`webConnectorAccount/${SET_DETAIL}`, this.formItemCleaned);
    },

    openSettings() {
      this.$router.push({ name: "organization-qbd-settings" });
    },

    async downloadConnectorFile() {
      try {
        await this.downloadFile("integrations/qbwc-account/qbwc-file/", "WebConnectorApp.qwc");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to download your web connector file",
          type: "error"
        });
      }
    },

    closeForm() {
      this.formItemErrors = { account: {} };
      this.$store.commit(`webConnectorAccount/${SET_DETAIL}`, null);
    },

    async registerWebConnector() {
      try {
        await this.sendCreate();

        this.$store.commit(`entitySettings/${SET_DETAIL}`, {
          ...this.settings,
          accounting_package_connected: { QBD: true }
        });

        await this.downloadConnectorFile();
        await this.fetchMySettings();

        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Web Connector registration success", type: "success" });
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "There was an error registering the web connector",
          type: "error"
        });
      }
    },

    async unregisterWebConnector() {
      try {
        await this.$store.dispatch("webConnectorAccount/delete", this.entity.id);

        this.$store.commit(`entitySettings/${SET_DETAIL}`, {
          ...this.settings,
          accounting_package_connected: { QBD: false }
        });

        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Web Connector Removed", type: "info" });
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to disconnect your connector",
          type: "error"
        });
      }
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style>
.QBD-connector {
}
</style>
