import { http } from "@/vuex-bcore";

const url = "pos/trucks";

function create(truck) {
  return http.post(`${url}/`, truck);
}

function update(truck) {
  return http.patch(`${url}/${truck.id}/`, truck);
}

export default {
  create,
  update
};
