<template>
  <div>
    <v-snackbar
      v-for="message in messages"
      ref="message-snackbar"
      :key="message.key"
      v-model="showMessages"
      :timeout="-1"
    >
      <v-icon :color="getColor(message.type)" left>{{ `mdi-${getIcon(message.type)}` }}</v-icon>
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="removeMessage(message.key)">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { CLEAR_MESSAGES, REMOVE_MESSAGE } from "@/store/mutation-types";

export default {
  name: "GlobalSnackbarMessages",

  data() {
    return {
      showMessages: true
    };
  },

  computed: {
    lastMessageId() {
      return this.$store.state.theme.lastMessageId;
    },

    messages() {
      return this.$store.state.theme.messages;
    }
  },

  watch: {
    lastMessageId() {
      this.timeOutMessage();
    }
  },

  methods: {
    removeMessage(key) {
      return this.$store.commit(`theme/${REMOVE_MESSAGE}`, key);
    },

    getIcon(type) {
      switch (type) {
        case "success":
          return "check-circle-outline";
        case "error":
          return "alert-circle-outline";
        case "warning":
          return "alert-outline";
        default:
          return "information-outline";
      }
    },

    getColor(type) {
      switch (type) {
        case "warning":
          return "orange";
        case "error":
          return "red";
        case "success":
          return "green";
        default:
          return "white";
      }
    },

    timeOutMessage(delay) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.$store.commit(`theme/${CLEAR_MESSAGES}`));
        }, delay || 4000);
      });
    }
  }
};
</script>
