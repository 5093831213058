<template>
  <BaseLayout>
    <v-main id="main-canvas">
      <slot />
    </v-main>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/views/layouts/BaseLayout.vue";

export default {
  name: "LoginLayout",
  components: { BaseLayout },
  computed: {}
};
</script>

<style>
#main-canvas {
  background-color: #f1f1f1;
}
</style>
