<template>
  <responsive-dialog :display-dialog="displayDialog" :dialog-title="dialogTitle" @closed="close">
    <template slot="app-bar-right">
      <v-btn v-if="checkPermission('add_paymentmethod')" color="success" text tile @click.stop="formObject = {}">
        <v-icon left>mdi-plus</v-icon>
        ADD
      </v-btn>
    </template>

    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card-title>{{ entity.name }}</v-card-title>
        </v-col>

        <v-col cols="4">
          <v-switch
            v-if="canEditInvoiceFraud"
            v-model="settings.enable_anti_fraud_security"
            :loading="settingsLoading"
            :messages="antiFraudSecurityMessages"
            label="Anti-Fraud Security"
            @change="updateEntitySettings"
          ></v-switch>

          <auto-pay v-if="displayAutoPay" :target-id="targetId" :account="account" />
        </v-col>
      </v-row>

      <!-- EXPAND ALL BUTTON -->
      <v-row>
        <v-col cols="2">
          <v-btn v-if="allPanelsOpen" text @click="closeAllPanels">
            <v-icon left>mdi-chevron-up</v-icon>
            Collapse all
          </v-btn>
          <v-btn v-else text @click="showAllPanels">
            <v-icon left>mdi-chevron-down</v-icon>
            Expand all
          </v-btn>
        </v-col>
      </v-row>

      <v-skeleton-loader v-if="loading" class="mt-7" type="paragraph"></v-skeleton-loader>

      <!-- EXPANSION PANELS -->
      <v-expansion-panels v-show="!loading" v-model="panels" multiple hover focusable>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="white-space: pre-line; margin-top: 30px">{{ item.instructions }}</div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.stop="copyToClipBoard(item.instructions)">Copy</v-btn>
              <v-btn
                v-if="checkPermission('change_paymentmethod')"
                text
                color="primary"
                @click.stop="formObject = item"
              >
                Edit
              </v-btn>
              <v-btn
                v-if="checkPermission('delete_paymentmethod')"
                text
                color="error"
                @click.stop="onClickDeleteButton(item)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <!-- EDITOR -->
    <payment-method-form :form-object="formObject" @updated="onPaymentMethodUpdated"></payment-method-form>

    <!-- DELETE DIALOG -->
    <delete-dialog
      object-name="payment method"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </responsive-dialog>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

import PaymentMethodForm from "@/invoiceboost/components/payment-method/PaymentMethodForm.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import expansionPanel from "@/mixins/expansionPanel";
import permissionMixin from "@/mixins/permission";
import settingsMixin from "@/mixins/settings";

import copyToClipBoardMixin from "@/mixins/copyToClipBoard";
import AutoPay from "@/invoiceboost/components/auto-pay/AutoPay.vue";

const defaultObject = () => {
  return {};
};

export default {
  name: "PaymentMethods",

  components: { PaymentMethodForm, DeleteDialog, ResponsiveDialog, AutoPay },

  mixins: [expansionPanel, copyToClipBoardMixin, permissionMixin, settingsMixin],

  props: {
    editable: { type: Boolean, default: false },
    token: { type: String, default: null },
    account: { type: String, default: "payable" }
  },

  data() {
    return {
      loading: false,
      displayDialog: false,
      idDeleteItem: -1,
      dialogTitle: `Accounts ${this.account} | Payment Methods`,
      deleteConfirmOpen: false,
      items: [],
      settings: {},

      entity: {},
      formObject: null,
      formNew: {
        amount: 0.0
      },

      settingsLoading: false
    };
  },

  computed: {
    canEditInvoiceFraud() {
      return this.editable && this.$store.getters["auth/hasEntityPermission"]("invoiceboost.change_entitysettings");
    },

    antiFraudSecurityMessages() {
      return this.settings.enable_anti_fraud_security
        ? `Enabled: Do not include payment instructions to prevent spoofers from imitating your company when invoices are emailed to customers not on ${process.env.VUE_APP_NAME}`
        : "Disabled: Include payment instructions in emails to customers. While this is more convenient for customers, it also significantly increases the risk of invoice fraud.";
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    idIsSet() {
      return this.entity && this.entity.id;
    },

    paymentMethodUrl() {
      let paymentMethodUrl = `invoiceboost/organizations/${this.entity.id}/payment_methods/`;
      const token = this.token;

      if (token) {
        paymentMethodUrl += `?token=${token}`;
      }

      return paymentMethodUrl;
    },

    targetId() {
      return this.entity.id !== this.ownerEntity.id ? this.entity.id : 0;
    },

    displayAutoPay() {
      // TODO: There should be better identifiers for when to display or not the autopay component
      return !this.token;
    }
  },

  async mounted() {
    if (this.idIsSet) {
      this.fetchItems();
      if (this.canEditInvoiceFraud) {
        await this.fetchEntitySettings();
      }
    }
  },

  methods: {
    open(entity) {
      this.displayDialog = true;
      this.entity = entity;

      this.fetchItems().then(response => {
        this.showAllPanels();
      });

      this.canEditInvoiceFraud && this.fetchEntitySettings();

      return this;
    },

    close() {
      this.displayDialog = false;
      this.entity = {};
      this.items = [];

      return this;
    },

    fetchItems() {
      this.loading = true;

      return this.$http
        .get(this.paymentMethodUrl)
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch payments." });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onPaymentMethodUpdated() {
      this.formObject = null;
      this.fetchItems();
    },

    onClickAddItem() {
      this.formObject = {
        ...this.formNew,
        ...{
          owner_entity: this.ownerEntity.id
        }
      };
    },

    onItemClick(item) {
      this.formObject = { ...item };
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.showSnackbarSuccess();
    },

    async sendDelete(id) {
      await this.$store.dispatch("paymentMethod/delete", id);
    },

    // Using slightly different name to avoid conflict with the mixin's method hasPermission
    checkPermission(action) {
      return this.editable && this.hasPermission(action);
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    },

    async fetchEntitySettings() {
      this.settingsLoading = true;
      try {
        const response = await this.fetchSettings(this.entity.id);
        this.settings = response.data;
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Failed to load settings", type: "error" });
      } finally {
        this.settingsLoading = false;
      }
    },

    async updateEntitySettings() {
      this.settingsLoading = true;
      try {
        await this.updateSettings(this.entity.id, this.settings);
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Settings saved", type: "success" });
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "There was an error saving your settings", type: "error" });
      } finally {
        this.settingsLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
