<template>
  <v-row justify="center">
    <v-dialog v-model="open" max-width="400px" persistent>
      <v-card>
        <v-card-title class="headline">{{ title }}?</v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="onClickCancelButton">CANCEL</v-btn>
          <v-btn color="primary" text @click="onClickConfirmButton">CONFIRM</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmDialog",

  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    open: { type: Boolean, default: false }
  },

  methods: {
    onClickCancelButton() {
      this.$emit("cancelled");
    },

    onClickConfirmButton() {
      this.$emit("confirmed");
    }
  }
};
</script>
