<template>
  <v-card v-if="displayPanel" class="invoice-receivable-details elevation-8 overflow-y-auto" outlined>
    <v-card-title class="d-flex">
      <span class="mr-auto">Invoice Details</span>
      <v-btn class="btn-close" @click.prevent="close" text icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- DETAIL FIELDS -->
    <v-card-text class="pt-0">
      <ul class="pa-0">
        <!-- FIELD: CUSTOMER -->
        <li>
          <strong>Customer</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <router-link :to="{ name: 'org-customers', params: { id: customer.id } }" target="_blank">
              {{ customer.name }}
            </router-link>
          </span>
        </li>

        <!-- FIELD: INVOICE NUMBER -->
        <li>
          <strong>Invoice #</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <a @click.prevent="previewInvoice" class="underline">{{ invoice.reference_no }}</a>
          </span>
        </li>

        <!-- FIELD: TOTAL -->
        <li>
          <strong>Total</strong>
          <span>{{ formatCurrency(invoice.total, invoice.currency) }}</span>
          <span v-show="hasPermission('change_invoice')" class="pl-1">
            |
            <a ref="edit-total" href="" class="update" @click.prevent="editInvoice">Edit</a>
          </span>
        </li>

        <!-- FIELD: BALANCE DUE -->
        <li>
          <strong>Balance due</strong>
          <span>{{ formatCurrency(invoice.balance_due, invoice.currency) }}</span>
        </li>

        <!-- FIELD: TERMS -->
        <li>
          <strong>Terms</strong>
          <span>{{ parseTerms(invoice.terms) }}</span>
        </li>

        <!-- FIELD: CREATED -->
        <li>
          <strong>Created</strong>
          <span>{{ formatDate(invoice.created_at) }}</span>
        </li>

        <!-- FIELD: LAST UPDATED -->
        <li>
          <strong>Last Updated</strong>
          <span>{{ formatDate(invoice.modified_at) }}</span>
        </li>

        <!-- FIELD: ASSIGNEES -->
        <li>
          <strong>Assignees</strong>
          <span>
            <invoice-assignees-editor
              :invoice-receivable="true"
              :invoice-id="invoice.id"
              :can-change-invoice="hasPermission('change_invoice')"
            />
          </span>
        </li>

        <!-- FIELD: STATUS -->
        <li>
          <strong>Status</strong>
          <span>
            <!-- <v-icon small>{{ getStatusIcon(invoice.status) }}</v-icon> -->
            {{ getStatusText(invoice.status) }}
          </span>
          <span v-show="showEditButton" class="pl-1">
            |
            <a href="" id="show-status-actions" class="update" @click.prevent="showStatusActions">Edit</a>
          </span>
        </li>
      </ul>
    </v-card-text>

    <!-- STATUS UPDATE FORM -->
    <v-card-text
      v-if="displayStatusActions && hasPermission('change_invoice')"
      class="status-actions justify-space-between pa-4 mb-4"
    >
      <v-form @submit.prevent="updateStatus">
        <v-radio-group v-model="form.status" class="mt-0 mb-4 pt-0" hide-details mandatory>
          <div class="mb-4">
            <strong>Edit Status</strong>
          </div>

          <v-radio value="pending">
            <template v-slot:label>
              <div>Pending</div>
            </template>
          </v-radio>

          <v-radio value="paid">
            <template v-slot:label>
              <div>Paid</div>
            </template>
          </v-radio>
        </v-radio-group>

        <v-divider class="mb-4" />

        <!-- COMMENT -->
        <v-text-field name="Invoice[comment]" label="Comment" v-model="form.comment" background-color="#FFF" outlined />

        <div class="d-flex justify-space-between">
          <v-btn color="default" :disabled="isLoading" @click.prevent="hideStatusActions" small>
            <!-- <v-icon left small>mdi-cancel</v-icon> -->
            Cancel
          </v-btn>

          <v-spacer />

          <v-btn type="submit" color="primary" :disabled="isLoading" small>
            <v-icon left small>mdi-content-save-edit</v-icon>
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card-text>

    <v-card-text class="pt-0">
      <v-divider class="mb-4" />

      <ul class="pa-0">
        <li>
          <strong>PDF</strong>
          <span>
            <v-icon small>
              {{ isLoading ? "mdi-download-outline" : "mdi-file-pdf-outline" }}
            </v-icon>
            <a :class="{ underline: !isLoading }" @click.prevent="downloadPdf" :disabled="isLoading">
              <span v-if="isLoading">Downloading...</span>
              <span v-else>Download</span>
            </a>
          </span>
        </li>

        <li>
          <strong>Preview</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <a class="underline" @click.prevent="previewInvoice">Open</a>
          </span>
        </li>

        <li>
          <strong>E-mail</strong>
          <span>
            <v-icon small>mdi-email-send-outline</v-icon>
            <span v-if="is_sending_email">Sending email...</span>
            <a v-else class="underline" @click.prevent="resend_email">Resend e-mail</a>
          </span>
        </li>
      </ul>
    </v-card-text>

    <!-- ACTIONS -->
    <v-card-text v-if="!displayStatusActions" class="invoice-actions pt-0">
      <v-divider class="mb-4" />

      <div class="d-flex">
        <v-btn
          v-show="hasPermission('delete_invoice')"
          class="btn-close"
          color="error"
          :disabled="false"
          @click.prevent="deleteInvoice"
          small
          outlined
        >
          <v-icon left small>mdi-delete-outline</v-icon>
          Delete
        </v-btn>

        <v-spacer />

        <v-btn
          ref="edit2-invoice"
          v-show="hasPermission('add_payment') && !hasAccountingPackageConnected && canMakePayment"
          class="btn-close"
          color="primary"
          :disabled="false"
          @click.prevent="openMarkPaidDialog"
          small
        >
          <v-icon left small>mdi-check-outline</v-icon>
          Mark Paid
        </v-btn>

        <v-spacer v-if="hasPermission('add_payment') && !hasAccountingPackageConnected && canMakePayment" />

        <v-btn
          ref="edit-invoice"
          v-show="hasPermission('change_invoice')"
          class="btn-close"
          color="secondary"
          :disabled="false"
          :to="{ name: 'org-invoices-edit', params: { id: invoice.id } }"
          link
          small
        >
          <v-icon left small>mdi-pencil-outline</v-icon>
          Edit Invoice
        </v-btn>
      </div>
    </v-card-text>

    <!-- EXPANDABLE DETAILS -->
    <v-expansion-panels v-if="true" class="pt-0 pb-4" flat focusable accordion>
      <!-- PAYMENTS HISTORY -->
      <v-expansion-panel class="payment-methods">
        <v-expansion-panel-header>
          Payments
          <v-spacer />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul class="pa-0 mt-4">
            <li v-if="hasPermission('add_payment') && !hasAccountingPackageConnected">
              <v-spacer />
              <a title="Add payment" ref="add_payment" @click="openPaymentForm()">
                <v-icon>mdi-plus-circle</v-icon>
                Add Payment
              </a>
              <InvoicePaymentForm ref="payment_form" @updated="onPaymentsUpdated"></InvoicePaymentForm>
            </li>
            <li v-if="paymentsHistory.length === 0">
              <div class="d-flex">No payments received.</div>
            </li>

            <li v-for="(payment, index) in paymentsHistory" :key="index" class="flex-column">
              <div class="d-flex">
                <strong class="flex-shrink-0">Amount</strong>
                <span>{{ formatCurrency(payment.amount, payment.amount_currency) }}</span>
              </div>
              <div class="d-flex">
                <strong class="flex-shrink-0">Created</strong>
                <span>{{ formatDate(payment.created_at) }}</span>
                <v-spacer />
                <a
                  v-show="hasPermission('change_payment')"
                  :ref="`edit_payment_${payment.id}`"
                  @click="openPaymentForm(payment)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </a>
              </div>
              <v-divider class="my-3" />
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- STATUS HISTORY -->
      <v-expansion-panel class="status-history">
        <v-expansion-panel-header>Status History</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul class="pa-0">
            <li v-for="(history, index) in statusHistory" :key="index" class="flex-column">
              <div class="d-flex">
                <strong class="flex-shrink-0">Status</strong>
                <span>{{ getStatusText(history.status) }} | {{ formatDate(history.created_at) }}</span>
              </div>
              <div class="d-flex">
                <strong class="flex-shrink-0">Employee</strong>
                <span>{{ history.created_by || "N/A" }}</span>
              </div>
              <div class="d-flex">
                <strong class="flex-shrink-0">Comment</strong>
                <span>{{ history.comment || "N/A" }}</span>
              </div>

              <v-divider class="my-3" />
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <delete-dialog
      :open="displayDeleteDialog"
      :object-name="deleteDialogObjectName"
      @deleted="onConfirmDeleteDialog"
      @cancelled="onCancelDeleteDialog"
    />

    <confirm-dialog
      :open="displayMarkPaidDialog"
      :title="markPaidDialogTitle"
      :text="markPaidDialogText"
      @confirmed="onConfirmMarkPaid"
      @cancelled="onCancelMarkPaid"
    />
  </v-card>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

import InvoiceAssigneesEditor from "@/invoiceboost/components/invoice/InvoiceAssigneesEditor.vue";

import ConfirmDialog from "@/bcore/components/ConfirmDialog.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";

import InvoiceStatusMixin from "@/mixins/invoiceStatus";
import CurrencyFormatMixin from "@/mixins/currencyFormat";
import DateMixin from "@/mixins/date";
import PreviewMixin from "@/mixins/preview";
import DownloadMixin from "@/mixins/download";
import InvoiceTermsMixin from "@/mixins/invoiceTerms";
import eventHub from "@/eventHub";
import api from "@/api";
import InvoicePaymentForm from "@/invoiceboost/components/invoice/InvoicePaymentForm.vue";
import PermissionMixin from "@/mixins/permission";
import SettingsMixin from "@/mixins/settings";

export default {
  name: "InvoiceDetails",

  components: {
    InvoiceAssigneesEditor,
    DeleteDialog,
    ConfirmDialog,
    InvoicePaymentForm
  },

  mixins: [
    InvoiceStatusMixin,
    CurrencyFormatMixin,
    DateMixin,
    PreviewMixin,
    DownloadMixin,
    InvoiceTermsMixin,
    PermissionMixin,
    SettingsMixin
  ],

  props: {},

  data() {
    return {
      isLoading: false,
      is_sending_email: false,

      displayPanel: false,
      displayStatusActions: false,
      displayEPDC: false, // Expected Payment Date Calendar

      displayDeleteDialog: false,
      deleteDialogObjectName: "",

      displayMarkPaidDialog: false,
      markPaidDialogText: "Are you sure you want to mark this invoice as Paid?",

      invoice: null,

      form: {
        status: "",
        comment: "",
        expected_payment_date: null
      },

      paymentsHistory: [],
      statusHistory: []
    };
  },

  computed: {
    customer() {
      return this.invoice.customer;
    },

    canMakePayment() {
      return Number(this.invoice.balance_due) > 0;
    },

    markPaidDialogTitle() {
      return `Mark invoice ${this.invoice.reference_no} as Paid?`;
    },

    showEditButton() {
      return !this.displayStatusActions && this.hasPermission("change_invoice");
    }
  },

  watch: {},

  mounted() {
    eventHub.listen("InvoiceDetails.open", this.open);
    eventHub.listen("Invoice.UPDATED", this.invoiceUpdated);
  },

  methods: {
    fetchInvoice(invoice_id) {
      this.isLoading = true;

      return api.invoices
        .fetch(invoice_id)
        .then(response => {
          this.open(response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    open(invoice) {
      this.displayPanel = true;
      this.$set(this, "invoice", invoice);

      this.form.status = invoice.status;
      this.form.expected_payment_date = invoice.expected_payment_date;

      this.fetchPayments();
      this.fetchStatusHistory();
    },

    close() {
      this.displayPanel = false;
    },

    editInvoice() {
      this.$router.push({ name: "org-invoices-edit", params: { "id": this.invoice.id } });
    },

    invoiceUpdated(invoice) {
      if (this.invoice && this.invoice.id == invoice.id) {
        this.fetchInvoice(this.invoice.id);
      }
    },

    showStatusActions() {
      // reset invoice status selection everytime before displaying the fields
      this.form.status = this.invoice.status;
      this.form.comment = "";
      this.displayStatusActions = true;
    },

    hideStatusActions() {
      this.displayStatusActions = false;
    },

    updateStatus() {
      this.isLoading = true;

      const form = this.form;
      const status = form.status;
      const payload = {
        id: this.invoice.id,
        status: status,
        comment: form.comment
      };

      api.invoice_receivable
        .update_status(payload)
        .then(response => {
          const data = response.data;

          this.invoice.status = data.status;
          this.hideStatusActions();
          this.displayMessage({ text: "Status updated.", type: "success" });

          eventHub.trigger("Invoice.UPDATED", this.invoice);
        })
        .catch(() => {
          this.displayMessage({ text: "Status update failed.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    payBalanceDue() {
      this.isLoading = true;

      const payload = {
        id: this.invoice.id
      };

      api.invoice_receivable
        .pay_balance_due(payload)
        .then(response => {
          const data = response.data;

          this.invoice.status = data.invoice.status;
          this.invoice.balance_due = data.invoice.balance_due;
          this.displayMessage({ text: "Balance due paid.", type: "success" });
          this.onPaymentsUpdated();
          this.closeMarkPaidDialog();

          eventHub.trigger("Invoice.UPDATED", this.invoice);
        })
        .catch(error => {
          this.displayMessage({ text: "Balance due payment failed.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    downloadPdf() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const pdfUrl = `/invoiceboost/invoices/${this.invoice.id}/pdf/`;
      this.downloadResource(pdfUrl, `invoice-${this.invoice.reference_no}.pdf`).finally(() => {
        this.isLoading = false;
      });
    },

    previewInvoice() {
      // TODO: refactor preview mixin
      const invoice = this.invoice;

      this.previewTitle = `Invoice: ${invoice.reference_no}`;
      this.previewUrl = `/invoiceboost/invoices/${invoice.id}/preview/`;
      this.showPreview();
    },

    fetchPayments() {
      return api.invoices.fetch_payments(this.invoice.id).then(response => {
        this.paymentsHistory = response.data;
      });
    },

    openPaymentForm(payment = null) {
      if (!payment) {
        payment = {
          amount: 0.0,
          date: this.toDjangoDateField(new Date())
        };
      }

      payment.invoice = this.invoice;

      this.$refs.payment_form.open(payment);
    },

    onPaymentsUpdated(invoicePayment) {
      this.fetchPayments();

      if (invoicePayment) {
        let payment_invoices = invoicePayment.payment_invoices || [];

        if (payment_invoices.length) {
          let payment = payment_invoices[0];
          let invoice = payment.invoice;
          this.invoice.status = invoice.status;
          this.invoice.balance_due = invoice.balance_due;
        }
      }

      // Emit global event so that the rest of the system can react to invoice changes.
      eventHub.trigger("Invoice.UPDATED", this.invoice);
    },

    fetchStatusHistory() {
      return api.invoice_receivable.fetch_status_history(this.invoice.id).then(response => {
        this.statusHistory = response.data;
      });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    deleteInvoice() {
      this.deleteDialogObjectName = "Invoice " + this.invoice.reference_no;
      this.displayDeleteDialog = true;
    },

    onConfirmDeleteDialog() {
      this.isLoading = true;

      api.invoices
        .delete(this.invoice.id)
        .then(() => {
          eventHub.trigger("Invoice.DELETED", this.invoice);

          this.displayMessage({ type: "success", text: "The invoice has been deleted successfully." });
          this.close();
        })
        .catch(error => {
          this.displayMessage({ type: "error", text: "There was an error while deleting this invoice." });
        })
        .finally(() => {
          this.displayDeleteDialog = false;
          this.isLoading = false;
        });
    },

    onCancelDeleteDialog() {
      this.displayDeleteDialog = false;
    },

    closeMarkPaidDialog() {
      this.displayMarkPaidDialog = false;
    },

    openMarkPaidDialog() {
      this.displayMarkPaidDialog = true;
    },

    onConfirmMarkPaid() {
      this.payBalanceDue();
    },

    onCancelMarkPaid() {
      this.closeMarkPaidDialog();
    },

    resend_email() {
      const invoice_id = this.invoice.id;

      if (!invoice_id || this.is_sending_email) {
        return;
      }

      this.is_sending_email = true;

      let url = `invoiceboost/invoices/${invoice_id}/send/`;

      this.$http.post(url)
        .then(response => {
          this.displayMessage({ text: "Email Sent", type: "primary" });
        })
        .catch(exception => {
          this.displayMessage({ text: "Email failed to send", type: "error" });
        })
        .finally(() => {
          this.is_sending_email = false;
        });
    },
  }
};
</script>

<style>
.invoice-receivable-details {
  width: 420px;
  height: 85vh;

  position: absolute;
  right: 10px;
}

.invoice-receivable-details ul {
  list-style: none;
}

.invoice-receivable-details ul li {
  display: flex;
  line-height: 2.2;
}

.invoice-receivable-details ul li strong {
  width: 120px;
}

.invoice-receivable-details ul li span {
  position: relative;
}

.invoice-receivable-details ul li span i:first-child {
  position: absolute;
  top: 7px;
  left: -20px;
}

.invoice-receivable-details .status-actions {
  background-color: #eee;
  color: #0c5394;
}

.invoice-receivable-details .status-history ul li strong {
  width: 90px;
}

.invoice-receivable-details .status-history ul li {
  line-height: 1.75;
}

.invoice-receivable-details .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
  margin-bottom: 12px;
}

.invoice-receivable-details .payment-methods .v-expansion-panel-header,
.invoice-receivable-details .status-history .v-expansion-panel-header {
  background-color: #eeeeee;
  margin-bottom: 2px;
  border-radius: 0;
}

.invoice-receivable-details .v-card__title {
  background: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.invoice-receivable-details .v-divider {
  flex-basis: 100%;
  width: 100%;
}

.invoice-receivable-details a.underline {
  text-decoration: underline;
}
</style>
