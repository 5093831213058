<template>
  <v-row v-if="has_errors">
    <v-col cols="12">
      <v-alert type="error" class="non-field-errors">
        <ul :class="{ multiline: errors.length > 1 }">
          <li v-for="(error, index) in errors" :key="index">
            {{ error.message }}
          </li>
        </ul>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";

const defaultErrors = () => {
  return [];
};

export default Vue.extend({
  name: "NonFieldErrors",

  props: { errors: { type: Array, default: defaultErrors } },

  computed: {
    has_errors() {
      return !!(Array.isArray(this.errors) && this.errors.length > 0);
    }
  }
});
</script>

<style>
.non-field-errors ul:not(.multiline) {
  list-style: none;
  padding-left: 0;
}

.non-field-errors ul.multiline li {
  line-height: 1.5;
}
</style>
