<template>
  <!-- PORTAL BRANDING-->
  <v-card elevation="1" outlined tile class="grey lighten-5">
    <v-card-subtitle class="grey--text text--darken-4 font-weight-bold">Premium settings</v-card-subtitle>

    <v-divider></v-divider>

    <v-card-text>
      <v-container class="pa-0 pt-2" fluid>
        <v-row>
          <v-col>
            <v-textarea
              name="PremiumSettings[HelpMessage]"
              label="Message Text"
              background-color="#FFF"
              v-model="form.fields.help_message"
              :error-messages="form.errors.help_message"
              :disabled="is_loading"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" text @click="submit">
              Change Message
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import { ADD_MESSAGE } from "@/store/mutation-types";

function get_fields() {
  return {
    help_message: ""
  };
}

export default {
  name: "PremiumSettings",

  data() {
    return {
      form: {
        fields: get_fields(),
        errors: {}
      },
      is_loading: false
    };
  },

  computed: {
    entity() {
      return this.$store.state.auth.entity;
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.is_loading = true;
      api.premium.settings
        .fetch(this.entity.id)
        .then(response => {
          this.form.fields = response.data;
        })
        .catch(e => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch premium settings" });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    submit() {
      this.is_loading = true;
      api.premium.settings
        .update(this.entity.id, this.form.fields)
        .then(response => {
          this.form.fields = response.data;
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Premium settings updated." });
        })
        .catch(e => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to update premium settings" });
        })
        .finally(() => {
          this.is_loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
