<template>
  <v-card>
    <v-card-title class="display-1 font-weight-thin">{{ pageTitle }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <p>
            Customer:
            <b>{{ customer }}</b>
          </p>
          <p>
            {{ itemName }} #:
            <b>{{ item.reference_no }}</b>
          </p>
        </v-col>
        <v-col cols="6">
          <p>
            Delivery #:
            <b>{{ item.reference_no }}</b>
          </p>
          <p>
            Waiver required:
            <b>{{ waiverRequiredString }}</b>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row no-gutters>
            <v-col cols="3"><p>Line 1</p></v-col>
            <v-col cols="9">
              <p>{{ deliveryAddress.address_line1 }}</p>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row no-gutters>
            <v-col cols="3"><p>Line 2</p></v-col>
            <v-col cols="9">
              <p>{{ deliveryAddress.address_line2 }}</p>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row no-gutters>
            <v-col cols="3"><p>Zip Code</p></v-col>
            <v-col cols="9">
              <p>{{ deliveryAddress.code }}</p>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row no-gutters>
            <v-col cols="3"><p>City</p></v-col>
            <v-col cols="9">
              <p>{{ deliveryAddress.city }}</p>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row no-gutters>
            <v-col cols="3"><p>State</p></v-col>
            <v-col cols="9">
              <p>{{ deliveryAddress.state }}</p>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row no-gutters>
            <v-col cols="3"><p>Comments</p></v-col>
            <v-col cols="9">
              <p>{{ item.comments }}</p>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
        </v-col>
        <v-col cols="6">
          <google-map-loader :map-config="mapConfig" :api-key="getMapsAPIKey()" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import GoogleMapLoader from "@/point-of-sale/components/googleMaps/GoogleMapLoader";
import { ADD_MESSAGE } from "@/store/mutation-types";

function defaultObject() {
  return {
    target_entity: { name: "" },
    delivery_to_quote: [],
    delivery_to_order: []
  };
}
export default {
  name: "DeliveryDetails",

  components: {
    GoogleMapLoader
  },

  // props: {
  // item: { type: Object, default: defaultObject() },
  // delivery: { type: Object, default: null }
  // isQuote: { type: Boolean, required: false }
  // },

  data() {
    return {
      form: {},
      mapConfig: {
        center: { lat: 32.79199, lng: -97.527637 },
        zoom: 12,
        maxZoom: 15,
        minZoom: 3,
        streetViewControl: false
      },
      item: {},
      delivery: {},
      deliveryAddress: {},
      customer: null
    };
  },

  computed: {
    pageTitle() {
      return this.isQuote ? `Quote Delivery Details` : `Order Delivery Details`;
    },

    itemName() {
      return this.isQuote ? `Quote` : `Order`;
    },

    waiverRequiredString() {
      return this.delivery.is_waiver_required ? `Yes` : `No`;
    }
  },

  watch: {
    item: function (newVal, oldVal) {
      this.item = newVal;
      this.customer = this.item.target_entity.name;
    }
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    getMapsAPIKey() {
      return "";
    },

    async fetchItem() {
      this.loading = true;
      try {
        let id = this.$route.params.id;
        await this.$http
          .get(`pos/delivery/${id}/`)
          .then(response => {
            this.item = response.data;
            const [firstAddress] = this.item.address;
            this.deliveryAddress = firstAddress;
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Could not fetch delivery information. Please try again.",
          type: "error"
        });
      }
    }
  }
};
</script>

<style scoped></style>
