import { http } from "@/vuex-bcore";

const url = "invoiceboost/payments";

function purchase(payload) {
  return http.post(`${url}/purchase/`, payload);
}

/*
Directly charge a credit card without storing the details
 */
function direct_cc_charge(payload) {
  return http.post(`${url}/purchase/?provider=direct`, payload);
}

function ach_charge(payload) {
  return http.post(`${url}/ach-charge/`, payload);
}

export default {
  purchase,
  direct_cc_charge,
  ach_charge
};
