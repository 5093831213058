<template>
  <!-- <template>
  </template> -->
  <v-card-title v-if="noWraps" class="display-1 font-weight-thin">{{ pageTitle }}</v-card-title>
  <v-row v-else>
    <v-col>
      <v-card-title class="display-1 font-weight-thin">{{ pageTitle }}</v-card-title>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PageTitle",

  props: {
    noWraps: { type: Boolean, default: false }
  },

  computed: {
    pageTitle() {
      return this.$store.state.theme.pageTitle;
    }
  }
};
</script>
