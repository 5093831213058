<template>
  <responsive-dialog
    ref="dialog"
    content-class="payinvoice-dialog"
    dialog-width="800px"
    :display-dialog="displayDialog"
    :dialog-title="dialogTitle"
    :show-actions="false"
    @closed="close"
  >
    <!-- DISPLAY GENERIC ERRORS -->
    <non-field-errors :errors="transactionErrors"></non-field-errors>

    <template v-if="!displayConfirmation">
      <h4>Payment Methods</h4>
      <!-- PAYMENT TYPE SELECTION -->
      <v-container fluid>
        <v-radio-group class="d-flex justify-space-between" v-model="preferredPaymentType" row>
          <!-- CREDIT CARD OPTION -->
          <v-radio value="credit-card" id="credit-card-option">
            <template v-slot:label>
              <div :class="{ 'success--text': isCreditCardMethodSelected }">
                <v-icon x-large>mdi-credit-card-outline</v-icon>
                <strong>Credit Card</strong>
              </div>
            </template>
          </v-radio>

          <!-- ACH ACCOUNT OPTION -->
          <v-radio value="ach" id="ach-option">
            <template v-slot:label>
              <div :class="{ 'success--text': isACHMethodSelected }">
                <v-icon x-large>mdi-bank-outline</v-icon>
                <strong>ACH Account</strong>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>

      <v-divider />

      <!-- CREDIT CARD SELECTION -->
      <!-- eslint-disable-next-line  -->
      <credit-card-manager
        v-if="isCreditCardMethodSelected && invoices.length > 0 && invoices[0].target_entity"
        :allow-expand="false"
        :allow-selectors="true"
        :supplier-id="invoices[0].target_entity"
        :allow-direct-payment="true"
        @CreditCardSelected="onCreditCardSelected"
      ></credit-card-manager>

      <v-divider />

      <!-- ACH Account Selection -->
      <ach-account-form
        v-if="isACHMethodSelected && invoices.length > 0 && invoices[0].target_entity"
        :allow-expand="false"
        :allow-selectors="true"
        @ACHAccountSelected="onACHAccountSelected"
      />

      <!-- PAYMENT SUMMARY -->
      <v-card v-if="invoices.length > 0" elevation="0" class="payment-summary">
        <v-card-title>Payment Summary</v-card-title>

        <v-card-text>
          <!-- SELECTED PAYMENT METHOD -->
          <v-row dense>
            <v-col cols="3">
              <span class="text--primary font-weight-bold">Payment Method:</span>
            </v-col>

            <v-col v-if="paymentMethod">
              <!-- CC TYPE -->
              <span class="text--primary">{{ paymentMethod.card_type }}</span>
              <!-- SPACER -->
              <span class="text--primary">&nbsp;****&nbsp;</span>
              <!-- CC LAST DIGITS -->
              <span class="text--primary">{{ paymentMethod.card_number }}</span>
            </v-col>

            <v-col v-if="!paymentMethod">
              <span class="text--primary font-weight-bold red--text text--darken-1">Please select one.</span>
            </v-col>
          </v-row>

          <!-- TOTAL DUE -->
          <v-row dense>
            <v-col cols="3">
              <span class="text--primary font-weight-bold">Total:</span>
            </v-col>
            <v-col>
              <span class="text--primary">{{ formatCurrency(invoices_total, invoices_currency) }}</span>
            </v-col>
          </v-row>

          <!-- PAY CUSTOM AMOUNT -->
          <v-row dense v-if="isCustomAmount">
            <v-col cols="3">
              <span class="text--primary font-weight-bold">Custom amount:</span>
            </v-col>
            <v-col>
              <v-text-field v-model.number="customAmount" required class="d-inline-flex"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row dense>
            <v-col class="align-self-center">
              <a v-if="!isCustomAmount && !is_multiple" @click.prevent="displayCustomAmount" href="#" id="display_custom_amount">
                Pay custom amount
              </a>
              <a v-if="isCustomAmount" @click.prevent="displayFullAmount" href="#" id="display_full_amount">
                Pay full amount
              </a>
            </v-col>
            <v-col class="text-right">
              <v-btn
                ref="btn_confirm_payment"
                color="primary"
                :disabled="!paymentMethod || isLoading"
                :loading="isLoading"
                @click="confirmPayment"
              >
                <v-icon left small>mdi-lock</v-icon>
                Pay invoice
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>

    <!-- PAYMENT CONFIRMATION -->
    <v-card v-if="displayConfirmation" elevation="0" class="payment-confirmation">
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <span class="text--primary font-weight-bold">Payment Method:</span>
          </v-col>

          <v-col>
            <span class="text--primary">
              {{ paymentMethod.card_type }}&nbsp;****&nbsp;{{ paymentMethod.card_number }}
            </span>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="3">
            <span class="text--primary font-weight-bold">Status:</span>
          </v-col>
          <v-col>
            <span class="text--primary">{{ confirmation.confirmation_code }}</span>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row dense>
          <v-col cols="3">
            <span class="text--primary font-weight-bold">Total:</span>
          </v-col>
          <v-col>
            <span class="text--primary">{{ formatCurrency(confirmation.total, invoices_currency) }}</span>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="3">
            <span class="text--primary font-weight-bold">Paid:</span>
          </v-col>
          <v-col>
            <span class="text--primary">{{ formatCurrency(confirmation.paid, invoices_currency) }}</span>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="3">
            <span class="text--primary font-weight-bold">Remaining:</span>
          </v-col>
          <v-col>
            <span class="text--primary">{{ formatCurrency(confirmation.remaining, invoices_currency) }}</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row dense>
          <v-col class="text-right">
            <v-btn color="primary" @click="close">
              <v-icon left small>mdi-close</v-icon>
              <!-- just for format -->
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import { ADD_MESSAGE } from "@/store/mutation-types";

import CurrencyFormatMixin from "@/mixins/currencyFormat";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import CreditCardManager from "@/invoiceboost/components/payment-method/CreditCardManager.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import api from "@/api";
import AchAccountForm from "@/invoiceboost/components/payment-method/AchAccountForm";

const PayBillDialog = Vue.extend({
  name: "PayBillDialog",

  components: {
    ResponsiveDialog,
    CreditCardManager,
    AchAccountForm,
    NonFieldErrors
  },

  mixins: [CurrencyFormatMixin],

  props: {},

  data() {
    return {
      displayDialog: false,
      displayConfirmation: false,

      isLoading: false,
      isCustomAmount: false,

      invoices: [],
      paymentMethod: null,
      preferredPaymentType: null, // If you find a better name for this variable, God and I will thank you
      confirmation: null,

      customAmount: 0,

      transactionErrors: []
    };
  },

  computed: {
    dialogTitle() {
      // This might or should change to `Pay invoice for ${companyName}`
      return this.displayConfirmation ? "Payment Confirmation" : "Payment Details";
    },

    invoices_currency() {
      return this.invoices[0].currency;
    },

    settings() {
      return this.$store.state.entitySettings.detail;
    },

    isBill() {
      return this.invoices[0].hasOwnProperty("invoice");
    },

    invoice_ids() {
      const object_key = this.isBill ? "invoice" : "id";
      const reducer = (accumulator, currentValue) => {
        if (currentValue.balance_due > 0) {
          accumulator.push(currentValue[object_key]);
        }

        return accumulator;
      };
      return this.invoices.reduce(reducer, []);
    },

    is_multiple(){
      return this.invoices.length > 1;
    },

    invoices_total() {
      const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.total);
      return this.invoices.reduce(reducer, 0);
    },

    invoices_balance_due() {
      const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.balance_due);
      return this.invoices.reduce(reducer, 0);
    },

    isCreditCardMethodSelected() {
      return this.preferredPaymentType === "credit-card";
    },

    isACHMethodSelected() {
      return this.preferredPaymentType === "ach";
    },

    paymentData() {
      const dataCopy = {
        invoices: this.invoice_ids,
        custom_amount: this.isCustomAmount && this.customAmount ? this.customAmount : null
      };

      if (this.isCreditCardMethodSelected) {
        if (this.paymentMethod.temporary) {
          dataCopy["card_type"] = this.paymentMethod.card_type;
          dataCopy["cardholder_name"] = this.paymentMethod.cardholder_name;
          dataCopy["card_number"] = this.paymentMethod.card_number;
          dataCopy["exp_year"] = this.paymentMethod.exp_year.substr(-2);
          dataCopy["exp_month"] = this.paymentMethod.exp_month;
          dataCopy["cvv"] = this.paymentMethod.cvv;
        } else {
          dataCopy["payment_profile_id"] = this.paymentMethod.id;
        }
      } else if (this.isACHMethodSelected) {
        dataCopy["account_id"] = this.paymentMethod.id;
      }

      return dataCopy;
    },

    endpoint() {
      let endpoint = api.invoice_payment.purchase;

      if (this.isCreditCardMethodSelected) {
        endpoint = this.paymentMethod.temporary ? api.invoice_payment.direct_cc_charge : api.invoice_payment.purchase;
      } else if (this.isACHMethodSelected) {
        endpoint = api.invoice_payment.ach_charge;
      }

      return endpoint;
    }
  },

  watch: {
    preferredPaymentType(newVal, OldVal) {
      this.paymentMethod = null;
    }
  },

  methods: {
    open() {
      this.displayDialog = true;
      return this;
    },

    close() {
      this.displayDialog = false;
      this.resetTransactionErrors();
      this.$emit("closed");
      return this;
    },

    setTransactionErrors(errors) {
      this.transactionErrors = errors || [];
    },

    resetTransactionErrors() {
      this.transactionErrors = [];
    },

    pay(invoices) {
      this.invoices = invoices;
      this.isCustomAmount = false;
      this.displayConfirmation = false;
      this.confirmation = null;

      return this.open();
    },

    confirmPayment() {
      if (!(this.isCreditCardMethodSelected || this.isACHMethodSelected)) {
        this.displayMessage({ text: "You need to choose a payment method.", type: "error" });
        return;
      }

      this.isLoading = true;
      this.resetTransactionErrors();

      this.endpoint(this.paymentData)
        .then(response => {
          const data = response.data;

          this.displayConfirmation = true;

          this.confirmation = {
            confirmation_code: data.status,
            total: Number(this.invoices_total),
            paid: Number(data.amount),
            remaining: Number(this.invoices_balance_due) - Number(data.amount)
          };

          this.$emit("PaymentConfirmed", this.confirmation);
        })
        .catch(error => {
          let response = error.response.data;
          this.setTransactionErrors(response.errors);

          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    displayCustomAmount() {
      this.customAmount = this.invoices_total;
      this.isCustomAmount = true;
      return this;
    },

    displayFullAmount() {
      this.isCustomAmount = false;
      this.customAmount = 0;
      return this;
    },

    // EVENT HANDLERS
    onCreditCardSelected(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },

    onACHAccountSelected(paymentMethod) {
      this.paymentMethod = paymentMethod;
    }
  }
});

export default PayBillDialog;
</script>

<style>
/* So, vuetify dialogs don't like scoped style */

.payinvoice-dialog .creditcard-manager {
  margin-left: -16px;
  margin-right: -16px;
}

.payinvoice-dialog .v-card__actions {
  padding: 16px;
}

.payinvoice-dialog .payment-summary {
  margin-top: 12px;
}

.payinvoice-dialog .payment-summary .v-input {
  margin: 0;
  padding: 0;
}

.payinvoice-dialog .payment-summary .v-card__text .col,
.payinvoice-dialog .payment-confirmation .v-card__text .col {
  margin-top: 8px;
  margin-bottom: 8px;
}

.payinvoice-dialog .payment-confirmation .v-card__text {
  padding-left: 0;
  padding-right: 0;
}

.payinvoice-dialog .payment-confirmation .v-divider {
  margin: 10px 0;
}

.payinvoice-dialog .payment-confirmation .v-card__actions {
  padding-bottom: 0;
}
</style>
