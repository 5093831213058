<template>
  <v-card class="employee-viewer" elevation="0">
    <!-- TITLE -->
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn class="px-0 mr-3 align-center" title="Go back" @click="$router.go(-1)" outlined icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ employee.name }}</span>

      <v-spacer />
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#tab-general">General</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- GENERAL EMPLOYEE INFO FORM -->
        <v-tab-item value="tab-general">
          <v-container class="pa-0 px-4 mt-6 mb-4" fluid>
            <v-row>
              <v-col class="col-2">Name</v-col>
              <v-col>
                <strong>{{ employee.name }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Email</v-col>
              <v-col>
                <strong>{{ employee.email }}</strong>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col class="col-2">Address Line 1</v-col>
              <v-col>
                <strong>{{ employee.address.address_line1 }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Address Line 2</v-col>
              <v-col>
                <strong>{{ employee.address.address_line2 }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">City</v-col>
              <v-col>
                <strong>{{ employee.address.city }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">State</v-col>
              <v-col>
                <strong>{{ employee.address.state }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Zip code</v-col>
              <v-col>
                <strong>{{ employee.address.code }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Country</v-col>
              <v-col>
                <strong>{{ employee.address.country_name }}</strong>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col class="col-2"></v-col>
              <v-col class="">
                <v-btn color="primary" @click.stop="edit_employee" outlined>Edit</v-btn>
              </v-col>
            </v-row>

            <!-- INVITE DETAILS -->
            <v-row v-if="!employee.is_claimed">
              <v-col class="col-12">
                <v-divider />

                <InviteLink :entity="employee" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- PAYMENT METHOD FORMS -->
        <v-tab-item value="tab-payment-methods">
          <div class="pt-10">
            <ach-account-form :employee-id="employee.id" />

            <credit-card-manager :employee-id="employee.target_entity" account="receivable"></credit-card-manager>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <employee-form-dialog ref="employee_editor" @updated="employee_updated" />
  </v-card>
</template>

<script>
import EmployeeFormDialog from "@/invoiceboost/components/employee/EmployeeFormDialog.vue";
import InviteLink from "@/invoiceboost/components/InviteLink.vue";

import api from "@/api";
// import utils from "@/utilities";

function create_model() {
  return {
    id: null,
    name: "",
    email: "",
    is_claimed: false,
    address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      code: "",
      country: 0
    }
  };
}

export default {
  name: "EmployeeDetails",

  components: {
    EmployeeFormDialog,
    InviteLink
  },

  data() {
    return {
      tab: "tab-general",
      employee: create_model()
    };
  },

  computed: {
    employee_id() {
      return this.$route.params.id || null;
    }
  },

  mounted() {
    this.fetch_details(this.employee_id);
  },

  methods: {
    fetch_details(employee_id) {

      api.employees.fetch(employee_id)
        .then(response => {
          this.employee = Object.assign(create_model(), response.data);
        });
    },

    edit_employee() {
      // create a copy of the given data object to prevent live editing of the data displayed on the current list
      const data = Object.assign(create_model(), this.employee);
      this.$refs.employee_editor.open(data);
    },

    employee_updated(employee_data) {
      this.employee = employee_data;
    }
  }
};
</script>

<style>
</style>