import Vue from "vue";
import { formatDistance, parseISO, format, isPast } from "date-fns";

export default Vue.extend({
  methods: {
    getDateDistance(date) {
      const parsedDate = parseISO(date);
      let dateDistance = formatDistance(parsedDate, new Date());
      if (isPast(parsedDate)) {
        dateDistance += " ago";
      }
      return dateDistance;
    },
    formatDate(date) {
      if (date instanceof Date) {
        return format(date, "dd MMM, yyyy");
      }
      return date ? format(parseISO(date), "dd MMM, yyyy") : "";
    },
    toDjangoDateField(date) {
      return format(date, "yyyy-MM-dd");
    }
  }
});
