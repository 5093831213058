<template>
  <v-card class="reset-password-card elevation-5">
      <div class="pa-4">
        <v-card-text>

          <non-field-errors :errors="form.errors" />

          <v-text-field
            v-if="!showMessage"
            v-model="form.fields.username"
            prepend-icon="mdi-account"
            name="username"
            label="Username or Email"
            type="text"
            :error-messages="form.field_errors.username"
          ></v-text-field>

          <template v-if="showMessage">
            <p>We have sent you an email with a link to reset your password.</p>
            <p>
              Didn't receive the email yet? Please check your spam folder, or
              <span class="resend" @click="onResendClick">resend</span>
              the email.
            </p>
          </template>
        </v-card-text>

        <v-card-actions v-if="!showMessage">
          <v-btn to="/login" text color="accent">
            <v-icon left>mdi-arrow-left-bold</v-icon>
            Return to login
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            :disabled="context.is_loading"
            :loading="context.is_loading"
            large
            @click.stop="onClickResetPasswordButton"
          >
            Reset Password
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "ForgotPasswordDesktop",

  components: {
    NonFieldErrors,
  },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {
    };
  },

  computed: {
    form() {
      return this.context.form;
    },

    fields() {
      return this.context.form.fields;
    },

    showMessage() {
      return this.context.active_step === 2;
    }
  },

  methods: {
    onClickResetPasswordButton() {
      this.context.submit_step_one();
    },

    setFormErrors(exception) {
      this.context.set_form_errors(exception);
    },

    onResendClick() {
      this.context.resend();
    }
  }
};
</script>

<style>
.reset-password-card {
  margin-top: 24px;
}

.resend {
  color: var(--v-primary-base);
  text-decoration: underline;
  cursor: pointer;
}
</style>
