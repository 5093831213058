<template>
  <v-card class="org-customers" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="hasPermission('add_customer')"
        title="New Customer"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newCustomer"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder="Search..."
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loadingTable"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        @click:row="view_customer_details"
        disable-sort
        dense
      >
        <template v-slot:item.is_claimed="{ item }">
          <span v-if="item.is_claimed">Yes</span>
          <span v-else>No</span>
        </template>
      </v-data-table>
    </v-card-text>

    <customer-form-dialog ref="customer_editor" @created="customerCreated" />

    <delete-dialog
      :object-name="modelName"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";

import CustomerFormDialog from "@/invoiceboost/components/customer/CustomerFormDialog.vue";

import RelationshipViewMixin from "@/mixins/relationshipView";
import SettingsMixin from "@/mixins/settings";
import PermissionMixin from "@/mixins/permission";

export default {
  name: "Customers",

  components: {
    CustomerFormDialog,
    DeleteDialog
  },

  mixins: [RelationshipViewMixin, SettingsMixin, PermissionMixin],

  props: {
    modelName: { type: String, default: "customer" }
  },

  data() {
    return {
      pageTitle: "Customers"
    };
  },

  computed: {
    headers() {
      let columns = [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Claimed Account",
          value: "is_claimed"
        }
      ];

      if (this.isSuperuser) {
        columns.splice(2, 0, { text: "Owner", value: "owner_entity.name" });
      }

      return columns;
    },
  },
  methods: {
    newCustomer() {
      this.$refs.customer_editor.new();
    },

    view_customer_details(customer) {
      this.$router.push({ name: "org-customer-details", params: { id: customer.id } });
    },

    customerCreated() {
      this.fetchItems();
      this.$refs.customer_editor.close();
    },

    customerPriceOverride(customer) {
      // we want the customer's organization (target_entity) not the customer's id
      this.$router.push({ name: "customer-price-override", params: { id: customer.target_entity } });
    }
  }
};
</script>

<style>
.org-customers .search-field .v-input__slot {
  margin: 0;
}

.org-customers .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-customers table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-customers table thead tr {
  background-color: #eeeeee;
}

.org-customers table thead tr th {
  color: #000 !important;
}
</style>
