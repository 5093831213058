<template>
  <div>
    <div v-if="notifications.length" class="mb-7">
      <v-alert
        v-for="(alert, index) in notifications"
        border="left"
        elevation="2"
        :type="alert.type"
        :key="index"
        colored-border
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="alert.message"></span>
      </v-alert>
    </div>

    <v-data-table
      v-model="proxySelected"
      :headers="headers"
      :items="items"
      :loading="itemsLoading"
      item-key="id"
      item-text="description"
      @input="enterSelect($event)"
      show-select
      class="elevation-1"
      dense
    >
      <template v-slot:item.price="{ item }">
        {{ itemPrice(item) }}
      </template>
      <template v-slot:item.customPrice="{ item }">
        {{ customPrice(item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import currencyFormatMixin from "@/mixins/currencyFormat";
import { ADD_FILTERS } from "@/store/mutation-types";
import { calculateCustomPrice } from "@/utilities/price_override";

export default {
  name: "PriceOverrideItemsForm",

  mixins: [currencyFormatMixin],

  props: { priceOverride: { type: Object, required: true } },

  data() {
    return {
      selected: [],

      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Price", value: "price" },
        { text: "Custom Price", value: "customPrice" }
      ]
    };
  },

  computed: {
    itemsLoading() {
      return !!this.$store.getters["item/loading"]("list");
    },

    items() {
      return this.$store.state.item.list;
    },

    proxySelected: {
      get() {
        return this.priceOverride.items.length ? this.priceOverride.items : [];
      },
      set(newValue) {
        this.selected = newValue;
      }
    },

    notifications() {
      const alerts = [];
      const selected = this.proxySelected.length;

      if (!selected) {
        alerts.push({
          type: "warning",
          message: `<strong>Required</strong>, please select at least one item.`
        });
      }

      return alerts;
    }
  },

  mounted() {
    this.fetchItems();
  },

  beforeDestroy() {
    this.$store.dispatch(`item/clearState`, "list");
  },

  methods: {
    enterSelect() {
      this.$emit("updated", this.selected);
    },

    async fetchItems() {
      this.$store.commit(`item/${ADD_FILTERS}`, { extra_fields: 1 });
      await this.$store.dispatch("item/list");
    },

    customPrice(item) {
      if (!this.isItemSelected(item)) return "";

      let result = calculateCustomPrice(this.priceOverride, item);

      return this.formatCurrency(result);
    },

    isItemSelected(item) {
      return this.getSelectedItemIds().indexOf(item.id) !== -1;
    },

    getSelectedItemIds() {
      return this.selected.map(item => {
        return item.id;
      });
    },

    itemPrice(POItem) {
      let price = Number(POItem.price);
      return this.formatCurrency(price);
    }
  }
};
</script>

<style></style>
