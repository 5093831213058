<template>
  <base-layout>
    <v-card class="welcome-slides" rounded="0">
      <v-card-title class="d-block rounded-0">
        <div class="logo-wrapper">
          <img src="@/assets/accruvia-logo.svg" alt="accruvia-logo" class="logo" />
        </div>

        <div class="text-center mt-6">
          <img v-show="is_slide_one" src="@/assets/welcome-slides/slide-01.png" class="" />
          <img v-show="is_slide_two" src="@/assets/welcome-slides/slide-02.png" class="" style="margin-left: -30px" />
          <img v-show="is_slide_three" src="@/assets/welcome-slides/slide-03.png" class="my-7" />
          <img v-show="is_slide_four" src="@/assets/welcome-slides/slide-04.png" class="" />

          <div class="steps-indicator">
            <v-icon class="step" :class="{ active: is_slide_one }">mdi-circle</v-icon>
            <v-icon class="step" :class="{ active: is_slide_two }">mdi-circle</v-icon>
            <v-icon class="step" :class="{ active: is_slide_three }">mdi-circle</v-icon>
            <v-icon v-show="manage_bills" class="step" :class="{ active: is_slide_four }">mdi-circle</v-icon>
          </div>
        </div>
      </v-card-title>

      <!-- SLIDE 1 -->
      <v-card-text v-show="is_slide_one" class="content black--text px-10 pt-14 pb-10">
        <h4>WELCOME TO ACCRUVIA</h4>
        <p class="mt-6 mb-0">Ready to leave email invoicing in the dust? Let's get started.</p>
      </v-card-text>

      <!-- SLIDE 2 -->
      <v-card-text v-show="is_slide_two && send_invoices" class="content black--text px-10 pt-14 pb-10">
        <h4>PAY BILLS</h4>
        <p class="mt-6 mb-0">
          Manage expenses, approve bills, schedule payments and more with our accounts payable portal.
        </p>
        <strong class="d-block mt-8" style="font-size: 30px">Forever free.</strong>
        <strong class="d-block mt-4" style="font-size: 30px">No strings attached.</strong>
      </v-card-text>

      <v-card-text v-show="is_slide_two && manage_bills" class="content black--text px-10 pt-14 pb-10">
        <h4>MANAGE BILLS</h4>
        <p class="mt-6 mb-0">
          Manage expenses, approve bills, schedule payments and more with our accounts payable portal.
        </p>
        <strong class="d-block mt-8" style="font-size: 20px">
          How do you get subs to send you an invoice on Accruvia?
        </strong>
        <p class="mt-6 mb-0">
          Simply email or text an invitation link to your subcontractor so they can start invoicing you on the cloud.
          They can send up to <b>10 invoices a month for free,</b> or upgrade for unlimited invoicing freedom.
        </p>
      </v-card-text>

      <!-- SLIDE 3 -->
      <v-card-text v-show="is_slide_three && send_invoices" class="content black--text px-10 pt-14 pb-10">
        <h4>SEND INVOICES</h4>
        <p class="mt-6 mb-0">
          Create, send and track invoices all in one place. Send up to 10 invoices a month for free or upgrade to access
          unlimited invoicing freedom.
        </p>
      </v-card-text>

      <v-card-text v-show="is_slide_three && manage_bills" class="content black--text px-10 pt-14 pb-10">
        <h4>CAPTURING INVOICES</h4>
        <p class="mt-6 mb-0">
          When it comes to capturing invoice data, you have two options:
        </p>
        <strong class="d-block mt-8" style="font-size: 20px">
          1. Do it yourself
        </strong>
        <p class="mt-6 mb-0">
          Your team can manually enter invoice data into Accruvia in a few simple steps.
        </p>
        <strong class="d-block mt-8" style="font-size: 20px">
          2. Let us do it for you
        </strong>
        <p class="mt-6 mb-0">
          To save time and money, let us input the data for you for a small fee of $0.50 per invoice.
        </p>
      </v-card-text>

      <!-- SLIDE 4 -->
      <v-card-text v-show="is_slide_four && manage_bills" class="content black--text px-10 pt-14 pb-10">
        <h4>SYNC WITH QUICKBOOKS</h4>
        <p class="mt-6 mb-0">
          Once you're logged in, the first step is to sync your Quickbooks account.
        </p>
        <p class="mt-6 mb-0">
          Once an invoice is approved on the Accruvia Cloud, it will automatically appear in Quickbooks.
        </p>
        <p class="mt-6 mb-0">
          While this step is optional, we highly recommended it in order to keep your accounting process as
          seamless as possible.
        </p>
      </v-card-text>

      <v-card-actions class="d-block actions px-10 pt-0 justify-center">
        <v-btn color="primary" class="continue d-block" @click="next_slide" large>CONTINUE</v-btn>
        <v-btn class="skip d-block mt-5 ml-0" @click="skip_slides" large text>SKIP</v-btn>
      </v-card-actions>
    </v-card>
  </base-layout>
</template>

<script>
import BaseLayout from "@/views/layouts/BaseLayout.vue";

export default {
  name: "WelcomeSlides",

  components: {
    BaseLayout
  },

  data() {
    return {
      slide: 1
    };
  },

  computed: {
    is_slide_one() {
      return this.slide === 1;
    },

    is_slide_two() {
      return this.slide === 2;
    },

    is_slide_three() {
      return this.slide === 3;
    },

    is_slide_four() {
      return this.slide === 4;
    },

    selected_option() {
      let option = this.$route.query.option;
      let selected = option ? Number(option) : 1;
      return selected === 1 || selected === 2 ? selected : 1;
    },

    send_invoices() {
      return this.selected_option === 1;
    },

    manage_bills() {
      return this.selected_option === 2;
    },

    max_number_of_slides() {
      return this.manage_bills ? 4 : 3;
    },
  },

  methods: {
    next_slide() {
      const max = this.max_number_of_slides;

      if ((this.slide + 1) > max) {
        this.$router.push({ name: "home" });
        return;
      }

      this.slide++;
    },

    skip_slides() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>

<style>
.welcome-slides {
  height: 100%;
}

.welcome-slides .logo-wrapper {
  line-height: 0;
  text-align: right;
}

.welcome-slides .logo {
  height: 25px;
}

.welcome-slides .v-card__title {
  background-color: black;
  border-bottom-right-radius: 45px !important;
}

.welcome-slides .steps-indicator .step {
  color: #eee;
  font-size: 12px;
}

.welcome-slides .steps-indicator .step + .step {
  margin-left: 8px;
}

.welcome-slides .steps-indicator .step.active {
  color: #437b56;
}

.welcome-slides .content p {
  font-size: 16px;
  line-height: 2;
}

.welcome-slides .actions button {
  width: 100%;
}
</style>
