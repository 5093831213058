<template>
  <keep-alive>
    <component :is="active_component" :context="context" ref="editor"></component>
  </keep-alive>
</template>

<script>
import InvoiceEditorMobile from "./InvoiceEditorMobile.vue";
import SumColumnMixin from "@/mixins/sumColumn.js";
import viewport from "@/mixins/viewport";
import api from "@/api";
import { parseFormErrors } from "@/utilities/form";
import { ADD_MESSAGE } from "@/store/mutation-types";
import InvoiceEditorDesktop from "@/invoiceboost/components/invoice-editor/InvoiceEditorDesktop";
import debounce from "lodash/debounce";

function new_invoice() {
  return {
    target_entity: null,
    customer: null,
    customer_message: "",
    date: "",
    items: [],
    po_number: "",
    memo: "",
    tax: 0.0,
    tax_title: "",
    tax_percentage: 0.0,
    total: 0.0,
    subtotal: 0.0,
    payment_applied: 0.0
  };
}

function create_model_rules() {
  return {
    customer: [value => !!value || false]
  };
}

export default {
  name: "InvoiceEditor",

  components: {
    InvoiceEditorMobile
  },

  mixins: [viewport, SumColumnMixin],

  props: {},

  data() {
    return {
      form: {
        active_step: 1,

        is_loading: false,
        is_sending_email: false,
        display_date_calendar: false,
        display_due_date_calendar: false,

        display_dialog_error: false,
        display_dialog_send: false,
        display_dialog_success: false,
        display_dialog_save: false,
        display_po_info: false,

        search_customer: "",
        customer_search_results: [],
        email_self: false,
        fields: new_invoice(),
        field_errors: {},
        errors: [],
        rules: create_model_rules()
      }
    };
  },

  computed: {
    settings() {
      return this.$store.state.entitySettings.detail || {};
    },

    currency() {
      const settings = this.settings || {};
      return this.form.currency || settings.default_currency || "";
    },

    context() {
      return this;
    },

    active_component() {
      return this.viewport.is_desktop ? InvoiceEditorDesktop : InvoiceEditorMobile;
    },

    is_update() {
      return !!this.form.fields.id;
    },

    owner_entity() {
      return this.$store.state.auth.entity;
    },

    tax() {
      let calculated_tax;
      let tax_percentage = this.form.tax_percentage;

      if (tax_percentage) {
        calculated_tax = (tax_percentage / 100) * this.subtotal;
      }

      return calculated_tax || Number(this.form.tax) || 0;
    },

    subtotal() {
      return this.sumColumn("total", this.form.fields.items);
    },

    grand_total() {
      return this.subtotal + this.tax;
    },

    page_title() {
      return this.is_update ? `Edit Invoice ${this.form.fields.reference_no}` : "New Invoice";
    },

    is_email_communication_enabled() {
      const settings = this.settings || {};
      const globally_enabled = settings.enable_customers_email_communication;
      const customer = this.form.fields.customer;

      // early exit because global communications state take precedence
      if (!globally_enabled) {
        return false;
      }

      // only enable the send buttons after a customer have been selected
      // this should "only" happen when creating a new invoice
      if (!customer || !customer.email || !customer.enable_email_communication) {
        return false;
      }

      return !!customer.enable_email_communication;
    },

    disable_save_and_send() {
      return !!(
        !this.is_customer_selected ||
        !this.is_email_communication_enabled ||
        !this.has_items ||
        this.form.is_loading
      );
    },

    disable_save() {
      return !!(!this.is_customer_selected || !this.has_items || this.form.is_loading);
    },

    is_customer_selected() {
      return !!this.context.form.fields.target_entity;
    },

    has_items() {
      return this.form.fields.items.length > 0;
    },

    is_loading_settings() {
      return this.$store.getters["entitySettings/loading"]("detail");
    },

    is_loading: {
      get() {
        const action = this.$store.getters["invoice/loading"];

        return (
          this.form.is_loading ||
          action("create") ||
          action("update") ||
          action("detail") ||
          this.form.is_sending_email ||
          this.is_loading_settings
        );
      },
      set(value) {
        this.form.is_loading = value;
      }
    },

    notifications() {
      const alerts = [];
      const customer = this.form.fields.customer;
      const settings = this.settings || {};
      const enable_customers_email_communication = settings.enable_customers_email_communication;

      if (!enable_customers_email_communication) {
        alerts.push({
          type: "info",
          message: `<strong>SAVE & SEND not available</strong>, E-Mail Communications are disabled at the Organization level.`
        });
      }

      if (!customer) {
        alerts.push({
          type: "warning",
          message: `<strong>Customer field is required</strong>, you can't create an invoice without a customer.`
        });
      }

      if (enable_customers_email_communication && customer) {
        if (!customer.enable_email_communication) {
          alerts.push({
            type: "info",
            message: `<strong>SAVE & SEND not available</strong>, E-mail communications are disabled for the selected customer.`
          });
        } else if (!customer.email) {
          alerts.push({
            type: "info",
            message: `<strong>SAVE & SEND not available</strong>, the selected customer doesn't have an e-mail address.`
          });
        }
      }

      return alerts;
    }
  },

  watch: {},

  mounted() {
    if (this.$route.params.id) this.edit_invoice(this.$route.params.id);
  },

  methods: {
    new() {
      this.$router.push({ name: "org-invoices-new" });
      this.form.active_step = 1;
      this.form.customer_search_results = [];
      this.form.search_customer = "";
      this.form.fields = new_invoice();
      this.close_dialogs();
    },

    edit_invoice(id) {
      this.form.is_loading = true;
      return api.invoices
        .fetch(id)
        .then(response => {
          this.form.fields = response.data;
          const customer = this.form.fields.customer;

          this.form.customer_search_results = [
            { id: customer.id, target_entity: this.form.fields.target_entity, name: customer.name }
          ];

          this.$refs.editor.update_form(this.form.fields);
          return Promise.resolve(response);
        })
        .catch(error => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Unable to load this invoice", type: "error" });
          return Promise.reject(error);
        })
        .finally(_ => {
          this.form.is_loading = false;
        });
    },

    next_step() {
      this.form.active_step++;
    },

    confirm_send_invoice() {
      this.form.display_dialog_send = true;
    },

    confirm_save() {
      this.form.display_dialog_save = true;
    },

    display_message(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    send_invoice() {
      this.save_invoice(true);
    },

    close_dialogs() {
      this.form.display_dialog_error = false;
      this.form.display_dialog_send = false;
      this.form.display_dialog_success = false;
      this.form.display_dialog_save = false;
    },

    search_customers: debounce(function search_customers(val) {
      if (this.form.is_loading) {
        return;
      }
      if (val && val !== "") {
        this.form.is_loading = true;
        return api.customers
          .search(this.form.search_customer)
          .then(response => {
            this.form.customer_search_results = response.data.results;
          })
          .finally(() => {
            this.form.is_loading = false;
          });
      }
    }, 500),

    reset_field_errors() {
      this.form.field_errors = {};
    },

    set_field_defaults() {
      this.form.fields.currency = this.currency;
      this.form.fields.owner_entity = this.owner_entity.id;
      this.form.fields.subtotal = this.subtotal;
      this.form.fields.total = this.grand_total;
      this.form.fields.tax_percentage = this.form.fields.tax_percentage || 0.0;
    },

    save_invoice(send_email = false) {
      this.form.is_loading = true;
      this.reset_field_errors();
      this.set_field_defaults();

      const is_update = this.is_update;
      const promise = is_update ? this.send_update() : this.send_create();

      return promise
        .then(response => {
          this.form.fields = response.data;

          if (send_email) {
            this.send_email();
          }

          // TODO: These are specific to the mobile view and could be moved there
          this.close_dialogs();
          this.form.display_dialog_success = true;

          return Promise.resolve(response);
        })
        .catch(error => {
          this.close_dialogs();
          this.form.display_dialog_error = true;
          this.set_field_errors(error.response.data);
          return Promise.reject(error);
        })
        .finally(() => {
          this.form.is_loading = false;
        });
    },

    async send_email() {
      const invoice_id = this.form.fields.id;

      if (!invoice_id) {
        return;
      }

      this.is_sending_email = true;

      let url = `invoiceboost/invoices/${invoice_id}/send/`;

      if (this.form.email_self) {
        url += `?send_copy=yes`;
      }

      try {
        await this.$http.post(url);
        this.display_message({ text: "Email Sent", type: "primary" });
      } catch (error) {
        this.display_message({ text: "Email failed to send", type: "error" });
      }

      this.is_sending_email = false;
    },

    set_field_errors(errors) {
      const field_errors = errors.field_errors || [];
      this.form.field_errors = parseFormErrors(field_errors);
      this.form.errors = errors.errors || [];
    },

    send_create() {
      return api.invoices.create(this.form.fields);
    },

    send_update() {
      return api.invoices.update(this.form.fields);
    },

    toggle_po_info() {
      this.form.display_po_info = !this.form.display_po_info;
    }
  }
};
</script>
