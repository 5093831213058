import { http } from "@/vuex-bcore";

const url = "invoiceboost/currencies";

function fetch() {
  return http.get(`${url}/`);
}

export default {
  fetch
};
