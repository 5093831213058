<template>
  <tr>
    <td class="pt-2 pb-2">
      <v-autocomplete
        name="QuoteItem[Product]"
        label="Product*"
        placeholder="Start typing to Search"
        v-model="formItem.item"
        :items="items"
        :loading="itemsLoading"
        :search-input.sync="itemSearch"
        item-value="id"
        item-text="description"
        @change="onItemChange"
        @keyup="onFilterItem"
        return-object
        hide-no-data
        outlined
        dense
        solo
      ></v-autocomplete>
    </td>

    <td class="align-content-center">
      <span class="text--primary" v-if="formItem.item">{{ getItemPrice(formItem) }}</span>
    </td>

    <td>
      <v-text-field
        v-if="formItem.item"
        v-model="formItem.quantity"
        name="QuoteItem[Quantity]"
        label="Quantity"
        type="number"
        :step="0.01"
        :min="0"
        dense
        solo
      />
    </td>
    <td>
      <span class="text--primary" v-if="formItem.item">{{ formatCurrency(total) }}</span>
    </td>
    <td>
      <v-btn text @click.stop="onFormSubmit" v-if="formItem.item" :disabled="disableAddBtn" color="success">
        <v-icon left>mdi-plus</v-icon>
        Add
      </v-btn>
    </td>
  </tr>
</template>

<script>
import debounce from "lodash/debounce";

import { ADD_FILTERS } from "@/store/mutation-types";
import currencyFormatMixin from "@/mixins/currencyFormat";
import { determineItemPrice } from "@/utilities/price_override";

export default {
  name: "QuoteItemForm",

  mixins: [currencyFormatMixin],

  props: { priceOverride: { type: Object, default: null, required: false } },

  data() {
    return {
      formTitle: "Quote Item",
      isOpen: false,
      formErrors: {},
      formItem: {},
      itemSearch: ""
    };
  },

  computed: {
    itemsLoading() {
      return !!this.$store.getters["item/loading"]("list");
    },

    items() {
      return this.$store.state.item.list;
    },

    total() {
      let formItem = this.formItem;
      let item = formItem.item || {};
      let price = formItem.custom_price || item.price || 0;
      let quantity = formItem.quantity || 0;

      let total = price * quantity;
      return Math.round((total + Number.EPSILON) * 100) / 100;
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.formItem.id;
    },

    formHasErrors() {
      return Object.keys(this.formErrors).length > 0;
    },

    disableAddBtn() {
      return !(!!this.formItem.item && !!this.formItem.quantity);
    }
  },

  mounted() {
    this.fetchItems();
  },

  beforeDestroy() {
    this.$store.dispatch(`item/clearState`, "list");
  },

  methods: {
    resetFormErrors() {
      this.formErrors = {};
    },

    open(item) {
      this.formItem = item;
    },

    async fetchItems() {
      this.$store.commit(`item/${ADD_FILTERS}`, { extra_fields: 1 });
      await this.$store.dispatch("item/list");
    },

    onItemChange(value) {
      this.formItem.item = value;
    },

    onFilterItem: debounce(function () {
      this.$store.commit(`item/${ADD_FILTERS}`, { description__icontains: this.itemSearch });
      this.fetchItems();
    }, 500),

    getItemPrice() {
      this.formItem = determineItemPrice(this.formItem, this.priceOverride);
      return this.formItem.custom_price !== undefined
        ? this.formatCurrency(this.formItem.custom_price)
        : this.formatCurrency(this.formItem.item.price);
    },

    async onFormSubmit() {
      this.formItem.total = this.total;
      this.$emit("updated", this.formItem);
      this.closeForm();
    },

    closeForm() {
      this.isOpen = false;
      this.itemSearch = "";
      this.formItem = {};
      this.$emit("closed");
    }
  }
};
</script>

<style>
td .v-text-field__details {
  display: none;
}
</style>
