import { getActions, getGetters, getMutations, getState } from "@/vuex-bcore";

let actions = getActions(`invoiceboost/entity-settings`);
let state = getState();
const getters = getGetters();
const mutations = getMutations();

const entitySettings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default entitySettings;
