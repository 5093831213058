<template>
  <v-card elevation="0" class="invoice-list">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="context.hasPermission('add_invoice')"
        title="New Invoice"
        color="primary"
        class="px-0 mr-3 align-center"
        :to="{ name: 'org-invoices-new' }"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <span>Invoices</span>
      <v-spacer></v-spacer>
      <v-btn text fab @click="context.displayFilters = !context.displayFilters"><v-icon>mdi-magnify</v-icon></v-btn>
      <v-btn text fab @click="displayFilters"><v-icon>mdi-tune</v-icon></v-btn>
    </v-card-title>

    <v-card-text>
      <!-- INVOICE SEARCH-->
      <div v-if="context.displayFilters" class="d-flex align-center mb-4 mt-2">
        <v-text-field
          ref="invoice_search"
          class="search-field align-self-center mr-4"
          placeholder="Search"
          v-model="context.tableFilters.search"
          autofocus
          outlined
          clearable
          dense
        />
      </div>

      <invoice-filters-mobile ref="invoice_filters" account="receivable" @change="context.updateFilters" />

      <!-- INVOICE LIST -->
      <v-data-iterator
        :loading="context.isLoading"
        :items="context.items"
        :options.sync="context.tableOptions"
        :server-items-length="context.totalItems"
        :footer-props="{
          'items-per-page-options': context.itemsPerPageOptions,
        }"
        dense
        multi-sort
        disable-filtering
      >
        <template v-for="item in context.items">
          <v-list-item :key="item.id" :to="{ name: 'org-invoices-edit', params: { id: item.id } }" class="pl-0">
            <v-list-item-content>
              <v-list-item-title>{{ item.customer.name }} | {{ item.reference_no }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.due_date }} |
                <span>{{ context.getStatusText(item.status) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text :class="{ 'text-h6': true, 'green--text': item.status === 'paid' }">
                {{ context.formatCurrency(item.total, item.currency) }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider :key="`divider-${item.id}`" class="my-4"></v-divider>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>

<script>
import InvoiceFiltersMobile from "@/invoiceboost/components/invoice/InvoiceFiltersMobile.vue";

export default {
  name: "InvoicesMobile",

  components: {
    InvoiceFiltersMobile
  },

  props: {
    context: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {};
  },

  methods: {
    displayFilters() {
      this.$refs.invoice_filters.open();
    },
  }
};
</script>

<style>
.is-mobile .invoice-list > .v-card__title {
  justify-content: space-between;
  flex-grow: 1;
  padding: 8px 8px;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.2;
}

.is-mobile .invoice-list > .v-divider {
  margin: 0 8px;
}

.is-mobile .invoice-list > .v-list-item {
  margin: 0 8px;
}

.is-mobile .invoice-list > .v-card__text {
  padding: 0 8px;
}

.is-mobile .invoice-list > .v-card__actions {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  padding: 10px 36px 10px 56px;
  background-color: #fff;
}
</style>
