import { http } from "@/vuex-bcore";

const url = "invoiceboost/bills";

function fetch_status_history(invoice_id) {
  return http.get(`${url}/${invoice_id}/status/`);
}

function fetch_assignees(invoice_id) {
  return http.get(`${url}/${invoice_id}/assignees/`);
}

function add_assignee(invoice_id, payload) {
  return http.post(`${url}/${invoice_id}/assignees/`, payload);
}

function remove_assignee(invoice_id, assignee_id) {
  return http.delete(`${url}/${invoice_id}/assignees/${assignee_id}/`);
}

/**
EXPECTED PAYLOAD

invoice = {
  id: Number,
  status: String,
  comment: String
};
 */
function update_status(invoice) {
  return http.post(`${url}/${invoice.id}/status/`, invoice);
}

export default {
  fetch_status_history,
  update_status,
  fetch_assignees,
  add_assignee,
  remove_assignee
};
