<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table-row@2" />

    <v-simple-table v-show="!loading" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Description</th>
            <th class="text-left">Price</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Sub Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- ITEMS -->
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.description }}</td>
            <td>{{ formatCurrency(item.price, item.currency) }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ formatCurrency(item.total, item.currency) }}</td>
            <td>
              <v-btn text color="error" @click.stop="onClickDeleteButton(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>

          <!-- ITEMS FORM -->
          <invoice-item-form ref="invoice_item_form" @updated="onItemsUpdated" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import InvoiceItemForm from "@/invoiceboost/components/invoice/InvoiceItemForm.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";

import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";

export default {
  name: "InvoiceItems",

  components: { InvoiceItemForm, DeleteDialog },

  mixins: [sumColumnMixin, currencyFormatMixin],

  props: { loading: { type: Boolean, default: false } },

  data() {
    return {
      items: [],
      invoiceItemFormObject: null,
      invoiceItemFormNew: {
        description: "",
        price: 0.0,
        qty: 0.0
      }
    };
  },

  computed: {
    showTable() {
      return !this.loading && this.items.length > 0;
    },

    grandTotal() {
      return this.sumColumn("total", this.items);
    }
  },

  methods: {
    onItemsUpdated(item) {
      this.items.push(item);
      this.invoiceItemFormObject = null;
      this.$emit("updated", this.items);
    },

    onClickAddItem() {
      this.invoiceItemFormObject = {
        ...this.invoiceItemFormNew
      };
    },

    update(items) {
      this.items = items || [];
    },

    onClickDeleteButton(item) {
      this.items = this.items.reduce((acc, curr) => {
        if (JSON.stringify(curr) !== JSON.stringify(item)) acc.push(curr);
        return acc;
      }, []);

      this.$emit("updated", this.items);
    }
  }
};
</script>
