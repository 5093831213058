import store from "@/store";

import Router from "vue-router";
import { LOADED, SET_PAGE_TITLE } from "@/store/mutation-types";

// Anonymous routes
import NotFound from "@/views/NotFound.vue";
import MainLayout from "@/views/layouts/MainLayout.vue";

import FirstVisit from "@/views/first-visit/FirstVisit.vue";
import Login from "@/views/login/Login.vue";
import Signup from "@/views/signup/Signup.vue";

// import ResetPasswordRequest from "@/views/ResetPasswordRequest.vue";
import ResetPasswordConfirm from "@/views/ResetPasswordConfirm.vue";
import ForgotPassword from "@/views/forgot-password/ForgotPassword.vue";

// Authenticated routes
import baseRoutes from "@/bcore/routes.js";
import invoiceRoutes from "@/invoiceboost/routes.js";
import integrationRoutes from "@/integrations/routes";
import posRoutes from "@/point-of-sale/routes";
import subscriptionRoutes from "@/subscriptions/routes";
import InviteRedemption from "@/bcore/views/InviteRedemption.vue";

import WelcomeSlides from "@/invoiceboost/views/WelcomeSlides.vue";
// import WelcomeSurvey from "@/invoiceboost/views/WelcomeSurvey.vue";

import eventHub from "@/eventHub.js";

let routes = [
  {
    name: "first-visit",
    path: "/language-setup",
    component: FirstVisit,
    meta: { requiresAuth: false }
  },

  {
    name: "welcome-slides",
    path: "/welcome",
    component: WelcomeSlides,
    meta: { requiresAuth: true }
  },

  /*
  {
    name: "welcome-survey",
    path: "/welcome-survey",
    component: WelcomeSurvey,
    meta: { requiresAuth: true }
  },
   */

  {
    name: "main-layout",
    path: "/",
    component: MainLayout,

    beforeEnter(to, from, next) {
      // Direct user to the correct landing page

      if (to.path === "/") {
        let route = { name: "org-invoices" };
        let default_organization = store.state.auth.entity;
        let landing_page = default_organization ? default_organization.landing_page : "";

        if (landing_page) {
          route = { name: landing_page };
        } else if (store.getters["auth/hasEntityPermission"]("invoiceboost.view_accounts_payable")) {
          route = { name: "org-bills" };
        }
        else {
          next({ name: "home" });
        }
        next(route);
      }

      next();
    },

    children: [].concat(baseRoutes, invoiceRoutes, integrationRoutes, posRoutes, subscriptionRoutes)
  },

  {
    name: "login",
    path: "/login",
    component: Login,
    meta: { guestOnly: true }
  },

  {
    name: "logout",
    path: "/logout",
    beforeEnter(to, from, next) {
      eventHub.trigger("User.logout");
      next({ name: "login" });

      // app.user.logout().then(() => {});
    }
  },

  {
    name: "signup",
    path: "/signup",
    component: Signup,
    meta: { guestOnly: true }
  },

  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
    meta: { guestOnly: true }
  },

  {
    name: "reset-password-confirm",
    path: "/reset-password-confirm",
    component: ResetPasswordConfirm,
    meta: { guestOnly: true, pageTitle: "Reset Password" }
  },

  {
    name: "invite-redemption",
    path: "/invite-redemption",
    component: InviteRedemption,
    meta: { pageTitle: "Redeem invite" }
  },

  {
    path: "*",
    name: "not-found",
    component: NotFound
  }
];

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: routes
});

/**
 * Handle router events
 */
router.beforeEach((to, from, next) => {
  // TODO: Find away to close sticky menu between views when screen is smaller than a certain size
  // Close the navigation drawer between routes
  // eventHub.trigger("MainMenu.collapse");

  const appName = store.state.theme.customDomain.organization;

  // Set Page title
  to.matched.some(record => {
    if (record.meta.pageTitle) {
      document.title = `${record.meta.pageTitle} | ${appName}`;
      store.commit(`theme/${SET_PAGE_TITLE}`, record.meta.pageTitle);
    } else {
      document.title = `${appName}`;
      store.commit(`theme/${SET_PAGE_TITLE}`, "");
    }
  });

  // Cancel page loading state
  store.commit(`theme/${LOADED}`);

  // Handle route authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["auth/loggedIn"]) {
      next();
    } else {
      next({
        name: "login",
        params: { nextUrl: to.fullPath }
      });
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (store.getters["auth/loggedIn"]) {
      next({ name: "main-layout" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
