export default {
  data() {
    return {
      syncStatuses: {
        synced: { text: "Synced", color: "green" },
        partially_synced: { text: "Partially Synced", color: "amber" },
        not_synced: { text: "Sync Pending", color: "red lighten-1" }
      }
    };
  },
  methods: {
    getInvoiceSyncStatusColor(invoice) {
      let status = this.syncStatuses[invoice.sync_status];
      return status ? status.color : "grey";
    },
    getInvoiceSyncStatusText(invoice) {
      let status = this.syncStatuses[invoice.sync_status];
      return status ? status.text : "";
    }
  }
};
