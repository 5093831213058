const priceOverrideTypeMixin = {
  data() {
    const typeItems = {
      per_item: { text: "Per Item", color: "blue" },
      fixed_percentage: { text: "Fixed Percentage", color: "blue" }
    };

    return {
      typeItems: typeItems
    };
  },

  methods: {
    getTypeText(type) {
      return type !== undefined ? this.typeItems[type].text : "";
    }
  }
};

export default priceOverrideTypeMixin;
