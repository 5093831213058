<template>
  <responsive-dialog :display-dialog="displayDialog" :dialog-title="dialogTitle" @closed="close">
    <v-card-text>
      <form>
        <v-row>
          <v-col cols="3">
            <h4>Bills</h4>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.change_accounts_payable"
              label="Change Invoice Status"
              hide-details
            ></v-checkbox>

            <h4 class="mt-8">Payments</h4>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.make_payment"
              label="Make payments"
              hide-details
            ></v-checkbox>
          </v-col>

          <!-- ACCOUNTS RECEIVABLE -->
          <v-col cols="3" v-if="!has_premium_customer_restrictions">
            <h4>Invoices</h4>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.view_invoice"
              label="View"
              :disabled="disableInputViewInvoice"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.add_invoice"
              label="Add"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.change_invoice"
              label="Change"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.delete_invoice"
              label="Delete"
              hide-details
            ></v-checkbox>
          </v-col>

          <!-- CUSTOMERS -->
          <v-col cols="3" v-if="!has_premium_customer_restrictions">
            <h4>Customers</h4>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.view_customer"
              label="View"
              hide-details
              :disabled="disableInputViewCustomer"
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.add_customer"
              label="Add"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.change_customer"
              label="Change"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.delete_customer"
              label="Delete"
              hide-details
            ></v-checkbox>
          </v-col>

          <!-- SUPPLIERS -->
          <v-col cols="3" v-if="!has_premium_customer_restrictions">
            <h4>Suppliers</h4>
            <v-checkbox
              v-model="form.permissions"
              label="View"
              hide-details
              value="invoiceboost.view_supplier"
              :disabled="disableInputViewSupplier"
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              label="Add"
              hide-details
              value="invoiceboost.add_supplier"
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.change_supplier"
              label="Change"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.permissions"
              value="invoiceboost.delete_supplier"
              label="Delete"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn text tile color="primary" x-large @click="sendUpdate">Save</v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import { ADD_MESSAGE } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";

const defaultObject = () => {
  return {};
};

export default Vue.extend({
  name: "EmployeePermissions",

  components: { ResponsiveDialog },

  props: {
    editable: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      displayDialog: false,
      isClaimed: false,

      disableViewInvoice: false,

      dialogTitle: "Employee Permissions",

      formCleaned: {
        permissions: []
      },

      form: {
        permissions: []
      },

      employee: {}
    };
  },

  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    idIsSet() {
      return !!(this.employee && this.employee.id);
    },

    url() {
      if (this.isClaimed) {
        return `invoiceboost/employees/${this.employee.id}/entity-permissions/`;
      }

      return `invoiceboost/employees/${this.employee.id}/unclaimed-entity-permissions/`;
    },

    disableInputViewInvoice() {
      return /(add|change|delete)_invoice/.test(this.form.permissions.join(","));
    },

    disableInputViewCustomer() {
      return /(add|change|delete)_customer/.test(this.form.permissions.join(","));
    },

    disableInputViewSupplier() {
      return /(add|change|delete)_supplier/.test(this.form.permissions.join(","));
    },

    has_premium_customer_restrictions() {
      return this.$store.state.auth.entity.has_premium_customer_restrictions;
    },
  },

  watch: {
    "form.permissions": {
      handler(value, old) {
        const permissions = value;
        const stringified = permissions.join(",");
        const modules = ["invoice", "customer", "supplier"];

        for (const module of modules) {
          // Account Receivable
          const actions = new RegExp(`(add|change|delete)_${module}`, "ig");
          const viewPermission = `invoiceboost.view_${module}`;

          // Check if any invoice actions exist on the array and add the view permission if it is not added already
          if (actions.test(stringified) && !permissions.includes(viewPermission)) {
            permissions.push(viewPermission);
          }
        }
      },

      deep: true
    }
  },

  methods: {
    open(employee, isClaimed) {
      this.displayDialog = true;
      this.employee = employee;
      this.isClaimed = isClaimed;
      if (this.idIsSet) {
        this.fetchItems();
      }
      return this;
    },

    close() {
      this.displayDialog = false;
      this.employee = {};
      this.form = { ...this.formCleaned };
      return this;
    },

    async fetchItems() {
      this.loading = true;

      try {
        const response = await this.$http.get(this.url);
        this.form.permissions = response.data;
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch permissions." });
      }

      this.loading = false;
      return;
    },

    async sendUpdate() {
      try {
        await this.$http.put(this.url, this.form);
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Permissions saved", type: "success" });
        this.close();
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "There was an error saving your settings", type: "error" });
      }
    }
  }
});
</script>
