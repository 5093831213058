var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ticket-list p-0",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"display-1 font-weight-thin justify-space-between"},[(true)?_c('v-btn',{staticClass:"px-0 mr-3 align-center",attrs:{"title":"New Ticket","color":"primary","outlined":"","icon":""},on:{"click":_vm.newTicket}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('span',[_vm._v("Tickets")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"search-field align-self-center mr-4",attrs:{"placeholder":"Customer, Order #, Etc.","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.tableFilters.search),callback:function ($$v) {_vm.$set(_vm.tableFilters, "search", $$v)},expression:"tableFilters.search"}})],1)],1),_c('v-card-text',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"light":""}},[(_vm.selection.length > 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clearSelection}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.selection.length > 0 ? ((_vm.selection.length) + " selected") : 'Filter by')+" ")]),_c('v-spacer'),_c('v-slide-y-transition',[(!_vm.selection.length)?_c('v-select',{key:"filter-status",attrs:{"items":_vm.statuses,"placeholder":"Status","clear-icon":"mdi-close","dense":""}}):_vm._e()],1),_c('v-slide-y-transition',[(_vm.selection.length > 0)?_c('v-btn',{key:"dispatch",attrs:{"color":"primary","large":""},on:{"click":_vm.addDispatch}},[_vm._v(" Dispatch "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-dump-truck")])],1):_vm._e()],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loadingTable,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
      },"options":_vm.tableOptions,"server-items-length":_vm.metaData.count,"dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.selection",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!item.requires_delivery,"dense":""},model:{value:(item.selection),callback:function ($$v) {_vm.$set(item, "selection", $$v)},expression:"item.selection"}})]}},{key:"item.delivery_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.delivery_date)))]}},{key:"item.date_placed",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.date_placed)))]}},{key:"item.requires_delivery",fn:function(ref){
      var item = ref.item;
return [(item.requires_delivery)?_c('v-icon',[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{key:"action-dispatch",attrs:{"color":"primary","disabled":_vm.disableDispatchButton(item),"small":""},on:{"click":_vm.addDispatch}},[_vm._v(" Dispatch ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }