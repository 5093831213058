<template>
  <v-select
    v-model="selected"
    :label="label"
    :name="name"
    :loading="isLoading"
    :items="items"
    :messages="messages"
    :error-messages="errorMessages"
    :disabled="disabled"
    :background-color="backgroundColor"
    @change="onChange"
    outlined
  />
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import api from "@/api";

function defaultArray() {
  return [];
}

export default {
  name: "CurrencySelect",

  props: {
    name: { type: String, default: "" },
    label: { type: String, default: "Currency" },
    backgroundColor: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    initialValue: { type: String, default: "" },
    messages: { type: String, default: "" },
    errorMessages: { type: String, required: false, default: "" }
  },

  data() {
    return {
      items: [],
      selected: "",
      isLoading: false
    };
  },

  watch: {
    initialValue(value) {
      this.selected = value;
    }
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      return api.currencies
        .fetch()
        .then(response => {
          this.items = response.data;
          this.selected = this.initialValue;
        })
        .catch(error => {
          this.displayMessage({ text: "Could not get currencies.", type: "Error" });
        });
    },

    onChange() {
      this.$emit("change", this.selected);
    },

    select(value) {
      this.selected = value;
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>
