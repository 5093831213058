import api from "@/api";
import store from "@/store";
import router from "@/router.js";

import { parseISO, differenceInSeconds } from "date-fns";

import {
  SET_USER,
  SET_ENTITY,
  SET_DETAIL,
  SET_ENTITY_PERMISSIONS,
  SET_ROUTER_ENTITY,
} from "@/store/mutation-types.js";

import eventHub from "@/eventHub.js";

// replacing
// src\vuex-bcore\utils.js
// src\vuex-bcore\plugin.js
window.addEventListener("User.logout", logout);
eventHub.listen("User.logout", logout);

let expiring_session_interval_id;

function login(credentials) {
  return api.users.login(credentials).then(detect);
}

function detect() {
  store.commit(`auth/${SET_USER}`, { anonymous: true });

  return api.users
    .whoami()
    .then(async response => {
      let user = response.data;

      if (user.anonymous) {
        return Promise.reject(user);
      }

      if (user.is_superuser) {
        return logout().then(() => {
          return Promise.reject(user);
        });
      }

      store.commit(`auth/${SET_USER}`, user);

      //  this section might need a try catch
      let default_organization = await get_default_organization(user.user_id);
      set_active_organization(default_organization);

      let organization_settings = await get_organization_settings(default_organization.id);
      let user_permissions = await get_user_permissions();

      store.commit(`entitySettings/${SET_DETAIL}`, organization_settings);
      store.commit(`routerEntity/${SET_ROUTER_ENTITY}`, default_organization);
      store.commit(`auth/${SET_ENTITY_PERMISSIONS}`, user_permissions);

      // initialize auto logout strategies
      setup_auto_logout();

      return { user, default_organization, organization_settings, user_permissions };
    });
}

function logout() {
  return api.users
    .logout()
    .then(response => {
      set_active_organization(null);

      store.commit(`entitySettings/${SET_DETAIL}`, null);
      store.commit(`routerEntity/${SET_ROUTER_ENTITY}`, null);

      store.commit(`auth/${SET_USER}`, { anonymous: true });
      store.commit(`auth/${SET_ENTITY_PERMISSIONS}`, null);

      eventHub.trigger("User.SIGNEDOUT");
    })
    .catch(exception => {
      // silence unhandled promise exception
      return Promise.reject(exception);
    });
}

function setup_auto_logout() {
  let timeout = process.env.VUE_APP_LOGOUT_INTERVAL * 1000; // seconds to milliseconds

  // Automatically logout an expiring session
  clearInterval(expiring_session_interval_id);
  expiring_session_interval_id = window.setInterval(logout_expiring_session, timeout); // 30 seconds
}

function logout_expiring_session() {
  let session_expiry = store.state.auth.user.session_expiry_date;

  if (session_expiry) {
    let time_difference = differenceInSeconds(parseISO(session_expiry), new Date());

    if (time_difference < 30) {
      clearInterval(expiring_session_interval_id);

      logout().then(() => {
        if (router.currentRoute.meta.requiresAuth) {
          router.push({ name: "login" });

          // router.push({
          //   name: "login",
          //   params: {
          //     error: { code: "session_expired", message: "Your session has expired." }
          //   }
          // });
        }
      });
    }
  }
}

async function get_default_organization(user_id) {
  return api.users
    .fetch_organizations(user_id)
    .then(response => {
      let organizations = response.data;

      if (organizations.length) {
        let default_organization = organizations.find(org => org.is_default) || organizations[0];
        return default_organization;
      }

      // return Promise.reject({ error: "You have no active organizations." });
      return null;
    });
}

async function get_organization_settings(organization_id) {
  return api.organization_settings.fetch(organization_id)
    .then(response => {
      return response.data;
    });
  // .catch(exception => {
  //   return Promise.reject({
  //     error: "Unable to fetch organization settings.",
  //     method: "get_organization_settings",
  //     exception
  //   });
  // });
}

async function get_user_permissions() {
  return api.users.fetch_permissions().then(response => {
    return response.data;
  });
}

function set_active_organization(organization) {
  set_store_entity(organization);
  store.commit(`auth/${SET_ENTITY}`, organization);

  // set_entity(organization);
  // store.commit(`entitySettings/${SET_DETAIL}`, organization_settings);
  // store.commit(`routerEntity/${SET_ROUTER_ENTITY}`, organization);
  // store.commit(`auth/${SET_ENTITY_PERMISSIONS}`, user_permissions);

  eventHub.trigger("Organization.CHANGED", organization);
}

function set_store_entity(entity) {
  let data = entity || null;
  return localStorage.setItem("entity", JSON.stringify(data));
}

function get_store_entity() {
  let entity = localStorage.getItem("entity");

  if (!entity || entity == "null") {
    return null;
  }

  return JSON.parse(entity);
}

export default {
  detect,
  login,
  logout,
  get_store_entity,
};