const invoiceStatusMixin = {
  data() {
    const statusItems = {
      //TODO: This should be obtained from the API
      pending: { text: "Pending", color: "blue", icon: "mdi-timer-sand" },
      payment_sent: { text: "Payment Sent", color: "blue", icon: "mdi-cash-check" },
      paid: { text: "Paid", color: "green", icon: "mdi-cash-100" },
      partially_paid: { text: "Partially Paid", color: "orange", icon: "mdi-cash" },
      approved: { text: "Approved", color: "blue", icon: "mdi-check-circle-outline" },
      disputed: { text: "Disputed", color: "red", icon: "mdi-gavel" },
      null: { text: "Pending", color: "blue", icon: "mdi-timer-sand" }, // TODO: A hack to fix null API responses
      undefined: { text: "Pending", color: "blue", icon: "mdi-timer-sand" }, // TODO: A hack to fix null API responses
    };

    return {
      statusItems: statusItems
    };
  },

  computed: {
    allStatusOptions() {
      const items = this.statusItems;
      const options = [];

      for (let key in items) {
        options.push({
          value: key,
          text: items[key].text
        });
      }

      return options;
    }
  },

  methods: {
    getStatusText(status) {
      return this.statusItems[status].text;
    },

    getStatusColor(status) {
      return this.statusItems[status].color;
    },

    getStatusIcon(status) {
      return this.statusItems[status].icon;
    }
  }
};

export default invoiceStatusMixin;
