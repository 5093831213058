<template>
  <v-card class="org-invoices" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
          v-if="context.hasPermission('add_invoice')"
          title="New Invoice"
          color="primary"
          class="px-0 mr-3 align-center"
          :to="{name: 'org-invoices-new'}"
          outlined
          icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>Invoices</span>

      <v-spacer/>

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
            class="search-field align-self-center mr-4"
            placeholder="customer, Invoice #, Etc."
            prepend-inner-icon="mdi-magnify"
            v-model="context.tableFilters.search"
            outlined
            dense
        />

        <v-btn
            title="Filters"
            color="secondary"
            class="px-0 align-center"
            @click="toggleFiltersDisplay"
            outlined
            icon
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <invoice-filters
        ref="invoice_filters"
        class="justify-center ma-4"
        account="receivable"
        @change="context.updateFilters"
        @closed="context.displayFilters = false"
    />

    <v-card-text>
      <v-data-table
          :loading="context.isLoading"
          :headers="context.headers"
          :items="context.items"
          :options.sync="context.tableOptions"
          :server-items-length="context.totalItems"
          :footer-props="{
          'items-per-page-options': context.itemsPerPageOptions
        }"
          @click:row="context.displayDetails"
          dense
          multi-sort
          disable-filtering
      >
        <!-- COLUMN: CUSTOMER_NAME -->
        <template v-slot:item.customer_name="{ item }">
          {{ item.customer.name }}
        </template>

        <!-- COLUMN: REFERENCE_NO -->
        <template v-slot:item.reference_no="{ item }">
          <v-badge
              v-if="item.sync_status"
              :title="context.getInvoiceSyncStatusText(item)"
              :color="context.getInvoiceSyncStatusColor(item)"
              inline
              left
              dot
          ></v-badge>
          {{ item.reference_no }}
        </template>

        <!-- COLUMN: BALANCE DUE -->
        <template v-slot:item.balance_due="{ item }">
          {{ context.formatCurrency(item.balance_due, item.currency) }}
        </template>

        <!-- COLUMN: STATUS -->
        <template v-slot:item.status="{ item }">
          {{ context.getStatusText(item.status) }}
        </template>

        <!-- COLUMN: CREATED DATE -->
        <template v-slot:item.created_at="{ item }">{{ context.formatDate(item.created_at) }}</template>

        <!-- COLUMN: MODIFIED DATE -->
        <template v-slot:item.modified_at="{ item }">{{ context.formatDate(item.modified_at) }}</template>

        <template v-slot:item.actions="{ item }">
          <span class="" @click.prevent="">
            <v-icon left>mdi-page-next-outline</v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import InvoiceFilters from "@/invoiceboost/components/invoice/InvoiceFilters.vue";

const modelName = "invoice";
const settingsModelName = "entitySettings";

export default {
  name: "InvoicesDesktop",

  components: {
    InvoiceFilters
  },

  props: {
    context: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {};
  },

  methods: {
    showFilters() {
      this.context.displayFilters = true;
      this.$refs.invoice_filters.open();
    },

    hideFilters() {
      this.context.displayFilters = false;
      this.$refs.invoice_filters.close();
    },

    toggleFiltersDisplay() {
      if (this.context.displayFilters) {
        this.hideFilters();
      } else {
        this.showFilters();
      }
    }
  }
};
</script>

<style>
.org-invoices .search-field .v-input__slot {
  margin: 0;
}

.org-invoices .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-invoices table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-invoices table thead tr {
  background-color: #eeeeee;
}

.org-invoices table thead tr th {
  color: #000 !important;
}
</style>
