<template>
  <responsive-dialog
      ref="dialog"
      dialog-width="500px"
      :display-dialog="displayDialog"
      :dialog-title="dialogTitle"
      @closed="close"
  >
    <!-- MAIN CONTENT -->
    <template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-form v-model="form.isValid" ref="form" @submit.prevent="submitForm">

              <!-- DISPLAY GENERIC ERRORS -->
              <non-field-errors :errors="form.errors"></non-field-errors>

              <v-row no-gutters>
                <v-col cols="12">

                  <!-- FIELD: DESCRIPTION -->
                  <v-text-field
                      label="Account Description*"
                      v-model="form.data.description"
                      :rules="form.rules.description"
                      :error-messages="form.field_errors.description"
                      required
                      outlined
                  />

                  <!-- FIELD: NAME -->
                  <v-text-field
                      label="Name*"
                      v-model="form.data.name"
                      :rules="form.rules.name"
                      :error-messages="form.field_errors.name"
                      required
                      outlined
                  />

                  <!-- FIELD: ACCOUNT NUMBER -->
                  <v-text-field
                      label="Account number*"
                      v-model="form.data.account_number"
                      :rules="form.rules.account_number"
                      :error-messages="form.field_errors.account_number"
                      required
                      outlined
                  />

                  <!-- FIELD: ROUTING NUMBER -->
                  <v-text-field
                      label="Routing number*"
                      v-model="form.data.routing_number"
                      :rules="form.rules.routing_number"
                      :error-messages="form.field_errors.routing_number"
                      required
                      outlined
                  />

                  <!-- FIELD: ACCOUNT TYPE -->
                  <v-select
                      label="Account Type*"
                      v-model="form.data.account_type"
                      :items="['CHECKING', 'SAVINGS']"
                      :error-messages="form.field_errors.account_type"
                      outlined
                  />

                </v-col>
              </v-row>

            </v-form>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <v-spacer/>
      <v-btn
          :loading="isLoading"
          :disabled="isLoading || !isDirty"
          type="button"
          color="primary"
          text
          @click.prevent="submitForm"
      >
        <v-icon>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import {ADD_MESSAGE} from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import environmentMixin from "@/mixins/environment";
import utils from "@/utilities";


export default Vue.extend({
  name: "AddAchAccountDialog",

  components: {
    ResponsiveDialog,
    NonFieldErrors
  },

  mixins: [trackDirtyFieldsMixin, environmentMixin],

  props: {
    customerId: {type: Number, default: 0},
  },

  data() {
    return {
      displayDialog: false,
      isLoading: false,
      isDirty: false,
      storeCardInfo: false,

      form: {
        isValid: false,
        initialData: {},
        data: {},
        rules: {
          description: [value => !!value || "Description is required."],
          name: [value => !!value || "Name is required."],
          account_number: [value => !!value || "Account Number is required."],
          routing_number: [value => !!value || "Routing Number is required."],
          account_type: [value => !!value || "Account Type is required."],
        },
        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    isUpdate() {
      return !!this.form.data.id;
    },

    dialogTitle() {
      return this.isUpdate ? "Update ACH Account" : "New ACH Account";
    },

    baseListUrl() {
      // if customerId is not defined, load current user Accounts.
      return !this.customerId ? "payment-profiles/ach-accounts/" : "payment-profiles/ach-accounts/list-customer-accounts/";
    },

    baseUpdateUrl() {
      // if customerId is not defined, update current user account.
      return !this.customerId ? "payment-profiles/ach-accounts/" : "payment-profiles/ach-accounts/update-customer-account/";
    },

    baseCreateUrl() {
      // if customerId is not defined, create current user account.
      return !this.customerId ? "payment-profiles/ach-accounts/" : "payment-profiles/ach-accounts/create-customer-account/";
    },

    formHasErrors() {
      return Object.keys(this.form.errors).length;
    },

    settings() {
      return this.$store.state.entitySettings.detail;
    },

  },

  methods: {
    new() {
      this.resetForm();
      Object.assign(this.form.initialData, {});
      Object.assign(this.form.data, {});
      return this.open();
    },

    edit(account) {
      this.resetForm();
      Object.assign(this.form.initialData, account);
      Object.assign(this.form.data, account);
      return this.open();
    },

    open() {
      this.resetValidation();
      this.trackDirtyFields();
      this.displayDialog = true;
      return this;
    },

    close() {
      this.displayDialog = false;
      this.$emit("closed");
      this.resetValidation();
      this.resetForm();
      return this;
    },

    submitForm() {
      const isUpdate = this.isUpdate;

      const action = isUpdate ? "updated" : "added";
      const promise = isUpdate ? this.updateACHAccount() : this.registerACHAccount();
      const eventName = isUpdate ? "ACHAccountUpdated" : "ACHAccountAdded";

      this.isLoading = true;
      this.resetValidation();

      promise
          .then(response => {
            const creditCard = response.data;
            Object.assign(this.form.initialData, {}, creditCard);
            Object.assign(this.form.data, {}, creditCard);

            this.displayMessage({text: `Your Payment Method have been ${action} successfully.`, type: "success"});
            this.$emit(eventName, Object.assign({}, creditCard));
            this.close();
          })
          .catch((ex) => {
            let field_errors = utils.form.get_field_errors(ex);
            let errors = utils.form.get_errors(ex);

            if (utils.is_empty(field_errors) && utils.is_empty(errors)) {
              this.displayMessage({
                text: "There was an error performing this action, if this persist please contact us.",
                type: "error"
              });
            }

            this.form.field_errors = field_errors;
            this.form.errors = errors;

          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    prepRegisterACHAccount() {
      const dataCopy = Object.assign({}, this.form.data);

      if (this.customerId) {
        dataCopy.customer_id = this.customerId;
      }
      return dataCopy;
    },

    registerACHAccount() {
      const payload = this.prepRegisterACHAccount();

      return this.$http.post(this.baseCreateUrl, payload);
    },

    updateACHAccount() {
      const url = `${this.baseUpdateUrl}?account_id=${this.form.data.id}`;
      const data = this.form.data;
      const dataCopy = {
        customer_id: this.customerId,
        account_number: data.account_number,
        routing_number: data.routing_number,
      };

      return this.$http.post(url, dataCopy);
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    resetValidation() {
      this.form.field_errors = {};
      this.form.errors = [];

      let $form = this.$refs.form;

      if ($form) {
        $form.resetValidation();
      }

      return this;
    },

    resetForm() {
      this.form.initialData = {};
      this.form.data = {};
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField("form.data.description", "form.initialData.description");
      this.trackDirtyField("form.data.name", "form.initialData.name");
      this.trackDirtyField("form.data.account_number", "form.initialData.account_number");
      this.trackDirtyField("form.data.routing_number", "form.initialData.routing_number");
      this.trackDirtyField("form.data.account_type", "form.initialData.account_type");
    },

  }
});

</script>

