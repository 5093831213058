// @ts-ignore

import { resolve_namespace, is_equal } from "../utilities";

const trackDirtyFields = {
  data() {
    return {
      isDirty: false,
      dirtyFields: new Set()
    };
  },

  mounted() {
    const trackDirtyFields = this.trackDirtyFields;

    if (typeof trackDirtyFields === "function") {
      trackDirtyFields();
    }
  },

  methods: {
    trackDirtyField(field, reference) {
      const dirtyFields = this.dirtyFields;

      const dirtyFieldHandler = () => {
        const initialValue = resolve_namespace(this, reference) || "";
        const newValue = resolve_namespace(this, field) || "";

        if (initialValue === newValue || is_equal(initialValue, newValue)) {
          dirtyFields.delete(field);
        } else {
          dirtyFields.add(field);
        }

        this.isDirty = this.dirtyFields.size > 0;

        // CLEANUP:
        // console.log( 'dirtyFields', dirtyFields );
        // console.log( 'dirtyFields.size', dirtyFields.size );
        // console.log( '.' );

        // console.log( 'isDirty', this.isDirty );
        // console.log( '.' );
        // console.log( 'field', field );
        // console.log( 'initialValue', initialValue );
        // console.log( 'newValue', newValue );
        // console.log( '.' );
      };

      this.watchFieldChange(field, dirtyFieldHandler);

      // Track the reference field as well since initial values can be set to match the new value and the field is no longer dirty
      this.watchFieldChange(reference, dirtyFieldHandler);
    },

    watchFieldChange(field, handler) {
      const trigger = () => {
        return resolve_namespace(this, field);
      };

      const callback = (newValue, oldValue) => {
        handler(field, newValue, oldValue);
      };

      // TODO: run performance profile tools and evaluate if it is needed to manualy unwatch() these elements
      return this.$watch(trigger, callback, { deep: true });
    }
  }
};

export default trackDirtyFields;
