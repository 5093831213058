import PlatformSubscriptions from "@/subscriptions/views/PlatformSubscriptions";

const subscriptionRoutes = [
  {
    name: "platform-subscriptions",
    path: "/subscriptions/platform",
    component: PlatformSubscriptions,
    meta: { requiresAuth: true, pageTitle: `${process.env.VUE_APP_NAME} Subscription` }
  }
];

export default subscriptionRoutes;
