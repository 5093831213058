<template>
  <v-card class="customer-viewer" elevation="0">
    <!-- TITLE -->
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn class="px-0 mr-3 align-center" title="Go back" @click="$router.go(-1)" outlined icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ customer.name }}</span>

      <v-spacer />
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#tab-general">General</v-tab>
        <v-tab href="#tab-payment-methods">Payment Methods</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- GENERAL CUSTOMER INFO FORM -->
        <v-tab-item value="tab-general">
          <v-container class="pa-0 px-4 mt-6 mb-4" fluid>
            <v-row>
              <v-col class="col-2">Name</v-col>
              <v-col>
                <strong>{{ customer.name }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Email</v-col>
              <v-col>
                <strong>{{ customer.email }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Currency</v-col>
              <v-col>
                <strong>{{ customer.default_currency }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Address Line 1</v-col>
              <v-col>
                <strong>{{ customer.address.address_line1 }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Address Line 2</v-col>
              <v-col>
                <strong>{{ customer.address.address_line2 }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">City</v-col>
              <v-col>
                <strong>{{ customer.address.city }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">State</v-col>
              <v-col>
                <strong>{{ customer.address.state }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Zip code</v-col>
              <v-col>
                <strong>{{ customer.address.code }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Country</v-col>
              <v-col>
                <strong>{{ customer.address.country_name }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2"></v-col>
              <v-col class="">
                <v-btn color="primary" @click.stop="editCustomer(customer)" outlined>Edit</v-btn>
              </v-col>
            </v-row>

            <!-- INVITE DETAILS -->
            <v-row v-if="!customer.is_claimed">
              <v-col class="col-12">
                <v-divider />

                <InviteLink :entity="customer" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- PAYMENT METHOD FORMS -->
        <v-tab-item value="tab-payment-methods">
          <div class="pt-10">
            <ach-account-form :customer-id="customer.id" />

            <credit-card-manager :customer-id="customer.target_entity" account="receivable"></credit-card-manager>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <customer-form-dialog ref="customer_editor" @updated="fetch_customer" @invitation-sent="fetch_invite_link" />
  </v-card>
</template>

<script>
import CustomerFormDialog from "@/invoiceboost/components/customer/CustomerFormDialog.vue";
import AchAccountForm from "@/invoiceboost/components/payment-method/AchAccountForm.vue";
import CreditCardManager from "@/invoiceboost/components/payment-method/CreditCardManager.vue";
import InviteLink from "@/invoiceboost/components/InviteLink.vue";

import api from "@/api";
// import utils from "@/utilities";

function create_model() {
  return {
    id: null,
    name: "",
    email: "",
    currency: "",
    is_claimed: false,
    address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      code: "",
      country: 0
    }
  };
}

export default {
  name: "CustomerDetails",

  components: {
    CreditCardManager,
    CustomerFormDialog,
    AchAccountForm,
    InviteLink
  },

  data() {
    return {
      tab: "tab-general",
      customer: create_model(),

      invite_label: `Email invitation to join ${process.env.VUE_APP_NAME}`,
      invite_link: null,
      is_sending_invite: false,
    };
  },

  computed: {
    customer_id() {
      return this.$route.params.id || null;
    }
  },

  mounted() {
    this.fetch_customer();
  },

  methods: {
    fetch_customer() {
      api.customers
        .fetch_customer(this.customer_id)
        .then(response => {
          this.customer = Object.assign({ address: {} }, response.data);

          return this.fetch_invite_link();
        })
        .catch(exception => {
          // console.log(exception);
        });
    },

    fetch_invite_link() {
      let customer = this.customer;

      if (customer.is_claimed) {
        return Promise.resolve();
      }

      return api.invitations.get_link(customer.target_entity)
        .then(response => {
          this.invite_link = response.data.link;
        });
    },

    editCustomer(customer) {
      // create a copy of the given data object to prevent live editing of the data displayed on the current list
      const dataCopy = Object.assign({}, customer);

      // copy address
      dataCopy.address = Object.assign({}, customer.address);

      this.$refs.customer_editor.open(dataCopy);
    },
  }
};
</script>