export default {
  methods: {
    /**
     * Use axios to download protected files with JSON web token
     * @param url
     * @param name
     */
    async downloadFile(url, name) {
      try {
        this.$store.state.auth.username;
        const response = await this.$http.get(url, {
          responseType: "blob"
        });
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", name); //or any other extension
        document.body.appendChild(link);
        link.click();
        return true;
      } catch (error) {
        return error;
      }
    },
    /**
     * Get the file name from the Content-Disposition header
     * @param disposition "Content-Disposition" Header
     * @return string Filename
     */
    getFileName(disposition) {
      let filename = "";
      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      return filename;
    }
  }
};
