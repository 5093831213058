<template>
  <responsive-dialog
    content-class="customer-form-dialog"
    dialog-width="700px"
    :display-dialog="displayDialog"
    :dialog-title="formTitle"
    @closed="close"
  >
    <!-- CUSTOMER FORM -->
    <v-form ref="form" v-model="form.isValid" :lazy-validation="false" @submit.prevent="submitForm">
      <v-container class="pa-0">
        <!-- DISPLAY GENERIC ERRORS -->
        <non-field-errors :errors="form.errors"></non-field-errors>

        <!-- DISPLAY FORM NOTIFICATIONS -->
        <v-row v-if="isClaimed || (!isGlobalEmailCommunicationEnabled && !isClaimed)">
          <v-col cols="12">
            <v-alert type="info" border="left" elevation="2" :icon="false" colored-border>
              <ul>
                <li v-if="isClaimed">
                  <!-- NOTIFY WHEN THE ACCOUNT HAS BEEN CLAIMED -->
                  <strong>You're viewing a claimed account</strong>
                </li>

                <li v-if="!isGlobalEmailCommunicationEnabled && !isClaimed">
                  <!-- eslint-disable-next-line -->
                  <strong>E-mail invitation not available</strong>
                  , communications are disabled at the organization level.
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>

        <!-- FIELD: COMPANY NAME -->
        <v-row class="mb-4">
          <v-col cols="12">
            <v-text-field
              v-if="isClaimed && isDifferentCompanyName"
              name="Customer[Company]"
              label="The Customer's claimed name (readonly)"
              v-model="form.customer.company"
              :rules="form.rules.company"
              :error-messages="form.field_errors.company"
              disabled
              outlined
              required
            />

            <!-- FIELD: CUSTOMER NAME -->

            <v-text-field
              name="Customer[Name]"
              label="Name*"
              v-model="form.customer.name"
              :rules="form.rules.name"
              :error-messages="form.field_errors.name"
              :disabled="isLoading"
              outlined
              required
            />

            <!-- FIELD: DEFAULT CURRENCY -->
            <currency-select
              name="Customer[DefaultCurrency]"
              ref="currency_select"
              :initial-value="form.customer.default_currency"
              :error-messages="form.field_errors.default_currency"
              @change="onCurrencyChange"
            />

            <!-- FIELD: EMAIL -->
            <v-text-field
              name="Customer[Email]"
              label="Email"
              v-model="form.customer.email"
              :rules="form.rules.email"
              :error-messages="form.field_errors.email"
              :disabled="isLoading"
              outlined
            />

            <!-- FIELD: ENABLE CUSTOMER COMMUNICATIONS -->
            <v-switch
              v-model="switch_enable_customers_email_communication"
              :error-messages="form.field_errors.enable_email_communication"
              :loading="false"
              :disabled="!hasEmail || !isGlobalEmailCommunicationEnabled || !hasWritePermission"
              label="Enable Customer Email Communication"
              class="enable-customer-comms ma-0 pa-0"
              hide-details
            />
          </v-col>
        </v-row>

        <!-- ADDRESS -->
        <v-row>
          <v-col cols="12">
            <!-- FIELD: ADDRESS LINE 1 -->
            <v-text-field
              name="Customer[AddressLine1]"
              label="Address Line 1"
              v-model="form.customer.address.address_line1"
              :error-messages="form.field_errors['address.address_line1']"
              :disabled="isLoading"
              outlined
              required
            />

            <!-- FIELD: ADDRESS LINE 2 -->
            <v-text-field
              name="Customer[AddressLine2]"
              label="Address Line 2"
              v-model="form.customer.address.address_line2"
              :error-messages="form.field_errors['address.address_line2']"
              :disabled="isLoading"
              outlined
            />

            <!-- FIELD: CITY -->
            <v-text-field
              name="Customer[City]"
              label="City"
              v-model="form.customer.address.city"
              :error-messages="form.field_errors['address.city']"
              :disabled="isLoading"
              outlined
              required
            />

            <!-- FIELD: ADDRESS STATE -->
            <v-text-field
              name="Customer[State]"
              label="State"
              v-model="form.customer.address.state"
              :error-messages="form.field_errors['address.state']"
              :disabled="isLoading"
              outlined
              required
            />

            <!-- FIELD: ZIP CODE -->
            <v-text-field
              name="Customer[ZipCode]"
              label="Zip code"
              v-model="form.customer.address.code"
              :error-messages="form.field_errors['address.code']"
              :disabled="isLoading"
              outlined
              required
            />

            <!-- FIELD: COUNTRY -->
            <country-search
              name="Customer[Country]"
              ref="country_search"
              :field-errors="form.field_errors['address.country']"
              :disabled="isLoading"
              @change="onCountrySelected"
              clearable
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <!-- SEND EMAIL INVITATION -->
      <div v-if="!isClaimed" class="invite-column">
        <v-switch
          v-if="!isUpdate"
          v-model="switch_email_invitation"
          :label="inviteLabel"
          :loading="isSendingInvite"
          :disabled="!hasEmail || !isSendInviteAllowed"
        />

        <small v-if="!hasEmail && isSendInviteAllowed">You need to enter an email address to send the invite.</small>
      </div>

      <v-spacer />

      <v-btn
        type="button"
        color="primary"
        ref="submit_button"
        :loading="isLoading"
        :disabled="isLoading || !isDirty || !hasWritePermission"
        @click.prevent="submitForm"
        text
      >
        <v-icon>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import CurrencySelect from "@/invoiceboost/components/CurrencySelect.vue";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import CountrySearch from "@/bcore/components/country/CountrySearch.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import { ADD_MESSAGE } from "@/store/mutation-types";

import api from "@/api";

import deep_copy from "@/utilities/deep_copy";
import settingsMixin from "@/mixins/settings";
import permissionMixin from "@/mixins/permission";
import utils from "@/utilities";

const is_empty = utils.is_empty;

function createModelRules() {
  return {
    name: [value => !!value || "Name is required."],

    email: [
      value => {
        if (value && value.length > 0) {
          return /.+@.+\..+/.test(value) || "E-mail must be in a valid format.";
        }

        return true;
      }
    ]
  };
}

export default Vue.extend({
  name: "CustomerForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    CountrySearch,
    CurrencySelect
  },

  mixins: [trackDirtyFieldsMixin, settingsMixin, permissionMixin],

  props: {},

  data() {
    return {
      isLoading: false,
      displayDialog: false,
      inviteLabel: `Email invitation to join ${process.env.VUE_APP_NAME}`,
      enableSendInvite: true,
      isSendingInvite: false,

      defaultCountryId: null,

      form: {
        isValid: false,

        customer: this.create_customer_model(),
        customerInitialData: this.create_customer_model(),

        rules: createModelRules(),
        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    /* COMPUTED MODELS/VALUES */

    hasWritePermission() {
      return this.hasPermission("change_customer");
    },

    switch_enable_customers_email_communication: {
      get() {
        // the computed value should return false when:
        // - the organization has communications disabled
        // - the user doesn't have a valid email or the field is empty
        if (!this.isGlobalEmailCommunicationEnabled || !this.hasEmail) {
          return false;
        }

        return this.form.customer.enable_email_communication;
      },

      set(value) {
        this.form.customer.enable_email_communication = value;
      }
    },

    switch_email_invitation: {
      get() {
        if (
          !this.isGlobalEmailCommunicationEnabled ||
          !this.form.customer.enable_email_communication ||
          !this.hasEmail
        ) {
          return false;
        }

        return this.enableSendInvite;
      },

      set(value) {
        this.enableSendInvite = value;
      }
    },

    /* VALIDATORS */

    hasEmail() {
      const email = this.form.customer.email || "";
      return /.+@.+\..+/.test(email);
    },

    isUpdate() {
      return !!this.form.customer.id;
    },

    isClaimed() {
      return this.form.customer.is_claimed;
    },

    isSendInviteAllowed() {
      return this.isGlobalEmailCommunicationEnabled && this.isCustomersEmailCommunicationEnabled;
    },

    isCustomersEmailCommunicationEnabled() {
      return !!this.form.customer.enable_email_communication;
    },

    isGlobalEmailCommunicationEnabled() {
      return !!this.$store.state.entitySettings.detail.enable_customers_email_communication;
    },

    isDifferentCompanyName() {
      const customer = this.form.customer;
      return customer.name !== customer.company;
    },

    /* SHORTCUTS */

    targetEntity() {
      return this.form.customer;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    entitySettings() {
      return this.$store.state.entitySettings.detail;
    },

    formTitle() {
      return this.isUpdate ? "Update Customer" : "New Customer";
    }
  },

  mounted() {
    // Intentionally executing this fetch early as possible
    this.fetchDefaultCountry();
  },

  methods: {
    create_customer_model(data) {
      let owner_entity_id = this.ownerEntity ? this.ownerEntity.id : 0;
      let model = {
        id: null,
        name: "",
        email: "",
        company: null,
        default_currency: "USD",
        enable_auto_charge: false,
        enable_email_communication: true,
        owner_entity: owner_entity_id,
        address: {
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          code: "",
          country: null
        }
      };

      if (data) {
        return deep_copy(data, model);
      }

      return model;
    },

    new() {
      this.resetStates();
      this.resetValidation();

      this.form.customer = this.create_customer_model();
      this.form.customerInitialData = this.create_customer_model();

      this.$nextTick(() => {
        // set default country selection
        this.form.customer.address.country = this.defaultCountryId;
        this.$refs.country_search.select(this.defaultCountryId);
      });

      this.displayDialog = true;
    },

    open(data) {
      if (!data) {
        return null;
      }

      this.resetStates();
      this.resetValidation();

      // Clone data to prevent sharing state
      this.form.customer = this.create_customer_model(data);
      this.form.customerInitialData = this.create_customer_model(data);

      this.$nextTick(() => {
        // update country search selection
        this.$refs.country_search.select(this.form.customer.address.country);
      });

      this.displayDialog = true;

      return this;
    },

    close() {
      this.displayDialog = false;
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetStates() {
      this.isLoading = false;
      this.isSendingInvite = false;
      this.enableSendInvite = true;

      return this;
    },

    resetValidation() {
      this.form.field_errors = {};
      this.form.errors = [];

      let $form = this.$refs.form;

      if ($form) {
        $form.resetValidation();
      }

      return this;
    },

    submitForm() {
      if (!this.validate()) {
        return;
      }

      this.resetValidation();

      const is_update = this.isUpdate;
      const has_email = this.hasEmail;
      const enable_send_invite = this.enableSendInvite;

      const action = is_update ? "updated" : "created";
      const promise = is_update ? this.updateCustomer() : this.createCustomer();
      const form = this.form;

      this.isLoading = true;

      return promise
        .then(customer => {
          // update local data with the server response
          form.customer = this.create_customer_model(customer);
          form.customerInitialData = this.create_customer_model(customer);

          this.displayMessage({ text: `The customer has been ${action} successfully.`, type: "success" });
          this.$emit(action, this.create_customer_model(customer));
        })
        .then(() => {
          // only send email invite through this step when creating an entity
          if (!is_update && has_email && enable_send_invite) {
            this.sendInvite();
          }

          return Promise.resolve();
        })
        .catch(exception => {
          let form = this.form;
          let field_errors = utils.form.get_field_errors(exception);
          let errors = utils.form.get_errors(exception);

          if (is_empty(field_errors) && is_empty(errors)) {
            errors = [
              {
                message: "There was an issue performing this action, if this persist please contact us.",
                type: "error"
              }
            ];
          }

          form.field_errors = field_errors;
          form.errors = errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createCustomer() {
      const customer = this.form.customer;

      return api.customers.create(customer).then(response => {
        return response.data;
      });
    },

    updateCustomer() {
      const customer = this.form.customer;

      return api.customers.update(customer).then(response => {
        return response.data;
      });
    },

    sendInvite() {
      this.isSendingInvite = true;

      return api.invitations
        .send(this.form.customer)
        .then(() => {
          this.$emit("invitation-sent");
          this.displayMessage({ text: "Invitation sent", type: "info" });
        })
        .catch(exception => {
          this.displayMessage({ text: "Invitation failed to send", type: "error" });
        })
        .finally(() => {
          this.isSendingInvite = false;
        });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    fetchDefaultCountry() {
      const organization_id = this.entitySettings.id;

      api.organizations
        .fetch_address(organization_id)
        .then(response => {
          this.defaultCountryId = response.data.country;
        })
        .catch(error => {
          // this.displayMessage({ text: "Unable to load default country.", type: "error" });
        });
    },

    /* EVENT HANDLERS */

    onCountrySelected(country) {
      this.form.customer.address.country = country && country.id ? country.id : null;
    },

    onCurrencyChange(currency) {
      this.form.customer.default_currency = currency;
    },

    /* HELPERS */

    trackDirtyFields() {
      const address = "form.customer.address";
      const address_initial_data = "form.customerInitialData.address";

      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField(
        "form.customer.enable_email_communication",
        "form.customerInitialData.enable_email_communication"
      );

      this.trackDirtyField("form.customer.default_currency", "form.customerInitialData.default_currency");
      this.trackDirtyField("form.customer.name", "form.customerInitialData.name");
      this.trackDirtyField("form.customer.email", "form.customerInitialData.email");

      this.trackDirtyField(`${address}.address_line1`, `${address_initial_data}.address_line1`);

      this.trackDirtyField(`${address}.address_line2`, `${address_initial_data}.address_line2`);

      this.trackDirtyField(`${address}.city`, `${address_initial_data}.city`);
      this.trackDirtyField(`${address}.state`, `${address_initial_data}.state`);
      this.trackDirtyField(`${address}.code`, `${address_initial_data}.code`);
      this.trackDirtyField(`${address}.country`, `${address_initial_data}.country`);
    }
  }
});
</script>

<style>
.customer-form-dialog .v-input--is-disabled input {
  color: #757575;
}

.customer-form-dialog .v-input--is-disabled .v-text-field__details > .v-messages {
  color: #263238 !important;
}

.customer-form-dialog strong {
  color: #424242;
}

.customer-form-dialog .non-field-errors {
  margin-bottom: 0;
}

.customer-form-dialog .invite-column {
  position: relative;
}

.customer-form-dialog .invite-column .caption {
  position: absolute;
  left: 50px;
}
</style>
