import { http } from "@/vuex-bcore";

const url = "invoiceboost/transaction";

function fetch_related_invoices(transaction_id) {
  return http.get(`${url}/${transaction_id}/invoices/`);
}

function refund_transaction(transaction_id, provider) {
  return http.get(`${url}/${transaction_id}/refund/?provider=${provider}`);
}

export default {
  fetch_related_invoices,
  refund_transaction
};
