<template>
  <div class="employee-selector">
    <v-select
      :name="name"
      :value="value"
      :label="label"
      :items="options"
      :loading="isLoading"
      :outlined="outlined"
      :dense="dense"
      :return-object="returnObject"
      :error-messages="error_messages"
      item-text="text"
      item-value="value"
      clearable
      multiple
      @change="change"
    >
      <template v-slot:selection="{ item, index }">
        <div v-if="index === 0" class="v-select__selection v-select__selection--comma">{{ item.text }}</div>
        <div v-if="index === 1" class="grey--text caption">(+{{ value.length - 1 }} others)</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "EmployeeSelector",

  model: {
    prop: "value",
    event: "change"
  },

  props: {
    name: { type: String, default: "" },
    value: { type: Array, default: () => [] },
    label: { type: String, default: "Assigned to" },
    dense: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    returnObject: { type: Boolean, default: false }
  },

  data() {
    return {
      isLoading: false,
      options: [],
      error_messages: null
    };
  },

  mounted() {
    this.fetchEmployees();
  },

  methods: {
    fetchEmployees() {
      this.isLoading = true;
      this.error_messages = null;

      api.employees
        .fetch_all()
        .then(response => {
          let data = response.data;
          let options = data.results.map(currentValue => {
            return { text: currentValue.name, value: currentValue.target_entity };
          });

          this.populateOptions(options);
        })
        .catch(error => {
          // console.log( error );
          this.error_messages = "Unable to fetch employees.";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    populateOptions(options) {
      this.options = options;
    },

    change(selected) {
      this.$emit("change", selected);
    }
  }
};
</script>
