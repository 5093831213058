<template>
  <router-view />
</template>

<script>
import settingsMixin from "@/mixins/settings";

export default {
  name: "App",

  mixins: [settingsMixin],

  data() {
    return {};
  },

  methods: {}
};
</script>

<style>
/* TODO: keep an eye on chrome 87 updates if they address this issue */
fieldset {
  /* border: none; */
  /* color: transparent !important; */
  /* background: transparent !important; */
  /* border-color: transparent !important; */
  border-color: #e0e0e0 !important;
}

/*
  remove focus state on v-btn
  https://github.com/vuetifyjs/vuetify/issues/8572
*/

.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0 !important;
}
</style>
