// Common
export const LOADING = "LOADING";
export const LOADED = "LOADED";

// Authentication
export const SET_ENTITY = "SET_ENTITY";
export const SET_ENTITY_PERMISSIONS = "SET_ENTITY_PERMISSIONS";
export const SET_USER = "SET_USER";

// Models
export const SET_LIST = "SET_LIST";
export const SET_DETAIL = "SET_DETAIL";
export const SET_META = "SET_META";
export const ADD_FILTERS = "ADD_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const ADD_REQUEST = "ADD_REQUEST";
export const REMOVE_REQUEST = "REMOVE_REQUEST";

// Errors
export const ADD_ERRORS = "ADD_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
