<template>
  <login-layout>
    <v-card>
      <v-form v-if="invite" @submit.prevent="submit_form">
        <v-card>
          <v-card-title class="px-6">{{ formTitle }}</v-card-title>

          <v-card-subtitle class="px-6">{{ formInstructions }}</v-card-subtitle>

          <v-card-text>
            <v-container>
              <non-field-errors :errors="form.errors" />

              <v-row>
                <v-col>
                  <v-text-field
                    label="Username"
                    v-model="form.fields.username"
                    :error-messages="form.field_errors.username"
                    :rules="form_rules.username"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="Password"
                    type="password"
                    v-model="form.fields.password"
                    :error-messages="form.field_errors.password"
                    :rules="form_rules.password"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="First Name"
                    v-model="form.fields.first_name"
                    :error-messages="form.field_errors.first_name"
                    :rules="form_rules.first_name"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="Last Name"
                    v-model="form.fields.last_name"
                    :error-messages="form.field_errors.last_name"
                    :rules="form_rules.first_name"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Email"
                    v-model="form.fields.email"
                    :error-messages="form.field_errors.email"
                    :rules="form_rules.email"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Phone Number"
                    v-model="phone_number"
                    :error-messages="form.field_errors.phone_number"
                    :rules="form_rules.phone_number"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container>
              <v-row align="center" justify="center">
                <v-btn type="submit" outlined color="primary" x-large :loading="is_loading">SIGN UP</v-btn>
              </v-row>

              <v-row align="center" justify="center">
                <v-btn text small class="mt-4" @click.prevent="sign_in">Already have an account? Sign In</v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-form>

      <payment-methods ref="payment_methods" :token="token" />
    </v-card>
  </login-layout>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import PaymentMethods from "@/invoiceboost/components/payment-method/PaymentMethods.vue";
import LoginLayout from "@/views/layouts/LoginLayout.vue";

import { ADD_MESSAGE } from "@/store/mutation-types";

import app from "@/app";
import api from "@/api";
import utils from "@/utilities";

const is_empty = utils.is_empty;

export default {
  name: "InviteRedemption",

  components: {
    NonFieldErrors,
    PaymentMethods,
    LoginLayout
  },

  data() {
    return {
      is_loading: false,
      invite: null,
      phone_number: "",

      form_rules: {
        username: [value => !!value || "Username is required."],
        password: [value => !!value || "Password is required."],
        first_name: [value => !!value || "First Name is required."],
        last_name: [value => !!value || "Last Name is required."],

        email: [
          value => {
            if (!value) {
              return "E-mail is required.";
            }

            if (value.length > 0) {
              return /.+@.+\..+/.test(value) || "E-mail must be in a valid format.";
            }

            return true;
          }
        ],

        phone_number: [value => !!value || "Phone Number is required."]
      },

      form: {
        fields: {
          first_name: "",
          last_name: "",
          password: "",
          email: "",
          username: ""
        },

        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    token() {
      return this.$route.query.token;
    },

    formTitle() {
      return this.$route.query.bcore
        ? "Registration form"
        : `You're almost ready to ${this.invoiceAction} this invoice.`;
    },

    formInstructions() {
      return !this.$route.query.bcore && this.invite && typeof this.invite.owner_entity === "object"
        ? `Take 20 seconds to fill out the form below so that ${this.invite.owner_entity.name} knows it's really you.`
        : "Register now to claim your account.";
    },

    invoiceAction() {
      return this.$route.query.action || "process";
    },

    showPaymentMethods() {
      return this.$route.query.show_payment_methods && this.$route.query.show_payment_methods.toLowerCase() == "yes";
    },

    paymentEntity() {
      if (this.$route.query.show_payment_methods && this.invite) {
        return this.invite.owner_entity;
      }

      return null;
    }
  },

  mounted() {
    this.logout();
    this.validate_token();
  },

  methods: {
    logout() {
      return this.$store.dispatch("auth/logout");
    },

    validate_token() {
      return api.invitations
        .validate(this.token)
        .then(response => {
          this.invite = response.data;
          this.displayPaymentMethods();
        })
        .catch(exception => {
          this.$router.push("/");
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: "This invitation is invalid or expired.", type: "error"
          });
        });
    },

    reset_errors() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit_form() {
      this.reset_errors();
      this.is_loading = true;

      return api.invitations
        .redeem(this.token, this.form.fields, this.phone_number)
        .then(response => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: "Registration successful. You'll be logged in automatically.",
            type: "success"
          });

          this.auto_sign_in();
        })
        .catch(exception => {
          let form = this.form;
          let field_errors = utils.form.get_field_errors(exception);
          let errors = utils.form.get_errors(exception);

          if (is_empty(field_errors) && is_empty(errors)) {
            errors = [{
              message: "There was an issue performing this action, if this persist please contact us.",
              type: "error"
            }];
          }

          form.field_errors = field_errors;
          form.errors = errors;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    auto_sign_in() {
      app.user.login({
        username: this.form.fields.email,
        password: this.form.fields.password
      }).then(() => {
        this.$router.push({ name: "welcome-slides" });
      });
    },

    sign_in() {
      this.$router.push({ name: "login", query: { redirect: `/invite-redemption?token=${this.token}` } });
    },

    displayPaymentMethods() {
      if (this.showPaymentMethods && this.invite) {
        this.$refs.payment_methods.open(this.invite.owner_entity);
      }
    }
  }
};
</script>
