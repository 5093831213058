<template>
  <div v-if="!readOnly">
    <span class="subtitle-2">
      <v-icon left>mdi-paperclip</v-icon>
      Attachments
    </span>
    <p class="caption">Click below or drag a file into the box to add an attachment</p>
    <v-progress-linear
      v-if="uploadPercentage > 0 || loading"
      :value="uploadPercentage"
      :indeterminate="uploadPercentage === 100 || loading"
    ></v-progress-linear>
    <media-field style="min-height: 100px" @updated="onMediaUpdate" @uploadPercentage="onUploadPercentage">
      <media-drop-zone>
        <v-chip v-for="item in items" :key="item.id" small class="ma-2" close @click:close="onClickDeleteButton(item)">
          {{ item.media.filename }}
        </v-chip>
      </media-drop-zone>
    </media-field>

    <a @click.prevent="onClickAddFile">Select existing</a>
    <media-popup model-name="media" @selected="onMediaUpdate" />
    <delete-dialog
      object-name="attachment"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </div>
  <!--- Read only template ---->
  <div v-else>
    <v-skeleton-loader v-if="loading" :loading="loading" type="chip"></v-skeleton-loader>
    <v-chip
      v-for="item in items"
      :key="item.id"
      close-icon="mdi-download"
      small
      class="ma-2"
      close
      @click:close="onClickDownloadButton(item)"
    >
      {{ item.media.filename }}
    </v-chip>
  </div>
</template>

<script>
import mixins from "vue-typed-mixins";

import { ADD_MESSAGE, OPEN_DIALOG } from "@/store/mutation-types";

import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import MediaDropZone from "@/bcore/components/media/MediaDropZone.vue";
import MediaPopup from "@/bcore/components/media/MediaPopup.vue";
import MediaField from "@/bcore/components/media/MediaField.vue";

import downloadMixin from "@/mixins/download";
import eventHub from "@/eventHub.js";

const defaultObject = () => {
  return {};
};

export default mixins(downloadMixin).extend({
  name: "InvoiceMedia",

  components: { DeleteDialog, MediaPopup, MediaField, MediaDropZone },

  props: {
    invoice: { type: Object, default: defaultObject() },
    readOnly: { type: Boolean, default: true }
  },

  data() {
    return {
      loading: false,
      idDeleteItem: -1,
      deleteConfirmOpen: false,
      items: [],
      formObject: null,
      uploadPercentage: 0,

      formNew: {
        media: {}
      }
    };
  },

  computed: {
    invoiceIdIsSet() {
      return !!this.invoice && !!this.invoice.id;
    },

    mediaUrl() {
      return this.readOnly
        ? `invoiceboost/bills/${this.invoice.id}/media/`
        : `invoiceboost/invoices/${this.invoice.id}/media/`;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    }
  },

  watch: {
    invoice: {
      handler(value) {
        if (value && value.id) {
          this.fetchItems();
        } else {
          this.items = [];
        }
      }
    }
  },

  mounted() {
    if (this.invoiceIdIsSet) {
      this.fetchItems();
    }
  },

  methods: {
    async fetchItems() {
      this.loading = true;
      const response = await this.$http.get(this.mediaUrl);
      this.loading = false;
      this.items = response.data;
    },

    async onItemsUpdated() {
      await this.fetchItems();
      this.formObject = null;
    },

    onItemClick(item) {
      this.formObject = { ...item };
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },

    onClickDownloadButton(item) {
      //TODO allow cross origin headers on cloudFront
      this.downloadResource(item.media.file || "", item.media.filename || "");
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.showSnackbarSuccess();
    },

    async sendDelete(id) {
      await this.$store.dispatch("invoiceMedia/delete", id);
    },

    async onMediaUpdate(item) {
      this.uploadPercentage = 0;
      const attachment = {
        media: item.id,
        invoice: this.invoice.id
      };

      try {
        const response = await this.$store.dispatch(`invoiceMedia/create`, attachment);
        this.items = [...[response.data], ...this.items];
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Could not attach the file", type: "error" });
      }
    },

    onClickAddFile() {
      this.$store.commit(`dialog/${OPEN_DIALOG}`, "media");
    },

    onUploadPercentage(value) {
      this.uploadPercentage = value;
    },

    onClickDropArea() {
      eventHub.$emit("clickMediaUpload");
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    }
  }
});
</script>
