import { http } from "@/vuex-bcore";

const url = "pos/drivers";

function create(driver) {
  return http.post(`${url}/`, driver);
}

function update(driver) {
  return http.patch(`${url}/${driver.id}/`, driver);
}

export default {
  create,
  update
};
