import getGetters from "./crud/getGetters";
import getMutations from "./crud/getMutations";
import getActions, { customAction } from "./crud/getActions";
import getState from "./crud/getState";
import http from "./http.js";
import auth from "./auth";
import authEvent from "./auth/event";
import install from "./install.js";

const Bcore = {
  install: install
};

export default Bcore;
export { getGetters, getActions, customAction, getMutations, getState, http, auth, authEvent };
export * from "./types";
export * from "./utils.js";
