<template>
  <responsive-dialog :display-dialog="displayDialog" :dialog-title="formTitle" @closed="close">
    <form @submit.prevent="onFormSubmit">
      <v-card-text>
        <v-container>
          <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>

          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="formItem.by_date_of_the_week"
                :error-messages="formErrors.by_date_of_the_week"
                :loading="false"
                label="Schedule by date of the week"
              />
            </v-col>
          </v-row>

          <v-row v-if="!formItem.by_date_of_the_week">
            <v-col cols="4">
              <v-select
                v-model.number="formItem.cardinal"
                label="Day of the month"
                item-text="text"
                item-value="value"
                :items="cardinal"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="formItem.by_date_of_the_week">
            <v-col cols="4">
              <v-select v-model="formItem.ordinal" label="Date" :items="ordinal"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="formItem.day_of_the_week"
                label="Day of the week"
                :items="days_of_the_weeks"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </form>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <v-spacer />
      <v-btn type="submit" color="primary" text :loading="formLoading" x-large @click="onFormSubmit">Save</v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import { parseFormErrors } from "@/utilities/form";

function createDataModel() {
  return {
    by_date_of_the_week: false,
    cardinal: 0,
    day_of_the_week: null,
    id: 0,
    ordinal: null,
    owner_entity: 0,
    text: ""
  };
}

export default Vue.extend({
  name: "PaymentScheduleForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors
  },

  props: {},

  data() {
    return {
      displayDialog: false,
      isLoading: false,

      formErrors: {},
      formItem: createDataModel()
    };
  },

  computed: {
    formLoading() {
      return (
        this.$store.getters["paymentSchedule/loading"]("create") ||
        this.$store.getters["paymentSchedule/loading"]("update")
      );
    },

    isItemUpdate() {
      return !!this.formItem.id;
    },

    formTitle() {
      return this.isItemUpdate ? "Update Payment Schedule" : "New Payment Schedule";
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    formHasErrors() {
      return Object.keys(this.formErrors).length > 0;
    },

    cardinal() {
      return [
        { text: "1st", value: 1 },
        { text: "2nd", value: 2 },
        { text: "3rd", value: 3 },
        { text: "4th", value: 4 },
        { text: "5th", value: 5 },
        { text: "6th", value: 6 },
        { text: "7th", value: 7 },
        { text: "8th", value: 8 },
        { text: "9th", value: 9 },
        { text: "10th", value: 10 },
        { text: "11th", value: 11 },
        { text: "12th", value: 12 },
        { text: "13th", value: 13 },
        { text: "14th", value: 14 },
        { text: "15th", value: 15 },
        { text: "16th", value: 16 },
        { text: "17th", value: 17 },
        { text: "18th", value: 18 },
        { text: "19th", value: 19 },
        { text: "20th", value: 20 },
        { text: "21st", value: 21 },
        { text: "22nd", value: 22 },
        { text: "23rd", value: 23 },
        { text: "24th", value: 24 },
        { text: "25th", value: 25 },
        { text: "26th", value: 26 },
        { text: "27th", value: 27 },
        { text: "28th", value: 28 },
        { text: "29th", value: 29 },
        { text: "30th", value: 30 }
      ];
    },

    ordinal() {
      return [
        { text: "1st", value: 1 },
        { text: "2nd", value: 2 },
        { text: "3rd", value: 3 },
        { text: "4th", value: 4 }
      ];
    },

    days_of_the_weeks() {
      return [
        { text: "Monday", value: 1 },
        { text: "Tuesday", value: 2 },
        { text: "Wednesday", value: 3 },
        { text: "Thursday", value: 4 },
        { text: "Friday", value: 5 },
        { text: "Saturday", value: 6 },
        { text: "Sunday", value: 0 }
      ];
    }
  },

  methods: {
    edit(schedule) {
      this.formItem = schedule;
      return this.open();
    },

    open() {
      this.displayDialog = true;
      return this;
    },

    close() {
      this.displayDialog = false;
      this.formItem = {};
      this.$emit("closed");
      return this;
    },

    resetFormErrors() {
      this.formErrors = {};
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async onFormSubmit() {
      this.resetFormErrors();
      this.formItem.owner_entity = this.ownerEntity.id;
      this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      if (this.formHasErrors) {
        return;
      }

      this.$emit("updated");
      this.close();
    },

    async sendUpdate() {
      try {
        await this.$store.dispatch("paymentSchedule/update", { id: this.formItem.id, data: this.formItem });
      } catch (error) {
        this.setFormErrors(error.response.data);
      }
    },

    async sendCreate() {
      try {
        await this.$store.dispatch("paymentSchedule/create", this.formItem);
      } catch (error) {
        this.setFormErrors(error.response.data);
      }
    }
  }
});
</script>
