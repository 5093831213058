<template>
  <v-card class="ticket-list p-0" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">

      <v-btn
          v-if="true"
          title="New Ticket"
          color="primary"
          class="px-0 mr-3 align-center"
          @click="newTicket"
          outlined
          icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <span>Tickets</span>

      <v-spacer/>

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
            class="search-field align-self-center mr-4"
            placeholder="Customer, Order #, Etc."
            prepend-inner-icon="mdi-magnify"
            v-model="tableFilters.search"
            outlined
            dense
        />
      </div>

    </v-card-title>

    <v-card-text>

      <v-toolbar light class="mb-2">

        <v-btn icon @click="clearSelection" v-if="selection.length > 0">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{ selection.length > 0 ? `${selection.length} selected` : 'Filter by' }}
        </v-toolbar-title>

        <v-spacer/>

        <v-slide-y-transition>

          <!-- FILTERS: STATUS -->
          <v-select
              v-if="!selection.length"
              key="filter-status"
              :items="statuses"
              placeholder="Status"
              clear-icon="mdi-close"
              dense
          />

        </v-slide-y-transition>

        <v-slide-y-transition>
          <!-- ACTION: DISPATCH TICKETS -->
          <v-btn v-if="selection.length > 0" key="dispatch" color="primary" @click="addDispatch" large>
            Dispatch
            <v-icon right>mdi-dump-truck</v-icon>
          </v-btn>

        </v-slide-y-transition>

      </v-toolbar>

      <v-data-table
          :headers="headers"
          :items="items"
          :loading="loadingTable"
          :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
          :options.sync="tableOptions"
          :server-items-length="metaData.count"
          dense
      >
        <!-- COLUMN: TICKET SELECTION -->
        <template v-slot:item.selection="{ item }">
          <v-checkbox v-model="item.selection" :disabled="!item.requires_delivery" dense></v-checkbox>
        </template>

        <!-- COLUMN: DELIVERY DATE -->
        <template v-slot:item.delivery_date="{ item }">{{ formatDate(item.delivery_date) }}</template>

        <!-- COLUMN: DATE PLACED -->
        <template v-slot:item.date_placed="{ item }">{{ formatDate(item.date_placed) }}</template>

        <!-- COLUMN: REQUIRES DELIVERY -->
        <template v-slot:item.requires_delivery="{ item }">
          <v-icon v-if="item.requires_delivery">mdi-check-bold</v-icon>
        </template>

        <!-- COLUMN: ACTIONS -->
        <template v-slot:item.actions="{ item }">

          <!-- ACTION: DISPATCH TICKETS -->
          <v-btn
              key="action-dispatch"
              color="primary"
              @click="addDispatch"
              :disabled="disableDispatchButton(item)"
              small
          >
            Dispatch
          </v-btn>

        </template>

      </v-data-table>
    </v-card-text>

  </v-card>
</template>

<script>

import api from "@/api";
import permissionMixin from "@/mixins/permission";
import date from "@/mixins/date";

export default {
  name: "Tickets",
  mixins: [permissionMixin, date],
  data() {
    return {
      headers: [
        {text: "Select", value: "selection", sortable: false},
        {text: "ID", value: "id", sortable: false},
        {text: "Order", value: "order", sortable: false},
        {text: "Customer", value: "customer"},
        {text: "Status", value: "status"},
        {text: "Delivery Date", value: "delivery_date"},
        {text: "Requires Delivery", value: "requires_delivery"},
        {text: "Actions", value: "actions", sortable: false}
      ],
      loadingTable: false,
      defaultItemsPerPage: 10,
      tableFilters: {search: null},
      tableOptions: {itemsPerPage: 20},
      itemsPerPageOptions: [5, 10, 20, 50, 100],
      items: [],
      statuses: ["Pending", "Dispatched", "Cancelled"],
      metaData: {
        count: 0,
        next: null,
        previous: null,
        currentPage: 0,
      }
    };
  },
  computed: {

    canAddDispatch() {
      // TODO: fix condition when the backend is wired up
      return this.hasPermission("add_dispatch") || true;
    },

    selection() {
      return this.items.filter(item => item.selection);
    },

  },

  mounted() {

    api.tickets.fetch().then((rs) => {
      this.setTableItems(rs.data);
    }).catch(e => {
    });

  },
  methods: {

    setTableItems(data) {
      this.items = data.results;
      this.metaData.count = data.count;
      this.metaData.next = data.next;
      this.metaData.previous = data.previous;
      this.metaData.currentPage = 0;
    },

    addDispatch() {
    },

    newTicket() {
      this.$router.push({name: "new-ticket-form"});
    },

    clearSelection() {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].selection = false;
      }
    },

    disableDispatchButton(ticket) {
      return !ticket.requires_delivery || this.selection.length > 0;
    }

  }
};
</script>

<style>
.ticket-list table thead tr {
  background-color: #eeeeee;
}

.ticket-list .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}
</style>