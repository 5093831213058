import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#437b56",
        secondary: "#5e5e5e",
        accent: "#7499cd",
        error: "#dd4034",
        info: "#3a83e2",
        success: "#5db361",
        warning: "#ffcc60"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
