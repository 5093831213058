import { http } from "@/vuex-bcore";
import settings from "./organization-settings";

const url = "invoiceboost/organizations";

function fetch(id) {
  return http.get(`${url}/${id}/`);
}

function update(organization) {
  return http.patch(`${url}/${organization.id}/`, organization);
}

// Address methods
const address_url = "invoiceboost/entity-addresses";

function fetch_address(organization_id) {
  // return http.get(`${address_url}/${organization_id}/`);
  return http.get(`invoiceboost/organizations/${organization_id}/address/`);
}

function create_address(address) {
  return http.post(`${address_url}/`, address);
}

function update_address(address) {
  return http.patch(`${address_url}/${address.id}/`, address);
}

export default {
  settings,

  fetch,
  update,

  fetch_address,
  create_address,
  update_address
};
