const filterCountMixin = {
  props: { modelName: { type: String, default: "" } },
  computed: {
    filterState() {
      return this.$store.state[this.modelName].filters;
    },
    filterCount() {
      const filters = Object.keys(this.filterState);
      const nonPageFilters = ["page", "page_size", "ordering"];
      let filterCount = 0;
      for (let i = 0; i < filters.length; i++) {
        const value = this.filterState[filters[i]];
        if (value && !nonPageFilters.includes(filters[i]) && value !== "") {
          filterCount += 1;
        }
      }
      return filterCount;
    }
  }
};

export default filterCountMixin;
