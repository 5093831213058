<template>
  <login-layout>
    <keep-alive>
      <component :is="active_component" :context="context"></component>
    </keep-alive>
  </login-layout>
</template>

<script>
import axios from "axios";

import LoginLayout from "@/views/layouts/LoginLayout.vue";
import ForgotPasswordMobile from "./ForgotPasswordMobile.vue";
import ForgotPasswordDesktop from "./ForgotPasswordDesktop.vue";

import api from "@/api";
import utils from "@/utilities";
import viewport from "@/mixins/viewport";
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

const is_empty = utils.is_empty;

export default {
  name: "ForgotPassword",

  components: {
    NonFieldErrors,
    LoginLayout
  },

  mixins: [viewport],

  data() {
    return {
      active_step: 1,

      is_loading: false,
      show_password: false,

      form: {
        fields: {
          username: "",
          recovery_code: "",
          password: "",
          confirm_password: ""
        },

        rules: {
          recovery_code: [value => !!value || "Reset code is required."],
          password: [value => {
            if (!value || value.length < 8) {
              return "Must be at least 8 characters";
            }

            return true;
          }],

          confirm_password: [value => {
            let fields = this.form.fields;

            if (fields.password !== fields.confirm_password) {
              return "Passwords do not match";
            }

            return true;
          }],
        },

        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    context() {
      return this;
    },

    active_component() {
      return this.viewport.is_desktop ? ForgotPasswordDesktop : ForgotPasswordMobile;
    }
  },

  methods: {
    goto(route_name) {
      this.$router.push({ name: route_name });
    },

    resend() {
      this.active_step = 1;

      this.form.fields.username = "";
      this.form.field_errors = [];
      this.form.Errors = [];
    },

    reset_forms() {
      this.form.fields = {
        username: "",
        recovery_code: "",
        password: "",
        confirm_password: ""
      };
    },

    reset_errors() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit_step_one() {
      this.is_loading = true;

      this.reset_errors();

      let payload = {
        username: this.form.fields.username
      };

      api.users
        .request_password_reset(payload)
        .then(response => {
          this.active_step = 2;
        })
        .catch(exception => {
          this.set_form_errors(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    submit_step_two() {
      this.active_step = 3;
    },

    submit_step_three() {
      this.is_loading = true;

      let fields = this.form.fields;
      let payload = {
        token: fields.recovery_code,
        password: fields.password
      };

      api.users
        .reset_password_confirm(payload)
        .then(response => {
          this.active_step = 4;
        })
        .catch(exception => {
          this.set_form_errors(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    set_form_errors(exception) {
      let form = this.form;
      let field_errors = utils.form.get_field_errors(exception);
      let errors = utils.form.get_errors(exception);

      if (is_empty(field_errors) && is_empty(errors)) {
        errors = [
          {
            message: "There was an issue performing this action, if this persist please contact us.",
            type: "error"
          }
        ];
      }

      form.field_errors = field_errors;
      form.errors = errors;
    }
  }
};
</script>

<style>
</style>
