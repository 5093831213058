<template>
  <responsive-dialog
    ref="dialog"
    dialog-width="500px"
    :display-dialog="displayDialog"
    :dialog-title="dialogTitle"
    @closed="close"
  >
    <!-- MAIN CONTENT -->
    <template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-form v-model="form.isValid" ref="form" @submit.prevent="submitForm">
              <v-row no-gutters>
                <v-col cols="12">
                  <!-- FIELD: CARD DESCRIPTION -->
                  <v-text-field
                    label="Card Description"
                    v-model="form.data.description"
                    :rules="form.rules.description"
                    required
                    :messages="['e.g Discovery gold card']"
                    outlined
                  ></v-text-field>

                  <!-- FIELD: NAME ON CARD -->
                  <v-text-field
                    label="Name on Card"
                    v-model="form.data.cardholder_name"
                    :rules="form.rules.cardholder_name"
                    :disabled="isUpdate"
                    required
                    outlined
                  ></v-text-field>

                  <!-- FIELD: CARD NUMBER (USED WHEN ADDING A CARD) -->
                  <v-text-field
                    v-model="form.data.card_number"
                    :label="isUpdate ? 'Last Digits' : 'Card Number'"
                    :rules="form.rules.card_number"
                    :disabled="isUpdate"
                    @keyup="setCardType"
                    required
                    outlined
                  ></v-text-field>

                  <!-- FIELD: CARD TYPE -->
                  <v-text-field label="Card Type" v-model="form.data.card_type" :disabled="true" outlined />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-select
                    label="Exp. Month"
                    v-model="form.data.exp_month"
                    :rules="form.rules.exp_month"
                    :items="expMonths"
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="4">
                  <v-select
                    label="Exp. Year"
                    v-model="form.data.exp_year"
                    :rules="form.rules.exp_year"
                    :items="expYears"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12">
                  <!-- FIELD: CARD PIN -->
                  <v-text-field
                    label="Card Pin (CVV)"
                    v-model="form.data.cvv"
                    :rules="form.rules.cvv"
                    :counter="4"
                    maxlength="4"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="allowDirectPayment">
                <v-col cols="12">
                  <v-checkbox
                    v-model="storeCardInfo"
                    label="Store card information for later use?"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="displayIsDefault">
                <v-col cols="6">
                  <v-switch v-model="form.data.is_default" label="Set as default"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <v-spacer />
      <v-btn
        :loading="isLoading"
        :disabled="isLoading || !isDirty"
        type="button"
        color="primary"
        text
        @click.prevent="submitForm"
      >
        <v-icon>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import { ADD_MESSAGE } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import environmentMixin from "@/mixins/environment";

function createDataModel(vueInstance) {
  // TODO: environment variable should be on a global utility object or namespace
  return vueInstance.environment !== "production"
    ? {
      id: 0,
      card: "credit_card",
      description: "Emergency Card",
      card_type: "Visa",
      cardholder_name: "Rick Sanchez",
      card_number: "4012881888818888",
      exp_month: "03",
      exp_year: "2025",
      cvv: "999",
      owner_entity: 0,
      is_default: false
    }
    : {
      id: 0,
      card: "credit_card",
      card_type: "Visa",
      description: "",
      cardholder_name: "",
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvv: "",
      owner_entity: 0,
      is_default: false
    };
}

function createModelRules() {
  return {
    cardholder_name: [
      value => {
        return !!value || "Name on card is required.";
      }
    ],

    card_number: [
      value => {
        return !!value || "Card number is required.";
      }
    ],

    description: [
      value => {
        return !!value || "Card description is required.";
      }
    ],

    exp_month: [
      value => {
        return !!value || "Please select the expiration month of you card.";
      }
    ],

    exp_year: [
      value => {
        return !!value || "Please select the expiration year of you card.";
      }
    ],

    cvv: [
      value => {
        return !!value || "CVV code is required.";
      }
    ]
  };
}

function detectCardType(number) {
  const re = {
    Electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    Maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    Dankort: /^(5019)\d+$/,
    Interpayment: /^(636)\d+$/,
    Unionpay: /^(62|88)\d+$/,
    Visa: /^4[0-9]{0,}$/,
    MasterCard: /^5[1-5][0-9]{14}$/,
    "American Express": /^3[47][0-9]{13}$/, //American Express
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/
  };

  for (var key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
}

const AddCardDialog = Vue.extend({
  name: "AddCardDialog",

  components: {
    ResponsiveDialog
  },

  mixins: [trackDirtyFieldsMixin, environmentMixin],

  props: {
    customerId: { type: Number, default: 0 },
    allowDirectPayment: { type: Boolean, default: false }
  },

  data() {
    return {
      displayDialog: false,
      isLoading: false,
      isDirty: false,
      storeCardInfo: false,

      form: {
        isValid: false,

        initialData: createDataModel(this),
        data: createDataModel(this),
        rules: createModelRules(),
        errors: {}
      }
    };
  },

  computed: {
    isUpdate() {
      return !!this.form.data.id;
    },

    dialogTitle() {
      return this.isUpdate ? "Update card" : "New card";
    },

    baseUrl() {
      return !this.customerId ? "pci-vault/card-profile/" : "invoiceboost/customer-card-profile/";
    },

    expMonths() {
      return [
        { text: "01", value: "01" },
        { text: "02", value: "02" },
        { text: "03", value: "03" },
        { text: "04", value: "04" },
        { text: "05", value: "05" },
        { text: "06", value: "06" },
        { text: "07", value: "07" },
        { text: "08", value: "08" },
        { text: "09", value: "09" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" }
      ];
    },

    expYears() {
      const years = [];
      const currentYear = new Date().getFullYear();

      for (let i = 0; i < 20; i++) {
        years[i] = (currentYear + i).toString();
      }

      return years;
    },

    formHasErrors() {
      return Object.keys(this.form.errors).length;
    },

    ownerEntity() {
      return this.customerId ? { id: this.customerId } : this.$store.state.auth.entity;
    },

    settings() {
      return this.$store.state.entitySettings.detail;
    },

    displayIsDefault() {
      if (this.allowDirectPayment) {
        return this.allowDirectPayment && this.storeCardInfo;
      }
      return true;
    }
  },

  mounted() {
    this.trackDirtyFields();
  },

  methods: {
    new() {
      Object.assign(this.form.initialData, createDataModel(this));
      Object.assign(this.form.data, createDataModel(this));

      return this.open();
    },

    edit(creditCard) {
      Object.assign(this.form.initialData, {}, creditCard);
      Object.assign(this.form.data, {}, creditCard);

      return this.open();
    },

    open() {
      this.resetValidation();
      this.displayDialog = true;
      return this;
    },

    close() {
      this.displayDialog = false;
      this.$emit("closed");
      this.resetValidation();
      return this;
    },

    submitForm() {
      if (this.allowDirectPayment && !this.storeCardInfo) {
        const cardData = this.prepRegisterCardData();

        this.$emit("CardPaymentSubmitted", { card_profile: cardData, store_card: this.storeCardInfo });
        this.close();
      } else {
        const isUpdate = this.isUpdate;

        const action = isUpdate ? "updated" : "added";
        const promise = isUpdate ? this.updateCreditCard() : this.registerCreditCard();
        const eventName = isUpdate ? "CreditCardUpdated" : "CreditCardAdded";

        this.isLoading = true;

        promise
          .then(response => {
            const creditCard = response.data;
            Object.assign(this.form.initialData, {}, creditCard);
            Object.assign(this.form.data, {}, creditCard);

            this.displayMessage({ text: `Your Payment Method have been ${action} successfully.`, type: "success" });
            this.$emit(eventName, Object.assign({}, creditCard));
            this.close();
          })
          .catch(error => {
            this.displayMessage({
              text: "There was an error performing this action, if this persist please contact us.",
              type: "error"
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    prepRegisterCardData() {
      const dataCopy = Object.assign({}, this.form.data);

      dataCopy.owner_entity = this.ownerEntity.id;
      return dataCopy;
    },

    registerCreditCard() {
      const payload = this.prepRegisterCardData();

      return this.$http.post(this.baseUrl, payload);
    },

    updateCreditCard() {
      const url = `${this.baseUrl}${this.form.data.id}/`;
      const data = this.form.data;
      const dataCopy = {
        exp_month: data.exp_month,
        exp_year: data.exp_year,
        cvv: data.cvv,
        is_default: data.is_default,
        owner_entity: this.ownerEntity.id
      };

      return this.$http.patch(url, dataCopy);
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField("form.data.description", "form.initialData.description");
      this.trackDirtyField("form.data.cardholder_name", "form.initialData.cardholder_name");
      this.trackDirtyField("form.data.card_number", "form.initialData.card_number");
      this.trackDirtyField("form.data.exp_month", "form.initialData.exp_month");
      this.trackDirtyField("form.data.exp_year", "form.initialData.exp_year");
      this.trackDirtyField("form.data.cvv", "form.initialData.cvv");
      this.trackDirtyField("form.data.is_default", "form.initialData.is_default");
    },

    // EVENT HANDLERS
    setCardType() {
      this.form.data.card_type = detectCardType(this.form.data.card_number.trim());
    },

    resetValidation() {
      this.form.field_errors = {};
      this.form.errors = [];

      let $form = this.$refs.form;

      if ($form) {
        $form.resetValidation();
      }

      return this;
    },
  }
});

export default AddCardDialog;
</script>
