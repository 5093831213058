<template>
  <v-dialog
    :value="isOpen"
    :fullscreen="formFullscreen"
    :width="dialogWidth"
    :content-class="contentClass"
    max-width="1000px"
    scrollable
    persistent
    @click:outside="close"
  >
    <v-card :id="id" class="responsive-dialog" :class="dialogClass">
      <v-card-title>
        {{ dialogTitle }}

        <v-spacer />

        <slot name="app-bar-right"></slot>

        <v-btn icon @click.stop="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-6">
        <slot />
      </v-card-text>

      <v-divider class />

      <v-card-actions v-if="showActions" class="px-6 py-3">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ResponsiveDialog",

  props: {
    id: { type: String, default: "" },
    displayDialog: { type: Boolean, default: false },
    showActions: { type: Boolean, default: true },
    dialogTitle: { type: String, default: "Title" },
    dialogWidth: { type: String, default: "900px" },
    contentClass: { type: String, default: "" },
    dialogClass: { type: String, default: "" }
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    formFullscreen() {
      // Calculates if the form should be shown in fullscreen mode or not depending on the display size
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }

      return false;
    }
  },

  watch: {
    displayDialog: {
      handler(value) {
        if (value) {
          this.open();
        } else {
          this.close();
        }
      }
    }
  },

  methods: {
    open() {
      this.isOpen = true;
      return this;
    },

    close() {
      this.isOpen = false;
      this.$emit("closed");
      return this;
    }
  }
};
</script>

<style>
.responsive-dialog .v-card__actions {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
