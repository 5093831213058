<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Reset Password</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items></v-toolbar-items>
    </v-toolbar>

    <v-card-text>

      <non-field-errors :errors="form.errors"/>

      <v-form ref="reset_password_form" :lazy-validation="false">
        <v-text-field
            v-model="form.fields.password"
            prepend-icon="mdi-lock"
            name="ForgotPassword[NewPassword]"
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="form.field_errors.password"
            :rules="form.rules.password"
            @click:append="showPassword = !showPassword"
        />

        <v-text-field
            v-model="form.fields.confirm_password"
            prepend-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            label="Confirm Password"
            name="ForgotPassword[ConfirmNewPassword]"
            :rules="form.rules.confirm_password"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          :disabled="context.is_loading"
          :loading="context.is_loading"
          large
          @click.stop="confirm_reset_password"
      >
        Reset Password
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "ResetPasswordConfirmDesktop",

  components: {
    NonFieldErrors
  },

  props: {
    context: {
      type: Object, default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      showPassword: false,
      sendingRequest: false,
      token: this.$route.query.token,

      formErrors: {}
    };
  },

  computed: {
    form() {
      return this.context.form;
    },

    fields() {
      return this.context.form.fields;
    },

    show_password: {
      get() {
        return this.context.show_password;
      },

      set(value) {
        this.context.show_password = value;
      }
    }
  },

  methods: {
    /*** Event handlers ***/
    confirm_reset_password() {
      if (this.$refs.reset_password_form.validate()) {
        this.context
            .confirm_reset_password()
            .then(() => {
              this.context.goto("login");
            })
            .catch(() => {
            });
      }
    },

    setFormErrors(exception) {
      this.context.set_form_errors(exception);
    },
  }
};
</script>

<style></style>
