import { getActions, getGetters, getMutations, getState, ModelState, RootState } from "@/vuex-bcore";
import { Module } from "vuex";
import { Model } from "./types";

const actions = getActions<ModelState<Model>>("pos/tickets");
const state = getState<Model>();
const getters = getGetters<Model>();
const mutations = getMutations<Model>();

const ticket: Module<ModelState<Model>, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default ticket;
