<template>
  <v-dialog v-model="display_dialog" fullscreen>
    <v-card class="customer-editor-mobile rounded-0">
      <v-card-title>
        <span class="headline">NEW CUSTOMER</span>

        <v-spacer></v-spacer>

        <v-btn class="close-dialog" @click="close" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="content">
        <v-form ref="form" v-model="form.isValid" :lazy-validation="false" @submit.prevent="submitForm">
          <v-container class="pa-0">
            <!-- DISPLAY GENERIC ERRORS -->
            <non-field-errors :errors="form.errors"></non-field-errors>

            <!-- FIELD: CUSTOMER NAME -->
            <v-row>
              <v-col>
                <label class="c-label">Customer Name</label>
                <v-text-field
                  name="Customer[Name]"
                  hide-details="auto"
                  autocomplete="on"
                  v-model="form.fields.name"
                  :rules="form.rules.name"
                  :error-messages="form.field_errors.name"
                  :loading="is_loading"
                  outlined
                  required
                />
              </v-col>
            </v-row>

            <!-- FIELD: EMAIL -->
            <v-row>
              <v-col>
                <label class="c-label">E-mail</label>
                <v-text-field
                  name="Customer[Email]"
                  hide-details="auto"
                  autocomplete="on"
                  v-model="form.fields.email"
                  :rules="form.rules.email"
                  :error-messages="form.field_errors.email"
                  :disabled="is_loading"
                  outlined
                  required
                />
              </v-col>
            </v-row>

            <!-- FIELD: COMPANY NAME -->
            <!--            <v-row>-->
            <!--              <v-col>-->
            <!--                <label class="c-label">-->
            <!--                  Business Name (-->
            <!--                  <small>optional</small>-->
            <!--                  )-->
            <!--                </label>-->
            <!--                <v-text-field-->
            <!--                  name="Customer[Company]"-->
            <!--                  hide-details="auto"-->
            <!--                  autocomplete="on"-->
            <!--                  v-model="form.fields.company"-->
            <!--                  :rules="form.rules.company"-->
            <!--                  :error-messages="form.field_errors.company"-->
            <!--                  :disabled="is_loading"-->
            <!--                  outlined-->
            <!--                  required-->
            <!--                />-->
            <!--              </v-col>-->
            <!--            </v-row>-->

            <v-row v-if="!display_more_fields">
              <v-col>
                <a
                  class="text-decoration-none mt-2 d-inline-block"
                  style="font-size: 18px"
                  @click.prevent="expand_form"
                >
                  <v-icon class="mr-1">mdi-plus-circle</v-icon>
                  More fields
                </a>
              </v-col>
            </v-row>

            <!-- ADDRESS -->
            <div v-if="display_more_fields && form.fields.address">
              <h5 class="mb-6 mt-8" style="font-size: 18px; color: #000">Billing address</h5>

              <!-- FIELD: ADDRESS LINE 1 -->
              <v-row>
                <v-col>
                  <label class="c-label">Address Line 1</label>
                  <v-text-field
                    name="Customer[AddressLine1]"
                    hide-details="auto"
                    autocomplete="on"
                    v-model="form.fields.address.address_line1"
                    :error-messages="form.field_errors['address.address_line1']"
                    :disabled="is_loading"
                    outlined
                    required
                  />
                </v-col>
              </v-row>

              <!-- FIELD: ADDRESS LINE 2 -->
              <v-row>
                <v-col>
                  <label class="c-label">Address Line 2</label>
                  <v-text-field
                    name="Customer[AddressLine2]"
                    hide-details="auto"
                    autocomplete="on"
                    v-model="form.fields.address.address_line2"
                    :error-messages="form.field_errors['address.address_line2']"
                    :disabled="is_loading"
                    outlined
                  />
                </v-col>
              </v-row>

              <!-- FIELD: CITY -->
              <v-row>
                <v-col>
                  <label class="c-label">City</label>
                  <v-text-field
                    name="Customer[City]"
                    hide-details="auto"
                    autocomplete="on"
                    v-model="form.fields.address.city"
                    :error-messages="form.field_errors['address.city']"
                    :disabled="is_loading"
                    outlined
                    required
                  />
                </v-col>
              </v-row>

              <!-- FIELD: ADDRESS STATE -->
              <v-row>
                <v-col>
                  <label class="c-label">State</label>
                  <v-text-field
                    name="Customer[State]"
                    hide-details="auto"
                    autocomplete="on"
                    v-model="form.fields.address.state"
                    :error-messages="form.field_errors['address.state']"
                    :disabled="is_loading"
                    outlined
                    required
                  />
                </v-col>
              </v-row>

              <!-- FIELD: ZIP CODE -->
              <v-row>
                <v-col>
                  <label class="c-label">Zip code</label>
                  <v-text-field
                    name="Customer[ZipCode]"
                    hide-details="auto"
                    autocomplete="on"
                    v-model="form.fields.address.code"
                    :error-messages="form.field_errors['address.code']"
                    :disabled="is_loading"
                    outlined
                    required
                  />
                </v-col>
              </v-row>

              <!-- FIELD: COUNTRY -->
              <v-row>
                <v-col>
                  <label class="c-label">Country</label>
                  <country-search
                    name="Customer[Country]"
                    label=""
                    ref="country_search"
                    :field-errors="form.field_errors['address.country']"
                    :disabled="is_loading"
                    @change="onCountrySelected"
                    clearable
                  />
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center flex-column">
        <v-btn
          class="action add-customer"
          color="primary"
          ref="submit_button"
          :loading="is_loading"
          :disabled="is_loading || !isDirty || !hasWritePermission"
          @click.prevent="submitForm"
          large
        >
          ADD CUSTOMER
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import CountrySearch from "@/bcore/components/country/CountrySearch.vue";

import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import { ADD_MESSAGE } from "@/store/mutation-types";

import api from "@/api";

import deep_copy from "@/utilities/deep_copy";
import settingsMixin from "@/mixins/settings";
import permissionMixin from "@/mixins/permission";
import utils from "@/utilities";

const is_empty = utils.is_empty;

export default {
  name: "CustomerEditorMobile",

  components: {
    NonFieldErrors,
    CountrySearch
  },

  mixins: [trackDirtyFieldsMixin, settingsMixin, permissionMixin],

  props: {},

  data() {
    return {
      display_dialog: false,

      is_loading: false,
      display_more_fields: false,
      defaultCountryId: null,

      form: {
        isValid: false,

        fields: this.create_customer_model(),
        fieldsInitialData: this.create_customer_model(),
        fieldsInitialAddressData: this.create_address_model(),

        rules: {
          name: [value => !!value || "Name is required."],
          email: [
            value => {
              if (value && value.length > 0) {
                return /.+@.+\..+/.test(value) || "E-mail must be in a valid format.";
              }

              return true;
            }
          ]
        },

        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    /* SHORTCUTS */

    targetEntity() {
      return this.form.customer;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    entitySettings() {
      return this.$store.state.entitySettings.detail;
    },

    hasWritePermission() {
      return this.hasPermission("change_customer");
    }
  },

  mounted() {
    // Intentionally executing this fetch early as possible
    this.fetchDefaultCountry();
  },

  methods: {
    create_customer_model(data) {
      let model = {
        id: null,
        name: "",
        email: "",
        company: null,
        default_currency: "USD",
        enable_auto_charge: false,
        enable_email_communication: true,
        owner_entity: null,
        address: null
      };

      if (data) {
        return deep_copy(data, model);
      }

      return model;
    },

    create_address_model(data) {
      let model = {
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        code: "",
        country: null
      };

      if (data) {
        return deep_copy(data, model);
      }

      return model;
    },

    new() {
      // const data = this.create_customer_model();
      // return this.open(data);
      this.display_dialog = true;

      const data = this.create_customer_model();
      return this.open(data);
    },

    open(data) {
      if (!data) {
        return null;
      }

      this.resetStates();
      this.resetValidation();

      // Clone data to prevent sharing state
      this.form.fields = this.create_customer_model(data);
      this.form.fieldsInitialData = this.create_customer_model(data);

      if (data.address) {
        this.form.fieldsInitialAddressData = this.create_address_model(data);
        this.$nextTick(() => {
          // update country search selection
          this.$refs.country_search.select(this.form.fields.address.country);
          this.trackDirtyAddressFields();
        });
      }

      this.display_dialog = true;

      return this;
    },

    close() {
      this.display_dialog = false;
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetStates() {
      this.is_loading = false;
      this.isSendingInvite = false;
      this.enableSendInvite = true;

      return this;
    },

    resetValidation() {
      this.form.field_errors = {};
      this.form.errors = [];

      let $form = this.$refs.form;

      if ($form) {
        $form.resetValidation();
      }

      return this;
    },

    submitForm() {
      if (!this.validate()) {
        return;
      }

      this.resetValidation();

      const is_update = this.isUpdate;
      const has_email = this.hasEmail;
      const enable_send_invite = this.enableSendInvite;

      const action = is_update ? "updated" : "created";
      const promise = is_update ? this.updateCustomer() : this.createCustomer();
      const form = this.form;

      this.is_loading = true;

      return promise
        .then(customer => {
          // update local data with the server response
          form.fields = this.create_customer_model(customer);
          form.fieldsInitialData = this.create_customer_model(customer);

          this.displayMessage({ text: `The customer has been ${action} successfully.`, type: "success" });
          this.$emit(action, this.create_customer_model(customer));
        })
        .catch(exception => {
          let form = this.form;
          let field_errors = utils.form.get_field_errors(exception);
          let errors = utils.form.get_errors(exception);

          if (is_empty(field_errors) && is_empty(errors)) {
            errors = [
              {
                message: "There was an issue performing this action, if this persist please contact us.",
                type: "error"
              }
            ];
          }

          form.field_errors = field_errors;
          form.errors = errors;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    createCustomer() {
      const customer = this.form.fields;
      customer.owner_entity = this.ownerEntity.id;

      return api.customers.create(customer).then(response => {
        return response.data;
      });
    },

    updateCustomer() {
      const customer = this.form.fields;

      return api.customers.update(customer).then(response => {
        return response.data;
      });
    },

    expand_form(address) {
      this.form.fields.address = this.create_address_model();
      this.$nextTick(() => {
        // set default country selection
        this.form.fields.address.country = this.defaultCountryId;
        this.$refs.country_search.select(this.defaultCountryId);
      });
      this.display_more_fields = true;
      this.trackDirtyAddressFields();
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    fetchDefaultCountry() {
      const organization_id = this.entitySettings.id;

      api.organizations
        .fetch_address(organization_id)
        .then(response => {
          this.defaultCountryId = response.data.country;
        })
        .catch(error => {
          // this.displayMessage({ text: "Unable to load default country.", type: "error" });
        });
    },

    /* EVENT HANDLERS */

    onCountrySelected(country) {
      this.form.fields.address.country = country && country.id ? country.id : null;
    },

    onCurrencyChange(currency) {
      this.form.fields.default_currency = currency;
    },

    /* HELPERS */

    trackDirtyAddressFields() {
      const address = "form.fields.address";
      const address_initial_data = "form.fieldsInitialData.address";

      this.trackDirtyField(`${address}.address_line1`, `${address_initial_data}.address_line1`);

      this.trackDirtyField(`${address}.address_line2`, `${address_initial_data}.address_line2`);

      this.trackDirtyField(`${address}.city`, `${address_initial_data}.city`);
      this.trackDirtyField(`${address}.state`, `${address_initial_data}.state`);
      this.trackDirtyField(`${address}.code`, `${address_initial_data}.code`);
      this.trackDirtyField(`${address}.country`, `${address_initial_data}.country`);
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button

      this.trackDirtyField(
        "form.fields.enable_email_communication",
        "form.fieldsInitialData.enable_email_communication"
      );

      this.trackDirtyField("form.fields.default_currency", "form.fieldsInitialData.default_currency");
      this.trackDirtyField("form.fields.name", "form.fieldsInitialData.name");
      this.trackDirtyField("form.fields.last_name", "form.fieldsInitialData.last_name");
      this.trackDirtyField("form.fields.email", "form.fieldsInitialData.email");
    }
  }
};
</script>

<style scoped>
.v-dialog .customer-editor-mobile > .v-card__title {
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 24px;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.2;
}

.v-dialog .customer-editor-mobile > .v-card__text {
  padding-top: 24px;
}

.v-dialog .customer-editor-mobile .close-dialog {
  min-width: auto !important;
  padding: 0 8px !important;
}

.v-dialog .customer-editor-mobile .c-label {
  line-height: 2.5;
  font-size: 18px;
  color: gray;
}

.v-dialog .customer-editor-mobile .v-card__actions {
  padding: 8px 24px;
}

.v-dialog .customer-editor-mobile .v-card__actions .action {
  width: 100%;
}

.customer-editor-mobile .v-input--is-disabled input {
  color: #757575;
}

.customer-editor-mobile .v-input--is-disabled .v-text-field__details > .v-messages {
  color: #263238 !important;
}

.customer-editor-mobile strong {
  color: #424242;
}

.customer-editor-mobile .non-field-errors {
  margin-bottom: 0;
}

.customer-editor-mobile .invite-column {
  position: relative;
}

.customer-editor-mobile .invite-column .caption {
  position: absolute;
  left: 50px;
}
</style>
