<template>
  <div
    id="drop-area"
    @dragenter.prevent="saveDragTarget($event)"
    @dragleave.prevent="hideOverlay($event)"
    @dragover.prevent="showOverlay"
    @drop.prevent="handleDrop($event)"
    @click.stop="openFileBrowser"
  >
    <slot />
    <input
      ref="fileInput"
      type="file"
      class="media-input"
      multiple
      accept="*"
      @change="handleFiles($event.target.files)"
    />
    <v-overlay absolute :value="overlay" class="text-center">
      <h3>Drop File to Upload</h3>
      <v-icon x-large color="white">mdi-camera</v-icon>
    </v-overlay>
  </div>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "MediaField",
  data() {
    return {
      overlay: false,
      loading: false,
      dragTarget: null
    };
  },
  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    }
  },
  mounted() {
    this.$emit("uploadPercentage", 0);
    // eventHub.$on("clickMediaUpload", this.openFileBrowser);
  },
  beforeDestroy() {
    // eventHub.$off("clickMediaUpload");
  },
  methods: {
    openFileBrowser() {
      this.$refs["fileInput"].click();
    },
    preventDefaults(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    saveDragTarget(event) {
      this.dragTarget = event.target;
    },
    showOverlay() {
      this.overlay = true;
    },
    hideOverlay(event) {
      if (this.dragTarget === event.target) {
        this.overlay = false;
      }
    },
    handleDrop(event) {
      this.overlay = false;
      const dt = event.dataTransfer;
      const files = dt ? dt.files : null;
      files && this.handleFiles(files);
    },
    handleFiles(files) {
      Array.from(files).forEach(this.uploadFile);
    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("description", file.name);
      formData.append("owner_entity", `${this.ownerEntity.id}`);

      try {
        const response = await this.$http.post("media/", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressEvent => {
            this.$emit("uploadPercentage", Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }
        });
        this.$emit("updated", response.data);
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Upload Failed", type: "error" });
      }
      this.$emit("uploadPercentage", 0);
    }
  }
};
</script>

<style>
.media-input {
  display: none;
}
</style>
