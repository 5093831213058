<template>
  <v-container class="login-desktop d-flex flex-column justify-center" style="height: 80vh">
    <div class="mx-auto">
      <v-img
        class="flex-grow-0 mb-6 logo"
        :max-width="464"
        :max-height="200"
        color="accent"
        v-if="domain_theme.logo"
        :src="domain_theme.logo"
        contain
      />

      <v-card elevation="1">
        <v-card-text class="pa-6">
          <non-field-errors :errors="form.errors" />

          <v-form @submit.prevent="context.submit">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    name="username"
                    placeholder="Email"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    v-model="form.fields.username"
                    :error-messages="form.field_errors.username"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    name="password"
                    placeholder="Password"
                    prepend-inner-icon="mdi-lock"
                    hide-details="auto"
                    v-model="form.fields.password"
                    :type="context.showPass ? 'text' : 'password'"
                    :error-messages="form.field_errors.password"
                    :append-icon="context.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="context.toggle_password_visibility"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex align-center">
                  <v-checkbox
                    class="mt-0 pt-0"
                    label="Trust this computer"
                    v-model="form.fields.trusted_device"
                    hide-details
                  />

                  <v-spacer></v-spacer>

                  <v-btn
                    type="submit"
                    color="primary"
                    class="px-8"
                    :disabled="context.is_loading"
                    :loading="context.is_loading"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <p class="trusted-pc-notice px-4 mt-4">
            Trusted computers will have extended sessions. Don't enable this option on public computers.
          </p>
          <p>
            <router-link class="forgot-password text-decoration-none px-4 mt-4" color="accent" :to="{ name: 'forgot-password' }" text>
              Forgot password?
            </router-link>
          </p>
        </v-card-text>
      </v-card>

      <div class="pt-6 text-center">
        <router-link :to="{ name: 'signup' }" class="text-decoration-none grey--text">
          Don't have an account?
          <strong class="black--text">Sign Up</strong>
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "LoginDesktop",

  components: { NonFieldErrors },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {};
  },

  computed: {
    domain_theme() {
      return this.$store.state.theme.customDomain;
    },

    form() {
      return this.context.form;
    }
  },

  methods: {}
};
</script>

<style>
.login-desktop .v-card {
  min-width: 450px;
  max-width: 450px;
}

.login-desktop .v-card .trusted-pc-notice {
  font-size: 12px;
}
</style>
