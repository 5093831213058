<template>
  <responsive-dialog
    content-class="truck-form-dialog"
    :display-dialog="formOpened"
    :dialog-title="formTitle"
    @closed="closeForm"
  >
    <v-container class="pa-0">
      <v-form ref="form" v-model="isFormValid" :lazy-validation="false" @submit.prevent="submitForm">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field v-model="form.model" label="Model" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="form.is_active" :error-messages="formErrors.is_active" label="Is active" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="6">
            <v-text-field v-model="form.registration" label="Registration" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="truck_permit_exp_date"
              v-model="showExpDateCalendar"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="300px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.permit_exp_date"
                  label="Permit Expiration Date"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="formLoading"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.permit_exp_date"
                no-title
                @input="showExpDateCalendar = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="6">
            <v-text-field v-model="form.description" label="description" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="form.number" label="number" outlined></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <template slot="actions">
      <v-spacer />

      <v-btn type="submit" color="primary" :loading="formLoading" :disabled="disableSave" @click="submitForm" text>
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import { parseFormErrors } from "@/utilities/form";

import api from "@/api";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";

function createTruckModel() {
  return {
    owner_entity: null,
    description: null,
    is_active: true
  };
}

function createModelRules() {
  return {
    customer: [value => !!value || false]
  };
}

export default {
  name: "TruckForm",

  components: {
    ResponsiveDialog
  },

  data() {
    return {
      isLoading: false,
      formOpened: false,

      showExpDateCalendar: false,

      form: createTruckModel(),
      rules: createModelRules(),
      formErrors: {},
      isFormValid: false
    };
  },

  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    formTitle() {
      return this.isUpdate ? `Update Truck information` : `New Truck`;
    },

    formLoading() {
      const action = this.$store.getters["truck/loading"];

      return this.isLoading || action("create") || action("update") || action("detail");
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.form.id;
    },

    disableSave() {
      return !!this.formLoading;
    }
  },

  methods: {
    new() {
      this.resetValidation();

      this.form = createTruckModel();

      this.form.owner_entity = this.ownerEntity.id;

      this.formOpened = true;
    },

    open(item) {
      if (item) {
        if (item.id) {
          this.form = { ...item };
        }
        this.formOpened = true;
      }
    },

    resetValidation() {
      this.formErrors = {};
    },

    submitForm() {
      this.isLoading = true;
      this.resetValidation();

      const isUpdate = this.isItemUpdate;
      const event_name = isUpdate ? "updated" : "created";
      const promise = isUpdate ? this.update() : this.create();

      promise
        .then(response => {
          return response.data;
        })
        .then(() => {
          this.displayMessage({ text: `Truck ${event_name} successfully.`, type: "success" });
          this.$emit("updated", this.form);
          this.closeForm();
        })
        .catch(error => {
          this.setFormErrors(error.response.data);
          this.displayMessage({ text: "There was an error saving the truck information.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    create() {
      return api.truck.create(this.form);
    },

    update() {
      return api.truck.update(this.form);
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    closeForm() {
      this.formOpened = false;
      this.resetFormErrors();
      this.$emit("closed");
    },

    resetFormErrors() {
      this.formErrors = {};
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style>
.truck-form-dialog .v-alert + .v-alert {
  margin-top: 16px;
}

.truck-form-dialog .order-items {
  min-height: 200px;
}
</style>
