<template>
  <div class="creditcard-manager">
    <v-list flat subheader>
      <!-- SECTION: ADD ACH ACCOUNT METHOD  -->
      <v-subheader v-if="hasPermission('add_achaccount', 'payment_profiles')">

        <v-btn text @click.stop="newACHAccount">
          <v-icon left>mdi-bank-plus</v-icon>
          Add ACH Account
        </v-btn>

        <add-ach-account-dialog
          ref="payment_methods_form"
          @ACHAccountAdded="fetchPaymentMethods"
          @ACHAccountUpdated="fetchPaymentMethods"
          @ACHAccountSubmitted="processPayment"
          :customer-id="customerId"
        />
      </v-subheader>

      <!-- MESSAGE TO DISPLAY WHEN THERE ARE NO ACCOUNTS REGISTERED -->
      <v-list-item v-if="ACHAccounts.length === 0">
        <v-list-item-content>
          <v-card outlined :loading="isLoading" class="payment-method">
            <v-card-text class="payment-method__header">
              <v-row dense>
                <v-col>
                  <span v-if="isLoading" class="text--primary">Loading...</span>
                  <span v-if="!isLoading" class="text--primary">There are no ACH accounts registered yet.</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>

      <!-- LIST OF ACCOUNTS -->
      <v-list-item v-for="(ach, index) in ACHAccounts" :key="index">
        <v-list-item-content>
          <!-- PAYMENT METHOD -->

          <v-card
            outlined
            class="payment-method"
            :class="{ 'payment-method--selected': ach.state.is_selected && allowSelectors }"
          >
            <v-card-text class="payment-method__header">
              <v-row dense>
                <v-col>
                  <!-- CC SELECT MODE -->
                  <v-icon
                    v-if="allowSelectors"
                    left
                    @click="onSelectedAccount(ach)"
                    class="creditcard-manager__selector black-text"
                  >
                    <!-- FORCE NEW LINE -->
                    {{ ach.state.is_selected ? "mdi-radiobox-marked" : "mdi-radiobox-blank" }}
                  </v-icon>

                  <v-icon left>mdi-bank</v-icon>
                  <span class="text--primary font-weight-bold" v-if="ach.description">{{ ach.description }}</span>
<!--                  <span class="text&#45;&#45;primary font-weight-bold" v-else-if="ach.bank_name">{{ ach.bank_name }}</span>-->
                  <span class="text--primary font-weight-bold" v-else-if="ach.name">{{ ach.name }}</span>

                </v-col>

                <!-- HIDDEN ACCOUNT NUMBER -->
                <v-col class="text-center">
                  Account No.
                  <span class="text--primary font-weight-bold">{{ ach.account_number }}</span>

                  &nbsp;<span class="text--primary text-sm-caption" v-if="ach.account_type">
                    ({{ ach.account_type }})
                  </span>
                </v-col>

                <!-- HIDDEN ROUTING NUMBER -->
                <v-col class="text-center">Routing No. {{ ach.routing_number }}</v-col>

                <v-col v-if="ach.is_default">
                  <v-chip small>Default account</v-chip>
                </v-col>

                <v-col v-if="allowExpand">
                  <v-btn
                    icon
                    class="expand-button"
                    @click="ach.state.is_expanded = !ach.state.is_expanded"
                  >
                    <v-icon>{{ ach.state.is_expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-show="ach.state.is_expanded" class="payment-method__details">
              <v-expand-transition>
                <div>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-row>
                      <v-col cols="6" no-gutters>
                        <v-switch
                          label="Set as default globally"
                          v-model="ach.is_default"
                          :disabled="ach.state.is_loading"
                          :loading="ach.state.is_toggling_default"
                          @change="toggleGlobalDefault(cc, $event)"
                        ></v-switch>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col class="text-right">
                        <v-btn
                          v-if="hasPermission('delete_cardprofile', 'pci_vault')"
                          text
                          small
                          :disabled="ach.state.is_loading"
                          @click="deleteAccount(ach)"
                          class="justify-end"
                          color="red"
                        >
                          <!-- just for format -->
                          Remove
                        </v-btn>
                        <v-btn
                          v-if="hasPermission('change_cardprofile', 'pci_vault')"
                          text
                          small
                          :disabled="ach.state.is_loading"
                          @click="editAccount(ach)"
                          color="success"
                        >
                          <!-- just for format -->
                          Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- PAYMENT METHOD: DELETE DIALOG -->
    <delete-dialog
      :object-name="deleteObjectText"
      :open="displayDeleteDialog"
      @cancelled="closeDeleteDialog"
      @deleted="onDeleteDialogConfirmed"
    />
  </div>
</template>

<script>
import Vue from "vue";

import { ADD_MESSAGE } from "@/store/mutation-types";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";

import permissionMixin from "@/mixins/permission";
import AddAchAccountDialog from "@/invoiceboost/views/bill/AddAchAccountDialog";

const defaultObject = () => {
  return {};
};

export default Vue.extend({
  name: "AchAccountForm",

  components: {
    AddAchAccountDialog,
    DeleteDialog,
  },

  mixins: [permissionMixin],

  props: {
    allowExpand: { type: Boolean, default: true },
    allowSelectors: { type: Boolean, default: false },
    customerId: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      displayDialog: false,
      displayDeleteDialog: false,

      deleteACHAccount: null,
      selectedCard: null,
      accountStates: {},

      ACHAccounts: []
    };
  },

  computed: {
    deleteObjectText() {
      const deleteACHAccount = this.deleteACHAccount;
      if (!this.deleteACHAccount) {
        return "";
      }

      return `Payment Method ending in ${deleteACHAccount.account_number}`;
    },

    ownerEntity() {
      return this.customerId ? { id: this.customerId } : this.$store.state.auth.entity.id;
    },

    isCustomerManager() {
      return !!(this.customerId && this.customerId !== this.$store.state.auth.entity.id);
    },

    baseUrl() {
      return !this.customerId ? "payment-profiles/ach-accounts/" : "payment-profiles/ach-accounts/list-customer-accounts";
    }
  },

  mounted() {
    this.fetchPaymentMethods();
  },

  methods: {
    fetchPaymentMethods() {
      this.isLoading = true;

      const accountFilter = this.customerId ? `?customer_id=${this.customerId}` : "";

      this.$http
        .get(this.baseUrl + accountFilter)
        .then(response => {
          // different endpoints
          const achAccounts = accountFilter ? response.data : response.data.results;

          // Sort accounts by id to keep the list consistent from old to new
          achAccounts.sort(function (a, b) {
            return a.id - b.id;
          });

          for (let ach of achAccounts) {
            ach.state = this.getAccountState(ach.id);
            ach.state.is_selected = false;

            // Identify the default account and mark it as selected
            if (ach.is_default) {
              ach.state.is_selected = true;
              this.$emit("ACHAccountSelected", ach);
            }
          }

          this.ACHAccounts = achAccounts;
        })
        .catch(() => {
          this.displayMessage({
            text:
              "There was an error while fetching the data. Please try refreshing the page and contact us if this problem persist.",
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    newACHAccount() {
      this.$refs.payment_methods_form.new();
    },

    editAccount(achAccount) {
      this.$refs.payment_methods_form.edit(achAccount);
    },

    deleteAccount(achAccount) {
      this.deleteACHAccount = achAccount;
      this.displayDeleteDialog = true;
    },

    toggleGlobalDefault(achAccount, value) {
      const state = achAccount.state;

      // Disables Remove and Update buttons while a server update is being performed
      state.is_loading = true;

      // Displays loading icon on switch component
      state.is_toggling_default = true;

      const url = `${this.baseUrl}${achAccount.id}/`;
      const dataCopy = {
        is_default: achAccount.is_default,
        owner_entity: this.ownerEntity.id
      };

      this.$http
        .patch(url, dataCopy)
        .catch(() => {
          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
        })
        .then(() => {
          this.fetchPaymentMethods();
        })
        .finally(() => {
          state.is_loading = false;
          state.is_toggling_default = false;
        });
    },

    // EVENT HANDLERS

    onDeleteDialogConfirmed() {
      const deleteACHAccount = this.deleteACHAccount;

      this.$http
        .delete(`${this.baseUrl}${this.deleteACHAccount.id}`)
        .then(response => {
          const achAccounts = this.ACHAccounts;
          const foundAccount = achAccounts.find(ach => ach.id === deleteACHAccount.id);

          if (foundAccount) {
            achAccounts.splice(achAccounts.indexOf(foundAccount), 1);
          }

          this.closeDeleteDialog();
        })
        .catch(() => {
          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
        });
    },

    closeDeleteDialog() {
      this.deleteACHAccount = null;
      this.displayDeleteDialog = false;
    },

    onSelectedAccount(achAccount) {
      const achAccounts = this.ACHAccounts;

      for (let i = 0; i < achAccounts.length; i++) {
        let item = achAccounts[i];
        item.state.is_selected = item.id === achAccount.id;
      }

      this.$emit("ACHAccountSelected", achAccount);
    },

    // UTILITIES
    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    getAccountState(accountId) {
      const accountStates = this.accountStates;
      let state = accountStates[accountId];

      if (!state) {
        state = {
          is_selected: false,
          is_expanded: false,
          is_loading: false,
          is_toggling_default: false
        };

        Vue.set(accountStates, accountId, state);
      }

      return state;
    },

    processPayment(paymentInfo) {
      this.$emit("ACHAccountSubmitted", paymentInfo);
    }
  }
});
</script>

<style>
.creditcard-manager {
  padding-bottom: 48px;
}

.creditcard-manager .v-list {
  padding-top: 10px;
  padding-bottom: 0;
}

.creditcard-manager .v-subheader {
  padding-left: 24px;
  padding-right: 24px;
}

.creditcard-manager .v-list-item__content {
  overflow: visible;
}

.creditcard-manager .payment-method__details {
  padding-top: 0;
}

.creditcard-manager .v-input--switch {
  margin: 0;
  padding: 0;
}

/*.creditcard-manager .v-input--switch .v-input__slot {*/
/*  margin: 0;*/
/*}*/

.creditcard-manager .payment-method.payment-method--selected {
  /* for consistency, using vuetify's success color */
  border: 1px solid #4caf50;
}

.creditcard-manager__selector {
  cursor: pointer;
}

.creditcard-manager .expand-button {
  position: absolute;
  right: 14px;
  top: 14px;
}

.creditcard-manager .v-card__actions {
  padding-left: 0;
  padding-right: 0;
}
</style>
