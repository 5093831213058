<template>
  <div v-if="itemIdIsSet">
    <div>
      <template v-if="editable" class="mt-3">
        <v-row justify="center">
          <v-col cols="12" md="7">
            <v-card>
              <v-card-title>Organization Logo</v-card-title>
              <v-card-subtitle>
                Please select a full company logo. For the best results you logo should be:
              </v-card-subtitle>
              <v-card-text>
                <ul>
                  <li>A transparent background</li>
                  <li>Take up the entire canvas</li>
                  <li>The logo should be a minimum of 500px wide</li>
                  <li>This image will appear on your custom login screen and at the top of your printable invoice</li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="primary" @click.stop="onClickAddFile">Media Library</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="formItem.file" text color="error" @click.stop="onClickDeleteButton">
                  <v-icon left>mdi-delete</v-icon>
                  Delete Logo
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="5" style="text-align: center">
            <media-drop-zone>
              <v-skeleton-loader v-show="loading" type="image"></v-skeleton-loader>

              <media-field
                v-show="!loading"
                style="min-height: 100px"
                @updated="onMediaUpdate"
                @uploadPercentage="onUploadPercentage"
              >
                <v-img class="mx-auto" v-if="formItem.file" :src="formItem.file" :max-height="200" :max-width="200" contain />
              </media-field>

              <media-popup model-name="media" @selected="onMediaUpdate" />

              <delete-dialog
                object-name="logo"
                :open="deleteConfirmOpen"
                @cancelled="onConfirmCancelled"
                @deleted="onConfirmDeleteDeleted"
              />

              <div class="text-center mt-3">Click on the logo or drag and drop an image over it to change it.</div>
            </media-drop-zone>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <identicon v-if="!entity.logo" :icon-size="50" :icon-string="`${entity.id}`" />
        <v-img v-else :src="formItem.file" :max-height="50" :max-width="50" contain />
      </template>
    </div>
  </div>
</template>

<script>
import { ADD_MESSAGE, OPEN_DIALOG, SET_ENTITY } from "@/store/mutation-types";

import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import MediaPopup from "@/bcore/components/media/MediaPopup.vue";
import MediaDropZone from "@/bcore/components/media/MediaDropZone.vue";
import MediaField from "@/bcore/components/media/MediaField.vue";

import eventHub from "@/eventHub.js";

const defaultObject = () => {
  return {};
};

export default {
  name: "EntityLogo",

  components: { DeleteDialog, MediaPopup, MediaField, MediaDropZone },

  props: {
    entity: { type: Object, default: defaultObject() },
    editable: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      idDeleteItem: -1,
      deleteConfirmOpen: false,
      formItem: {},
      uploadPercentage: 0
    };
  },

  computed: {
    itemIdIsSet() {
      return !!this.entity && !!this.entity.id;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    itemUrl() {
      if (this.itemIdIsSet) {
        return `entities/${this.entity.id}/logo/`;
      }
      return "response/400/";
    }
  },

  watch: {
    entity: {
      handler(value) {
        this.fetchItem();
      },
      immediate: true
    }
  },

  methods: {
    async fetchItem() {
      this.loading = true;
      try {
        const response = await this.$http.get(this.itemUrl);
        this.formItem = response.data;
      } catch (error) {
        this.formItem.file = null;
      }
      this.loading = false;
    },

    onClickDeleteButton() {
      this.deleteConfirmOpen = true;

      this.idDeleteItem = this.entity.id;
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.formItem && (this.formItem.file = null);
      this.showSnackbarSuccess();
    },

    async sendDelete() {
      this.loading = true;
      await this.$http.delete(this.itemUrl);
      this.formItem = {};
      this.loading = false;
    },

    async onMediaUpdate(item) {
      this.loading = true;
      this.uploadPercentage = 0;

      const data = {
        media: item.id,
        is_private: false
      };

      try {
        const response = await this.$http.post(this.itemUrl, data);
        this.formItem = response.data;
        this.$store.commit(`auth/${SET_ENTITY}`, {
          ...this.$store.state.auth.entity,
          ...{ logo: response.data.file }
        });
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Could not update logo", type: "error" });
      }

      this.loading = false;
    },

    onClickAddFile() {
      this.$store.commit(`dialog/${OPEN_DIALOG}`, "media");
    },

    onUploadPercentage(value) {
      this.loading = value > 0;
      this.uploadPercentage = value;
    },

    onClickDropArea() {
      eventHub.$emit("clickMediaUpload");
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    }
  }
};
</script>
