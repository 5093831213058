<template>
  <v-card class="org-bills" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <span>Bills</span>

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-spacer />

        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder="Supplier, Invoice #, Etc."
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />

        <v-btn title="Filters" color="secondary" class="px-0 align-center" @click="toggleFiltersDisplay" outlined icon>
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <invoice-filters
      ref="invoice_filters"
      class="justify-center ma-4"
      @change="updateFilters"
      @closed="displayFilters = false"
    />

    <v-card-text>
      <v-card v-if="!isLoading" :elevation="0" outlined>
        <v-card-text class="pa-3">
          <v-row>
            <v-col>
              <div class="mr-1">
                <strong>Balance Overdue:</strong>
                <span :class="{ 'red--text': balanceDue > 0, 'green--text': balanceDue <= 0 }">
                  {{ formatCurrency(balanceDue) }}
                </span>
              </div>
              <div class="mr-1">
                <small v-if="currentEntity.has_premium_customer_restrictions"><a href v-if="balanceDue > 0" @click.prevent="payOverdue">Pay Now</a></small>
              </div>
            </v-col>
            <v-col>
              <div>
                <strong>Total Balance Due:</strong>
                <span :class="{ 'green--text': balanceDue <= 0 }">
                  {{ formatCurrency(totalBalance) }}
                </span>
              </div>
              <div>
                <small v-if="currentEntity.has_premium_customer_restrictions"><a href v-if="totalBalance > 0" @click.prevent="payOutstanding">Pay Now</a></small>
              </div>
            </v-col>
            <v-col v-if="currentEntity.has_premium_customer_restrictions" >
              <div>
                <strong>Bills Selected:</strong>
                <span>
                  {{ formatCurrency(totalSelected) }}
                </span>
              </div>
              <div>
                <small><a href @click.prevent="paySelection" v-if="totalSelected > 0">Pay Now</a></small>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-data-table
        v-model="selected"
        class="mt-4"
        :loading="isLoading"
        :headers="headers"
        :items="items"
        item-key="id"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        @click:row="displayDetails"
        dense
        multi-sort
        disable-filtering
        :show-select="currentEntity.has_premium_customer_restrictions"
      >
        <!-- COLUMN: SUPPLIER_NAME -->
        <template v-slot:item.supplier_name="{ item }">
          {{ item.supplier_name }}
        </template>

        <!-- COLUMN: REFERENCE_NO -->
        <template v-slot:item.reference_no="{ item }">
          <v-badge
            v-if="item.sync_status"
            :title="getInvoiceSyncStatusText(item)"
            :color="getInvoiceSyncStatusColor(item)"
            inline
            left
            dot
          ></v-badge>
          {{ item.reference_no }}
        </template>

        <!-- COLUMN: PO NUMBER -->
        <template v-slot:item.po_number="{ item }">{{ item.po_number }}</template>

        <!-- COLUMN: TOTAL -->
        <template v-slot:item.total="{ item }">
          {{ formatCurrency(item.total, item.currency) }}
        </template>

        <!-- COLUMN: BALANCE DUE -->
        <template v-slot:item.balance_due="{ item }">
          {{ formatCurrency(item.balance_due, item.currency) }}
        </template>

        <!-- COLUMN: STATUS -->
        <template v-slot:item.status="{ item }">
          {{ getStatusText(item.status) }}
          <span v-if="item.status === 'pending'">
            |
            <a href="" class="quick-approve" @click.stop.prevent="confirmAndApproveInvoice(item)">Approve</a>
          </span>
        </template>

        <!-- COLUMN: CREATED DATE -->
        <template v-slot:item.created_at="{ item }">{{ formatDate(item.created_at) }}</template>
      </v-data-table>
    </v-card-text>

    <!-- PAY BILL DIALOG -->
    <pay-bill-dialog ref="pay_bill_dialog" />

    <approve-payable-invoice-confirm-dialog ref="approve_invoice_dialog" @InvoiceApproved="onInvoiceApproved" />
  </v-card>
</template>

<script>
import { ADD_FILTERS, ADD_MESSAGE, LOADED, LOADING } from "@/store/mutation-types";

import InvoiceFilters from "@/invoiceboost/components/invoice/InvoiceFilters.vue";
import ApprovePayableInvoiceConfirmDialog from "@/invoiceboost/components/account/ApprovePayableInvoiceConfirmDialog.vue";

import CurrencyFormatMixin from "@/mixins/currencyFormat";
import InvoiceStatusMixin from "@/mixins/invoiceStatus";
import InvoiceSyncStatusMixin from "@/mixins/invoiceSyncStatus";
import DataTableMixin from "@/mixins/dataTable";
import DateMixin from "@/mixins/date";
import PreviewMixin from "@/mixins/preview";
import InvoiceTermsMixin from "@/mixins/invoiceTerms";
import FilterCountMixin from "@/mixins/filterCount";
import DownloadMixin from "@/mixins/download";
import PermissionMixin from "@/mixins/permission";
import PayBillDialog from "@/invoiceboost/views/bill/PayBillDialog.vue";

import deep_copy from "@/utilities/deep_copy";
import eventHub from "@/eventHub";
import api from "@/api";

export default {
  name: "Bills",

  components: {
    InvoiceFilters,
    ApprovePayableInvoiceConfirmDialog,
    PayBillDialog
  },

  mixins: [
    CurrencyFormatMixin,
    InvoiceStatusMixin,
    InvoiceSyncStatusMixin,
    DataTableMixin,
    DateMixin,
    PreviewMixin,
    InvoiceTermsMixin,
    FilterCountMixin,
    DownloadMixin,
    PermissionMixin
  ],

  data() {
    return {
      historyDialogTitle: "Invoice Status",

      selectedInvoice: null,

      formOpened: false,
      formLoading: false,
      displayFilters: false,
      invoiceStatusLoading: false,

      currentItem: null,
      statusOptions: [],
      invoiceForAttachments: {},
      entity: {}
    };
  },

  computed: {
    items() {
      return this.$store.state.bill.list;
    },

    headers() {
      let _headers = [
        { text: "Supplier", value: "supplier_name", sortable: true },
        { text: "Invoice #", value: "reference_no", sortable: true },
        { text: "PO #", value: "po_number", sortable: false },
        { text: "Amount", value: "total", sortable: true },
        { text: "Balance Due", value: "balance_due", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Due Date", value: "due_date", sortable: true }
      ];

      if (this.$store.state.auth.entity.has_premium_customer_restrictions) {
        _headers.splice(0, 1);
      }

      return _headers;
    },

    balanceDue() {
      return this.$store.state.bill.meta.balance_due || 0;
    },

    totalBalance() {
      return this.$store.state.bill.meta.total_balance || 0;
    },

    totalSelected() {
      const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.balance_due);
      return this.selected.reduce(reducer, 0);
    },

    isLoading() {
      return this.$store.getters[`bill/loading`]("list");
    },

    currentEntity() {
      return this.$store.state.auth.entity;
    }
  },

  mounted() {
    // this.fetchItems(); https://gitlab.com/soverton/invoice-boost/-/issues/278
    // this.getStatusOptions();
    eventHub.listen("Bill.UPDATED", this.onBillUpdated);
  },

  methods: {
    fetchItems() {
      this.$store.dispatch("bill/list");
    },

    showFilters() {
      this.displayFilters = true;
      this.$refs.invoice_filters.open();
    },

    hideFilters() {
      this.displayFilters = false;
      this.$refs.invoice_filters.close();
    },

    toggleFiltersDisplay() {
      if (this.displayFilters) {
        this.hideFilters();
      } else {
        this.showFilters();
      }
    },

    updateFilters(filters) {
      this.tableFilters = {};
      this.$store.commit(`${this.modelName}/${ADD_FILTERS}`, filters);
      this.fetchItems();
    },

    displayDetails(invoice) {
      // Send a copy of the invoice objet to prevent edit propagation
      eventHub.trigger("BillDetails.open", deep_copy(invoice));
    },

    confirmAndApproveInvoice(invoice) {
      this.selectedInvoice = invoice;
      this.$refs.approve_invoice_dialog.open(invoice);
    },

    onInvoiceApproved() {
      setTimeout(() => {
        this.displayDetails(this.selectedInvoice);
      }, 500);
    },

    onBillUpdated(invoice) {
      const invoice_id = invoice.id;
      const found = this.items.find(item => {
        return item.id == invoice_id;
      });

      deep_copy(invoice, found);
    },

    paySelection() {
      this.$refs.pay_bill_dialog.pay(this.selected);
    },

    payOverdue() {
      this.$store.commit(`theme/${LOADING}`);
      api.bills
        .overdue()
        .then(response => {
          this.$refs.pay_bill_dialog.pay(response.data);
        })
        .catch(e => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch overdue invoices" });
        })
        .finally(() => {
          this.$store.commit(`theme/${LOADED}`);
        });
    },

    payOutstanding() {
      this.$store.commit(`theme/${LOADING}`);
      api.bills
        .outstanding()
        .then(response => {
          this.$refs.pay_bill_dialog.pay(response.data);
        })
        .catch(e => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch overdue invoices" });
        })
        .finally(() => {
          this.$store.commit(`theme/${LOADED}`);
        });
    }
  }
};
</script>

<style>
.org-bills .search-field .v-input__slot {
  margin: 0;
}

.org-bills .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-bills table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-bills table thead tr {
  background-color: #eeeeee;
}

.org-bills table thead tr th {
  color: #000 !important;
}
</style>
