import { GetterTree } from "vuex";
import { ModelState, RootState } from "../types";

const getGetters = <S>(): GetterTree<ModelState<S>, RootState> => {
  return {
    filterString: (state): string => {
      const filters = state.filters;
      const str = [];
      for (const p in filters) {
        if (filters.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filters[p]));
        }
      }
      let filterString = "";
      if (str.length > 0) {
        filterString = "?" + str.join("&");
      }
      return filterString;
    },
    loading: state => (action: string): boolean => {
      // Check if the request is in the request queue (if true, then it's loading)
      return !!state.requests[action];
    }
  };
};

export default getGetters;
