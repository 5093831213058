<template>
  <responsive-dialog
    ref="dialog"
    content-class="invoice-approve-dialog"
    dialog-width="500px"
    :display-dialog="displayDialog"
    :dialog-title="dialogTitle"
    :show-actions="false"
    @closed="close"
  >
    <v-card-actions class="px-0 pt-6">
      <v-btn color="secondary" @click="close" class="px-5">Cancel</v-btn>

      <v-spacer />

      <v-btn color="primary" :loading="isLoading" @click="confirm">
        <v-icon small class="mr-2">mdi-check</v-icon>
        Confirm
      </v-btn>
    </v-card-actions>
  </responsive-dialog>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import api from "../../../api";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "ApprovePayableInvoiceConfirmDialog",

  components: {
    ResponsiveDialog
  },

  data() {
    return {
      invoice: null,
      isLoading: false,
      displayDialog: false
    };
  },
  computed: {
    dialogTitle() {
      return "Do you want to approve Invoice # " + (this.invoice ? ` ${this.invoice.reference_no}` : "") + "?";
    }
  },
  methods: {
    open(invoice) {
      this.invoice = invoice;
      this.displayDialog = true;
      return this;
    },

    close() {
      this.displayDialog = false;
      this.$emit("closed");
      return this;
    },

    confirm() {
      this.isLoading = true;

      const payload = {
        id: this.invoice.id,
        status: "approved",
        comment: ""
      };

      api.invoice_payable
        .update_status(payload)
        .then(response => {
          const data = response.data;
          this.invoice.status = data.status;

          this.$emit("InvoiceApproved");
          this.close();
        })
        .catch(error => {
          this.displayMessage({ text: "Status/Comment update failed.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>
