<template>
  <div v-if="!superUser" class="text-center">
    <v-menu v-model="open" :close-on-content-click="false" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <v-list-item v-for="entity in entities" :key="entity.id" selectable @click.stop="setEntity(entity)">
            <v-list-item-avatar tile>
              <entity-avatar :entity="entity" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ entity.name }}</v-list-item-title>
              <v-list-item-subtitle>
                ID: {{ entity.id }}
                <v-chip v-if="entity.is_default" x-small>DEFAULT</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { ADD_MESSAGE, SET_DETAIL } from "@/store/mutation-types";
import EntityAvatar from "@/bcore/components/entity/EntityAvatar.vue";
import settingsMixin from "@/mixins/settings.js";

export default {
  name: "OrganizationSelect",
  components: {
    EntityAvatar
  },
  mixins: [settingsMixin],
  data() {
    return {
      open: false,
      entities: [],
      entity_id: 2,
      loading: false,
      organizationForm: null
    };
  },
  computed: {
    userID() {
      return Number(this.$store.state.auth.user.user_id);
    },
    superUser() {
      return this.$store.state.auth.user.is_superuser;
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.getUserEntities();
      }
    }
  },
  methods: {
    async getUserEntities() {
      try {
        this.loading = true;
        const response = await this.$http.get(`invoiceboost/users/${this.userID}/organizations/`);
        this.entities = response.data;
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch your organizations" });
      }
      this.loading = false;
    },
    async setEntity(entity) {
      await this.$store.dispatch("auth/setEntity", entity);
      await this.$store.dispatch("routerEntity/setEntity", entity);
      await this.fetchMySettings();
      this.$router.go(0);
    },
    clickNewOrganization() {
      this.$store.commit(`organization/${SET_DETAIL}`, { user_id: this.userID });
    }
  }
};
</script>
