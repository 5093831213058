<template>
  <responsive-dialog :display-dialog="isOpen" :dialog-title="formTitle" @closed="onClickCloseButton">
    <v-progress-linear v-if="isLoading" indeterminate />

    <v-container>
      <media-field @uploadPercentage="onUploadPercentage" @updated="onMediaUpdated">
        <media-drop-zone class="text-center">Drag and drop files or click here to add media.</media-drop-zone>
      </media-field>
    </v-container>

    <v-list dense>
      <v-list-item v-if="isUploading">
        <v-progress-linear :value="uploadPercentage" :indeterminate="uploadPercentage === 100"></v-progress-linear>
      </v-list-item>

      <v-data-iterator
        :items="items"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        disable-filtering
        disable-sort
        class="pa-2"
      >
        <v-list-item v-for="item in items" :key="item.id" link @click.stop="onClickItem(item)">
          <v-list-item-avatar tile>
            <v-img v-if="isImageFile(item.filename)" size="50" :src="item.file">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular width="3" indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-icon v-else large>{{ getMediaIcon(item.filename) }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.description }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.size / 1000 }} KB</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click.native.stop>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link :href="item.file" target="_blank">
                  <v-list-item-title>
                    <v-icon left>mdi-eye</v-icon>
                    View
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop="onClickDeleteButton(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-delete</v-icon>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-data-iterator>
    </v-list>

    <delete-dialog
      object-name="media"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
    <media-form></media-form>
  </responsive-dialog>
</template>

<script>
import { ADD_MESSAGE, CLOSE_DIALOG } from "@/store/mutation-types";

import MediaForm from "@/bcore/components/media/MediaForm.vue";
import MediaField from "@/bcore/components/media/MediaField.vue";
import MediaDropZone from "@/bcore/components/media/MediaDropZone.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import DateMixin from "@/mixins/date";

import dataTableMixin from "@/mixins/dataTable";
import MediaMixin from "@/mixins/media";

export default {
  name: "MediaPopup",

  components: { DeleteDialog, MediaForm, ResponsiveDialog, MediaField, MediaDropZone },

  mixins: [DateMixin, dataTableMixin, MediaMixin],

  data() {
    return {
      formTitle: "Select Media",
      idDeleteItem: -1,
      deleteConfirmOpen: false,
      tabs: ["Public", "Private", "Upload"],
      newForm: {},
      uploadPercentage: 0,
      tableOptions: {
        itemsPerPage: 5
      }
    };
  },

  computed: {
    isUploading() {
      return this.uploadPercentage > 0;
    },

    items() {
      return this.$store.state.media.list;
    },

    isOpen() {
      return this.canBrowsePrivateMedia || this.canBrowsePublicMedia;
    },

    canBrowsePrivateMedia() {
      return this.$store.getters["dialog/isOpen"]("media");
    },

    canBrowsePublicMedia() {
      return this.$store.getters["dialog/isOpen"]("media");
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    isLoading() {
      return this.$store.getters["media/loading"]("list");
    }
  },

  methods: {
    async fetchItems() {
      if (this.isOpen) {
        try {
          await this.$store.dispatch("media/list");
        } catch (error) {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch media." });
        }
      }
    },

    onClickItem(item) {
      this.$emit("selected", item);
      this.$store.commit(`dialog/${CLOSE_DIALOG}`, "media");
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },

    onUploadPercentage(percent) {
      this.uploadPercentage = percent;
    },

    onMediaUpdated(item) {
      this.uploadPercentage = 0;
      this.fetchItems();
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.showSnackbarSuccess();
    },

    async sendDelete(id) {
      await this.$store.dispatch("media/delete", id);
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    },

    onClickCloseButton() {
      this.$store.commit(`dialog/${CLOSE_DIALOG}`, "media");
    },

    async onClickEdit(item) {
      await this.$store.dispatch("media/detail", item.id);
    }
  }
};
</script>
