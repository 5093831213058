import {
  ADD_ERRORS,
  CLEAR_ERRORS,
  LOADED,
  LOADING,
  SET_ENTITY,
  SET_ENTITY_PERMISSIONS,
  SET_USER
} from "../mutation-types";
import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthState, GenericObject, LoginRequestObject, PasswordResetRequestObject, RootState } from "../types";
import { ActionTree } from "vuex";
import http from "../http";
import authEvent from "./event";
import { storeEntity } from "../utils";
import { ADD_MESSAGE } from "@/store/mutation-types";

const actions: ActionTree<AuthState, RootState> = {
  /**
   *Login request
   * @param commit
   * @param payload { LoginRequestObject }
   * @param getters
   * @param dispatch
   */
  login({ commit, getters, dispatch }, payload: LoginRequestObject) {
    commit(LOADING);
    commit(CLEAR_ERRORS);

    return axios
      .post(process.env.VUE_APP_API_BASE_URL + "/login/", payload)
      .then(async () => {
        await dispatch("whoami");
        authEvent.$emit("loggedIn");
        return Promise.resolve(true);
      })
      .catch(err => {
        if (err.response) {
          commit(ADD_ERRORS, err.response.data);
        }
        commit(LOADED);
        return Promise.reject(err);
      });
  },

  /**
   *Set the current user context
   * @param commit
   * @param user { GenericObject }
   * @param dispatch
   * @param state
   */
  setCurrentUser({ commit, dispatch, state }, user: GenericObject) {
    commit(SET_USER, user);

    const userSubmitted = { ...user };

    if (!user || user.anonymous) {
      dispatch("logout");
    }

    return userSubmitted;
  },

  /**
   * Set the current entity context
   * @param commit
   * @param dispatch
   * @param getters
   * @param entity
   */
  setEntity({ commit, dispatch, getters }, entity: GenericObject) {
    storeEntity(entity);
    commit(SET_ENTITY, entity);
    dispatch("getEntityPermissions");
  },

  /**
   * Get the current user permissions in relative to the current entity
   * @param commit
   */
  getEntityPermissions({ commit }) {
    return http
      .get(`users/entity_permissions/`)
      .then(response => {
        commit(SET_ENTITY_PERMISSIONS, response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  /**
   * Clear tokens and redirect to login
   * @param commit
   * @param dispatch
   */
  async logout({ commit, dispatch }) {
    // perform any additional logout operations here
    try {
      await axios.get(process.env.VUE_APP_API_BASE_URL + "/logout/");
      await dispatch("setEntity", {});
      commit(SET_USER, { anonymous: true });
    } finally {
      authEvent.$emit("logout");
    }
  },

  /**
   * Check the current user
   * @param commit
   * @param state
   * @param dispatch
   */
  async whoami({ commit, state, dispatch }) {
    return axios
      .get(process.env.VUE_APP_API_BASE_URL + "/users/whoami/")
      .then(async response => {
        const user = await dispatch("setCurrentUser", response.data);
        authEvent.$emit("session_started", user);
        return Promise.resolve(user);
      })
      .then(async user => {
        if (user.is_superuser || user.anonymous) return Promise.resolve([user, null]);

        const getDefaultOrganization = function (organizations: Array<any>) {
          return organizations.find(obj => obj.is_default);
        };

        try {
          const response = await http.get(`/invoiceboost/users/${user.user_id}/organizations/`);

          if (response.data.length < 1) {
            // If no organizations exist logout
            commit(`theme/${ADD_MESSAGE}`, { text: "You have no active organizations.", type: "error" });
            return Promise.reject();
          } else {
            // Set the default organization or the first available organization
            const defaultOrganization = getDefaultOrganization(response.data) || response.data[0];
            await dispatch("setEntity", defaultOrganization);
            await dispatch("entitySettings/detail", defaultOrganization.id, { root: true });
            await dispatch("routerEntity/setEntity", defaultOrganization, { root: true });
            authEvent.$emit("organization_loaded", defaultOrganization);
            return Promise.resolve([user, defaultOrganization]);
          }
        } catch {
          return Promise.reject();
        }
      })
      .catch(err => {
        dispatch("logout");
        commit(LOADED);
        return Promise.reject(err);
      });
  },

  /**
   * Request A password reset
   * @param commit
   * @param state
   * @param username
   */
  requestPasswordReset({ commit }, username: string) {
    commit(LOADING);
    return axios
      .post(`${process.env.VUE_APP_API_BASE_URL}/password_reset/`, { username })
      .then(response => {
        commit(LOADED);
        return Promise.resolve(response);
      })
      .catch(error => {
        commit(LOADED);
        return Promise.reject(error);
      });
  },

  /**
   * Reset User Password
   * @param commit
   * @param state
   * @param data
   */
  resetPassword({ commit }, data: PasswordResetRequestObject) {
    commit(LOADING);
    return axios
      .post(`${process.env.VUE_APP_API_BASE_URL}/password_reset/confirm/`, data)
      .then((response: AxiosResponse) => {
        commit(LOADED);
        return Promise.resolve(response);
      })
      .catch((error: AxiosError) => {
        commit(LOADED);
        return Promise.reject(error);
      });
  }
};
export default actions;
