import { ADD_MESSAGE } from "@/store/mutation-types";
import { parseFormErrors } from "@/utilities/form";
import Vue from "vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

export default Vue.extend({
  components: {
    NonFieldErrors
  },
  props: { formModel: { type: String, default: "modelName" } },
  data() {
    return {
      formOpened: false,
      formItemCleaned: {},
      formItem: {},
      formItemErrors: {},
      formItemErrorsCleaned: {}
    };
  },

  computed: {
    formDetail() {
      return this.$store.state[this.formModel].detail;
    },
    formErrors() {
      return this.$store.state[this.formModel].errors;
    },
    formIsLoading() {
      return (
        this.$store.getters[`${this.formModel}/loading`]("create") ||
        this.$store.getters[`${this.formModel}/loading`]("update")
      );
    },
    modelTitle() {
      return this.formModel[0].toUpperCase() + this.formModel.slice(1);
    },
    formTitle() {
      return this.isItemUpdate ? "Update " + this.modelTitle : "New " + this.modelTitle;
    },
    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!(this.formItem && this.formItem.id);
    },
    formFormHasErrors() {
      return Object.keys(this.formItemErrors).length > 0;
    }
  },
  watch: {
    formDetail: {
      handler(value) {
        this.formItem = value;
        this.formOpened = !!value;
      },
      deep: true
    }
  },
  methods: {
    /*** Event handlers ***/
    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    },
    onClickNewButton() {
      this.resetFormErrors();
      this.resetForm();
      this.showForm();
    },
    onClickFormCloseButton() {
      this.closeForm();
      this.resetFormErrors();
    },
    async onFormSubmit() {
      this.resetFormErrors();

      this.preUpdate();

      const response = this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      this.postUpdate(response);

      if (this.formFormHasErrors) {
        return;
      }
      this.$emit("updated", response.data);
      // this.closeForm();
      this.showSnackbarSuccess();
    },

    /*** Helper functions ***/
    setFormItem(item) {
      this.formItem = { ...item };
    },

    resetForm() {
      // Clean all form fields
      // A small timeout prevents the fields from changing during dismiss animation.
      setTimeout(() => {
        this.setFormItem(this.formItemCleaned);
      }, 100);
    },
    resetFormErrors() {
      this.formItemErrors = { ...this.formItemErrorsCleaned };
    },
    showForm() {
      this.formOpened = true;
    },
    closeForm() {
      this.formOpened = false;
      this.$emit("closed");
      this.resetForm();
    },
    preUpdate() {
      // Override this method in the child to perform pre update/create operations
    },
    postUpdate(response) {
      // Override this method in the child to perform post update/create operations
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formItemErrors = parseFormErrors(fieldErrors);
      this.formItemErrors.non_field_errors = errors.errors || [];
    },

    async sendUpdate() {
      if (this.formItem) {
        try {
          return await this.$store.dispatch(`${this.formModel}/update`, {
            id: this.formItem.id,
            data: this.formItem
          });
        } catch (error) {
          this.setFormErrors(error.response.data);
          return Promise.reject(error);
        }
      }
    },
    async sendCreate() {
      try {
        return await this.$store.dispatch(`${this.formModel}/create`, this.formItem);
      } catch (error) {
        this.setFormErrors(error.response.data);
        return Promise.reject(error);
      }
    }
  }
});
