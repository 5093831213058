<template>
  <v-app>
    <v-main>
      <v-container fill-height style="height: calc(100vh - 58px)">
        <!-- 500 INTERNAL SERVER ERROR OR ANY OTHER-->
        <div v-if="http_status === 500 || (http_status !== 429 && http_status !== 502)" class="mx-auto">
          <h1 class="display-2 primary--text mb-4">Service Unavailable</h1>

          <p class="mt-4">
            Sorry but our service is currently unavailable due to maintenance. Should this persist, please try again
            later.
          </p>

          <a class="v-btn v-btn--outlined v-btn--router theme--light v-size--default primary--text" href="/">
            Try again
          </a>
        </div>

        <!-- 429 TOO MANY REQUESTS -->
        <div v-if="http_status === 429" class="mx-auto">
          <h1 class="display-2 primary--text mb-4">429 Too Many Requests</h1>

          <p class="mt-4">
            Sorry but our service is currently unavailable because the user has sent too many requests.
          </p>
        </div>

        <!-- 502 BAD GATEWAY -->
        <div v-if="http_status === 502" class="mx-auto">
          <h1 class="display-2 primary--text mb-4">502 Bad Gateway</h1>

          <p class="mt-4">
            The server, while acting as a gateway or proxy, received an invalid response from the upstream server.
          </p>

          <a class="v-btn v-btn--outlined v-btn--router theme--light v-size--default primary--text" href="/">
            Try again
          </a>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import vuetify from "@/plugins/vuetify.js";

export default Vue.extend({
  name: "ServerError",

  vuetify,

  data() {
    return {
      http_status: 0
    };
  },

  methods: {
    set_http_status(status) {
      this.http_status = status;
    }
  }
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

#server-error {
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #eeeeee;
}

#server-error .wrapper {
  max-width: 560px;
  line-height: 1.3;
}

#server-error .my-4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

#server-error .mt-4 {
  margin-top: 20px;
}

#server-error .wrapper h1 {
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #263238;
  text-transform: uppercase;
}

#server-error .wrapper h2 {
  font-size: 21px;
  font-weight: 400;
  color: #263238;
  text-transform: uppercase;
}

#server-error .wrapper p {
  color: #263238;
  font-weight: 400;
}

#server-error .wrapper a {
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;

  color: #01579b;
}
</style>