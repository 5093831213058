<template>
  <responsive-dialog
    content-class="quote-form-dialog"
    :display-dialog="displayDialog"
    :dialog-title="formTitle"
    @closed="close"
  >
    <v-form ref="form" v-model="isFormValid" :lazy-validation="false" @submit.prevent="submitForm">
      <!-- DISPLAY GENERIC ERRORS -->
      <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>

      <!-- DISPLAY FORM NOTIFICATIONS -->
      <v-row v-if="notifications.length">
        <v-col>
          <v-alert
            v-for="(alert, index) in notifications"
            border="left"
            elevation="2"
            class="mb-0"
            :type="alert.type"
            :key="index"
            colored-border
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="alert.message"></span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="hasPermission('add_customer')">
        <v-col>
          <v-btn title="New Customer" color="primary" @click="newCustomer" outlined>
            <v-icon left>mdi-plus</v-icon>
            Customer
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: CUSTOMER -->
        <v-col cols="12" md="6">
          <!-- DISABLED TEXT FIELD IS USED ON UPDATE MODE -->
          <v-text-field
            label="Customer"
            v-if="isItemUpdate"
            :value="form.target_entity.name"
            :disabled="true"
            outlined
            required
          ></v-text-field>

          <!-- DROPDOWN/AUTOCOMPLETE IS USED WHEN CREATING A NEW QUOTE -->
          <customer-autocomplete
            v-if="!isItemUpdate"
            ref="customer_autocomplete"
            :customer-search="customersFilter"
            :form-loading="formLoading"
            :rules="rules.customer"
            @change="onCustomerChange"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox v-model="form.requires_delivery" label="Delivery Required?"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="form.description" label="Description" outlined></v-text-field>
        </v-col>
        <!-- FIELD: REFERENCE -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model.number="form.expiration_days"
            label="Expiration Days"
            outlined
            :error-messages="formErrors.expiration_days"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="quote-items">
        <v-col cols="12">
          <quote-items
            ref="quote_items"
            @updated="onItemsUpdate"
            :loading="formLoading"
            :price-override="determinePriceOverrideToUse"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6"></v-col>
        <v-col cols="12" md="6">
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>Subtotal</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">${{ calculateSubtotal }}</v-list-item-content>
            </v-list-item>

            <v-list-item v-if="taxPercentage">
              <v-list-item-content>{{ taxTitle }} ({{ taxPercentage }}%)</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                <!--  -->
                {{ formatCurrency(calculateTax, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Total</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">${{ grandTotal }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-form>

    <template slot="actions">
      <v-spacer />

      <v-btn
        type="submit"
        color="primary"
        :loading="formLoading"
        :disabled="disableSave"
        @click="performSaveOption('save')"
        text
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>

    <customer-form-dialog ref="customer_editor" @created="customerCreated" />
  </responsive-dialog>
</template>

<script>
import { ADD_FILTERS, ADD_MESSAGE, CLEAR_FILTERS } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import CustomerAutocomplete from "@/invoiceboost/components/CustomerAutocomplete";
import QuoteItems from "@/point-of-sale/components/quote/QuoteItems.vue";

import currencyFormatMixin from "@/mixins/currencyFormat";
import sumColumnMixin from "@/mixins/sumColumn";
import PreviewMixin from "@/mixins/preview";
import dateMixin from "@/mixins/date";
import permissionMixin from "@/mixins/permission";

import deep_copy from "@/utilities/deep_copy";
import CustomerFormDialog from "@/invoiceboost/components/customer/CustomerFormDialog";
import api from "@/api";
import { parseFormErrors } from "@/utilities/form";

function createQuoteModel() {
  return {
    owner_entity: null,
    target_entity: {},
    customer: null,
    items: []
  };
}

function createModelRules() {
  return {
    customer: [value => !!value || false]
  };
}

export default {
  name: "QuoteForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    QuoteItems,
    CustomerAutocomplete,
    CustomerFormDialog
  },

  mixins: [sumColumnMixin, currencyFormatMixin, PreviewMixin, dateMixin, permissionMixin],

  props: {
    formMessage: { type: String, default: null }
  },

  data() {
    return {
      displayDialog: false,

      sendCopy: false,

      form: createQuoteModel(),
      rules: createModelRules(),
      formErrors: {},
      isFormValid: false,

      customer: {},
      customers: [],
      customersFilter: null,
      isInitiatedByCustomer: false,
      customerPriceOverrides: [],
      customerPriceOverride: {}
    };
  },

  computed: {
    entitySettings() {
      return this.$store.state.entitySettings.detail;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    isCustomerSelected() {
      return !!this.form.customer;
    },

    hasItems() {
      return this.form.items.length > 0;
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.form.id;
    },

    formLoading() {
      const action = this.$store.getters["quote/loading"];
      return action("create") || action("update") || action("detail");
    },

    formTitle() {
      return this.isItemUpdate ? `Update Quote` : `New Quote`;
    },

    currency() {
      const entitySettings = this.entitySettings || {};
      return this.form.currency || entitySettings.default_currency || "";
    },

    entity() {
      return this.$store.state.auth.entity;
    },

    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    },

    calculateSubtotal() {
      let subtotal = this.sumColumn("total", this.form.items);
      return Math.round((Number(subtotal) + Number.EPSILON) * 100) / 100;
    },

    calculateTax() {
      let tax = this.sumColumn("total", this.form.items) * this.taxPercentage;
      return Math.round((tax + Number.EPSILON) * 100) / 10000;
    },

    grandTotal() {
      let total = this.calculateSubtotal + (this.calculateTax || 0);
      return Math.round((total + Number.EPSILON) * 100) / 100;
    },

    taxTitle() {
      return this.form.tax_title || "Taxes";
    },

    taxPercentage() {
      return this.entitySettings.default_tax_percentage || this.form.tax_percentage;
    },

    notifications() {
      const alerts = [];
      const customer = this.form.customer;

      if (!customer) {
        alerts.push({
          type: "warning",
          message: `<strong>Customer field is required</strong>, you can't create a quote without a customer.`
        });
      }

      return alerts;
    },

    disableSave() {
      return !!(!this.isCustomerSelected || !this.hasItems || this.formLoading);
    },

    determinePriceOverrideToUse() {
      // while we determine how QuickBooks manages POs precedence when a customer belongs to multiple POs.
      //  Use the most recent PO for that customer
      return this.customerPriceOverrides.length > 0 ? this.customerPriceOverrides[0] : null;
    }
  },

  beforeDestroy() {
    this.$store.commit(`customer/${CLEAR_FILTERS}`);
  },

  methods: {
    new() {
      this.resetValidation();

      const quote = createQuoteModel();

      this.form = quote;

      this.form.owner_entity = this.ownerEntity.id;
      this.customer = {};

      this.$nextTick(() => {
        this.$refs.customer_autocomplete.clear();
        this.$refs.quote_items.update(quote.items);
      });

      this.displayDialog = true;
    },

    open(data, isInitiatedByCustomer) {
      if (!data) {
        return;
      }

      this.resetValidation();

      const quote = deep_copy(data);

      this.form = quote;
      this.form.customer = quote.target_entity;
      this.customer = { id: quote.target_entity.id, name: quote.target_entity.name };

      // Add selected customer to customer select
      // this.$store.commit(`customer/${SET_LIST}`, [
      //   { target_entity: { id: quote.target_entity.id, name: quote.target_entity.name } }
      // ]);

      // this.customersFilter = quote.target_entity.name;
      this.isInitiatedByCustomer = isInitiatedByCustomer;

      this.$nextTick(() => {
        if (this.$refs.customer_autocomplete !== undefined) {
          this.$refs.customer_autocomplete.select(quote.target_entity);
        }
        this.$refs.quote_items.update(quote.items, true);
      });

      this.displayDialog = true;
    },

    resetValidation() {
      this.formErrors = {};
    },

    newCustomer() {
      this.$refs.customer_editor.new();
    },

    customerCreated() {
      this.$refs.customer_editor.close();
    },

    performSaveOption(saveOption) {
      switch (saveOption.value) {
        case "saveAndSend":
          this.submitForm(true);
          break;

        case "send":
          this.sendEmail();
          break;

        default:
          this.submitForm();
      }
    },

    async submitForm(send) {
      this.resetValidation();

      this.form.owner_entity = this.entity;
      this.form.initiating_entity = this.entity;
      this.form.tax = this.calculateTax;
      this.form.tax_percentage = this.taxPercentage;

      if (typeof this.customer.target_entity === "object" && typeof this.form.target_entity === "object") {
        this.form.target_entity = { ...this.customer.target_entity, ...this.form.target_entity };

        if (this.isInitiatedByCustomer) {
          this.form.initiating_entity = this.form.target_entity;
        }
      }

      this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      if (this.formHasErrors) {
        return;
      }

      if (this.form.requires_delivery) {
        this.$emit("requiresDelivery", this.form);
      }

      this.$emit("updated");

      if (send) {
        // this.sendEmail();
      }

      this.close();
    },

    close() {
      this.displayDialog = false;
    },

    async fetchCustomers() {
      this.$store.commit(`customer/${ADD_FILTERS}`, { extra_fields: 1 });
      await this.$store.dispatch("customer/list");
    },

    async sendUpdate() {
      try {
        await this.$store.dispatch("quote/update", { id: this.form.id, data: this.form });
        this.displayMessage({ text: "Quote updated successfully.", type: "success" });
      } catch (error) {
        this.displayMessage({ text: "Unable to update the quote.", type: "error" });
        this.setFormErrors(error.response.data);
      }
    },

    onCurrencyChange(value) {
      this.form.currency = value;
    },

    onCustomerChange(value) {
      this.form.customer = value;
      this.form.target_entity = value.target_entity;
      this.debounceInput(this.fetchCustomerPriceOverride);
    },

    async sendCreate() {
      try {
        const response = await this.$store.dispatch("quote/create", this.form);
        this.form = response.data;
        this.displayMessage({ text: "Quote created successfully.", type: "success" });
      } catch (error) {
        this.setFormErrors(error.response.data);
        this.displayMessage({ text: "Unable to create the quote.", type: "error" });
      }
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    onItemsUpdate(items) {
      this.form.items = items;
      this.form.subtotal = this.calculateSubtotal;
      this.form.tax = this.calculateTax;
      this.form.total = this.grandTotal;
    },

    fetchCustomerPriceOverride() {
      return api.price_override_customer
        .fetch_active_price_overrides(this.form.customer.target_entity)
        .then(response => {
          this.customerPriceOverrides = response.data;
        });
    },

    debounceInput(func, timeout = 300) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        return func();
      }, timeout);
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style>
.quote-form-dialog .v-alert + .v-alert {
  margin-top: 16px;
}

.quote-form-dialog .quote-items {
  min-height: 200px;
}
</style>
