<template>
  <div v-show="is_opened" class="nav-menu overlay">
    <!-- <div class=""> -->
    <div class="header">
      <a class="close" @click.prevent="close">
        <v-icon class="icon">mdi-close</v-icon>
      </a>
    </div>

    <ul class="items pa-0">
      <li v-for="(item, index) in menu_items" :key="index">
        <a
          class="item"
          v-if="check_permission(item.permission, item.package)"
          @click.prevent="goto(item.route_name)"
          router
        >
          {{ item.label }}
        </a>
      </li>
      <v-divider></v-divider>
      <li>
        <a class="item" @click.prevent="logout">Logout</a>
      </li>
    </ul>
  </div>
</template>

<script>
import permissionMixin from "@/mixins/permission";
import eventHub from "@/eventHub";

const command = {
  toggle: "MainMenu.toggle",
  // expand: "MainMenu.expand",
  // collapse: "MainMenu.collapse"
};

const event = {
  expanded: "MainMenu.EXPANDED",
  collapsed: "MainMenu.COLLAPSED"
};

export default {
  name: "PremiumCustomerMobileMenu",

  mixins: [permissionMixin],

  data() {
    return {
      is_opened: false,

      menu_items: [
        { label: "Home", route_name: "home", permission: null },
        { label: "Bills", route_name: "org-bills", permission: "view_accounts_payable" },
        { label: "Employees", route_name: "org-employees", permission: "view_employee" },
        { label: "My Account", route_name: "org-profile", permission: null },
      ]
    };
  },

  mounted() {
    eventHub.listen(command.toggle, this.toggle);
  },

  methods: {
    open() {
      this.is_opened = true;
      this.$emit("change", true);
      eventHub.trigger(event.expanded);
    },

    close() {
      this.is_opened = false;
      this.$emit("change", false);
      eventHub.trigger(event.collapsed);
    },

    toggle() {
      if (this.is_opened) {
        this.close();
      } else {
        this.open();
      }
    },

    goto(route_name) {
      this.close();
      this.$router.push({ name: route_name });
    },

    check_permission(permission, be_package) {
      if (!permission) {
        return true;
      }

      return this.hasPermission(permission, be_package);
    },

    logout() {
      this.close();
      eventHub.trigger("App.confirm_logout");
    }

    // menuItemVisible(item) {
    //   // Menu item visibility logic
    //   switch (item) {
    //     case "employees":
    //       return this.hasPermission("view_employee");
    //     case "organizations":
    //       return this.hasPermission("view_entity", "bcore");
    //     case "paymentMethods":
    //       return this.hasPermission("view_paymentmethod");
    //     case "paymentSchedules":
    //       return this.hasPermission("view_paymentschedule");
    //     case "entitySettings":
    //       return this.hasPermission("view_entitysettings");
    //     case "customers":
    //       return this.hasPermission("view_customer");
    //     case "suppliers":
    //       return this.hasPermission("view_supplier");
    //     case "bill":
    //       return this.hasPermission("view_accounts_payable");
    //     case "invoice":
    //       return this.hasPermission("view_invoice");
    //     case "transactionsRefunds":
    //       return this.hasPermission("view_transaction", "payments");
    //     case "quote":
    //       return this.hasPermission("view_quote", "point_of_sale");
    //     case "order":
    //       return this.hasPermission("view_order", "point_of_sale");
    //     case "delivery":
    //       return this.hasPermission("view_delivery", "point_of_sale");
    //     case "priceOverride":
    //       return this.hasPermission("view_priceoverride", "point_of_sale");
    //     default:
    //       return false;
    //   }
    // }
  }
};
</script>

<style>
.nav-menu {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.9);
}

.nav-menu .header {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 15px;

  text-align: right;
  width: 100px;
}

.nav-menu .header .close {
  display: inline-block;
  padding: 12px;
  margin: 0 20px 0 0;
}

.nav-menu .header .close .icon {
  color: #fff;
}

.nav-menu .header .close:hover,
.nav-menu .header .close:focus {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}

.nav-menu .header .close:hover .icon,
.nav-menu .header .close:focus .icon {
  color: #000;
}

.nav-menu .items {
  margin-top: 15px;
}

.nav-menu .items li {
  display: block;
}

.nav-menu .item {
  display: inline-block;
  min-width: 230px;
  padding: 25px 30px 25px 40px;

  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 0;
}

.nav-menu .item:hover {
  color: #7ec65d;
}

.nav-menu .v-divider {
  border-color: white;
  margin: 5px 40px;
}
</style>