<template>
  <v-card class="invite-link mt-2" elevation="0">
    <v-card-title class="font-weight-regular justify-space-between px-0">Invite Link</v-card-title>

    <v-card-text
      class="pa-3 grey lighten-4 black--text"
      style="word-break: break-all; cursor: pointer; height: 68px"
      ref="invite_link_text"
      @click.prevent="select_link"
    >
      <!-- <v-text-field
        ref="invite_link_input"
        v-model="invite_link"
        hide-details="true"
        @click.prevent="select_link"
        outlined
        readonly
        dense
      /> -->
      {{ invite_link }}
    </v-card-text>

    <v-card-actions class="pa-0 mt-4 justify-space-between">
      <v-btn :disabled="is_sending_invite" @click.prevent="copy_link" elevation="1" text>
        <v-icon left>mdi-content-copy</v-icon>
        Copy link
      </v-btn>

      <v-btn @click="send_invite" :loading="is_sending_invite" elevation="1" text>
        <v-icon left>mdi-send</v-icon>
        {{ invite_label }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "InviteLink",

  props: {
    entity: { type: Object, default: () => { return {}; } },
  },

  data() {
    return {
      invite_label: `Email invitation to join ${process.env.VUE_APP_NAME}`,
      invite_link: null,
      is_sending_invite: false
    };
  },

  watch: {
    entity: {
      handler() {
        this.fetch_invite_link();
      }
    }
  },

  methods: {
    fetch_invite_link() {
      let entity = this.entity;

      if (entity.is_claimed) {
        return Promise.resolve();
      }

      return api.invitations.get_link(entity.target_entity)
        .then(response => {
          this.invite_link = response.data.link;
        });
    },

    send_invite() {
      this.is_sending_invite = true;
      let old_link = this.invite_link;

      this.invite_link = "Generating new link...";

      return api.invitations
        .send(this.entity)
        .then(() => {
          this.displayMessage({ text: "Invitation sent", type: "info" });

          return this.fetch_invite_link();
        })
        .catch(exception => {
          this.invite_link = old_link;
          this.displayMessage({ text: "Invitation failed to send", type: "error" });
        })
        .finally(() => {
          this.is_sending_invite = false;
        });
    },

    select_link() {
      // let input = this.$refs.invite_link_input.$refs.input;
      // input.select();
      utils.clipboard.select_range(this.$refs.invite_link_text);
    },

    copy_link() {
      utils.clipboard.copy_range(this.$refs.invite_link_text).then(() => {
        this.display_message({ text: "Invitation link copied", type: "info" });
      });
    },

    display_message(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },
  }
};
</script>

<style>
</style>