<template>
  <v-dialog v-model="is_open" max-width="290">
    <v-card class="logout-dialog">
      <v-card-title class="headline">Log out?</v-card-title>

      <v-card-text>Are you sure you want to log out?</v-card-text>

      <v-card-actions>
        <v-btn color="error" text @click="close">CANCEL</v-btn>

        <v-spacer />

        <v-btn color="success" text @click="logout">
          <v-icon>mdi-power</v-icon>
          YES
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eventHub from "@/eventHub";
import app from "@/app";

export default {
  name: "LogoutDialog",

  data() {
    return {
      is_open: false
    };
  },

  mounted() {
    eventHub.listen("App.confirm_logout", () => {
      this.open();
    });
  },

  methods: {
    open() {
      this.is_open = true;
    },

    close() {
      this.is_open = false;
      this.$emit("closed");
    },

    logout() {
      this.close();

      app.user.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },
  }
};
</script>

<style>
</style>
