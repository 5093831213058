<template>
  <base-layout :class="app_classes">
    <!-- APP HEADER DESKTOP -->
    <v-app-bar v-show="is_desktop" :class="app_bar_classes" fixed dark app>
      <v-container class="align-center py-0 px-0 fill-height">
        <v-btn @click="toggle_main_menu" class="menu-toggler mr-1" icon>
          <v-icon class="icon">mdi-menu</v-icon>
        </v-btn>

        <v-toolbar-title v-show="is_desktop" class="mr-auto">
          <i v-if="!isCustomDomain" class="icon-accruvia mr-2" style="font-size: 24px"></i>
          <span :class="{ 'organization-name': !isCustomDomain }" style="">{{ appName }}</span>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          v-show="is_desktop && !subscribed_to_paid_plan && !has_premium_customer_restrictions"
          color="white"
          :to="{ name: 'platform-subscriptions' }"
          outlined
          link
        >
          Upgrade
        </v-btn>

        <help-button v-show="is_desktop" />

        <v-btn v-show="is_desktop" @click="display_logout_dialog" icon>
          <v-icon>mdi-power</v-icon>
        </v-btn>

        <img v-show="is_mobile" src="@/assets/accruvia-logo-alt.svg" alt="accruvia-logo" class="accruvia-logo" />
      </v-container>
    </v-app-bar>

    <!-- APP HEADER MOBILE -->
    <header v-show="is_mobile" class="app-header">
      <v-container class="align-center fill-height">
        <v-btn @click="toggle_main_menu" class="menu-toggler mr-1" icon>
          <v-icon class="icon">mdi-menu</v-icon>
        </v-btn>
        <v-spacer />
        <img v-show="is_mobile" src="@/assets/accruvia-logo.svg" alt="accruvia-logo" class="accruvia-logo" />
      </v-container>
    </header>

    <!-- MAIN CONTENT -->
    <v-main class="content" :class="main_classes">
      <v-container class="">
        <div class="left-column">
          <!-- LEFT MENU -->
          <template v-if="viewport.is_desktop">
            <PremiumCustomerMenu v-if="has_premium_customer_restrictions" @change="set_menu_state" />
            <MainMenu v-else @change="set_menu_state" />
          </template>
        </div>

        <!-- <v-sheet min-height="85vh" class="center-panel v-sheet flex-grow-1 flex-shrink-1"> -->
        <v-sheet class="center-panel">
          <router-view />
        </v-sheet>

        <router-view name="side_panel" class="side-panel" />
      </v-container>
    </v-main>

    <template v-if="is_mobile">
      <PremiumCustomerMobileMenu v-if="has_premium_customer_restrictions" @change="set_menu_state" />
      <MobileMenu v-else @change="set_menu_state" />
    </template>

    <!-- LOG OUT DIALOG -->
    <LogoutDialog />

    <PaymentMethodMissingDialog />
  </base-layout>
</template>

<script>
import BaseLayout from "@/views/layouts/BaseLayout.vue";
import MobileMenu from "@/bcore/components/MobileMenu.vue";
import PremiumCustomerMobileMenu from "@/premium/components/PremiumCustomerMobileMenu";
import LogoutDialog from "@/bcore/components/LogoutDialog.vue";

import HelpButton from "@/invoiceboost/components/HelpButton.vue";
import PaymentMethodMissingDialog from "@/invoiceboost/components/payment-method/PaymentMethodMissingDialog.vue";

import viewport from "@/mixins/viewport";
import eventHub from "@/eventHub.js";
import MainMenu from "@/bcore/components/MainMenu";
import PremiumCustomerMenu from "@/premium/components/PremiumCustomerMenu";

export default {
  name: "MainLayout",

  components: {
    BaseLayout,
    MainMenu,
    PremiumCustomerMenu,
    LogoutDialog,
    MobileMenu,
    PremiumCustomerMobileMenu,
    HelpButton,
    PaymentMethodMissingDialog
  },

  mixins: [viewport],

  props: {
    viewTitle: { type: String, default: "" }
  },

  data: () => ({
    classes: new Set([]),

    isMainMenuExpanded: true,

    showMessages: true,
    openDialog: true
  }),

  computed: {
    /* COMPUTED MODELS/VALUES */
    appName() {
      return this.$store.state.theme.customDomain.organization;
    },

    pageTitle() {
      return this.$store.state.theme.pageTitle;
    },

    app_classes() {
      let classes = [];

      if (this.isCustomDomain) {
        classes.push("custom-domain");
      }

      return classes;
    },

    has_premium_customer_restrictions() {
      let auth = this.$store.state.auth;
      return auth.entity && auth.entity.has_premium_customer_restrictions;
    },

    main_classes() {
      const classes = Array.from(this.classes);
      const viewport = this.viewport;
      const viewSize = viewport.isSmall ? "small" : viewport.isMedium ? "medium" : "large";

      classes.push("main-menu--is-" + (this.isMainMenuExpanded ? "expanded" : "collapsed"));
      classes.push("viewport--is-" + viewSize);

      if (viewport.is_desktop) {
        classes.push("mt-4 pb-8");
      }

      return classes;
    },

    subscribed_to_paid_plan() {
      return !!this.$store.state.entitySettings.detail && this.$store.state.entitySettings.detail.paid_plan;
    },

    app_bar_classes() {
      let classes = [];

      if (this.viewport.is_desktop) {
        classes.push("primary");
      } else {
        classes.push("white");
      }

      return classes;
    },

    theme() {
      return this.$store.state.theme;
    },

    isCustomDomain() {
      return Boolean(this.theme.customDomain.name.trim());
    },

    dialogMessages() {
      return this.$store.state.theme.dialogMessages;
    },

    /* SHORTCUTS */

    /* VALIDATORS */
    is_desktop() {
      return this.viewport.is_desktop;
    },

    is_mobile() {
      return this.viewport.is_mobile;
    }
  },

  mounted() {},

  methods: {
    display_logout_dialog() {
      eventHub.trigger("App.confirm_logout");
    },

    toggle_main_menu() {
      eventHub.trigger("MainMenu.toggle");
    },

    set_menu_state(isExpanded) {
      this.isMainMenuExpanded = isExpanded;
    }
  }
};
</script>

<style>
/* VIEWING accruvia app */
header .organization-name {
  font-family: "conthrax";
}

/* WHEN VIEWING CUSTOM DOMAIN */
.v-application.custom-domain header .icon-accruvia {
  display: none;
}

.v-application.custom-domain header .organization-name {
  font-family: Arial, Helvetica, sans-serif;
}

/*
  Styling: Desktop First
  Fallback: viewport--is-large > viewport--is-medium > viewport--is-small
*/
main.content {
  overflow-y: scroll;
}

.is-desktop main.content {
  background-color: #eeeeee;
}

main.content > .v-main__wrap > .container {
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

main.content .left-column {
  width: auto;
  min-width: auto;
  position: relative;
  z-index: 4;
  padding-right: 24px;
}

main.content .center-panel {
  /* overflow-y: auto; */
  flex-grow: 1;
  flex-shrink: 1;
}

.is-desktop main.content .center-panel {
  width: 10%;
  min-height: 85vh;
}

main.content.viewport--is-large.main-menu--is-collapsed .left-column,
main.content.viewport--is-medium.main-menu--is-expanded .left-column {
  width: 80px;
}

main.content.viewport--is-medium.main-menu--is-collapsed .left-column {
  padding-right: 0;
}

main.content.viewport--is-small .left-column {
  position: initial;
  padding-right: 0;
}

main.content.main-menu--is-expanded .left-column {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

/* MOBILE VIEWPORT < 960 */
.is-mobile .menu-toggler {
  color: #186734 !important;
}

.is-mobile .menu-toggler {
  width: 48px;
  height: 48px;
}

.is-mobile .menu-toggler .icon {
  font-size: 36px;
}

.is-mobile .app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  height: 60px;
  background-color: #000;
  border-bottom: 3px solid #186734;
}

.is-mobile .app-header .container {
  padding: 4px 16px;
}

.is-mobile .accruvia-logo {
  height: 30px;
  margin-right: 10px;
}
</style>
