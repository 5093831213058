<template>
  <v-card elevation="0">
    <v-card-title>
      <span>Quickbooks Desktop Settings</span>
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="tab" fixed-tabs background-color="primary" dark>
        <!-- TABS -->
        <v-tab href="#tab-1">
          Quickbooks Connection
        </v-tab>
        <v-tab href="#tab-2">
          Accounts/Payments
        </v-tab>
        <v-tab href="#tab-3">
          Items
        </v-tab>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-text>
                <strong>DB Path</strong><br/>
                <span>{{ accountInfo.qbwc_file_path }}</span>
                <v-spacer/>

                <strong>WebConnector Username</strong><br/>
                <span>{{ accountInfo.username }}</span>

                <v-spacer/>

                <form v-if="!accountInfo.password.password">
                  <v-btn class="mt-6" outlined color="danger" @click="resetPassword">
                    <v-icon left>mdi-lock-reset</v-icon>
                    Reset Password
                  </v-btn>
                </form>

                <v-card v-if="accountInfo.password.password" tile>
                  <v-container>
                    <v-card-title>Web Connector Password</v-card-title>

                    <v-card-subtitle>
                      Please save your web connector password securely. It will not be revealed again.
                    </v-card-subtitle>

                    <v-card-text>
                      <v-text-field
                          v-model="accountInfo.password.password"
                          readonly
                          outlined
                          small
                          @click="copyToClipBoard(accountInfo.password.password)"
                      >
                        <template slot="append">
                          <v-icon>mdi-clipboard</v-icon>
                        </template>
                      </v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn color="success" @click="hidePassword">CLOSE</v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card>

              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog";
import copyToClipBoardMixin from "@/mixins/copyToClipBoard";
import api from "@/api";
import {ADD_MESSAGE} from "@/store/mutation-types";

export default {
  name: "QuickbooksDesktopSettings",
  components: {
    ResponsiveDialog
  },
  mixins: [copyToClipBoardMixin],
  data() {
    return {
      tab: null,
      accountInfo: {
        password: {
          password: "",
        },
        username: null,
        qbwc_file_path: null,
      }
    };
  },
  mounted() {
    api.integrations.getQBDWebConnectAccount()
        .then(rs => {
          const result = rs.data.results[0];
          this.accountInfo.username = result.account.username;
          this.accountInfo.qbwc_file_path = result.account.qbwc_file_path;
        });
  },
  methods: {

    hidePassword() {
      this.accountInfo.password.password = "";
    },

    resetPassword() {
      const vm = this;
      api.integrations
          .resetQBDPassword()
          .then(function (res) {
            vm.accountInfo.password.password = res.data["new-password"];
          })
          .catch(e => {
            this.$store.commit(`theme/${ADD_MESSAGE}`, {
              text: "Unable to change password",
              type: "error"
            });
          });
    }
  },

};
</script>
