// TODO: deprecate the use of this logic to open modals (use open() and close() methods)
import { ADD_MESSAGE } from "@/store/mutation-types";

const DeleteMixin = {
  data() {
    return {
      idDeleteItem: -1,
      deleteConfirmOpen: false
    };
  },
  methods: {
    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },
    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },
    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      this.closeForm();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Payment deleted", type: "success" });
      this.$emit("updated");
    }
  }
};

export default DeleteMixin;
