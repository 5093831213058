import {http} from "@/vuex-bcore";

const qboAuthURL = "qbo";
const qbOnlineURL = "integrations/qbo-account";
const qbDesktopURL = "integrations/qbwc-account";

function getAuthorizationURL() {
  return http.get(`${qboAuthURL}/authorization-url`);
}

function getQBDWebConnectAccount() {
  return http.get(`${qbDesktopURL}/`);
}

function resetQBDPassword() {
  return http.post(`${qbDesktopURL}/reset-password/`);
}

function fetchQBOAccounts() {
  return http.get(`${qbOnlineURL}/accounts/`);
}

function fetchQBOPaymentDepositAccount() {
  return http.get(`${qbOnlineURL}/payment-deposit-accounts/`);
}

function saveQBOPaymentDepositAccount(data) {
  return http.post(`${qbOnlineURL}/update-payment-deposit-accounts/`, data);
}


export default {
  getAuthorizationURL,
  getQBDWebConnectAccount,
  resetQBDPassword,
  fetchQBOAccounts,
  fetchQBOPaymentDepositAccount,
  saveQBOPaymentDepositAccount
};
