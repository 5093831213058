<template>
  <v-card class="org-price-overrides" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between mb-3">
      <v-btn
        v-if="hasPermission('add_priceoverride', 'point_of_sale')"
        title="New Price Override"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newPriceOverride"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>Price Override</span>

      <v-spacer />
    </v-card-title>

    <v-card-text>
      <price-override-table ref="price_override_table" model-name="priceOverride" />
    </v-card-text>
  </v-card>
</template>

<script>
import PermissionMixin from "@/mixins/permission";
import PriceOverrideTable from "@/point-of-sale/components/price-override/PriceOverrideTable.vue";

export default {
  name: "PriceOverrides",

  components: { PriceOverrideTable },

  mixins: [PermissionMixin],

  data() {
    return {
      formLoading: false,
      formOpened: false
    };
  },

  computed: {
    isLoading() {
      return this.$store.getters["priceOverride/loading"]("list");
    }
  },

  methods: {
    newPriceOverride() {
      this.$refs.price_override_table.newPriceOverride();
    },

    fetchItems() {
      this.$refs.price_override_table.fetchItems();
    }
  }
};
</script>

<style>
/* Specify a row height for the table header and body rows */
.org-price-overrides table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-price-overrides table thead tr {
  background-color: #eeeeee;
}

.org-price-overrides table thead tr th {
  color: #000 !important;
}
</style>
