<template>
  <responsive-dialog :display-dialog="displayDialog" dialog-title="Invite supplier to Accruvia" dialog-width="600px"
                     @closed="close">
    <v-form ref="form" v-model="form.isValid" :lazy-validation="false" @submit.prevent="sendInvitation">
      <v-container class="pa-0">
        <!-- DISPLAY GENERIC ERRORS -->
        <non-field-errors :errors="form.errors.non_field_errors"></non-field-errors>

        <!-- FIELD: EMAIL -->
        <v-row v-if="!displayConfirmation">
          <v-col cols="12">
            E-mail invitation to: <b>{{ form.supplier.name }}</b>
          </v-col>
        </v-row>
        <v-row v-if="!displayConfirmation">
          <v-col cols="12">
            <v-text-field
                placeholder="Email address"
                v-model="form.supplier.email"
                :rules="form.rules.email"
                :error-messages="form.errors.email"
                :disabled="isSendingInvite"
                outlined
            />
          </v-col>
        </v-row>

        <!-- CONFIRMATION -->

        <v-row v-if="displayConfirmation">
          <v-col cols="12" style="font-size: 18px">
            <b>Are you sure you want to send an invitation email to {{ form.supplier.name }}?</b>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <template v-slot:actions>
      <v-spacer/>

      <v-btn v-if="displayConfirmation" class="mr-2 px-4" :disabled="isSendingInvite" @click="close">NO</v-btn>

      <v-btn
          v-if="displayConfirmation"
          class="px-4"
          color="primary"
          :loading="isSendingInvite"
          :disabled="!hasEmail"
          @click="sendInvitation"
      >
        YES
      </v-btn>

      <v-btn
          v-if="!displayConfirmation"
          color="primary"
          :loading="isSendingInvite"
          :disabled="!hasEmail"
          @click="displaySendConfirmation"
      >
        <v-icon left>mdi-send</v-icon>
        SEND
      </v-btn>
    </template>

  </responsive-dialog>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import {ADD_MESSAGE} from "@/store/mutation-types";

import api from "@/api";
import deep_copy from "../../../utilities/deep_copy";

function createModelRules() {
  return {
    email: [
      value => {
        if (value && value.length > 0) {
          return /.+@.+\..+/.test(value) || "E-mail must be valid.";
        }

        return true;
      }
    ]
  };
}

function cloneSupplierModel(data) {
  return deep_copy(data);
}

export default {
  name: "InvitationDialog",

  components: {
    NonFieldErrors,
    ResponsiveDialog
  },

  data() {
    return {
      displayDialog: false,
      displayConfirmation: false,
      isSendingInvite: false,

      form: {
        isValid: false,

        supplier: {
          email: "",
          target_entity: "",
          name: ""
        },

        rules: createModelRules(),
        errors: {}
      }
    };
  },

  computed: {
    hasEmail() {
      const email = this.form.supplier.email || "";
      return /.+@.+\..+/.test(email);
    },
    dialogTitle() {
      return this.displayConfirmation ?
          `Are you sure you want to send an invitation email to ${this.form.supplier.name}?`
          : "Send This Supplier an Email Invitation to join Accruvia";
    }
  },

  methods: {
    open(supplier) {
      if (!supplier) {
        return;
      }

      this.form.supplier = cloneSupplierModel(supplier);

      this.resetValidation();
      this.displayConfirmation = false;
      this.displayDialog = true;
      return this;
    },

    close() {
      this.displayDialog = false;
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    resetValidation() {
      this.form.errors = {};

      const $form = this.$refs.form;
      if ($form) {
        $form.resetValidation();
      }

      return this;
    },

    validate() {
      return this.$refs.form.validate();
    },

    displaySendConfirmation() {
      this.displayConfirmation = true;
    },

    sendInvitation() {
      if (!this.validate()) {
        return;
      }

      this.isSendingInvite = true;

      return api.invitations
          .send(this.form.supplier)
          .then(() => {
            this.displayMessage({text: "Invitation sent", type: "info"});
            this.close();
          })
          .catch(error => {
            this.displayMessage({text: "Invitation failed to send", type: "error"});
          })
          .finally(() => {
            this.isSendingInvite = false;
          });
    },
  },
};
</script>

<style scoped>

</style>