<template>
  <v-card tile outlined class="ma-5">
    <v-card-text>
      <v-row>
        <v-col cols="6" class="d-flex align-center justify-center flex-wrap">
          <v-img
            class="white--text align-end"
            contain
            height="100px"
            :src="require('@/assets/integrations/xero_logo_grayscale.png')"
          />
        </v-col>

        <v-col cols="6" class="d-flex align-center justify-center">
          <v-btn outlined color="grey" tile disabled style="min-width: 190px;">
            COMING SOON |
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Xero"
};
</script>
