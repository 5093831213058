<template>
  <v-card class="org-profile" elevation="0">
    <!-- TITLE -->
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />
    </v-card-title>

    <!-- MAIN CONTENT -->
    <v-card-text class="pt-4 px-4 pb-0">
      <v-tabs v-model="tab" background-color="primary" dark>
        <!-- TABS -->
        <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>

        <!-- TABS ITEMS -->
        <v-tabs-items v-model="tab">
          <v-tab-item :tabindex="0">
            <v-form v-model="form.isValid" @submit.prevent="submitForm" class="pt-6 pb-8">
              <!-- DISPLAY GENERIC ERRORS -->
              <non-field-errors :errors="form.errors.non_field_errors"></non-field-errors>

              <!-- WARN USER WHEN THE ACCOUNT IS OWNED BY SOMEONE ELSE -->
              <v-row v-if="addressEntityHasOwner">
                <v-col cols="12">
                  <v-alert type="info">
                    <!-- just to keep the format -->
                    {{ "This account is owned by another user and certain details may not be edited." }}
                  </v-alert>
                </v-col>
              </v-row>

              <!-- FIELD: USER -->
              <v-row v-if="currentUser.is_superuser">
                <v-col cols="12">
                  <v-autocomplete
                    label="User"
                    placeholder="Start typing to Search"
                    v-model="form.user"
                    :items="users"
                    :loading="loadingUsers"
                    :search-input.sync="userSearch"
                    :error="form.errors.user"
                    item-value="id"
                    item-text="username"
                    outlined
                    hide-no-data
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <!-- FIELD: NAME -->
                  <v-text-field
                    name="Organization[Name]"
                    label="Name*"
                    v-model="form.data.name"
                    :rules="form.rules.name"
                    :error-messages="form.errors.name"
                    :disabled="isLoading"
                    outlined
                    required
                  />

                  <!-- FIELD: EMAIL -->
                  <v-text-field
                    name="Organization[Email]"
                    label="Email"
                    v-model="form.data.email"
                    :rules="form.rules.email"
                    :error-messages="form.errors.email"
                    :disabled="isLoading"
                    outlined
                  />
                </v-col>
              </v-row>

              <!-- ADDRESS FIELDS -->
              <v-row>
                <v-col>
                  <!-- FIELD: ADDRESS LINE 1 -->
                  <v-text-field
                    name="Organization[AddressLine1]"
                    label="Address Line 1*"
                    v-model="form.data.address.address_line1"
                    :rules="form.rules.address_line1"
                    :error-messages="form.errors.address_line1"
                    :disabled="isLoading || addressEntityHasOwner"
                    outlined
                    required
                  ></v-text-field>

                  <!-- FIELD: ADDRESS LINE 2 -->
                  <v-text-field
                    name="Organization[AddressLine2]"
                    label="Address Line 2"
                    v-model="form.data.address.address_line2"
                    :error-messages="form.errors.address_line2"
                    :disabled="isLoading || addressEntityHasOwner"
                    outlined
                  ></v-text-field>

                  <!-- FIELD: CITY -->
                  <v-text-field
                    name="Organization[City]"
                    label="City*"
                    v-model="form.data.address.city"
                    :rules="form.rules.city"
                    :error-messages="form.errors.city"
                    :disabled="isLoading"
                    outlined
                    required
                  ></v-text-field>

                  <!-- FIELD: ADDRESS STATE -->
                  <v-text-field
                    name="Organization[State]"
                    label="State*"
                    v-model="form.data.address.state"
                    :rules="form.rules.state"
                    :error-messages="form.errors.state"
                    :disabled="isLoading"
                    outlined
                    required
                  ></v-text-field>

                  <!-- FIELD: ZIP CODE -->
                  <v-text-field
                    name="Organization[ZipCode]"
                    label="Zip code*"
                    v-model="form.data.address.code"
                    :rules="form.rules.zip"
                    :error-messages="form.errors.code"
                    :disabled="isLoading"
                    outlined
                    required
                  ></v-text-field>

                  <!-- FIELD: COUNTRY -->
                  <country-search
                    :initial-value="form.data.address.country"
                    :field-errors="form.errors.country"
                    :disabled="isLoading || addressEntityHasOwner"
                    :rules="form.rules.country"
                    @change="onCountrySelected"
                  ></country-search>
                </v-col>
              </v-row>

              <!-- ACTIONS -->
              <v-row>
                <v-col class="d-flex">
                  <v-spacer />
                  <v-btn
                    :loading="isLoading"
                    :disabled="isLoading || !isDirty"
                    type="button"
                    color="primary"
                    text
                    @click.prevent="submitForm"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item v-if="isUpdate" :tabindex="1">
            <entity-avatar :entity="form.data" :editable="true" class="entity-avatar pt-6 pb-8" />
          </v-tab-item>

          <v-tab-item v-if="isUpdate" :tabindex="2">
            <entity-logo :entity="form.data" :editable="true" class="entity-logo pt-6 pb-8" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import CountrySearch from "@/bcore/components/country/CountrySearch.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import EntityAvatar from "@/bcore/components/entity/EntityAvatar.vue";
import EntityLogo from "@/bcore/components/entity/EntityLogo.vue";

import PageTitle from "@/mixins/pageTitle";
import TrackDirtyFieldsMixin from "@/mixins/trackDirtyFields";

import { ADD_MESSAGE } from "@/store/mutation-types";

import { parseFormErrors } from "@/utilities/form";

import api from "@/api";
import deep_copy from "@/utilities/deep_copy";

// this method provides a clean state of organization model in order to reset the form
function createDataModel() {
  let organization = createOrganizationModel();
  return Object.assign({}, organization);
}

function createOrganizationModel() {
  // entity organization.details
  return {
    id: 0,
    name: "",
    email: "",
    address: {
      id: 0,
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      code: "",
      country: 0,

      owner_entity: 0,
      is_primary: true
    }
  };
}

function createModelRules() {
  return {
    name: [value => !!value || "Name is required."],

    email: [
      value => {
        if (value && value.length > 0) {
          return /.+@.+\..+/.test(value) || "E-mail must be valid.";
        }

        return true;
      }
    ],

    address_line1: [
      value => {
        return !!value || "Address Line 1 is required.";
      }
    ],

    city: [
      value => {
        return !!value || "City is required.";
      }
    ],

    state: [
      value => {
        return !!value || "State is required.";
      }
    ],

    zip: [
      value => {
        return !!value || "Zip Code is required.";
      }
    ],

    country: [
      value => {
        return !!value || "Country is required.";
      }
    ]
  };
}

export default {
  name: "OrganizationProfile",

  components: {
    CountrySearch,
    NonFieldErrors,
    EntityAvatar,
    EntityLogo
  },

  mixins: [PageTitle, TrackDirtyFieldsMixin],

  props: {},

  data() {
    return {
      tab: 0,

      isLoading: false,
      isDirty: false,

      form: {
        isValid: false,

        initialData: createDataModel(),
        data: createDataModel(),
        rules: createModelRules(),
        errors: {}
      }
    };
  },

  computed: {
    /* COMPUTED MODELS/VALUES */
    formTitle() {
      return this.isUpdate ? "Update Organization" : "New Organization";
    },

    tabs() {
      let tabs = ["Details"];

      if (this.isUpdate) {
        tabs.push("Avatar");
        tabs.push("Logo");
      }

      return tabs;
    },

    /* VALIDATORS */
    isUpdate() {
      return !!this.form.data.id;
    },

    isAddressUpdate() {
      return !!this.form.data.address.id;
    },

    addressEntityHasOwner() {
      const address = this.form.data.address;
      return !!(address.owner_entity && address.owner_entity.user_id);
    },

    formHasErrors() {
      return Object.keys(this.form.errors).length;
    },

    /* SHORTCUTS */
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    entitySettings() {
      return this.$store.state.entitySettings.detail;
    }
  },

  // Fetching organization details after the settings are loaded
  watch: {
    entitySettings: {
      handler(value) {
        if (!value) {
          return;
        }

        this.fetch();
      },

      immediate: true
    }
  },

  mounted() {
    this.tab = 0;
    this.trackDirtyFields();
  },

  methods: {
    fetch() {
      this.isLoading = true;

      const defaultFields = {
        owner_entity: this.ownerEntity.id
      };

      api.organizations
        .fetch(this.ownerEntity.id)

        .then(response => {
          const form = this.form;
          let organization = response.data;

          organization.address = Object.assign({}, defaultFields, organization.address);
          organization.address.country = organization.address.country || 0;

          // copy the new organization details into an empty model with the default fields
          // we need to deep copy org object for tracking dirty fields
          form.data = Object.assign(createDataModel(), organization);
          form.initialData = Object.assign(createDataModel(), deep_copy(organization));
        })
        .catch(() => {
          this.displayMessage({ text: "Can not fetch organization details.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onCountrySelected(country) {
      // Check selected data (country) is not null and id is not zero or empty value
      if (country && country.id) {
        this.form.data.address.country = country.id;
      }
    },

    submitForm() {
      this.isLoading = true;

      const form = this.form;

      return api.organizations
        .update(form.data)
        .then(organization => {
          // Update local data with the server's response
          Object.assign(form.data, organization);
          Object.assign(form.initialData, deep_copy(organization));

          this.showSuccessMessage();
          this.isLoading = false;

          return this;
        })
        .catch(error => {
          this.isLoading = false;
          this.setFormErrors(error.response.data);
          return Promise.reject(error);
        });
    },

    setFormErrors(errors) {
      // TODO: Carlos
      const form = this.form;
      const fieldErrors = errors.field_errors || [];
      form.errors = parseFormErrors(fieldErrors);
      form.errors.non_field_errors = errors.errors || [];
    },

    showSuccessMessage() {
      return this.displayMessage({
        type: "success",
        text: "Organization details were updated succesfuly."
      });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField("form.data.name", "form.initialData.name");
      this.trackDirtyField("form.data.email", "form.initialData.email");

      this.trackDirtyField("form.data.address.address_line1", "form.initialData.address.address_line1");
      this.trackDirtyField("form.data.address.address_line2", "form.initialData.address.address_line2");
      this.trackDirtyField("form.data.address.city", "form.initialData.address.city");
      this.trackDirtyField("form.data.address.state", "form.initialData.address.state");
      this.trackDirtyField("form.data.address.code", "form.initialData.address.code");
      this.trackDirtyField("form.data.address.country", "form.initialData.address.country");
    }
  }
};
</script>

<style>
/* .org-profile {} */
</style>
