<template>
  <v-card class="price-override-details" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <!--  -->
      Price Override Details
    </v-card-title>

    <v-card-text v-if="priceOverride" class="">
      <v-row>
        <v-col cols="6">
          <!-- FIELD: NAME -->
          <p>
            <strong>Name:</strong>
            <span>{{ priceOverride.name }}</span>
          </p>
          <!-- FIELD: TYPE -->
          <p>
            <strong>Type:</strong>
            <span>{{ getTypeText(priceOverride.type) }}</span>
          </p>
          <!-- FIELD: is_active -->
          <p>
            <strong>Status:</strong>
            <v-chip
              v-show="!is_loading"
              class="ml-2"
              :color="getStatusColor(priceOverride.is_active)"
              outlined
              label
              small
            >
              {{ getStatusText(priceOverride.is_active) }}
            </v-chip>
          </p>
        </v-col>

        <v-col cols="6">
          <!-- FIELD: FROM DATE -->
          <p>
            <strong>Start date:</strong>
            <span>{{ priceOverride.from_date }}</span>
          </p>
          <!-- FIELD: TO DATE -->
          <p>
            <strong>End date:</strong>
            <span>{{ priceOverride.to_date ? priceOverride.to_date : "Indefinite" }}</span>
          </p>
          <p>
            The price will be adjusted by
            <b>{{ priceOverrideConditionText }}</b>
          </p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="!isFixedPercentage" class="">
      <v-simple-table class="pod-items" fixed-header>
        <thead>
          <tr>
            <th class="text-left">Item</th>
            <th class="text-left">Description</th>
            <th class="text-left">Current Price</th>
            <th class="text-left">New Price</th>
          </tr>
        </thead>

        <tbody v-show="is_loading">
          <tr>
            <td colspan="4">
              <v-progress-linear indeterminate color="green darken-2"></v-progress-linear>
            </td>
          </tr>
        </tbody>

        <tbody v-show="!is_loading">
          <tr v-for="item in priceOverride.items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ itemPrice(item) }}</td>
            <td>{{ getCustomPrice(item) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>

    <v-card-text class="">
      <v-simple-table class="pod-customers" fixed-header>
        <thead>
          <tr>
            <th class="d-flex align-center">
              <span>Customers</span>
              <v-spacer />
              <v-btn text color="primary" @click="openCustomersForm">
                <v-icon left small>mdi-pencil</v-icon>
                Edit
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in priceOverride.customers" :key="customer.id">
            <td>
              <v-icon>mdi-account</v-icon>
              {{ customer.name }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>

    <price-override-customers-form
      v-if="priceOverride && priceOverride.id"
      ref="customers_form"
      :price-override-id="priceOverride.id"
      :initial-value="priceOverride.customers"
      @updated="fetchItem"
    />
  </v-card>
</template>

<script>
import PriceOverrideCustomersForm from "@/point-of-sale/components/price-override/PriceOverrideCustomersForm";

import priceOverrideTypeMixin from "@/mixins/priceOverrideType";
import priceOverrideStatusMixin from "@/mixins/priceOverrideStatus";
import currencyFormatMixin from "@/mixins/currencyFormat";
import { calculateCustomPrice } from "@/utilities/price_override";

import { ADD_MESSAGE } from "@/store/mutation-types";

import api from "@/api";

export default {
  name: "PriceOverrideDetails",

  components: { PriceOverrideCustomersForm },

  mixins: [priceOverrideTypeMixin, priceOverrideStatusMixin, currencyFormatMixin],

  data() {
    return {
      is_loading: false,
      priceOverride: {}
    };
  },

  computed: {
    priceOverrideConditionText() {
      if (this.priceOverride.percentage_amount === null) {
        return this.formatCurrency(this.priceOverride.custom_price);
      }

      return `${this.priceOverride.percentage_amount}%`;
    },

    isFixedPercentage() {
      return this.priceOverride.type === "fixed_percentage";
    }
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    fetchItem() {
      this.is_loading = true;

      api.price_overrides
        .fetch_details(this.$route.params.id)
        .then(response => {
          this.priceOverride = response.data;
        })
        .catch(exception => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: "Could not fetch price override information. Please try again.",
            type: "error"
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    getCustomPrice(item) {
      let result = calculateCustomPrice(this.priceOverride, item);
      return this.formatCurrency(result);
    },

    itemPrice(POItem) {
      let price = Number(POItem.price);
      return this.formatCurrency(price);
    },

    openCustomersForm() {
      this.$refs.customers_form.edit(this.priceOverride.id, this.priceOverride.customers);
    }
  }
};
</script>

<style>
.price-override-details .pod-items {
  max-height: 300px;
}

.price-override-details table thead th {
  font-size: 14px !important;
}
</style>
