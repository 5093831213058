import { http } from "@/vuex-bcore";

const url = "pos/customers";

// fetch all active POs for a given customer
function fetch_active_price_overrides(customer_id) {
  return http.get(`${url}/${customer_id}/price-overrides/active/`);
}

export default {
  fetch_active_price_overrides
};
