<template>
  <v-card class="org-invoices" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      {{ context.page_title }}
    </v-card-title>
    <v-card-text>
      <!-- DISPLAY GENERIC ERRORS -->
      <non-field-errors :errors="context.form.errors"></non-field-errors>

      <!-- DISPLAY FORM NOTIFICATIONS -->
      <v-row v-if="context.notifications.length">
        <v-col>
          <v-alert
            v-for="(alert, index) in context.notifications"
            border="left"
            elevation="0"
            class="mb-0 pl-6"
            :type="alert.type"
            :key="index"
            :icon="false"
            colored-border
            tile
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="alert.message"></span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: CUSTOMER -->
        <v-col cols="12" md="6">
          <!-- DISABLED TEXT FIELD IS USED ON UPDATE MODE -->
          <v-text-field v-if="isUpdate" :value="form.customer.name" :disabled="true" label="Customer" outlined />

          <!-- DROPDOWN/AUTOCOMPLETE IS USED WHEN CREATING A NEW INVOICE -->
          <customer-autocomplete
            v-show="!isUpdate"
            name="InvoiceEditor[SearchCustomer]"
            ref="customer_autocomplete"
            :form-loading="context.is_loading"
            :rules="context.form.rules.customer"
            @change="onCustomerChange"
          />
        </v-col>

        <!-- FIELD: CURRENCY -->
        <v-col cols="12" md="6">
          <currency-select
            v-model="form.currency"
            :error-messages="formErrors.currency"
            :initial-value="currency"
            :disabled="context.is_loading || isUpdate"
            @change="onCurrencyChange"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: INVOICE DATE -->
        <v-col cols="12" md="6">
          <v-menu
            ref="invoice_date_menu"
            v-model="showDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="300px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                name="InvoiceEditor[InvoiceDate]"
                label="Invoice Date"
                append-icon="mdi-calendar"
                v-model="form.date"
                v-bind="attrs"
                v-on="on"
                :disabled="context.is_loading"
                :error-messages="formErrors.date"
                @click:append="on.click"
                outlined
              />
            </template>
            <v-date-picker v-model="form.date" no-title @input="showDateCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>

        <!-- FIELD: DUE DATE -->
        <v-col cols="12" md="6">
          <v-menu
            ref="invoice_due_date_menu"
            v-model="showDueDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="300px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                name="InvoiceEditor[InvoiceDueDate]"
                label="Due Date"
                append-icon="mdi-calendar"
                v-model="form.due_date"
                v-bind="attrs"
                v-on="on"
                :disabled="context.is_loading"
                :error-messages="formErrors.due_date"
                @click:append="on.click"
                outlined
              />
            </template>
            <v-date-picker v-model="form.due_date" no-title @input="showDueDateCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: REFERENCE -->
        <v-col cols="12" md="6">
          <v-text-field
            label="Invoice No"
            name="InvoiceEditor[ReferenceNumber]"
            messages="Auto generated if left blank"
            v-model="form.reference_no"
            :error-messages="formErrors.reference_no"
            outlined
          />
        </v-col>

        <!-- FIELD: PO # -->
        <v-col cols="12" md="6">
          <v-text-field
            label="PO No."
            name="InvoiceEditor[PoNumber]"
            v-model="form.po_number"
            :error-messages="formErrors.po_number"
            :disabled="context.is_loading"
            outlined
          />
        </v-col>
      </v-row>

      <v-row id="invoice-items">
        <v-col cols="12">
          <v-alert
            v-show="!context.has_items"
            class="pl-6"
            border="left"
            type="warning"
            elevation="0"
            :icon="false"
            colored-border
            tile
          >
            <strong>Invoice Items are required.</strong>
            Add a description, price, quantity and click the "+ ADD" button.
          </v-alert>

          <invoice-items ref="invoice_items" @updated="onItemsUpdate" :loading="context.is_loading" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <invoice-media v-if="isUpdate" :invoice="form" :read-only="false" />
          <div v-if="!isUpdate">
            <v-switch v-model="switchSetTaxField" label="Set Sales Tax %" @change="toggleTaxSwitch"></v-switch>
            <v-text-field v-if="switchSetTaxField" v-model="userSetTaxPercentage" label="Tax Percentage"></v-text-field>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>Subtotal</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                <!--  -->
                {{ formatCurrency(subtotal, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="taxPercentage">
              <v-list-item-content>{{ taxTitle }} ({{ taxPercentage }}%)</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                <!--  -->
                {{ formatCurrency(tax, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Total</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                <!--  -->
                {{ formatCurrency(grandTotal, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <a @click="paymentsOpened = isUpdate">
                  Payments
                  <v-icon small color="primary">mdi-cash-plus</v-icon>
                </a>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <!--  -->
                {{ formatCurrency(form.payment_applied, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <b>Balance Due</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ formatCurrency(balanceDue, form.currency) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <!-- CUSTOMER MESSAGE -->
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Customer message"
            v-model="form.customer_message"
            :error-messages="formErrors.customer_message"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- ACTIONS BUTTONS -->
    <v-card-actions>
      <v-btn v-if="isUpdate" :disabled="context.is_loading" color="primary" text @click.prevent="onPreviewClick(form)">
        <v-icon left>mdi-eye</v-icon>
        Preview
      </v-btn>

      <v-spacer />

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            ref="save_and_send_button"
            v-on="on"
            :disabled="!context.is_email_communication_enabled || context.is_loading"
            color="success"
            text
            @click.native.prevent
          >
            Save &amp; Send
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-checkbox
              ref="send_me_a_copy"
              v-model="context.form.email_self"
              label="Send me a copy"
              :disabled="context.disable_save_and_send"
              @click.stop="context.form.email_self = !context.form.email_self"
            />
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            ref="send_email_menu_item"
            :disabled="!isUpdate || context.disable_save_and_send"
            @click.prevent="performSaveOption('SEND')"
          >
            <v-list-item-title>Send</v-list-item-title>
          </v-list-item>

          <v-list-item
            ref="save_and_send_email_menu_item"
            :disabled="context.disable_save_and_send"
            @click.prevent="performSaveOption('SAVE_AND_SEND')"
          >
            <v-list-item-title>Save &amp; Send</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        type="submit"
        color="primary"
        :loading="context.is_loading"
        :disabled="context.disable_save"
        @click="performSaveOption('SAVE')"
        text
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-card-actions>

    <!-- PAYMENTS DIALOG -->
    <responsive-dialog
      :show-actions="false"
      :display-dialog="paymentsOpened"
      :dialog-title="paymentsTitle"
      @closed="closePayments"
    >
      <v-container>
        <invoice-payments :invoice="form" @updated="onPaymentUpdate" />
      </v-container>
    </responsive-dialog>
  </v-card>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import InvoicePayments from "@/invoiceboost/components/invoice/InvoicePayments.vue";

import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import InvoiceItems from "@/invoiceboost/components/invoice/InvoiceItems.vue";
import InvoiceMedia from "@/invoiceboost/components/invoice/InvoiceMedia.vue";
import CurrencySelect from "@/invoiceboost/components/CurrencySelect.vue";
import CustomerAutocomplete from "@/invoiceboost/components/CustomerAutocomplete";

import currencyFormatMixin from "@/mixins/currencyFormat";
import sumColumnMixin from "@/mixins/sumColumn";
import PreviewMixin from "@/mixins/preview";
import dateMixin from "@/mixins/date";

import deep_copy from "@/utilities/deep_copy";
import MainLayout from "@/views/layouts/MainLayout";

export default {
  name: "InvoiceEditorDesktop",

  components: {
    MainLayout,
    ResponsiveDialog,
    NonFieldErrors,
    InvoiceItems,
    InvoiceMedia,
    InvoicePayments,
    CurrencySelect,
    CustomerAutocomplete
  },

  mixins: [sumColumnMixin, currencyFormatMixin, PreviewMixin, dateMixin],

  props: {
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },

    formMessage: { type: String, default: null }
  },

  data() {
    return {
      paymentsTitle: "Payments",

      paymentsOpened: false,
      switchSetTaxField: false,
      userSetTaxPercentage: null,

      showDateCalendar: false,
      showDueDateCalendar: false,

      dueDates: [],

      // customers autocomplete
      customersFilter: "",
      customers: [],
      customer: {}
    };
  },

  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    formErrors() {
      return this.context.form.field_errors;
    },

    form: {
      get() {
        return this.context.form.fields;
      },

      set(value) {
        this.context.form.fields = value;
      }
    },

    currency() {
      const settings = this.context.settings;
      return this.form.currency || settings.default_currency || "";
    },

    defaultCurrency() {
      const settings = this.context.settings;
      return settings.default_currency || "";
    },

    invoiceDueDate() {
      const settings = this.context.settings;
      let due_date = "";
      let today = new Date();

      if (settings.enable_payment_schedule) {
        //  Payment schedule can have multiple schedules
        this.fetchSchedulePayments();
        return this.form.due_date;
      } else {
        today.setDate(today.getDate() + (settings.default_credit_terms || 30));
        due_date = this.toDjangoDateField(today);
        return due_date;
      }
    },

    formMessages() {
      let messages = [];

      if (this.formMessage) {
        messages.push(this.formMessage);
      }

      if (!this.customerHasEmail && this.customerHasUser) {
        messages.push(
          "This organization does not have an accounts payable address, the email will be sent directly to the user."
        );
      }

      return messages;
    },

    isUpdate() {
      return !!this.form.id;
    },

    customerHasEmail() {
      const customer = this.form.customer;
      return !!(customer && customer.email);
    },

    customerHasUser() {
      const customer = this.form.customer;
      return !!(customer && customer.user);
    },

    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    },

    subtotal() {
      return this.sumColumn("total", this.form.items);
    },

    tax() {
      let calculatedTax;
      if (this.form.tax_percentage) {
        calculatedTax = (this.form.tax_percentage / 100) * this.subtotal;
      }
      return calculatedTax || Number(this.form.tax) || 0;
    },

    taxTitle() {
      return this.form.tax_title || "Taxes";
    },

    taxPercentage() {
      return this.form.tax_percentage || 0.0;
    },

    grandTotal() {
      return this.subtotal + this.tax;
    },

    balanceDue() {
      if (this.form.total && this.form.payment_applied) {
        return this.grandTotal - this.form.payment_applied;
      }

      return this.grandTotal;
    }
  },

  watch: {
    userSetTaxPercentage: {
      handler(value) {
        if (value) {
          this.context.form.tax_percentage = Math.round((Number(value) + Number.EPSILON) * 100) / 100;
        }
      }
    }
  },

  methods: {
    update_form(form) {
      this.form = deep_copy(form);
      this.$nextTick(() => {
        this.$refs.invoice_items.update(this.form.items);
      });
    },

    closePayments() {
      this.paymentsOpened = false;
    },

    performSaveOption(action) {
      if (action === "SAVE_AND_SEND") {
        return this.context.send_invoice();
      }

      if (action === "SEND") {
        return this.context.send_email();
      }

      // 'SAVE'
      this.context
        .save_invoice(false)
        .then(_ => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Invoice Saved", type: "success" });
        })
        .catch(_ => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: "Invoice not saved. Please check the form for errors",
            type: "error"
          });
        });
    },

    async fetchSchedulePayments() {
      try {
        const response = await this.$http.get(`invoiceboost/payment-schedules`);
        let resData = response.data;

        if (resData.length === 0) {
          return;
        }

        let today = new Date();
        this.dueDates = this.convertToDate(resData);

        let afterDates = data.filter(date => {
          return date.getTime() - today.getTime() > 0;
        });

        if (typeof afterDates[0] !== "undefined") {
          let due = new Date(afterDates[0].getFullYear(), afterDates[0].getMonth(), afterDates[0].getDate());
          this.form.due_date = this.toDjangoDateField(due);
        }
      } catch (error) {
        this.displayMessage({ type: "error", text: "Unable to fetch schedules." });
      }
    },

    // Event handlers
    onPaymentUpdate(total) {
      this.form.payment_applied = total;
    },

    onPreviewClick(item) {
      this.previewTitle = `Invoice: ${item.reference_no}`;
      this.previewUrl = `/invoiceboost/invoices/${item.id}/preview/`;
      this.showPreview();
    },

    onCustomerChange(value) {
      this.form.customer = value;
      this.form.target_entity = value.target_entity;
    },

    onCurrencyChange(value) {
      this.form.currency = value;
    },

    onItemsUpdate(items) {
      this.form.items = items;
    },

    // Utilities
    convertToDate(schedules) {
      const result = [];

      schedules.forEach(element => {
        if (element.by_date_of_the_week && element.ordinal && element.day_of_the_week) {
          result.push(this.fromWeekday(element.ordinal, element.day_of_the_week));
        } else if (!element.by_date_of_the_week && element.cardinal) {
          result.push(this.fromDay(element.cardinal));
        }
      });

      const diffdate = new Date();

      diffdate.setDate(1);

      result.sort((a, b) => {
        let distance_a = Math.abs(diffdate.getTime() - a.getTime());
        let distance_b = Math.abs(diffdate.getTime() - b.getTime());
        return distance_a - distance_b; // sort a before b when the distance is smaller
      });

      return result;
    },

    fromDay(day) {
      let date = new Date();
      date.setDate(parseInt(day));
      return date;
    },

    fromWeekday(ordinal, dayOfTheWeek) {
      let date = new Date();
      let month = date.getMonth();
      let weekdays = [];

      date.setDate(1);
      let dayOfWeek = parseInt(dayOfTheWeek) + 6;
      date.setDate(date.getDate() + ((dayOfWeek - d.getDay()) % 7));

      while (date.getMonth() === month) {
        weekdays.push(new Date(date.getTime()));
        date.setDate(date.getDate() + 7);
      }

      let index = parseInt(ordinal) - 1;

      if (typeof weekdays[index] === "undefined") {
        return new Date();
      }

      return weekdays[index];
    },

    toggleTaxSwitch(value) {
      if (!value) {
        this.form.tax_percentage = null;
        this.form.tax = null;
      } else {
        this.form.tax_percentage = this.userSetTaxPercentage;
      }
    }
  }
};
</script>

<style>
#invoice-form .v-alert + .v-alert {
  margin-top: 20px;
}

#invoice-form #invoice-items {
  min-height: 200px;
}

#invoice-form .v-alert .v-icon {
  margin-right: 8px;
}
</style>
