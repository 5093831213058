<template>
  <div class="missing-payment-methods-dialog">
    <v-dialog
      max-width="500px"
      v-model="showMessages"
      v-for="message in dialogMessages"
      :open="openDialog"
      :key="message.key"
      persistent
    >
      <v-card>
        <v-card-title class="headline">AR Payment method missing</v-card-title>
        <v-card-text>
          {{ message.text }}
        </v-card-text>

        <v-card-actions>
          <v-btn @click="close_dialog(message.key)" text>CLOSE</v-btn>

          <v-spacer />

          <v-btn color="success" @click="add_payment(message.key)" text>ADD NOW</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PaymentMethods v-if="entity" ref="payment_methods" account="receivable" editable />
  </div>
</template>

<script>
import { REMOVE_DIALOG_MESSAGE } from "@/store/mutation-types";
import PaymentMethods from "@/invoiceboost/components/payment-method/PaymentMethods.vue";

export default {
  name: "PaymentMethodMissingDialog",

  components: {
    PaymentMethods
  },

  data() {
    return {
      showMessages: true,
      openDialog: true
    };
  },

  computed: {
    dialogMessages() {
      return this.$store.state.theme.dialogMessages;
    },

    entity() {
      return this.$store.state.auth.entity;
    },
  },

  mounted() {
    // console.log("this.dialogMessages", this.dialogMessages);
  },

  methods: {
    // onClickCloseButton() {
    //   this.$emit("closed");
    // },

    // onClickAddNowButton() {
    //   this.$emit("accepted");
    // },

    close_dialog(key) {
      this.openDialog = false;
      this.$store.commit(`theme/${REMOVE_DIALOG_MESSAGE}`, key);
    },

    add_payment(key) {
      this.close_dialog(key);
      this.$refs.payment_methods.open(this.entity);
    }
  }
};
</script>
