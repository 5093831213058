export default {
  data() {
    return {
      previewUrl: "",
      previewTitle: "Preview"
    };
  },
  methods: {
    /**
     * Show an html preview window
     * Set the previewUrl and previewTitle accordingly before calling
     * this method.
     */
    async showPreview() {
      let html = `<h3>Loading...</h3>`;
      const previewWindow = window.open("", "previewWindow");
      previewWindow && (previewWindow.document.body.innerHTML = html);
      previewWindow && (previewWindow.document.title = this.previewTitle);
      try {
        const response = await this.$http.get(this.previewUrl);
        html = response.data;
      } catch (e) {
        html = `<h3>${e}</h3>`;
      }
      previewWindow && (previewWindow.document.body.innerHTML = html);
    }
  }
};
