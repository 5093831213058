import { http } from "@/vuex-bcore";

const url = "subscriptions/platform";

/**
 EXPECTED PAYLOAD

 subscription = {
  id: Number,
  plan_name: String, REQUIRED
  plan: Number, READ-ONLY
  plan_description: String, READ-ONLY
  customer_name: String, READ-ONLY
};
 */
function create(platform_subscription) {
  return http.post(`${url}/`, platform_subscription);
}

export default {
  create
};
