<template>
  <login-layout>
    <keep-alive>
      <component :is="active_component" :context="context"></component>
    </keep-alive>
  </login-layout>
</template>

<script>
import LoginLayout from "@/views/layouts/LoginLayout.vue";
import LoginMobile from "./LoginMobile.vue";
import LoginDesktop from "./LoginDesktop.vue";

import { ADD_DIALOG_MESSAGE } from "@/store/mutation-types";

import viewport from "@/mixins/viewport";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import app from "@/app";
import utils from "@/utilities";
import eventHub from "@/eventHub.js";

const is_empty = utils.is_empty;

export default {
  name: "Login",

  components: {
    NonFieldErrors,
    LoginLayout
  },

  mixins: [viewport],

  data() {
    return {
      is_loading: false,
      showPass: false,

      form: {
        fields: {
          username: "",
          password: "",
          trusted_device: false
        },

        field_errors: {},
        errors: []
      },
    };
  },

  computed: {
    context() {
      return this;
    },

    active_component() {
      return this.viewport.is_desktop ? LoginDesktop : LoginMobile;
    },

    organization_name() {
      const defaultOrganization = this.$store.state.auth.entity;

      if (defaultOrganization && defaultOrganization.name) {
        return defaultOrganization.name;
      }

      return null;
    }
  },

  mounted() {
      eventHub.listen("Organization.CHANGED", this.checkARNoPaymentMethod);
  },

  beforeDestroy() {
    eventHub.unlisten("Organization.CHANGED");
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      let error = to.params.error;

      if (error && error.message) {
        vm.form.errors = [to.params.error];
      }
    });
  },

  methods: {
    submit() {
      this.is_loading = true;

      app.user.login(this.form.fields)
        .then(user => {
          let redirect = this.$route.query.redirect || "/";
          this.$router.push(redirect);
        })
        .catch(exception => {
          let form = this.form;

          let field_errors = utils.form.get_field_errors(exception);
          let errors = utils.form.get_errors(exception);

          if (exception.is_superuser) {
            errors = [{
              type: "superuser_login",
              message: "Superusers may not access this UI, please use the Admin Panel."
            }];
          } else if (is_empty(field_errors) && is_empty(errors)) {
            errors = [{
              type: "error",
              message: "There was an issue performing this action, if this persist please contact us.",
            }];
          }

          form.field_errors = field_errors;
          form.errors = errors;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    // this should be moved to the main layout or the home page
    async checkARNoPaymentMethod(organization) {
      // organization might have been changed to null
      if (!organization) {
        return;
      }

      const response = await this.$http.get(`invoiceboost/invoices/`);

      if (response.data.count > 0) {
        const payments_response = await this.$http.get(`invoiceboost/payment-methods/`);

        if (payments_response.data.count === undefined || payments_response.data.count < 1) {
          this.$store.commit(`theme/${ADD_DIALOG_MESSAGE}`, {
            text: this.organization_name + " has not set up its payment details.",
            type: "warning"
          });
          // this.$store.commit(`showMissingPaymentDialog`, true);
        }
      }

      return Promise.resolve();
    },

    toggle_password_visibility() {
      this.showPass = !this.showPass;
    }
  }
};
</script>

<style>
</style>
