<template>
  <v-switch
    v-model="value"
    @change="saveValue"
    :label="inputLabel"
    :messages="inputMessages"
    :disabled="isDisabled"
    :loading="isLoading"
  ></v-switch>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "AutoPayRestricted",
  props: { targetId: { type: Number, default: 0 }, account: { type: String, default: "payable" } },

  data() {
    return {
      isLoading: false,
      isDisabled: false,
      value: false,
      settings: {},
    };
  },

  computed: {
    isGlobal() {
      return true;
    },

    targetUrl() {
        return this.settingsUrl;
    },

    settingsUrl() {
      return `/invoiceboost/entity-settings/${this.currentEntity.id}/`;
    },

    targetType() {
      return "supplier";
    },

    currentEntity() {
      return this.$store.state.auth.entity;
    },

    premium_organization_sponsor() {
      return this.$store.state.auth.entity.premium_organization_sponsor;
    },

    targetField() {
      return "enable_auto_pay";
    },

    inputMessages() {

      return this.isDisabled
        ? [
            "You have already enabled Auto-pay in organization settings, you will be automatically charged"
          ]
        : [
            `Automatically pays all future invoices for ${this.premium_organization_sponsor.name} if you have a default credit card set.`
          ];
    },

    inputLabel() {
      return "Auto-Pay";
    }
  },

  mounted() {
    this.fetchValue();

    if (!this.isGlobal) {
      // Disable target level settings if global setting is enabled
      this.$http.get(this.settingsUrl).then(response => {
        this.isDisabled = response.data[this.targetField];
      });
    }
  },

  methods: {
    async fetchValue() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(this.targetUrl);
        this.value = response.data[this.targetField];
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: `Could not get Auto-Pay setting`,
          type: "error"
        });
      }
      this.isLoading = false;
    },

    async saveValue() {
      this.isLoading = true;
      try {
        let payload = {};
        payload["enable_auto_pay"] = this.value;

        const response = await this.$http.patch(this.targetUrl, payload);
        this.value = response.data[this.targetField];

        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: `Auto-Pay status updated`,
          type: "success"
        });
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: `Could not update Auto Pay setting`,
          type: "error"
        });

        this.value = !this.value;
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped></style>
