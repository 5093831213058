<template>
  <v-card v-if="displayPanel" class="org-bill-details overflow-y-auto" elevation="8" outlined>
    <v-card-title class="d-flex">
      <span class="mr-auto">Bill Details</span>
      <v-btn class="btn-close" @click.prevent="close" text icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- DETAIL FIELDS -->
    <v-card-text>
      <ul class="pa-0">
        <!-- FIELD: SUPPLIER NUMBER -->
        <li v-if="!has_premium_customer_restrictions">
          <strong>Supplier</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <router-link :to="{ name: 'org-suppliers', params: { id: supplier } }" target="_blank">
              {{ invoice.supplier_name }}
            </router-link>
          </span>
        </li>

        <!-- FIELD: INVOICE NUMBER -->
        <li>
          <strong>Invoice #</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <a @click.prevent="previewInvoice" style="text-decoration: underline">{{ invoice.reference_no }}</a>
          </span>
        </li>

        <!-- FIELD: TOTAL -->
        <li>
          <strong>Amount</strong>
          <span>{{ formatCurrency(invoice.total, invoice.currency) }}</span>
        </li>

        <!-- FIELD: BALANCE DUE -->
        <li>
          <strong>Balance Due</strong>
          <span>{{ formatCurrency(invoice.balance_due, invoice.currency) }}</span>
        </li>

        <!-- FIELD: TERMS -->
        <li>
          <strong>Terms</strong>
          <span>{{ parseTerms(invoice.terms) }}</span>
        </li>

        <!-- FIELD: CREATED -->
        <li>
          <strong>Created</strong>
          <span>{{ formatDate(invoice.created_at) }}</span>
        </li>

        <!-- FIELD: LAST UPDATED -->
        <li>
          <strong>Last Updated</strong>
          <span>{{ formatDate(invoice.modified_at) }}</span>
        </li>

        <!-- FIELD: ASSIGNEES -->
        <li>
          <strong>Assignees</strong>
          <span>
            <invoice-assignees-editor
              :invoice-payable="true"
              :invoice-id="invoice.id"
              :can-change-invoice="hasPermission('change_accounts_payable')"
            />
          </span>
        </li>

        <!-- FIELD: STATUS -->
        <li>
          <strong>Status</strong>
          <span>
            <!-- <v-icon small>{{ getStatusIcon(invoice.status) }}</v-icon> -->
            {{ getStatusText(invoice.status) }}
          </span>
          <span v-show="showEditButton" class="pl-1">
            |
            <a href="" id="show-status-actions" class="update" @click.prevent="showStatusActions">Edit</a>
          </span>
        </li>
      </ul>
    </v-card-text>

    <!-- STATUS UPDATE FORM -->
    <v-card-text
      v-if="displayStatusActions && hasPermission('change_accounts_payable')"
      class="status-actions justify-space-between pa-4 mb-4"
    >
      <v-form @submit.prevent="updateStatus">
        <v-radio-group v-model="form.status" class="mt-0 mb-4 pt-0" hide-details mandatory>
          <div class="mb-4">
            <strong>Edit Status</strong>
          </div>

          <v-radio value="approved" :disabled="isPaidInvoice">
            <template v-slot:label>
              <div>Approved</div>
            </template>
          </v-radio>

          <v-radio value="disputed" :disabled="isPaidInvoice">
            <template v-slot:label>
              <div>Disputed</div>
            </template>
          </v-radio>

          <v-radio value="payment_sent" :disabled="isPaidInvoice">
            <template v-slot:label>
              <div>Payment Sent</div>
            </template>
          </v-radio>

        </v-radio-group>

        <v-divider class="mb-4" />

        <!-- COMMENT -->
        <v-text-field
          name="Invoice[comment]"
          label="Comment"
          v-model="form.comment"
          background-color="#FFF"
          :disabled="isPaidInvoice"
          outlined
        />

        <!-- EXPECTED PAYMENT DATE -->
        <div v-show="isStatusApproved">
          <v-text-field
            name="Invoice[ExpectedPaymentDate]"
            label="Expected payment date"
            append-icon="mdi-calendar"
            background-color="#FFF"
            v-model="invoice.expected_payment_date"
            @click:append="displayEPDC = true"
            outlined
          />

          <v-dialog v-model="displayEPDC" max-width="300px">
            <v-date-picker v-model="invoice.expected_payment_date"></v-date-picker>
          </v-dialog>
        </div>

        <div class="d-flex justify-space-between">
          <v-btn color="default" :disabled="isLoading" @click.prevent="hideStatusActions" small>
            <!-- <v-icon left small>mdi-cancel</v-icon> -->
            Cancel
          </v-btn>

          <v-spacer />

          <v-btn type="submit" id="save_status_btn" color="primary" :disabled="isLoading || isPaidInvoice" small>
            <v-icon left small>mdi-content-save-edit</v-icon>
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card-text>

    <v-card-text class="pt-0">
      <v-divider class="mb-4" />

      <ul class="pa-0">
        <li>
          <strong>PDF</strong>
          <span>
            <v-icon small>
              {{ isLoading ? "mdi-download-outline" : "mdi-file-pdf-outline" }}
            </v-icon>
            <a :class="{ underline: !isLoading }" @click.prevent="downloadPdf" :disabled="isLoading">
              <span v-if="isLoading">Downloading...</span>
              <span v-else>Download</span>
            </a>
          </span>
        </li>

        <li>
          <strong>Preview</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <a class="underline" @click.prevent="previewInvoice">Open</a>
          </span>
        </li>

        <li v-if="invoice && invoice.attachments.length > 0">
          <strong>Attachments</strong>
          <div class="mt-6">
            <ul>
              <li v-for="attachment in invoice.attachments" :key="attachment.url">
                <v-icon class="mr-4">
                  {{ attachment.downloading ? "mdi-download-outline" : "mdi-download" }}
                </v-icon>

                <a
                  :class="{ underline: !attachment.downloading }"
                  @click.prevent="downloadAttachment(attachment)"
                  :disabled="attachment.downloading"
                >
                  <span>{{ attachment.description }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </v-card-text>

    <!-- ACTIONS -->
    <v-card-text
      v-if="!displayStatusActions && hasPaymentProcessors && invoice.balance_due > 0"
      class="invoice-actions pt-0"
    >
      <v-divider class="mb-4" />

      <div class="d-flex justify-space-between">
        <v-spacer />
        <v-btn
          v-if="isPayableInvoice"
          class=""
          color="secondary"
          :disabled="isPayInvoiceDisabled"
          @click.prevent="payInvoice"
          small
        >
          <v-icon left small>mdi-credit-card-outline</v-icon>
          Pay Invoice
        </v-btn>
      </div>
    </v-card-text>

    <!-- EXPANDABLE DETAILS -->
    <v-expansion-panels class="pt-0 pb-4" flat focusable accordion>
      <v-expansion-panel class="payment-methods">
        <v-expansion-panel-header>Payment Methods</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul class="pa-0">
            <li v-if="supplierPaymentMethods.length === 0">
              <div class="d-flex">No payments methods available.</div>
            </li>

            <li v-for="item in supplierPaymentMethods" :key="item.id" class="flex-column">
              <div class="d-flex">
                <strong class="flex-shrink-0">Name</strong>
                <span>{{ item.name }}</span>
              </div>
              <div class="d-flex">
                <strong class="flex-shrink-0">Instructions</strong>
                <span><a href @click.prevent="copyToClipBoard(item.instructions)">Copy</a></span>
              </div>

              <div class="d-flex" style="white-space: pre-line; margin-top: 30px">
                {{ item.instructions }}
              </div>

              <v-divider class="my-3" />
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="status-history">
        <v-expansion-panel-header>Status History</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul class="pa-0">
            <li v-for="(history, index) in statusHistory" :key="index" class="flex-column">
              <div class="d-flex">
                <strong class="flex-shrink-0">Status</strong>
                <span>{{ getStatusText(history.status) }} | {{ formatDate(history.created_at) }}</span>
              </div>
              <div class="d-flex">
                <strong class="flex-shrink-0">Employee</strong>
                <span>{{ history.created_by || "N/A" }}</span>
              </div>
              <div class="d-flex">
                <strong class="flex-shrink-0">Comment</strong>
                <span>{{ history.comment || "N/A" }}</span>
              </div>

              <v-divider class="my-3" />
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- PAY BILL DIALOG -->
    <pay-bill-dialog ref="pay_bill_dialog" @PaymentConfirmed="paymentConfirmed" />
  </v-card>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

import PayBillDialog from "@/invoiceboost/views/bill/PayBillDialog.vue";
import InvoiceAssigneesEditor from "@/invoiceboost/components/invoice/InvoiceAssigneesEditor.vue";

import InvoiceStatusMixin from "@/mixins/invoiceStatus";
import CurrencyFormatMixin from "@/mixins/currencyFormat";
import CopyToClipBoardMixin from "@/mixins/copyToClipBoard";
import DateMixin from "@/mixins/date";
import PreviewMixin from "@/mixins/preview";
import DownloadMixin from "@/mixins/download";
import InvoiceTermsMixin from "@/mixins/invoiceTerms";
import PermissionMixin from "@/mixins/permission";

import eventHub from "@/eventHub";
import api from "@/api";

export default {
  name: "BillDetails",

  components: {
    PayBillDialog,
    InvoiceAssigneesEditor
  },

  mixins: [
    InvoiceStatusMixin,
    CurrencyFormatMixin,
    DateMixin,
    PreviewMixin,
    DownloadMixin,
    InvoiceTermsMixin,
    PermissionMixin,
    CopyToClipBoardMixin
  ],

  props: {},

  data() {
    return {
      isLoading: false,

      displayPanel: false,
      displayStatusActions: false,
      displayEPDC: false, // Expected Payment Date Calendar

      invoice: null,

      form: {
        status: "",
        comment: "",
        expected_payment_date: null
      },

      statusHistory: [],
      supplierPaymentMethods: [],
      supplierPaymentProcessors: []
    };
  },

  computed: {
    supplier() {
      return this.invoice.supplier;
    },

    has_premium_customer_restrictions() {
      return this.$store.state.auth.entity.has_premium_customer_restrictions;
    },

    showEditButton() {
      return !this.displayStatusActions && this.hasPermission("change_accounts_payable");
    },

    isPayInvoiceDisabled() {
      return this.displayStatusActions || this.isLoading;
    },

    isPaidInvoice() {
      return this.invoice && this.invoice.status === "paid";
    },

    isPayableInvoice() {
      return this.hasPermission("make_payment");
    },

    isStatusApproved() {
      return this.form.status === "approved";
    },

    hasPaymentProcessors() {
      return this.supplierPaymentProcessors.length > 0;
    }
  },

  watch: {},

  mounted() {
    eventHub.listen("BillDetails.open", this.open);
  },

  methods: {
    open(invoice) {
      this.displayPanel = true;
      this.invoice = invoice;

      this.form.status = invoice.status;
      this.form.expected_payment_date = invoice.expected_payment_date;

      this.fetchStatusHistory();
      this.fetchSupplierPaymentMethods();
      this.fetchSupplierPaymentProcessors();
    },

    close() {
      this.displayPanel = false;
    },

    fetchStatusHistory() {
      return api.invoice_payable.fetch_status_history(this.invoice.id).then(response => {
        this.statusHistory = response.data;
      });
    },

    fetchSupplierPaymentMethods() {
      if (!this.supplier) return;

      this.isLoading = true;

      const paymentMethodUrl = `invoiceboost/organizations/${this.invoice.target_entity}/payment_methods/`;

      return this.$http
        .get(paymentMethodUrl)
        .then(response => {
          this.supplierPaymentMethods = response.data;
        })
        .catch(() => {
          this.displayMessage({ text: "Unable to fetch payment methods.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetchSupplierPaymentProcessors() {
      if (!this.supplier) return;

      const paymentProcessorUrl = `invoiceboost/organizations/${this.invoice.target_entity}/payment_processors/`;

      return this.$http
        .get(paymentProcessorUrl)
        .then(response => {
          this.supplierPaymentProcessors = response.data;
        })
        .catch(() => {
          this.displayMessage({ text: "Unable to fetch payment processors.", type: "error" });
        });
    },

    showStatusActions() {
      // reset invoice status selection everytime before displaying the fields
      this.form.status = this.invoice.status;
      this.form.comment = "";
      this.displayStatusActions = true;
    },

    hideStatusActions() {
      this.displayStatusActions = false;
    },

    updateStatus() {
      this.isLoading = true;

      const form = this.form;
      const payload = {
        id: this.invoice.id,
        status: form.status,
        comment: form.comment
      };

      api.invoice_payable
        .update_status(payload)
        .then(response => {
          const data = response.data;
          this.invoice.status = data.status;

          this.hideStatusActions();
          this.displayMessage({ text: "Status/Comment updated.", type: "success" });

          eventHub.trigger("Bill.UPDATED", this.invoice);
        })
        .catch(error => {
          this.displayMessage({ text: "Status/Comment update failed.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    payInvoice() {
      this.$refs.pay_bill_dialog.pay([this.invoice]);
    },

    downloadPdf() {
      this.isLoading = true;

      const pdfUrl = `/invoiceboost/bills/${this.invoice.id}/pdf/`;
      this.downloadResource(pdfUrl, `invoice-${this.invoice.reference_no}.pdf`).finally(() => {
        this.isLoading = false;
      });
    },

    downloadAttachment(attachment) {
      if (attachment.downloading) {
        return;
      }

      attachment.downloading = true;

      const url = `/invoiceboost/bills/${this.invoice.id}/download-media?id=${attachment.id}`;
      this.downloadResource(url, attachment.description).finally(() => {
        attachment.downloading = false;
      });
    },

    previewInvoice() {
      // TODO: refactor preview mixin
      const invoice = this.invoice;

      this.previewTitle = `Invoice: ${invoice.reference_no}`;
      this.previewUrl = `/invoiceboost/bills/${invoice.id}/preview/`;
      this.showPreview();
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    paymentConfirmed(confirmation) {
      const balance_due = Number(confirmation.remaining);
      if (balance_due === 0) this.invoice.status = "paid";
      else this.invoice.status = "partially_paid";
      this.invoice.balance_due = balance_due;
      eventHub.trigger("Bill.UPDATED", this.invoice);
    }
  }
};
</script>

<style>
.org-bill-details {
  width: 420px;
  height: 85vh;

  position: absolute;
  right: 10px;
}

.org-bill-details ul {
  list-style: none;
}

.org-bill-details ul li {
  display: flex;
  line-height: 2.2;
}

.org-bill-details ul li strong {
  width: 120px;
}

.org-bill-details ul li span {
  position: relative;
}

.org-bill-details ul li span i:first-child {
  position: absolute;
  top: 7px;
  left: -20px;
}

.org-bill-details .status-actions {
  background-color: #eeeeee;
  color: #0c5394;
}

.org-bill-details .status-history ul li strong {
  width: 90px;
}

.org-bill-details .status-history ul li {
  line-height: 1.75;
}

.org-bill-details .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
  margin-bottom: 12px;
}

.org-bill-details .payment-methods .v-expansion-panel-header,
.org-bill-details .status-history .v-expansion-panel-header {
  background-color: #eeeeee;
  margin-bottom: 2px;
  border-radius: 0;
}

.org-bill-details .v-card__title {
  background: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.org-bill-details .v-divider {
  flex-basis: 100%;
  width: 100%;
}

.org-bill-details a.underline {
  text-decoration: underline;
}
</style>
