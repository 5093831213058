<template>
  <v-card class="org-drivers" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="hasPermission('add_driver', 'point_of_sale')"
        title="New Driver"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newDriver"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder=""
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        dense
        multi-sort
        disable-filtering
      >
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="hasPermission('change_driver', 'point_of_sale')" @click="editDriver(item)">
                <v-icon left>mdi-pencil</v-icon>
                Edit
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>

    <driver-form ref="driver_form" @updated="onDriverCreated" />

    <v-dialog v-model="confirmOpen" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Confirm action</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onConfirmButton">ACCEPT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DataTableMixin from "@/mixins/dataTable";
import PermissionMixin from "@/mixins/permission";
import DateMixin from "@/mixins/date";
import PageTitle from "@/mixins/pageTitle";
import DriverForm from "@/point-of-sale/components/driver/DriverForm";

import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "Drivers",

  components: {
    PageTitle,
    DriverForm
  },

  mixins: [PageTitle, DataTableMixin, DateMixin, PermissionMixin],

  data() {
    return {
      formLoading: false,
      formOpened: false,
      displayFilters: false,

      deleteConfirmOpen: false,
      idDeleteItem: -1,

      confirmOpen: false,
      dialogText: null,

      currentItem: null,
      entity: {},

      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Phone #", value: "phone_number", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.$store.state.driver.list;
    },

    isLoading() {
      return this.$store.getters["driver/loading"]("list");
    }
  },

  beforeDestroy() {
    this.clearItems();
  },

  methods: {
    async fetchItems() {
      await this.$store.dispatch("driver/list");
    },

    clearItems() {
      this.$store.dispatch(`driver/clearState`, "list");
    },

    newDriver() {
      this.$refs.driver_form.new();
    },

    onDriverCreated(driverCreated) {
      this.fetchItems();
    },

    editDriver(driver) {
      this.$refs.driver_form.open(driver);
    },

    async onConfirmButton() {
      // await api.driver.cancel(this.currentItem.id);
      // this.confirmOpen = false;
      // await this.fetchItems();
    },

    onClickDetailItem(item) {
      // this.$refs.quote_details.open(item);
    }
  }
};
</script>

<style>
.org-drivers .search-field .v-input__slot {
  margin: 0;
}

.org-drivers .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-drivers table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-drivers table thead tr {
  background-color: #eeeeee;
}

.org-drivers table thead tr th {
  color: #000 !important;
}
</style>
