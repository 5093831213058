<template>
  <v-form @submit.prevent="onSubmitPaymentForm">
    <v-row>
      <v-col cols="12">
        <template>
          <v-row class="ml-4">
            <v-col cols="12" md="6">
              <b>Payment method</b>
              <v-divider></v-divider>
              <v-radio-group v-model="paymentMethod" row>
                <v-radio label="Cash" value="cash"></v-radio>
                <v-radio label="Credit Card" value="card"></v-radio>
              </v-radio-group>
              <v-row v-if="displayCardManager" justify="space-between">
                <credit-card-manager
                  :display-auto-pay="false"
                  :allow-expand="false"
                  :allow-selectors="true"
                  :allow-direct-payment="true"
                  :customer-id="order.target_entity.id"
                  :supplier-id="order.owner_entity.id"
                  @CreditCardSelected="cardSelected"
                  @CardPaymentSubmitted="onSubmitDirectPayment"
                />
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="pl-3">
              <v-text-field prefix="$" v-model="paymentAmount" label="Payment amount"></v-text-field>
              <v-checkbox
                v-model="closeOnPayment"
                label="Close order after payment processing"
                hide-detail
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn type="submit" color="primary" x-large :loading="isLoading" :disabled="disablePaymentButton">
        Process order payment
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import CreditCardManager from "@/invoiceboost/components/payment-method/CreditCardManager.vue";

const defaultObject = () => {
  return { total: "0" };
};

export default {
  name: "OrderPaymentForm",

  components: { CreditCardManager },

  props: { order: { type: Object, default: defaultObject() } },

  data() {
    return {
      isLoading: false,
      closeOnPayment: false,
      paymentMethod: "cash",
      isDirectPayment: false,
      paymentAmount: "0",
      selectedCard: null,

      form: {
        close_on_payment: false,
        store_card: false,
        payment_amount: "0",
        payment_profile_id: null,

        card_profile: {}
      }
    };
  },

  computed: {
    displayCardManager() {
      return this.paymentMethod === "card";
    },

    taxTitle() {
      return this.tax_title || "Taxes";
    },

    disablePaymentButton() {
      if (this.displayCardManager && !this.selectedCard) {
        return true;
      }
      return this.isLoading;
    }
  },
  watch: {
    paymentAmount: {
      handler(value) {
        if (value) {
          this.paymentAmount = Math.round((Number(this.paymentAmount) + Number.EPSILON) * 100) / 100;
        }
      }
    }
  },

  mounted() {
    this.paymentAmount = this.order.total;
  },

  methods: {
    cardSelected(card) {
      this.selectedCard = this.form.card_profile = { ...card };
    },

    onSubmitPaymentForm() {
      this.isLoading = true;
      this.isDirectPayment = this.form.store_card = false;
      this.form.close_on_payment = this.closeOnPayment;
      this.processPayment();
    },

    onSubmitDirectPayment(paymentInfo) {
      this.isLoading = true;
      this.isDirectPayment = true;
      this.cardSelected(paymentInfo.card_profile);
      this.form.store_card = paymentInfo.store_card;
      this.form.close_on_payment = this.closeOnPayment;
      this.processPayment();
    },

    async processPayment() {
      this.form.payment_amount = this.paymentAmount;

      let url = `pos/orders/${this.order.id}`;
      if (this.paymentMethod === "card") {
        url = url + `/card_payment/`;
        if (this.isDirectPayment) {
          url = url + `?provider=direct`;
        }
      } else {
        url = url + `/cash_payment/`;
      }
      this.$http
        .post(url, this.form)
        .then(response => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Order payment processed successfully" });
          this.$emit("accepted");
        })
        .catch(error => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Failed to process order payment" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
