import { http } from "@/vuex-bcore";

const url = "invoiceboost/employees";

function fetch(employee_id) {
  return http.get(`${url}/${employee_id}/`);
}

function fetch_all() {
  return http.get(`${url}/`);
}

function create(employee) {
  return http.post(`${url}/`, employee);
}

function update(employee) {
  return http.patch(`${url}/${employee.id}/`, employee);
}

export default {
  fetch,
  fetch_all,
  create,
  update
};
