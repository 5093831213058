import { ModelState } from "../types";

const getState = <S>(): ModelState<S> => {
  return {
    list: [],
    detail: null,
    loading: false,
    meta: {},
    filters: {},
    requests: {},
    errors: {}
  };
};

export default getState;
