<template>
  <v-card class="org-employees" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn title="New Employee" color="primary" class="px-0 mr-3 align-center" @click="newEmployee" outlined icon>
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        class="pa-2"
        :headers="headers"
        :items="items"
        :loading="loadingTable"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        @click:row="view_employee_details"
        disable-filtering
        disable-sort
      >
        <template v-slot:item.action="{ item }">
          <v-menu v-if="!item.is_owner" bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link :href="item.file" target="_blank">
                <v-list-item-title @click.prevent="editEmployee(item)">
                  <v-icon left>mdi-pencil</v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="$refs.permissions.open(item, Boolean(item.user))">
                <v-list-item-title>
                  <!--  -->
                  <v-icon left>mdi-lock</v-icon>
                  Permissions
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent="$refs.transfer.open(item)">
                <v-list-item-title>
                  <!--  -->
                  <v-icon left>mdi-account-multiple</v-icon>
                  Transfer ownership
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent="deleteEmployee(item)">
                <v-list-item-title>
                  <!--  -->
                  <v-icon left>mdi-delete</v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-chip v-else small color="primary">Owner</v-chip>
        </template>
      </v-data-table>
    </v-card-text>

    <employee-form-dialog ref="employee_form" @created="reloadItems" @updated="reloadItems"></employee-form-dialog>

    <employee-permissions ref="permissions"></employee-permissions>

    <delete-dialog
      :object-name="deleteItemName"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />

    <organization-transfer ref="transfer"></organization-transfer>
  </v-card>
</template>

<script>
import EmployeeFormDialog from "@/invoiceboost/components/employee/EmployeeFormDialog.vue";
import EmployeePermissions from "@/invoiceboost/components/employee/EmployeePermissions.vue";

import OrganizationTransfer from "@/bcore/components/organization/OrganizationTransfer.vue";

import PageTitle from "@/mixins/pageTitle";
import relationshipViewMixin from "@/mixins/relationshipView";

export default {
  name: "Employees",

  components: {
    EmployeeFormDialog,
    EmployeePermissions,
    OrganizationTransfer
  },

  mixins: [PageTitle, relationshipViewMixin],

  data() {
    return {
      deleteItemName: null
    };
  },

  methods: {
    newEmployee() {
      this.$refs.employee_form.new();
    },

    editEmployee(employee) {
      // passing {employee} directly because the edit method clones the original data so that this is not a problem in this case
      this.$refs.employee_form.open(employee);
    },

    deleteEmployee(employee) {
      this.deleteItemName = employee.name;
      this.onClickDeleteButton(employee);
    },

    reloadItems() {
      this.fetchItems();
    },

    view_employee_details(employee) {
      this.$router.push({ name: "org-employee-details", params: { id: employee.id } });
    }
  }
};
</script>

<style>
.org-employees .search-field .v-input__slot {
  margin: 0;
}

.org-employees .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-employees table tr {
  line-height: 40px;
  cursor: pointer;
}
</style>
