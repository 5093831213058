/**
 * Pass the amount and value to format as currency
 * @param value: {number} The monetary amount
 * @param currency: {string} The 3 letter international currency code (e.g USD)
 */
function format(value, currency = "USD") {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2
  });

  return formatter.format(value);
}

export default {
  format
};