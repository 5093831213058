<template>
  <responsive-dialog
    content-class="driver-form-dialog"
    :display-dialog="formOpened"
    :dialog-title="formTitle"
    @closed="closeForm"
  >
    <v-container class="pa-0">
      <v-form ref="form" v-model="isFormValid" :lazy-validation="false" @submit.prevent="submitForm">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field v-model="form.name" label="Name" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="form.phone_number" label="Phone Number" outlined></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <template slot="actions">
      <v-spacer />

      <v-btn type="submit" color="primary" :loading="formLoading" :disabled="disableSave" @click="submitForm" text>
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import api from "@/api";
import { parseFormErrors } from "@/utilities/form";
import { ADD_MESSAGE } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";

function createDriverModel() {
  return {
    owner_entity: null,
    name: null,
    phone_number: null
  };
}

function createModelRules() {
  return {
    customer: [value => !!value || false]
  };
}

export default {
  name: "DriverForm",

  components: {
    ResponsiveDialog
  },

  data() {
    return {
      isLoading: false,
      formOpened: false,

      form: createDriverModel(),
      rules: createModelRules(),
      formErrors: {},
      isFormValid: false
    };
  },

  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    formTitle() {
      return this.isUpdate ? `Update Driver information` : `New Driver`;
    },

    formLoading() {
      const action = this.$store.getters["driver/loading"];

      return this.isLoading || action("create") || action("update") || action("detail");
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.form.id;
    },

    disableSave() {
      return !!this.formLoading;
    }
  },
  
  methods: {
    new() {
      this.resetValidation();

      this.form = createDriverModel();

      this.form.owner_entity = this.ownerEntity.id;

      this.formOpened = true;
    },

    open(item) {
      if (item) {
        if (item.id) {
          this.form = { ...item };
        }
        this.formOpened = true;
      }
    },

    resetValidation() {
      this.formErrors = {};
    },

    async submitForm() {
      this.isLoading = true;
      this.resetValidation();

      const isUpdate = this.isItemUpdate;
      const event_name = isUpdate ? "updated" : "created";
      const promise = isUpdate ? this.update() : this.create();

      promise
        .then(response => {
          return response.data;
        })
        .then(() => {
          this.displayMessage({ text: `Driver ${event_name} successfully.`, type: "success" });
          this.$emit("updated", this.form);
          this.closeForm();
        })
        .catch(error => {
          this.setFormErrors(error.response.data);
          this.displayMessage({ text: "There was an error saving the driver information.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    create() {
      return api.driver.create(this.form);
    },

    update() {
      return api.driver.update(this.form);
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    closeForm() {
      this.formOpened = false;
      this.resetFormErrors();
      this.$emit("closed");
    },

    resetFormErrors() {
      this.formErrors = {};
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style>
.driver-form-dialog .v-alert + .v-alert {
  margin-top: 16px;
}

.driver-form-dialog .order-items {
  min-height: 200px;
}
</style>
