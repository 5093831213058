<template>
  <v-card>
    <v-card-title class="display-1 font-weight-thin">Delivery Options</v-card-title>
    <v-card-text class="pl-8 pr-8 pb-12">
      <!-- General info -->
      <v-row>
        <v-col cols="6">
          <p>
            Delivery: #
            <b>{{ delivery.reference_no }}</b>
          </p>
        </v-col>
        <v-col cols="6">
          <p>
            Customer:
            <b>{{ customer }}</b>
          </p>
        </v-col>
      </v-row>
      <v-form>
        <v-row>
          <!-- Col 1 -->
          <v-col cols="6" md="6">
            <v-text-field
              v-model="form.deliver_by"
              label="Deliver by"
              outlined
              append-icon="mdi-calendar"
              @click:append="showDateCalendar = true"
            ></v-text-field>
            <v-dialog v-model="showDateCalendar" max-width="300px">
              <v-date-picker v-model="form.deliver_by"></v-date-picker>
            </v-dialog>
            <v-btn color="primary" text outlined @click="onClickOpenItemPicker">{{ itemPickerBtnText }}</v-btn>
            <div v-if="itemsSelected" style="margin-top: 1rem;">
              <v-card elevation="1" outlined tile class="lighten-5">
                <v-card-text>
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Picked</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-for="selected in selectedItems" :key="selected.item.id">
                        <tr>
                          <td>{{ selected.item.item.name }}</td>
                          <td>{{ selected.interval_qty }}</td>
                          <td>{{ selected.item.quantity }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- Col 2 -->
          <v-col cols="6" md="6">
            <v-select
              v-model="addressOption"
              :items="addressOptions"
              :menu-props="{ top: true, offsetY: true }"
              label="Delivery Address"
            ></v-select>
            <div v-if="displayAddress">
              <v-card elevation="1" outlined tile class="grey lighten-5">
                <v-card-text>
                  <span class="input__label">{{ deliveryAddress.address_line1 }},</span>
                  <span>{{ deliveryAddress.address_line2 }}</span>
                  <span>{{ deliveryAddress.city }},</span>
                  <span>{{ deliveryAddress.state }},</span>
                  <span>{{ deliveryAddress.code }}</span>
                </v-card-text>
              </v-card>
            </div>
            <div v-if="!displayAddress">
              <v-btn color="success" text outlined>
                <v-icon>mdi-plus</v-icon>
                New Drop-oof site
              </v-btn>
            </div>
            <v-text-field
              style="margin-top: 2rem;"
              prefix="$"
              v-model="form.delivery_fee"
              label="Delivery Fee"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn type="submit" :disabled="!canSubmitForm" color="primary" text @click="performSaveOption()">
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-card-actions>
    <delivery-interval-pick-items ref="interval_pick_items" @updated="onItemsUpdated" @click="onClickOpenItemPicker" />
  </v-card>
</template>
<script>
import mixins from "vue-typed-mixins";
import permissionMixin from "@/mixins/permission";
import currencyFormatMixin from "@/mixins/currencyFormat";
import DeliveryIntervalPickItems from "@/point-of-sale/components/deliveryInterval/DeliveryIntervalPickItems.vue";
import { ADD_MESSAGE } from "@/store/mutation-types";
import { parseFormErrors } from "@/utilities/form";

const getDefaultArray = () => {
  return [];
};

const defaultObject = () => {
  return {};
};

export default mixins(permissionMixin, currencyFormatMixin).extend({
  name: "DeliveryIntervalForm",

  components: {
    DeliveryIntervalPickItems
  },

  props: {
    orderedItems: { type: Array, default: getDefaultArray },
    delivery: { type: Object, default: defaultObject },
    isItemUpdate: { type: Boolean, default: false },
    redirectTo: { type: String, default: "deliveries" }
  },

  data() {
    return {
      addressOptions: [
        { value: "same", text: "Same delivery address" },
        { value: "custom", text: "Custom drop-off site" }
      ],
      displayAddress: true,
      showDateCalendar: false,
      addressOption: { value: "same", text: "Same delivery address" },

      deliveryAddress: null,
      form: { deliver_by: null, delivery_fee: null },
      selectedItems: []
    };
  },

  created() {
    if (this.delivery.address) {
      const [first] = this.delivery.address;
      this.deliveryAddress = first;
    }
  },

  computed: {
    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    },

    customer() {
      return this.delivery.target_entity.name || "";
    },

    itemsSelected() {
      return this.selectedItems.length > 0;
    },

    itemPickerBtnText() {
      return this.itemsSelected ? "Edit Selection" : "Select Items";
    },

    canSubmitForm() {
      return this.itemsSelected && this.form.delivery_fee && this.form.deliver_by;
    }
  },

  watch: {
    addressOption: function (newVal, oldVal) {
      this.displayAddress = newVal === "same";
    },

    delivery: function (newVal, oldVal) {
      this.delivery = newVal;
    }
  },

  methods: {
    onClickOpenItemPicker() {
      this.$refs.interval_pick_items.open(this.orderedItems);
    },

    performSaveOption() {
      this.onFormSubmit();
    },

    onFormSubmit() {
      this.form.delivery = Number(this.delivery.id);
      this.form.owner_entity = this.delivery.owner_entity;
      this.form.target_entity = this.delivery.target_entity;
      this.form.delivery_address = this.deliveryAddress;

      this.sendCreate();
    },
    sendCreate() {
      try {
        this.$http.post(`pos/delivery-intervals/`, this.form).then(response => {
          this.form = response.data;
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            type: "success",
            text: "Delivery information stored successfully"
          });

          this.$router.push({ name: this.redirectTo });
        });
      } catch (error) {
        const errors = error && error.response ? error.response.data : {};
        this.setFormErrors(errors);
      }
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    onItemsUpdated(items) {
      this.selectedItems = JSON.parse(JSON.stringify(items.selectedItems));
      this.form.items = this.prepItems(items.selectedItems);
    },

    prepItems(selectedItems) {
      // the backend is expecting just the orderItem id, so give it what it needs!
      selectedItems.forEach(function (entry) {
        entry.item = entry.item.id;
      });
      return selectedItems;
    }
  }
});
</script>

<style scoped></style>
