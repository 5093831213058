<template>
  <v-card class="org-tickets" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="hasPermission('add_truck', 'point_of_sale')"
        title="New Truck"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newTruck"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder=""
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        dense
        multi-sort
        disable-filtering
      >
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="hasPermission('change_truck', 'point_of_sale')" @click="editTruck(item)">
                <v-icon left>mdi-pencil</v-icon>
                Edit
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>

    <truck-form ref="truck_form" @updated="onTruckCreated" />

    <v-dialog v-model="confirmOpen" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Confirm action</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onConfirmButton">ACCEPT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DataTableMixin from "@/mixins/dataTable";
import PermissionMixin from "@/mixins/permission";
import DateMixin from "@/mixins/date";
import PageTitle from "@/mixins/pageTitle";
import TruckForm from "@/point-of-sale/components/truck/TruckForm";

// import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "Trucks",

  components: {
    PageTitle,
    TruckForm
  },

  mixins: [PageTitle, DataTableMixin, DateMixin, PermissionMixin],

  data() {
    return {
      formLoading: false,
      formOpened: false,
      displayFilters: false,

      deleteConfirmOpen: false,
      idDeleteItem: -1,

      confirmOpen: false,
      dialogText: null,

      currentItem: null,
      entity: {},

      headers: [
        { text: "Model", value: "model", sortable: true },
        { text: "Number", value: "number", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Is Active?", value: "is_active", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.$store.state.truck.list;
    },

    isLoading() {
      return this.$store.getters["truck/loading"]("list");
    }
  },

  beforeDestroy() {
    this.clearItems();
  },

  methods: {
    async fetchItems() {
      await this.$store.dispatch("truck/list");
    },

    clearItems() {
      this.$store.dispatch(`truck/clearState`, "list");
    },

    newTruck() {
      this.$refs.truck_form.new();
    },

    onTruckCreated(truckCreated) {
      this.fetchItems();
    },

    editTruck(truck) {
      this.$refs.truck_form.open(truck);
    },

    async onConfirmButton() {
      // await api.truck.cancel(this.currentItem.id);
      // this.confirmOpen = false;
      // await this.fetchItems();
    },

    onClickDetailItem(item) {
      // this.$refs.quote_details.open(item);
    }
  }
};
</script>

<style>
.org-tickets .search-field .v-input__slot {
  margin: 0;
}

.org-tickets .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-tickets table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-tickets table thead tr {
  background-color: #eeeeee;
}

.org-tickets table thead tr th {
  color: #000 !important;
}
</style>
