<template>
  <v-card class="home-page" elevation="0">
    <v-card-title class="justify-center flex-column">
      <div class="company-logo d-flex justify-center align-center">{{ context.entity_nitials }}</div>

      <h3 class="mt-10">Hi, {{ context.entity_first_name }}!</h3>

      <v-btn
        v-show="viewport.is_mobile && !subscribed_to_paid_plan && !has_premium_customer_restrictions"
        class="mt-4"
        type="submit"
        color="primary"
        :to="{ name: 'platform-subscriptions' }"
        outlined
        text
      >
        Upgrade Account
      </v-btn>
    </v-card-title>

    <v-card-text class="justify-center">
      <div class="shortcuts mx-auto">
        <v-btn :to="{ name: 'org-invoices-new' }" elevation="1" x-large>
          <i class="icon">
            <img src="@/assets/icons/new_invoice.svg" />
          </i>

          <span class="text">New Invoice</span>
        </v-btn>

        <v-btn @click="goto('org-invoices')" elevation="1" x-large>
          <i class="icon">
            <img src="@/assets/icons/my_invoices.svg" />
          </i>

          <span class="text">My Invoices</span>
        </v-btn>

        <v-btn @click="goto('org-bills')" elevation="1" x-large>
          <!--          <v-badge color="red" content="2" offset-x="20" offset-y="18">-->
          <!--          </v-badge>-->
          <i class="icon">
            <img src="@/assets/icons/my_bills.svg" />
          </i>

          <span class="text">My Bills</span>
        </v-btn>

        <v-btn @click="goto('org-profile')" elevation="1" x-large>
          <i class="icon">
            <img src="@/assets/icons/my_account.svg" />
          </i>

          <span class="text">My Account</span>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import viewport from "@/mixins/viewport";

export default {
  name: "HomeMobile",

  components: {},

  mixins: [viewport],

  props: {
    context: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  computed: {
    subscribed_to_paid_plan() {
      return !!this.$store.state.entitySettings.detail && this.$store.state.entitySettings.detail.paid_plan;
    },

    has_premium_customer_restrictions() {
      return this.$store.state.auth.entity.has_premium_customer_restrictions;
    },
  },

  mounted() {},

  methods: {
    goto(route_name) {
      this.$router.push({ name: route_name });
    }
  }
};
</script>

<style>
.home-page {
  height: 100%;
}

.home-page .company-logo {
  height: 100px;
  width: 100px;
  background-color: #f0f0f0;
  border-radius: 100%;
}

.home-page .shortcuts {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;

  max-width: 380px;
  padding: 0;
  margin-top: 24px;
}

.home-page .shortcuts .v-btn {
  background-color: #fff;
  height: 160px;
  padding: 0;
}

.home-page .shortcuts .v-btn .icon {
  line-height: 0;
}

.home-page .shortcuts .v-btn .text {
  display: block;
  margin-top: 10px;
}

.home-page .shortcuts .v-btn .v-btn__content {
  flex-direction: column;
}
</style>
