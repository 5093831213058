import { Module } from "vuex";
import { Model } from "./types";
import { getGetters, getActions, getMutations, getState, RootState, ModelState } from "@/vuex-bcore";
const actions = getActions<ModelState<Model>>("users");
const state = getState<Model>();
const getters = getGetters<Model>();
const mutations = getMutations<Model>();

const user: Module<ModelState<Model>, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default user;
