<template>
  <v-card class="org-quotes" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="hasPermission('add_quote', 'point_of_sale')"
        title="New Quote"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newQuote"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder="customer, Order #, Etc."
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        dense
        multi-sort
        disable-filtering
      >
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canCancelQuote(item)" @click="onClickCancelQuote(item)">
                <v-icon left>mdi-cancel</v-icon>
                cancel
              </v-list-item>
              <v-list-item v-if="canEditQuote(item)" @click="editQuote(item)">
                <!--  -->
                <v-icon left>mdi-pencil</v-icon>
                Edit
              </v-list-item>
              <v-list-item @click="sendEmail(item)">
                <!--  -->
                <v-icon left>mdi-mail</v-icon>
                Email
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item v-if="isDeliveryRequired(item)" @click="onClickDeliverySummary(item)">
                Delivery Summary
              </v-list-item>
              <v-list-item v-if="canGenerateOrAddToOrder(item)" @click="onClickAcceptQuote(item)">
                Generate order
              </v-list-item>
              <v-list-item v-if="canGenerateOrAddToOrder(item)" @click="onClickAddToOrder(item)">
                Add to Order
              </v-list-item>
              <v-list-item @click.prevent="previewQuote(item)">
                <v-icon small>mdi-open-in-new</v-icon>
                Preview
              </v-list-item>
              <v-list-item @click="onClickDetailItem(item)">Summary</v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- ORDER FORM -->
    <order-form ref="order_form" :is-create-from-quote="true" @updated="fetchItems" />

    <quote-form ref="quote_form" @updated="fetchItems" @requiresDelivery="navToQuoteDelivery" />

    <quote-details ref="quote_details" :is-customer="false" @updated="fetchItems" />

    <v-dialog v-model="confirmOpen" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Confirm action</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onConfirmButton">ACCEPT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import QuoteForm from "@/point-of-sale/components/quote/QuoteForm.vue";
import QuoteDetails from "@/point-of-sale/components/quote/QuoteDetails";
import OrderForm from "@/point-of-sale/components/order/OrderForm.vue";

import { ADD_MESSAGE } from "@/store/mutation-types";
import DataTableMixin from "@/mixins/dataTable";
import PermissionMixin from "@/mixins/permission";
import DateMixin from "@/mixins/date";
import PreviewMixin from "@/mixins/preview";
import PageTitle from "@/mixins/pageTitle";
import api from "@/api";

export default {
  name: "Quotes",

  components: {
    OrderForm,
    QuoteForm,
    QuoteDetails,
    PageTitle
  },

  mixins: [PageTitle, DataTableMixin, DateMixin, PermissionMixin, PreviewMixin],

  data() {
    return {
      formLoading: false,
      formOpened: false,
      displayFilters: false,

      isCreateFromQuote: true,

      deleteConfirmOpen: false,
      idDeleteItem: -1,

      confirmOpen: false,
      dialogText: null,

      currentItem: null,
      entity: {},

      headers: [
        { text: "Quote no", value: "reference_no", sortable: true },
        { text: "Customer", value: "target_entity.name", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Status ", value: "status", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.$store.state.quote.list;
    },

    isLoading() {
      return this.$store.getters["quote/loading"]("list");
    }
  },

  beforeDestroy() {
    this.clearItems();
  },

  methods: {
    async fetchItems() {
      await this.$store.dispatch("quote/list");
    },

    clearItems() {
      this.$store.dispatch(`quote/clearState`, "list");
    },

    newQuote() {
      this.$refs.quote_form.new();
    },

    editQuote(quote) {
      this.$refs.quote_form.open(quote);
    },

    onClickCancelQuote(quote) {
      this.confirmOpen = true;
      this.currentItem = quote;
      this.dialogText = "Are you sure you want to cancel this quote?";
    },

    async onConfirmButton() {
      await api.quote.cancel(this.currentItem.id);
      this.confirmOpen = false;
      await this.fetchItems();
    },

    onClickDetailItem(item) {
      this.$refs.quote_details.open(item);
    },

    onClickAcceptQuote(item) {
      let payload = { ...item };
      delete payload.status;
      delete payload.expiration_days;
      this.$refs.order_form.open(payload);
    },

    onClickAddToOrder(item) {
      let setAddToOrder = true;
      this.$refs.quote_details.open(item, setAddToOrder);
    },

    canEditQuote(quote) {
      return quote.status !== "ordered" && this.hasPermission("change_quote", "point_of_sale");
    },

    canCancelQuote(quote) {
      return quote.status !== "cancelled" && this.canEditQuote(quote);
    },

    canGenerateOrAddToOrder(quote) {
      return quote.status === "valid" && this.hasPermission("change_quote", "point_of_sale");
    },

    navToQuoteDelivery(quote) {
      this.$router.push({ name: "quote-delivery", params: { quoteId: quote.id } });
    },

    onClickDeliverySummary(quote) {
      this.$router.push({ name: "quote-delivery-summary", params: { quoteId: quote.id } });
    },

    isDeliveryRequired(item) {
      return item.requires_delivery;
    },

    async sendEmail(quote) {
      if (quote.id) {
        try {
          await api.quote.send(quote.id);
          this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Email Sent", type: "primary" });
        } catch (error) {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Email failed to send", type: "error" });
        }
      }
    },

    previewQuote(quote) {
      this.previewTitle = `quote: ${quote.reference_no}`;
      this.previewUrl = api.quote.previewUrl(quote.id);
      this.showPreview();
    }
  }
};
</script>

<style>
.org-quotes .search-field .v-input__slot {
  margin: 0;
}

.org-quotes .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-quotes table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-quotes table thead tr {
  background-color: #eeeeee;
}

.org-quotes table thead tr th {
  color: #000 !important;
}
</style>
