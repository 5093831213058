<template>
  <v-card>
    <page-title />
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <div>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="isLoading"
              :footer-props="{
                'items-per-page-options': itemsPerPageOptions
              }"
              :options.sync="tableOptions"
              :server-items-length="totalItems"
              dense
              multi-sort
              disable-filtering
            >
              <template v-slot:item.actions="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click.native.stop>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="onClickEditItem(item)">
                      <v-icon left>mdi-pencil</v-icon>
                      Edit
                    </v-list-item>
                    <v-list-item>
                      <v-icon left>mdi-mail</v-icon>
                      Email
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="onClickInterval(item)">Delivery interval</v-list-item>
                    <v-list-item @click="onClickDetailItem(item)">Summary</v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PageTitle from "@/bcore/components/PageTitle.vue";
import mixins from "vue-typed-mixins";
import dataTableMixin from "@/mixins/dataTable";
import DateMixin from "@/mixins/date";
import permissionMixin from "@/mixins/permission";

export default mixins(dataTableMixin, DateMixin, permissionMixin).extend({
  name: "Deliveries",

  components: {
    PageTitle
  },

  data() {
    return {
      deleteConfirmOpen: false,
      idDeleteItem: -1,

      currentItem: null,
      headers: [
        { text: "Delivery no", value: "reference_no", sortable: true },
        { text: "Customer", value: "target_entity.name", sortable: true },
        { text: "Delivery ", value: "description", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.$store.state.delivery.list;
    },

    isLoading() {
      return this.$store.getters["delivery/loading"]("list");
    }
  },

  beforeDestroy() {
    this.clearItems();
  },

  methods: {
    async fetchItems() {
      await this.$store.dispatch("delivery/list");
    },

    clearItems() {
      this.$store.dispatch(`delivery/clearState`, "list");
    },

    onClickEditItem(item) {
      if (item.delivery_to_order) {
        const [first] = item.delivery_to_order;
        let orderId = first.order;
        this.$router.push({ name: "order-delivery", params: { orderId: orderId, isItemUpdate: true } });
      }
    },

    onClickDetailItem(item) {
      if (item.delivery_to_order) {
        const [first] = item.delivery_to_order;
        let orderId = first.order;
        this.$router.push({ name: "order-delivery", params: { orderId: orderId } });
      }
    },

    onClickInterval(item) {
      const [first] = item.delivery_to_order;
      let deliveryId = first.delivery;
      this.$router.push({ name: "delivery-interval", params: { deliveryId: deliveryId } });
    }
  }
});
</script>

<style scoped></style>
