<template>
  <v-card class="org-platform-subscriptions" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <span>Upgrade Plan</span>
    </v-card-title>

    <v-card-text elevation="0">
      <!-- SUBSCRIPTION WIZARD-->
      <v-stepper v-model="step" vertical class="elevation-0">
        <!-- STEP 1: PAYMENT DETAILS -->
        <v-stepper-step :complete="step > 1" step="1">
          Payment Details
          <small>Add a credit card</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <credit-card-manager :display-auto-pay="false" :allow-selectors="true" @CreditCardSelected="setCardProfile" />
          <v-btn color="primary" @click="step = 2" :disabled="!form.card_profile">
            Continue
          </v-btn>
        </v-stepper-content>

        <!-- STEP 2: SUMMARY AND CONFIRMATION -->
        <v-stepper-step :complete="step > 2" step="2">
          Confirm Upgrade
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-simple-table class="mb-5">
            <template v-slot:default>
              <tbody>
                <!-- PLAN SUMMARY -->
                <tr>
                  <td class="text-left" colspan="2">
                    <h3>Plan Summary</h3>
                  </td>
                </tr>
                <tr v-for="item in planSummary" :key="item.label">
                  <th>{{ item.label }}</th>
                  <td>{{ item.value }}</td>
                </tr>

                <!-- CARD DETAILS -->
                <tr>
                  <td class="text-left" colspan="2" @click="step = 1">
                    <h3>
                      Card Details
                      <a>
                        <v-icon right>mdi-pencil</v-icon>
                      </a>
                    </h3>
                  </td>
                </tr>
                <tr v-for="item in cardDetails" :key="item.label">
                  <th>{{ item.label }}</th>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-btn color="primary" @click="createSubscription" :loading="loading">
            Upgrade
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import CreditCardManager from "@/invoiceboost/components/payment-method/CreditCardManager";
import api from "@/api";
import { ADD_MESSAGE, SET_DETAIL } from "@/store/mutation-types";
import currencyFormatMixin from "@/mixins/currencyFormat";

export default {
  name: "PlatformSubscriptions",

  components: { CreditCardManager },

  mixins: [currencyFormatMixin],

  data() {
    return {
      step: 1,
      loading: false,
      form: {
        plan_name: "Paid",
        card_profile: null
      },
      cardDetails: [],
      planSummary: [
        { label: "Plan Name", value: `${process.env.VUE_APP_NAME} Paid Plan` },
        { label: "Amount", value: this.formatCurrency(150) }, //TODO: Don't hard-code this get plans from API
        { label: "Billing Frequency", value: "Monthly" }
      ]
    };
  },

  methods: {
    setCardProfile(payment_profile) {
      this.form.card_profile = payment_profile.id;
      this.cardDetails = [
        { label: "Name", value: payment_profile.cardholder_name },
        { label: "Card", value: `${payment_profile.card_type} ending in ${payment_profile.card_number}` },
        { label: "Expiry", value: `${payment_profile.exp_month}-${payment_profile.exp_year}` }
      ];
    },

    createSubscription() {
      this.loading = true;
      api.platform_subscriptions
        .create(this.form)
        .then(response => {
          this.$store.commit(`entitySettings/${SET_DETAIL}`,{...this.$store.state.entitySettings, paid_plan: 1});
          this.$router.push("/organization/invoices"); //TODO: Direct appropriately "/" won't work.
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: "Congratulations. You have successfully upgraded your subscription plan.",
            type: "success"
          });
        })
        .catch(error => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            text: "Sorry there was an error processing your upgrade.",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
