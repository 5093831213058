<template>
  <v-card v-if="displayPanel" class="invoice-receivable-details overflow-y-auto" elevation="8" outlined>
    <v-card-title class="d-flex">
      <span class="mr-auto">Transaction Details</span>
      <v-btn class="btn-close" @click.prevent="close" text icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- DETAIL FIELDS -->
    <v-card-text class="pt-0">
      <ul class="pa-0">
        <!-- FIELD: CUSTOMER -->
        <li>
          <strong>Customer</strong>
          <span>
            <v-icon small>mdi-open-in-new</v-icon>
            <router-link
              :to="{ name: 'org-customers', params: { id: transaction.target_entity.id } }"
              target="_blank"
            >
              {{ transaction.target_entity.name }}
            </router-link>
          </span>
        </li>

        <!--        &lt;!&ndash; FIELD: INVOICE NUMBER &ndash;&gt;-->
        <!--        <li>-->
        <!--          <strong>Invoice #</strong>-->
        <!--          <span>-->
        <!--            <v-icon small>mdi-open-in-new</v-icon>-->
        <!--            <a @click.prevent="previewInvoice" class="underline">{{ transaction.reference_no }}</a>-->
        <!--          </span>-->
        <!--        </li>-->

        <!-- FIELD: REFERENCE # -->
        <li>
          <strong>Reference #</strong>
          <span>{{ transaction.reference_no }}</span>
        </li>

        <!-- FIELD: AMOUNT -->
        <li>
          <strong>Amount</strong>
          <span>{{ formatCurrency(transaction.amount, transaction.currency) }}</span>
        </li>

        <v-divider class="my-3" />

        <!-- FIELD: STATUS -->
        <li>
          <strong>Status</strong>
          <span>
            {{ transaction.status }}
          </span>
        </li>

        <!-- FIELD: TRANSACTION TYPE -->
        <li>
          <strong>Type</strong>
          <span>
            {{ transaction.type }}
          </span>
        </li>

        <!-- FIELD: GATEWAY -->
        <li>
          <strong>Gateway</strong>
          <span>
            {{ transaction.gateway }}
          </span>
        </li>

        <v-divider class="my-3" />

        <!-- FIELD: GATEWAY -->
        <li>
          <strong>Date created</strong>
          <span>
            {{ formatDate(transaction.created_at) }}
          </span>
        </li>

        <!-- FIELD: GATEWAY -->
        <li>
          <strong>Last updated</strong>
          <span>
            {{ formatDate(transaction.modified_at) }}
          </span>
        </li>
      </ul>
    </v-card-text>

    <!-- ACTIONS -->
    <v-card-text v-if="canRefund" class="invoice-actions pt-0">
      <v-divider class="mb-4" />

      <div class="d-flex">
        <v-form @submit.prevent="onFormSubmit">
          <v-btn type="submit" :loading="isLoading" :disabled="isLoading" class="btn-close" color="primary">
            Refund
          </v-btn>
        </v-form>
      </div>
    </v-card-text>

    <!-- RELATED INVOICES -->
    <v-card-text>
      <!-- Related Invoices list   -->
      <span class="headline">Related invoices</span>
      <v-simple-table :loading="isLoadingInvoices">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Amount</th>
              <th class="text-left">Invoice no</th>
              <th class="text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in relatedInvoices" :key="item.id" class="related-invoice-item">
              <td>{{ formatCurrency(item.total, item.currency) }}</td>
              <td>{{ item.reference_no }}</td>
              <td>{{ item.status }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import DateMixin from "@/mixins/date";
import CurrencyFormatMixin from "@/mixins/currencyFormat";
import permissionMixin from "@/mixins/permission";

import { ADD_MESSAGE } from "@/store/mutation-types";

import eventHub from "@/eventHub";
import api from "@/api";

export default {
  name: "TransactionDetails",

  components: {},

  mixins: [DateMixin, CurrencyFormatMixin, permissionMixin],

  data() {
    return {
      isLoading: false,

      displayPanel: false,
      dialogOpened: false,
      isLoadingInvoices: false,

      transaction: null,
      relatedInvoices: [],
      relatedReceipts: []
    };
  },

  computed: {
    dialogTitle() {
      return `Transaction details`;
    },

    canRefund() {
      return (
        !this.transaction.is_refunded &&
        this.transaction.status === "approved" &&
        (this.transaction.type === "authorize" || this.transaction.type === "purchase") &&
        this.hasPermission("change_transaction", "payments")
      );
    }
  },

  mounted() {
    eventHub.listen("TransactionsDetails.open", this.open);
  },

  methods: {
    open(transaction) {
      this.displayPanel = true;
      this.$set(this, "transaction", transaction);

      this.fetchRelatedInvoices(transaction);
    },

    close() {
      this.displayPanel = false;
    },

    fetchRelatedInvoices(transaction) {
      this.isLoadingInvoices = true;

      api.transaction
        .fetch_related_invoices(transaction.id)
        .then(response => {
          this.relatedInvoices = response.data;
        })
        .catch(() => {
          this.displayMessage({ type: "error", text: "Unable to fetch related invoices" });
        })
        .finally(() => {
          this.isLoadingInvoices = false;
        });
    },

    onFormSubmit() {
      this.isLoading = true;

      api.transaction
        .refund_transaction(this.transaction.id, "direct") // TODO: a direct refund requires re-entry of card details?
        .then(response => {
          this.transaction.status = response.data.status;
          this.displayMessage({
            type: "success",
            text: "Transaction refund processed successfully"
          });
        })
        .catch(() => {
          this.displayMessage({ type: "error", text: "Failed to refund transaction" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    reset() {
      this.dialogOpened = false;
      this.transaction = { target_entity: {} };
      this.relatedInvoices = [];
      this.relatedReceipts = [];

      return this;
    },

    formatStatus(status) {
      return status.replace("_", "");
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style></style>
