import { getActions, getGetters, getMutations, getState } from "@/vuex-bcore";

const actions = getActions("integrations/qbwc-account");
const state = getState();
const getters = getGetters();
const mutations = getMutations();

const webConnectorAccount = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default webConnectorAccount;
