import Customers from "@/invoiceboost/views/Customers.vue";
import CustomerDetails from "@/invoiceboost/components/customer/CustomerDetails.vue";

import Suppliers from "@/invoiceboost/views/Suppliers.vue";
import SupplierDetails from "@/invoiceboost/components/supplier/SupplierDetails.vue";

import Employees from "@/invoiceboost/views/Employees.vue";
import EmployeeDetails from "@/invoiceboost/components/employee/EmployeeDetails.vue";

import APPaymentMethods from "@/invoiceboost/views/bill/PaymentMethods.vue";

import OrganizationProfile from "@/invoiceboost/views/OrganizationProfile.vue";
import OrganizationSettings from "@/invoiceboost/views/OrganizationSettings.vue";

import CustomerPriceOverrides from "@/point-of-sale/components/price-override/CustomerPriceOverrides.vue";

const baseRoutes = [
  {
    name: "org-customers",
    path: "/organization/customers/",
    component: Customers,
    meta: { requiresAuth: true },
    props(route) {
      return route.params;
    }
  },

  {
    name: "org-customer-details",
    path: "/organization/customers/:id",
    component: CustomerDetails,
    meta: { requiresAuth: true, noSuperuser: true }
  },

  {
    name: "org-suppliers",
    path: "/organization/suppliers",
    component: Suppliers,
    meta: { requiresAuth: true, pageTitle: "Suppliers" },
    props(route) {
      return Object.assign({ modelName: "supplier" }, route.params);
    }
  },

  {
    name: "org-supplier-details",
    path: "/organization/suppliers/:id",
    component: SupplierDetails,
    meta: { requiresAuth: true, pageTitle: "Supplier Details" }
  },

  {
    name: "org-employees",
    path: "/organization/employees",
    component: Employees,
    props: { modelName: "employee" },
    meta: { requiresAuth: true, pageTitle: "Employees" }
  },

  {
    name: "org-employee-details",
    path: "/organization/employees/:id",
    component: EmployeeDetails,
    meta: { requiresAuth: true, pageTitle: "Employee Details" }
  },

  {
    // TODO: this should be the relationship customer.id
    // we want the customer's organization (target_entity) not the customer's id
    name: "customers-price-overrides",
    path: "/organization/customers/:id/price-overrides",
    component: CustomerPriceOverrides,
    meta: { requiresAuth: true, noSuperuser: true }
  },

  {
    name: "org-profile",
    path: "/organization/profile/",
    component: OrganizationProfile,
    meta: { requiresAuth: true, pageTitle: "Organization Profile" }
  },

  {
    name: "organization-ap-payment-methods",
    path: "organization/bill/payment-methods",
    component: APPaymentMethods,
    meta: { requiresAuth: true }
  },

  {
    name: "organization-settings",
    path: "/organization/settings/",
    component: OrganizationSettings,
    meta: { requiresAuth: true, pageTitle: "Organization Settings" }
  }
];

export default baseRoutes;
