<template>
  <v-dialog v-model="open" max-width="500px" persistent>
    <v-card>
      <v-card-title class="headline">Delete {{ objectName }}?</v-card-title>
      <v-card-text>Warning: the {{ objectName }} will be deleted permanently. This action can't be undone.</v-card-text>
      <v-card-actions>
        <v-btn ref="cancel_button" color="primary" text @click="onClickCancelButton">
          CANCEL
        </v-btn>
        <v-btn ref="delete_button" color="error" text @click="onClickDeleteButton">
          DELETE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: { objectName: { type: String, default: "Object" }, open: { type: Boolean, default: false } },
  methods: {
    onClickCancelButton() {
      this.$emit("cancelled");
    },

    onClickDeleteButton() {
      this.$emit("deleted");
    }
  }
};
</script>
