const sumColumnMixin = {
  methods: {
    sumColumn(column, items) {
      if (items && items.length) {
        return items.reduce((a, b) => Number(a) + (Number(b[column]) || 0), 0);
      }
      return 0;
    }
  }
};

export default sumColumnMixin;
