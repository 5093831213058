import MainLayout from "@/views/layouts/MainLayout.vue";
import { ADD_FILTERS, ADD_MESSAGE, SET_DETAIL, SET_LIST } from "@/store/mutation-types";
import debounce from "lodash/debounce";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import OrganizationSearch from "@/bcore/components/organization/OrganizationSearch.vue";
import PersonSearch from "@/bcore/components/person/PersonSearch.vue";
import dataTableMixin from "@/mixins/dataTable";
import permissionMixin from "@/mixins/permission";

const relationshipView = {
  components: {
    MainLayout,
    DeleteDialog,
    ResponsiveDialog,
    NonFieldErrors,
    OrganizationSearch,
    PersonSearch
  },
  mixins: [dataTableMixin, permissionMixin],
  props: { modelName: { type: String, required: true } },
  data() {
    return {
      // Form edit create data
      formDetailNew: {
        owner_entity: this.$store.state.auth.entity.id,
        target_entity: {
          name: "",
          type: "organization"
        }
      },
      // Form delete data
      idDeleteItem: -1,
      deleteConfirmOpen: false
    };
  },

  computed: {
    entity() {
      return this.$store.state.auth.entity;
    },
    formDetail() {
      return this.$store.state[this.modelName].detail;
    },
    entityHasOwner() {
      if (this.formDetail) {
        return !!(this.formDetail && this.formDetail.is_claimed);
      }
      return false;
    },
    isSuperuser() {
      return this.$store.state.auth.user.is_superuser;
    },
    headers() {
      let columns = [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Actions",
          value: "action"
        }
      ];

      if (this.isSuperuser) {
        columns.splice(2, 0, { text: "Owner", value: "owner_entity.name" });
      }

      return columns;
    },
    items() {
      return this.$store.state[this.modelName].list;
    },

    totalItems() {
      return this.$store.state[this.modelName].meta.count;
    },

    loadingTable() {
      return this.$store.getters[`${this.modelName}/loading`]("list");
    },
    loadingEntities() {
      return this.$store.getters["entity/loading"]("list") || this.$store.getters["entity/loading"]("detail");
    },
    modelTitle() {
      return this.modelName[0].toUpperCase() + this.modelName.slice(1);
    }
  },
  watch: {
    tableOptions: {
      handler() {
        debounce(() => {
          this.$store.commit(`${this.modelName}/${ADD_FILTERS}`, {
            page: this.tableOptions.page,
            page_size: this.tableOptions.itemsPerPage
          });
        }, 500);
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch(`${this.modelName}/clearState`, "list");
  },

  methods: {
    /*** Event handlers ***/

    async onClickEditButton(item) {
      this.setFormDetail(item);

      // Ensure that the selected entity is in the entity list so that it can displayed in the update(for superusers)
      if (this.isSuperuser) {
        this.$store.commit(`entity/${SET_LIST}`, [this.$store.state.entity.list, ...[item.owner_entity]]);
      }
    },
    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    },

    showSnackbarError(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: message, timeout: 8000 });
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      this.idDeleteItem = item.id;
    },
    onClickNewButton() {
      this.newForm();
    },
    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      try {
        await this.sendDelete(this.idDeleteItem);
        await this.fetchItems();
        this.idDeleteItem = -1;
        this.showSnackbarSuccess();
      } catch (e) {
        const errors = e.response.data.errors || [];
        const error = errors[0];
        let message = error && error.message ? error.message : "Couldn't delete the specified item";
        this.showSnackbarError(message);
      }
      this.deleteConfirmOpen = false;
    },
    setFormDetail(item) {
      const detail = typeof item === "object" ? { ...item } : item;
      this.$store.commit(`${this.modelName}/${SET_DETAIL}`, detail);
    },
    async onFormUpdate() {
      await this.fetchItems();
    },
    newForm() {
      this.setFormDetail(this.formDetailNew);
    },
    async fetchItems() {
      let response;
      try {
        response = await this.$store.dispatch(`${this.modelName}/list`);
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Failed to get results" });
      }
    },

    async sendDelete(id) {
      await this.$store.dispatch(`${this.modelName}/delete`, id);
    }
  }
};
export default relationshipView;
