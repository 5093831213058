<template>
  <v-row>
    <v-col cols="12">
      <delivery-form
        v-if="displayForm"
        :is-quote="isQuote"
        :item="item"
        :delivery="delivery"
        :delivery-address="deliveryAddress"
        :url="url"
        :redirect-to="redirectTo"
        :is-item-update="isItemUpdate"
      />
      <delivery-details
        v-if="displayDetails"
        :is-quote="isQuote"
        :item="item"
        :delivery="delivery"
        :delivery-address="deliveryAddress"
      />
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import DeliveryForm from "@/point-of-sale/components/delivery/DeliveryForm.vue";
import DeliveryDetails from "@/point-of-sale/components/delivery/DeliveryDetails.vue";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default Vue.extend({
  name: "Delivery",

  components: {
    DeliveryForm,
    DeliveryDetails
  },

  data() {
    return {
      url: null,
      itemUrl: null,
      redirectTo: null,
      item: {},
      isItemUpdate: false,
      displayForm: false,
      displayDetails: false,
      isQuote: null,
      delivery: null,
      deliveryAddress: null
    };
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    async fetchItem() {
      try {
        if (this.$route.params.isItemUpdate !== undefined) {
          this.isItemUpdate = this.$route.params.isItemUpdate;
        }
        if (this.$route.params.quoteId !== undefined) {
          let id = this.$route.params.quoteId;
          this.itemUrl = `pos/quotes/${id}/`;
          this.redirectTo = "quotes";
        }
        if (this.$route.params.orderId !== undefined) {
          let id = this.$route.params.orderId;
          this.itemUrl = `pos/orders/${id}/`;
          this.redirectTo = "orders";
        }
        if (this.itemUrl) {
          this.isQuote = this.redirectTo === "quotes";
          this.url = this.itemUrl + "delivery/";
          await this.$http.get(this.itemUrl).then(response => {
            this.item = response.data;
            // pick right backward relationship or set
            let deliverToSet = this.isQuote ? "delivery_to_quote" : "delivery_to_order";
            if (this.item[deliverToSet].length === 0 || this.isItemUpdate) {
              this.displayDetails = false;
              this.displayForm = true;
            } else {
              this.displayForm = false;
              this.displayDetails = true;
            }
            this.getDeliveryInfo(this.item, this.isQuote);
          });
        }
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Could not fetch delivery information. Please try again.",
          type: "error"
        });
      }
    },

    getDeliveryInfo(item, isQuote) {
      // item can be either a quote or an order
      let deliverToSet = isQuote ? "delivery_to_quote" : "delivery_to_order";
      if (item[deliverToSet] && item[deliverToSet].length > 0) {
        // quotes and orders can have multiple deliveries, but at the moment we are only interested in the first Delivery instance
        const [first] = item[deliverToSet];
        this.delivery = first.delivery;
        const [firstAddress] = this.delivery.address;
        this.deliveryAddress = firstAddress;
      }
    }
  }
});
</script>
