<template>
  <responsive-dialog :id="'invoice-form'" :display-dialog="formOpened" :dialog-title="formTitle" @closed="closeForm">
    <v-container class="pa-0">
      <!-- DISPLAY GENERIC ERRORS -->
      <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>

      <!-- DISPLAY FORM NOTIFICATIONS -->
      <v-row v-if="notifications.length">
        <v-col>
          <v-alert
            v-for="(alert, index) in notifications"
            border="left"
            elevation="2"
            :type="alert.type"
            :key="index"
            colored-border
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="alert.message"></span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: NAME -->
        <v-col cols="6">
          <v-text-field v-model="form.name" label="Price Override Name" name="PriceOverride[Name]" outlined />
        </v-col>

        <!-- FIELD: ACTIVE -->
        <v-col cols="6">
          <v-switch v-model="form.is_active" :error-messages="formErrors.is_active" label="Price override active" />
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: START DATE -->
        <v-col cols="6">
          <v-menu
            ref="invoice_date_menu"
            v-model="showFromDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="300px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.from_date"
                label="Start Date"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :disabled="formLoading"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.from_date" no-title @input="showFromDateCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>

        <!-- FIELD: TO DATE -->
        <v-col cols="6">
          <v-menu
            ref="invoice_due_date_menu"
            v-model="showToDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="300px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.to_date"
                label="End Date"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :disabled="formLoading"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.to_date" no-title @input="showToDateCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <!-- FIELD: TYPE -->
        <v-col cols="6">
          <!-- <v-select label="Type" v-model="form.type" :items="OverrideTypes" :disabled="isUpdate"></v-select> -->

          <div>
            <label class="pl-1">Type</label>
            <v-radio-group class="mt-0" v-model="form.type" mandatory row>
              <v-radio label="Fixed Percentage" value="fixed_percentage"></v-radio>
              <v-radio label="Per Item" value="per_item"></v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="!isFixedPercentage" cols="6">
          <v-select
            label="Adjust the item price by"
            class="ma-0 pa-0 mr-4"
            hide-details="auto"
            v-model="selectedPerItemCondition"
            :items="perItemConditions"
            @change="onChange"
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            label="Adjust the item price by"
            class="ma-0 pa-0 mr-4"
            hide-details="auto"
            type="number"
            v-model="adjustmentAmount"
            :step="0.01"
            :append-icon="displayPercentage ? 'mdi-percent' : ''"
            @change="onChange"
          />
        </v-col>
      </v-row>
    </v-container>

    <price-override-items-form
      v-if="!isFixedPercentage"
      class="mb-4 mt-7"
      :price-override="form"
      @updated="onItemFormUpdated"
    />

    <template slot="actions">
      <v-spacer />
      <v-btn type="submit" color="primary" :loading="formLoading" :disabled="disableSave" @click="onFormSubmit" text>
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import dateMixin from "@/mixins/date";
import NonFieldErrors from "@/bcore/components/NonFieldErrors";
import api from "@/api";
import { ADD_MESSAGE } from "@/store/mutation-types";
import PriceOverrideItemsForm from "@/point-of-sale/components/price-override/PriceOverrideItemsForm";
import deep_copy from "@/utilities/deep_copy";
import { parseFormErrors } from "@/utilities/form";

function createPriceOverrideModel() {
  return {
    owner_entity: null,
    name: "",
    percentage_amount: 0.0,
    type: "fixed_percentage",
    custom_price: null,
    is_active: true,
    from_date: "",
    to_date: null,
    items: [],
    customers: []
  };
}

export default {
  name: "PriceOverrideForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    PriceOverrideItemsForm
  },

  mixins: [dateMixin],

  data() {
    return {
      isLoading: false,
      formOpened: false,

      showFromDateCalendar: false,
      showToDateCalendar: false,

      selectedPerItemCondition: "percentage_amount",
      adjustmentAmount: 0,

      form: createPriceOverrideModel(),
      formErrors: {}
    };
  },

  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    formLoading() {
      const action = this.$store.getters["priceOverride/loading"];

      return this.isLoading || action("create") || action("update") || action("detail") || this.isSendingEmail;
    },

    disableSave() {
      return !!(!this.isFormComplete || this.formLoading);
    },

    isFixedPercentage() {
      return this.form.type === "fixed_percentage";
    },

    isCustomPrice() {
      return this.selectedPerItemCondition === "custom_price";
    },

    displayPercentage() {
      return !this.isCustomPrice || this.isFixedPercentage;
    },

    isUpdate() {
      return !!this.form.id;
    },

    isFormComplete() {
      let baseCondition = this.form.name && this.form.from_date && this.adjustmentAmount !== 0;
      if (!this.isFixedPercentage) {
        return baseCondition && this.form.items.length > 0;
      }
      return baseCondition;
    },

    OverrideTypes() {
      return [
        { text: "Fixed Percentage", value: "fixed_percentage" },
        { text: "Per Item", value: "per_item" }
      ];
    },

    perItemConditions() {
      return [
        { text: "Amount", value: "custom_price" },
        { text: "Percentage", value: "percentage_amount" }
      ];
    },

    notifications() {
      const alerts = [];
      const name = this.form.name;
      const amount = this.form.amount;
      if (!name) {
        alerts.push({
          type: "warning",
          message: `<strong>Name field is required</strong>, you can't create a price override without a name.`
        });
      }
      if (amount === 0) {
        alerts.push({
          type: "warning",
          message: `<strong>Percentage field cannot be 0</strong>, change the value.`
        });
      }
      return alerts;
    },

    formTitle() {
      return this.isUpdate ? `Update Price Override` : `New Price Override`;
    },

    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    }
  },

  methods: {
    new() {
      const date = this.toDjangoDateField(new Date());
      this.form = createPriceOverrideModel();
      this.form.owner_entity = this.ownerEntity.id;
      this.form.from_date = date;

      this.formOpened = true;
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async onFormSubmit() {
      this.isLoading = true;
      this.resetFormErrors();

      const isUpdate = this.isUpdate;
      const event_name = isUpdate ? "updated" : "created";
      const promise = isUpdate ? this.sendUpdate() : this.sendCreate();

      promise
        .then(response => {
          // this.$set(this, "form", priceOverride);
          return response.data;
        })
        .then(priceOverride => {
          this.displayMessage({ text: `Price Override ${event_name} successfully.`, type: "success" });
          this.$emit("updated");
          this.closeForm();
        })
        .catch(error => {
          this.setFormErrors(error.response.data);
          this.displayMessage({ text: "There was an error saving your Price Override.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    open(data) {
      if (!data) {
        return;
      }

      this.form = deep_copy(data);
      this.formOpened = true;
    },

    sendCreate() {
      return api.price_overrides.create(this.form);
    },

    sendUpdate() {
      return api.price_overrides.update(this.form);
    },

    closeForm() {
      this.formOpened = false;
      this.$emit("closed");
    },

    onItemFormUpdated(selectedItems) {
      this.form.items = selectedItems;
    },

    onChange() {
      this.adjustmentAmount = Math.round((Number(this.adjustmentAmount) + Number.EPSILON) * 100) / 100;

      if (this.isCustomPrice) {
        this.form.custom_price = this.adjustmentAmount;
        this.form.percentage_amount = null;
      } else {
        this.form.percentage_amount = this.adjustmentAmount;
        this.form.custom_price = null;
      }
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    resetFormErrors() {
      this.formErrors = {};
    }
  }
};
</script>

<style scoped></style>
