<template>
  <v-card class="login-mobile">
    <v-card-title class="d-block rounded-0">
      <div class="logo-wrapper">
        <img src="@/assets/accruvia-logo.svg" alt="accruvia-logo" class="logo" />
      </div>

      <div class="header">LOGIN TO ACCESS YOUR ACCOUNT</div>
    </v-card-title>

    <v-card-text>
      <non-field-errors :errors="form.errors" class="mx-0 mt-1" />

      <v-form @submit.prevent="context.submit">
        <v-container>
          <v-row>
            <v-col>
              <label class="c-label">Email</label>
              <v-text-field
                name="username"
                type="text"
                hide-details="auto"
                v-model="form.fields.username"
                :error-messages="form.field_errors.username"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label class="c-label">Password</label>
              <v-text-field
                name="password"
                hide-details="auto"
                v-model="form.fields.password"
                :type="context.showPass ? 'text' : 'password'"
                :error-messages="form.field_errors.password"
                :append-icon="context.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="context.toggle_password_visibility"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center">
              <v-checkbox
                class="mt-0 pt-0"
                label="Trust this device"
                v-model="form.fields.trusted_device"
                hide-details
              />

              <v-spacer></v-spacer>

              <v-btn icon @click="display_trusted_device_notice = !display_trusted_device_notice">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" v-show="display_trusted_device_notice">
              <p class="trusted-pc-notice my-0 text-justify">
                Trusted devices will have extended sessions. Don't check this option on public computers.
              </p>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                class="btn-submit"
                :disabled="context.is_loading"
                :loading="context.is_loading"
                x-large
              >
                LOGIN
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <router-link :to="{ name: 'signup' }" class="text-decoration-none grey--text">
                Don't have an account?
                <strong class="black--text">Sign Up</strong>
              </router-link>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <router-link
                class="forgot-password text-decoration-none"
                color="accent"
                :to="{ name: 'forgot-password' }"
                text
              >
                Forgot password?
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

export default {
  name: "LoginMobile",

  components: { NonFieldErrors },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {
      display_trusted_device_notice: false
    };
  },

  computed: {
    form() {
      return this.context.form;
    }
  }
};
</script>

<style>
.login-mobile {
  height: 100%;
}

.login-mobile .v-card__title {
  background-size: cover;
  background-position: bottom;
  background-image: url("~@/assets/mobile/background-swoosh.svg");
  padding-bottom: 18%;
}

.login-mobile .logo-wrapper {
  line-height: 0;
  text-align: right;
}

.login-mobile .logo {
  height: 35px;
}

.login-mobile .v-card__title .header {
  font-size: 14px;
  color: #fff;
  text-align: center;
  word-break: break-word;

  margin-top: 28px;
  margin-bottom: 16px;
}

.login-mobile .c-label {
  padding-left: 15px;
  line-height: 2.5;
  font-size: 18px;
  color: #000;
}

.login-mobile .btn-submit {
  width: 100%;
}

.login-mobile .v-card .trusted-pc-notice {
  font-size: 12px;
}
</style>
