<template>
  <div>
    <responsive-dialog
      :show-actions="false"
      :display-dialog="dialogOpened"
      :dialog-title="dialogTitle"
      @closed="closeDialog"
    >
      <v-row>
        <v-col cols="6">
          <p>
            Order #:
            <b>{{ order.reference_no }}</b>
          </p>
          <p>
            Customer:
            <b>{{ customer.target_entity.name }}</b>
          </p>
        </v-col>
        <v-col cols="6">
          <p>
            status:
            <b>{{ order.status }}</b>
          </p>
          <p v-if="order.requires_delivery">
            <b>Requires delivery</b>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Description</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Quantity</th>
                  <th class="text-left">Sub Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="orderItem in items" :key="orderItem.id">
                  <td>{{ orderItem.description }}</td>
                  <td>{{ formatCurrency(orderItem.price) }}</td>
                  <td>{{ orderItem.quantity }}</td>
                  <td>{{ formatCurrency(orderItem.total) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <template>
            <v-row>
              <v-col cols="12" md="6" v-if="!isCustomer">
                <v-btn
                  class="ma-2"
                  text
                  outlined
                  v-if="canEditOrder(order)"
                  @click="
                    $emit('edit_order', order);
                    closeDialog();
                  "
                >
                  Edit Order
                </v-btn>
                <v-btn class="ma-2" v-if="isOrderPayable" text outlined @click="displayPaymentForm">
                  Process payment
                </v-btn>
                <v-btn class="ma-2" v-if="isDeliveryRequired" text outlined @click="onClickShowDeliveryDetails(order)">
                  Delivery Details
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      Subtotal
                    </v-list-item-content>
                    <v-list-item-content class="align-end" text-align="end">
                      {{ formatCurrency(order.subtotal, order.currency) }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="order.tax_percentage">
                    <v-list-item-content>{{ taxTitle }} ({{ order.tax_percentage }}%)</v-list-item-content>
                    <v-list-item-content class="align-end" text-align="end">
                      {{ formatCurrency(order.tax, order.currency) }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      Total
                    </v-list-item-content>
                    <v-list-item-content class="align-end" text-align="end">
                      {{ formatCurrency(grandTotal) }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="isPaymentFormVisible">
        <v-col cols="12">
          <order-payment-form :order="order" @accepted="onPaymentAccepted"></order-payment-form>
        </v-col>
      </v-row>
      <v-row v-if="isOrderInvoiced" class="ml-2 mt-1 mb-3">
        <order-invoices-list ref="invoices_list" />
      </v-row>
    </responsive-dialog>
  </div>
</template>

<script>
import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import permissionMixin from "@/mixins/permission";
import OrderPaymentForm from "@/point-of-sale/components/order/OrderPaymentForm.vue";
import OrderInvoicesList from "@/point-of-sale/components/order/OrderInvoicesList.vue";
import { ADD_MESSAGE } from "@/store/mutation-types";
import api from "@/api";

export default {
  name: "OrderDetails",

  components: {
    ResponsiveDialog,
    OrderPaymentForm,
    OrderInvoicesList
  },

  mixins: [sumColumnMixin, currencyFormatMixin, permissionMixin],

  props: { isCustomer: { type: Boolean, default: false } },

  data() {
    return {
      dialogOpened: false,
      items: [],
      order: {},
      customer: {
        target_entity: {}
      },

      isPaymentFormVisible: false,
      isOrderInvoiced: false
    };
  },

  computed: {
    isLoading() {
      return this.$store.getters["order/loading"]("list");
    },

    dialogTitle() {
      return `Order details`;
    },

    grandTotal() {
      let tax = Math.round((Number(this.order.tax) + Number.EPSILON) * 100) / 100;
      let total = Math.round((Number(this.order.subtotal) + Number.EPSILON) * 100) / 100;
      return total + (tax || 0);
    },

    taxTitle() {
      return this.tax_title || "Taxes";
    },

    isDeliveryRequired() {
      return this.order.requires_delivery;
    },

    isOrderPayable() {
      return this.order.status === "open";
    }
  },

  methods: {
    open(item) {
      if (item) {
        this.order = item;
        this.items = item.items;
        this.customer = { target_entity: { ...item.target_entity } };
        this.dialogOpened = !!item;
        const promise = this.determineOrderInvoiced(item);
        promise
          .then(is_invoiced => {
            if (is_invoiced) {
              this.loadInvoices(item.id);
            }
          })
          .catch(() => {
            this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "unable to fetch order's invoices.", type: "Error" });
          });
      }
    },

    determineOrderInvoiced(item) {
      this.isOrderInvoiced = false;
      return api.order
        .is_invoiced(item.id)
        .then(response => {
          this.isOrderInvoiced = response.data.is_invoiced;
          return response.data.is_invoiced;
        })
        .catch(() => {
          this.isOrderInvoiced = false;
        });
    },

    loadInvoices(item_id) {
      this.$refs.invoices_list.fetchItems(item_id);
    },

    canEditOrder(item) {
      return item.status === "open" && this.hasPermission("change_order", "point_of_sale");
    },

    closeDialog() {
      this.dialogOpened = false;
      this.isPaymentFormVisible = false;
      this.$emit("closed");
    },

    onClickShowDeliveryDetails(item) {
      // this.$router.push({ name: "order-delivery", params: { orderId: item.id } });
      this.$router.push({ name: "order-delivery-summary", params: { orderId: item.id } });
    },

    displayPaymentForm() {
      this.isPaymentFormVisible = true;
    },

    onPaymentAccepted() {
      this.dialogOpened = false;
      this.isPaymentFormVisible = false;
      this.$emit("paymentProcessed");
    }
  }
};
</script>

<style scoped></style>
