<template>
  <!-- Wrap this in the MediaField tags for a dropzone -->
  <div class="media-drop">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MediaDropZone"
};
</script>

<style scoped>
.media-drop {
  min-height: 100px;
  border: 2px dashed #eeeeee;
  padding: 16px;
}
</style>
