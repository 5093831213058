<template>
  <v-expansion-panels popout focusable :value="0">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row no-gutters>
          <v-col cols="4">Order</v-col>
          <v-col cols="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <span v-if="!orderSearch" key="0">Select Order</span>
              <span v-else key="1">{{ pickedOrder.description }}</span>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-spacer></v-spacer>
          <!-- FIELD: ORDER -->
          <v-col cols="5">
            <v-autocomplete
              :items="orders"
              :loading="ordersLoading"
              :search-input.sync="orderSearch"
              outlined
              hide-no-data
              return-object
              item-value="id"
              item-text="description"
              label="Order*"
              placeholder="Start typing to Search"
              @change="onOrderChange"
            ></v-autocomplete>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-col cols="3">Select the order you want to add the quote to</v-col>
        </v-row>
        <v-row v-if="items">
          <v-col cols="12">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Description</th>
                    <th class="text-left">Price</th>
                    <th class="text-left">Quantity</th>
                    <th class="text-left">Sub Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.description }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.total }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="closeDialog">Cancel</v-btn>
          <v-btn :disabled="!allowSave" text color="primary" @click="onSubmit">Save</v-btn>
        </v-card-actions>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import mixins from "vue-typed-mixins";
import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";

import { ADD_FILTERS } from "@/store/mutation-types";

import debounce from "lodash/debounce";

export default mixins(sumColumnMixin, currencyFormatMixin).extend({
  name: "QuoteIntoOrder",

  data() {
    return {
      orderSearch: "",
      allowSave: false,
      pickedOrder: {},
      items: null,
      panel: 0
    };
  },

  computed: {
    ordersLoading() {
      return !!this.$store.getters["order/loading"]("list");
    },
    orders() {
      return this.$store.state.order.list;
    }
  },

  watch: {
    orderSearch: {
      handler: function () {
        debounce(val => {
          if (val && val !== "") {
            this.$store.commit(`order/${ADD_FILTERS}`, { description__icontains: this.orderSearch });
            this.fetchOrders();
          }
        }, 500);
      }
    }
  },

  methods: {
    clearOrderSearch() {
      this.orderSearch = "";
    },

    closeDialog() {
      this.dialogOpened = false;
      this.clearOrderSearch();
      this.$emit("closed");
    },

    onOrderChange(value) {
      this.pickedOrder = value;
      this.allowSave = true;
      this.items = value.items;
    },

    async fetchOrders() {
      this.$store.commit(`order/${ADD_FILTERS}`, { extra_fields: 1 });
      await this.$store.dispatch("order/list");
    },

    async onSubmit() {
      this.$emit("orderPicked", this.pickedOrder);
      this.closeDialog();
    }
  }
});
</script>
