import { http } from "@/vuex-bcore";

const url = "countries";

function fetch() {
  return http.get(`${url}/`);
}

export default {
  fetch
};
