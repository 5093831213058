<template>
  <responsive-dialog
    id="customers-form"
    :display-dialog="displayDialog"
    dialog-title="Select customers"
    @closed="closeForm"
  >
    <v-autocomplete
      v-model="value"
      label="Customer*"
      placeholder="Start typing to Search"
      item-value="target_entity"
      item-text="name"
      hide-details="auto"
      :items="customers"
      :loading="customersLoading"
      :search-input.sync="search"
      :disabled="is_loading"
      return-object
      hide-no-data
      outlined
      multiple
      no-filter
      chips
      small-chips
    />

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <v-spacer />

      <v-btn color="primary" :loading="is_loading" :disabled="is_loading" @click="on_submit" text>
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import api from "@/api";

function createPriceOverrideCustomersModel() {
  return {
    price_override_id: null,
    customers: []
  };
}

export default {
  name: "PriceOverrideCustomersForm",

  components: {
    ResponsiveDialog
  },

  data() {
    return {
      displayDialog: false,
      is_loading: false,

      form: createPriceOverrideCustomersModel(),
      customersLoading: false,

      search: "",
      customers: [],
      value: []
    };
  },

  computed: {},

  watch: {
    search() {
      this.debounceInput(this.filterCustomers);
    }
  },

  methods: {
    edit(price_override_id, selected_customers) {
      this.form = createPriceOverrideCustomersModel();
      this.form.price_override_id = price_override_id;

      this.displayDialog = true;

      this.value = selected_customers.map(customer => {
        return { target_entity: customer.id, name: customer.name };
      });
    },

    on_submit() {
      this.is_loading = true;
      this.form.customers = this.value;

      let customers_id = this.value.map(customer => {
        return customer.target_entity;
      });

      api.price_overrides
        .add_customers(this.form.price_override_id, customers_id)
        .then(response => {
          this.displayMessage({ text: `Price Override updated successfully.`, type: "success" });
          this.$emit("updated", response.data.customers);
          this.closeForm();
        })
        .catch(exception => {
          this.displayMessage({ text: "There was an error updating your Price Override.", type: "error" });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    filterCustomers() {
      if (this.customersLoading) {
        return;
      }

      this.customersLoading = true;

      return api.customers
        .search(this.search)
        .then(response => {
          this.customers = response.data.results.map(customer => {
            return { target_entity: customer.target_entity, name: customer.name };
          });
        })
        .finally(() => {
          this.customersLoading = false;
        });
    },

    /**
     * Debounce a function
     * @param func
     * @param timeout
     * TODO: Make this re-usable in other components ?
     */
    debounceInput(func, timeout = 300) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        return func();
      }, timeout);
    },

    clear() {
      this.value = [];
      this.customers = [];
      this.search = "";
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
    },

    closeForm() {
      this.displayDialog = false;
      this.$emit("closed");
    }
  }
};
</script>
