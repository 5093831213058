<template>
  <v-card class="signup-mobile">
    <v-card-title class="rounded-0" v-show="!confirmation.show_dialog">
      <div class="logo-wrapper">
        <img src="@/assets/accruvia-logo.svg" alt="accruvia-logo" class="logo" />
      </div>

      <div class="header">SIGN UP FOR YOUR FREE ACCOUNT</div>
    </v-card-title>

    <v-card-text v-show="!confirmation.show_dialog">
      <v-form v-model="context.form.is_valid" @submit.prevent="context.submit_form">
        <v-container>
          <non-field-errors :errors="context.form.errors" />

          <v-row>
            <v-col cols="12">
              <label class="c-label">Company Name (<small>optional</small>)</label>
              <v-text-field
                hide-details="auto"
                name="Signup[CompanyName]"
                v-model="context.form.fields.company_name"
                :error-messages="context.form.field_errors.company_name"
                :rules="context.form.rules.company_name"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label class="c-label">First Name</label>
              <v-text-field
                hide-details="auto"
                name="Signup[FirstName]"
                v-model="context.form.fields.first_name"
                :error-messages="context.form.field_errors.first_name"
                :rules="context.form.rules.first_name"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label class="c-label">Last Name</label>
              <v-text-field
                hide-details="auto"
                name="Signup[LastName]"
                v-model="context.form.fields.last_name"
                :error-messages="context.form.field_errors.last_name"
                :rules="context.form.rules.first_name"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <label class="c-label">Email</label>
              <v-text-field
                hide-details="auto"
                name="Signup[Email]"
                v-model="context.form.fields.email"
                :error-messages="context.form.field_errors.email"
                :rules="context.form.rules.email"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label class="c-label">Password</label>
              <v-text-field
                type="password"
                hide-details="auto"
                name="Signup[Password]"
                v-model="context.form.fields.password"
                :error-messages="context.form.field_errors.password"
                :rules="context.form.rules.password"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label class="c-label">I need the most help with:</label>
              <v-radio-group
                v-model="context.form.fields.landing_page"
                column
                class="ml-3"
                >
                  <v-radio label="Managing Bills" value="org-bills"/>
                  <v-radio label="Sending Invoices" value="org-invoices"
                  />
                </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="mt-2">
              <v-btn
                type="submit"
                color="primary"
                class="btn-submit"
                :disabled="context.isLoading"
                :loading="context.isLoading"
                x-large
              >
                SIGN UP
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <router-link :to="{ name: 'login' }" class="text-decoration-none grey--text text--darken-1">
                Already have an account?
                <strong class="black--text">Login</strong>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <!-- 
    Carlos (note to self): for the time being this dialog resides here, but I think this will be moved to Signup.vue
      -->
    <v-dialog v-model="confirmation.show_dialog" max-width="320">
      <v-card class="signup-mobile-confirmation">
        <div class="pa-2">
          <v-btn class="close-dialog" @click="close_confirmation" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title class="headline">{{ confirmation.title }}</v-card-title>

        <v-card-text class="content">{{ confirmation.message }}</v-card-text>

        <v-card-actions class="justify-center pb-12">
          <v-btn color="primary" class="login" large>LOGIN</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "SignupMobile",

  components: {
    NonFieldErrors
  },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {
      confirmation: {
        show_dialog: false,
        title: "",
        message: ""
      }
    };
  },

  mounted() {
    // this.confirm_account_created();
    // this.confirm_account_exists();
  },

  methods: {
    show_confirmation(options) {
      let confirmation = this.confirmation;

      confirmation.show_dialog = true;
      confirmation.title = options.title;
      confirmation.message = options.message;
    },

    close_confirmation() {
      let confirmation = this.confirmation;

      confirmation.show_dialog = false;
      confirmation.title = "";
      confirmation.message = "";
    },

    confirm_account_created() {
      this.show_confirmation({
        title: "YOU'RE ALL SET!",
        message: "Thank you for creating an account with us! Login go get started."
      });
    },

    confirm_account_exists() {
      this.show_confirmation({
        title: "YOU ALREADY HAVE AN ACCOUNT",
        message: "This email is already associated with an Accruvia account. Login to get started!"
      });
    }
  }
};
</script>

<style>
.signup-mobile {
  height: 100%;
}

.signup-mobile .v-card__title {
  background-size: cover;
  background-position: bottom;
  background-image: url("~@/assets/mobile/background-swoosh.svg");
  padding-bottom: 18%;
  display: block;
}

.signup-mobile .logo-wrapper {
  line-height: 0;
  text-align: right;
}

.signup-mobile .logo {
  height: 35px;
}

.signup-mobile .v-card__title .header {
  font-size: 14px;
  color: #fff;
  text-align: center;
  word-break: break-word;

  margin-top: 28px;
  margin-bottom: 16px;
}

.signup-mobile .c-label {
  padding-left: 15px;
  line-height: 2.5;
  font-size: 18px;
  color: #000;
}

.signup-mobile .v-input__slot .v-label {
  font-size: 18px;
  color: #000;
}

.signup-mobile .btn-submit {
  width: 100%;
}

.v-dialog .signup-mobile-confirmation .close-dialog {
  min-width: auto !important;
  padding: 0 8px !important;
}

.v-dialog .signup-mobile-confirmation .headline {
  text-align: center;
  color: #437b56;
  font-weight: bold;
  word-break: break-word;

  display: block;
  padding: 0;
  margin: 24px;
}

.v-dialog .signup-mobile-confirmation .content {
  color: #000;
  text-align: center;
}

.v-dialog .signup-mobile-confirmation button.login {
  width: 180px;
}
</style>
