<template>
  <tr>
    <td class="pt-2 pb-2">
      <v-text-field
        name="InvoiceItem[Description]"
        label="Description"
        v-model="formItem.description"
        hide-details
        dense
        solo
      />
    </td>
    <td class="align-content-center">
      <v-text-field
        name="InvoiceItem[Name]"
        label="Price"
        type="number"
        v-model="formItem.price"
        :step="0.01"
        :min="0"
        hide-details
        dense
        solo
      />
    </td>
    <td>
      <v-text-field
        name="InvoiceItem[Quantity]"
        label="Quantity"
        type="number"
        v-model="formItem.qty"
        :step="0.01"
        :min="0"
        hide-details
        dense
        solo
      />
    </td>
    <td>
      <v-chip class="pa-0" style="border: none" outlined label>{{ formatCurrency(total) }}</v-chip>
    </td>
    <td>
      <v-btn color="success" @click.stop="onFormSubmit">
        <v-icon left>mdi-plus</v-icon>
        Add
      </v-btn>
    </td>
  </tr>
</template>

<script>
import currencyFormatMixin from "@/mixins/currencyFormat";

function defaultObject() {
  return {};
}

export default {
  name: "InvoiceItemForm",

  mixins: [currencyFormatMixin],

  data() {
    return {
      formTitle: "Invoice Item",
      isOpen: false,
      formErrors: {},
      formItem: {}
    };
  },

  computed: {
    total() {
      if (this.formItem.price && this.formItem.qty) {
        return Number(this.formItem.price) * Number(this.formItem.qty);
      }

      return 0.0;
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.formItem.id;
    },

    formHasErrors() {
      return Object.keys(this.formErrors).length > 0;
    }
  },

  methods: {
    resetFormErrors() {
      this.formErrors = {};
    },

    open(item) {
      this.formItem = item;
    },

    async onFormSubmit() {
      this.formItem.total = this.total;
      this.$emit("updated", this.formItem);
      this.closeForm();
    },

    closeForm() {
      this.isOpen = false;
      this.formItem = {};
      this.$emit("closed");
    }
  }
};
</script>
