<template>
  <v-card class="organization-settings" elevation="0">
    <!-- TITLE -->
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <span>Organization Settings</span>

      <v-spacer />
    </v-card-title>

    <!-- MAIN CONTENT -->
    <v-card-text class="pt-4 px-4 pb-12">
      <!-- CUSTOMER DEFAULTS -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-card-subtitle class="grey--text text--darken-4 font-weight-bold">Customer Defaults</v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text>
          <v-container class="pa-0 pt-2" fluid>
            <!-- FIELD: DEFAULT CURRENCY -->
            <v-row>
              <v-col>
                <currency-select
                  :initial-value="form.data.default_currency"
                  :messages="currencyMessage"
                  :disabled="isLoading"
                  @change="onCurrencyChange"
                  background-color="#FFF"
                />
              </v-col>
            </v-row>

            <!-- FIELD: DEFAULT CREDIT TERMS -->
            <v-row>
              <v-col>
                <v-text-field
                  name="Settings[DefaultCreditTerms]"
                  label="Default Credit Terms*"
                  background-color="#FFF"
                  v-model.number="form.data.default_credit_terms"
                  :error-messages="form.errors.default_credit_terms"
                  :disabled="isLoading"
                  outlined
                  required
                />
              </v-col>
            </v-row>

            <!-- FIELD: DEFAULT TAX PERCENTAGE -->
            <v-row>
              <v-col>
                <v-text-field
                  name="Settings[DefaultTaxPercentage]"
                  label="Default Tax Percentage*"
                  background-color="#FFF"
                  v-model.number="form.data.default_tax_percentage"
                  :error-messages="form.errors.default_tax_percentage"
                  :disabled="isLoading"
                  outlined
                  required
                />
              </v-col>
            </v-row>

            <!-- ACTIONS -->
            <v-row>
              <v-col class="d-flex">
                <v-spacer />
                <v-btn
                  type="button"
                  color="primary"
                  :loading="isLoading"
                  :disabled="isLoading || !isDirty"
                  @click.prevent="submitForm"
                  text
                >
                  <v-icon>mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <!-- PREMIUM SETTINGS -->
      <template v-if="is_premium">
        <!-- PORTAL BRANDING -->
        <v-card elevation="1" outlined tile class="grey lighten-5">
          <v-card-subtitle class="grey--text text--darken-4 font-weight-bold">Portal Branding</v-card-subtitle>

          <v-divider></v-divider>

          <v-card-text>
            <v-container class="pa-0 pt-2" fluid>
              <EntityLogo :entity="ownerEntity" :editable="true" class="entity-logo" />

              <v-row class="mt-4">
                <v-col>
                  <h3>Primary Color</h3>

                  <v-color-picker
                    v-model="live_theme.primary"
                    class="my-4"
                    mode="rgba"
                    dot-size="25"
                    swatches-max-height="200"
                    show-swatches
                    hide-canvas
                  />

                  <v-btn
                    color="secondary"
                    :disabled="disable_color_picker_actions"
                    @click="restore_previous_theme"
                    text
                  >
                    Restore Primary Color
                  </v-btn>

                  <v-btn color="primary" :disabled="disable_color_picker_actions" @click="update_primary_color" text>
                    Save Changes
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <!-- PREMIUM SETTINGS -->
        <premium-settings />
      </template>

      <!-- REQUIRE APPROVAL BEFORE PAYMENT -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-subheader>
          <v-switch
            v-model="form.data.require_approval_before_payment"
            :error-messages="form.errors.require_approval_before_payment"
            :loading="isUpdatingRABP"
            @change="toggleRABP"
          >
            <template v-slot:label>
              <span class="input__label grey--text text--darken-4">Require Invoice Approval Before Payment.</span>
            </template>
          </v-switch>
        </v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <p class="grey--text text--darken-4 mb-0">
            <span class="font-weight-medium">When enabled,</span>
            you're required to approve invoices before making payments.
          </p>
        </v-card-text>
      </v-card>

      <!-- PAYMENT SCHEDULES -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-subheader>
          <v-switch
            v-model="form.data.enable_payment_schedule"
            :error-messages="form.errors.enable_payment_schedule"
            :loading="isUpdatingPaymentSchedules"
            @change="togglePaymentSchedules"
          >
            <template v-slot:label>
              <span class="input__label grey--text text--darken-4">Enable Payment Schedules.</span>
            </template>
          </v-switch>
        </v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <p class="grey--text text--darken-4 mb-0">
            <span class="font-weight-medium">When enabled,</span>
            you're able to specify payment schedules for your invoices.
          </p>
        </v-card-text>
      </v-card>

      <!-- ENABLE CUSTOMER COMMUNICATION -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-subheader>
          <v-switch
            v-model="form.data.enable_customers_email_communication"
            :error-messages="form.errors.enable_customers_email_communication"
            :loading="isUpdatingECEM"
            @change="toggleECEM"
          >
            <template v-slot:label>
              <span class="input__label grey--text text--darken-4">Enable Customer E-mail Communication.</span>
            </template>
          </v-switch>
        </v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <p class="grey--text text--darken-4 mb-0">
            <span class="font-weight-medium">When enabled,</span>
            you're presented the option to invite customers to {{ appName }} on the Customer Registry Form.
          </p>
        </v-card-text>
      </v-card>

      <!-- EMAIL NOTIFICATION OF INVOICE ASSIGNMENT -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-subheader>
          <v-switch
            v-model="form.data.enable_invoice_assignment_notification"
            :error-messages="form.errors.enable_invoice_assignment_notification"
            :loading="isUpdatingEENOIA"
            @change="toggleEENOIA"
          >
            <template v-slot:label>
              <span class="input__label grey--text text--darken-4">
                Enable E-Mail Notifications of Invoice Assignment.
              </span>
            </template>
          </v-switch>
        </v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <p class="grey--text text--darken-4 mb-0">
            <span class="font-weight-medium">When enabled,</span>
            the assignees will be notified via email that a new invoice has been assigned to them.
          </p>
        </v-card-text>
      </v-card>

      <!-- EMAIL NOTIFICATION OF CREDIT CARD PAYMENTS -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-subheader>
          <v-switch
            ref="toggle_enable_payment_notification"
            v-model="form.data.enable_payment_notification"
            :error-messages="form.errors.enable_payment_notification"
            :loading="isUpdatingPaymentNotification"
            @change="togglePaymentNotification"
          >
            <template v-slot:label>
              <span class="input__label grey--text text--darken-4">
                Enable E-Mail Notifications of Successful Credit Card Payments.
              </span>
            </template>
          </v-switch>
        </v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <p class="grey--text text--darken-4 mb-0">
            <span class="font-weight-medium">When enabled,</span>
            all the employees of the organization will be notified via email that a successful credit card transaction
            is created.
          </p>
        </v-card-text>
      </v-card>

      <!-- SECURITY -->
      <v-card elevation="1" outlined tile class="grey lighten-5">
        <v-subheader>
          <v-switch
            v-model="form.data.enable_anti_fraud_security"
            :error-messages="form.errors.enable_anti_fraud_security"
            :loading="isUpdatingAFS"
            @change="toggleAFS"
          >
            <template v-slot:label>
              <span class="input__label grey--text text--darken-4">Anti-Fraud Security</span>
            </template>
          </v-switch>
        </v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <p class="grey--text text--darken-4">
            <span class="font-weight-medium">When enabled,</span>
            payment instructions
            <strong>won't be included</strong>
            in any emails. This prevents spoofers from imitating your company when invoices are emailed to customers
            that are not registered on {{ appName }}.
          </p>
          <p class="grey--text text--darken-4 mb-0">
            <span class="font-weight-medium">When disabled,</span>
            payment instructions
            <strong>are included</strong>
            in emails to customers. While this is more convenient for customers, it also significantly increases the
            risk of invoice fraud.
          </p>
        </v-card-text>
      </v-card>
      <!--  -->
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencySelect from "@/invoiceboost/components/CurrencySelect.vue";
import EntityLogo from "@/bcore/components/entity/EntityLogo.vue";

import TrackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import { ADD_MESSAGE } from "@/store/mutation-types";
import PremiumSettings from "@/premium/components/PremiumSettings";

import api from "@/api";
import { parseFormErrors } from "@/utilities/form";

function createDataModel() {
  return {
    id: null,
    owner_entity: null,
    enable_payment_notification: false,
    enable_invoice_assignment_notification: false,
    enable_customers_email_communication: false,
    accounting_package_connected: false,
    enable_anti_fraud_security: false,
    default_currency: "USD",
    default_credit_terms: 0,
    default_tax_percentage: 0.0,
    theme: ""
  };
}

function createModelRules() {
  return {};
}

export default {
  name: "OrganizationSettings",

  components: {
    CurrencySelect,
    EntityLogo,
    PremiumSettings
  },

  mixins: [TrackDirtyFieldsMixin],

  props: {
    entity: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    let live_theme = this.$vuetify.theme.themes.light;

    return {
      isLoading: false,

      isUpdatingAFS: false,
      isUpdatingECEM: false,
      isUpdatingEENOIA: false,
      isUpdatingRABP: false,
      isUpdatingPaymentSchedules: false,
      isUpdatingPaymentNotification: false,

      initial_theme: Object.assign({}, live_theme),

      form: {
        isValid: false,

        initialData: createDataModel(),
        data: createDataModel(),
        rules: createModelRules(),
        errors: {}
      },

      currencyMessage: "Set your organization's default currency",
      antiFraudSecurityHint: "Send a copy of the payment instructions with the invoice email",
      antiFraudSecurityMessages: `When enabled: Do not include payment instructions to prevent spoofers from imitating your company when invoices are emailed to customers not on ${this.appName}\n When disabled: Include payment instructions in emails to customers. While this is more convenient for customers, it also significantly increases the risk of invoice fraud.`
    };
  },

  computed: {
    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    entitySettings() {
      return this.$store.state.entitySettings.detail;
    },

    formHasErrors() {
      return Object.keys(this.form.errors).length;
    },

    appName() {
      return process.env.VUE_APP_NAME || "";
    },

    live_theme() {
      return this.$vuetify.theme.themes.light;
    },

    disable_color_picker_actions() {
      return this.initial_theme.primary.toUpperCase() === this.live_theme.primary.toUpperCase();
    },

    is_premium() {
      return this.$store.state.auth.entity.is_premium_organization;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.fetch();
    });
  },

  methods: {
    importSettings() {
      const form = this.form;
      const settings = this.entitySettings;

      Object.assign(form.data, createDataModel(), settings);
      Object.assign(form.initialData, createDataModel(), settings);
    },

    // Update the $store settings with the current changes
    exportSettings() {
      const data = this.form.data;
      const settings = this.entitySettings;

      Object.assign(settings, data);
      Object.assign(settings, data);
    },

    fetch() {
      this.isLoading = true;

      const form = this.form;

      return api.organizations.settings
        .fetch(this.ownerEntity.id)
        .then(response => {
          const settings = response.data;

          Object.assign(form.data, createDataModel(), settings);
          Object.assign(form.initialData, createDataModel(), settings);
        })
        .catch(error => {
          const errors = error && error.response ? error.response.data : {};
          this.setFormErrors(errors);

          this.displayMessage({
            text: "Unable to fetch system settings, if this persist please contact us.",
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submitForm() {
      this.clearErrors();
      this.isLoading = true;

      return this.update(this.form.data).finally(() => {
        this.isLoading = false;
      });
    },

    update(data) {
      const form = this.form;

      return api.organizations.settings
        .update(this.ownerEntity.id, data)
        .then(response => {
          let responseData = response.data;

          Object.assign(form.data, responseData);
          Object.assign(form.initialData, responseData);

          this.exportSettings();

          this.displayMessage({ text: "Settings updated.", type: "success" });
          this.$emit("updated");
        })
        .catch(error => {
          const errors = error && error.response ? error.response.data : {};
          this.setFormErrors(errors);

          this.displayMessage({ text: "There was an error saving your settings.", type: "error" });
        });
    },

    // Toggle Anti Fraud Security
    toggleAFS(value) {
      // Use form.initialData to avoid submitting data that the user is editing on other fields
      const settings = Object.assign({}, this.form.initialData, { enable_anti_fraud_security: value });

      this.isUpdatingAFS = true;

      this.update(settings)
        .then(() => {
          this.displayMessage({ text: "Anti-Fraud Security updated.", type: "success" });
        })
        .finally(() => {
          this.isUpdatingAFS = false;
        });
    },

    // Toggle Enable Customers Email Communication
    toggleECEM(value) {
      // Use form.initialData to avoid submitting data that the user is editing on other fields
      const settings = Object.assign({}, this.form.initialData, { enable_customers_email_communication: value });

      this.isUpdatingECEM = true;

      this.update(settings)
        .then(() => {
          this.displayMessage({ text: "Customers Email Communications updated.", type: "success" });
        })
        .finally(() => {
          this.isUpdatingECEM = false;
        });
    },

    // TOGGLE REQUIRE APPROVAL BEFORE PAYMENT
    toggleRABP(value) {
      const settings = Object.assign({}, this.form.initialData, { require_approval_before_payment: value });

      this.isUpdatingRABP = true;

      this.update(settings)
        .then(() => {
          this.displayMessage({ text: "Invoice approval settings updated.", type: "success" });
        })
        .finally(() => {
          this.isUpdatingRABP = false;
        });
    },

    togglePaymentSchedules(value) {
      const settings = Object.assign({}, this.form.initialData, { enable_payment_schedule: value });

      this.isUpdatingPaymentSchedules = true;

      this.update(settings)
        .then(() => {
          this.displayMessage({ text: "Payment Schedule updated.", type: "success" });
        })
        .finally(() => {
          this.isUpdatingPaymentSchedules = false;
        });
    },

    togglePaymentNotification(value) {
      const settings = Object.assign({}, this.form.initialData, { enable_payment_notification: value });

      this.isUpdatingPaymentNotification = true;

      this.update(settings)
        .then(() => {
          this.displayMessage({ text: "Email Notifications of successful payment updated.", type: "success" });
        })
        .finally(() => {
          this.isUpdatingPaymentNotification = false;
        });
    },

    // TOGGLE ENABLE EMAIL NOTIFICATIONS OF INVOICE ASSIGNMENT
    toggleEENOIA(value) {
      const settings = Object.assign({}, this.form.initialData, { enable_invoice_assignment_notification: value });

      this.isUpdatingEENOIA = true;

      this.update(settings)
        .then(() => {
          this.displayMessage({ text: "Email Notifications of Invoice Assignment updated.", type: "success" });
        })
        .finally(() => {
          this.isUpdatingEENOIA = false;
        });
    },

    clearErrors() {
      this.form.errors = {};
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.form.errors = parseFormErrors(fieldErrors);
      this.form.errors.non_field_errors = errors.errors || [];
    },

    onCurrencyChange(value) {
      this.entitySettings.default_currency = value;
    },

    close() {
      this.form.data = createDataModel();
      this.$emit("closed");
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField("form.data.default_currency", "form.initialData.default_currency");
      this.trackDirtyField("form.data.default_credit_terms", "form.initialData.default_credit_terms");
      this.trackDirtyField("form.data.default_tax_percentage", "form.initialData.default_tax_percentage");
    },

    preview_theme(color) {
      // update the live theme color
      this.live_theme.primary = color;
    },

    restore_previous_theme() {
      this.$vuetify.theme.themes.light = Object.assign({}, this.initial_theme);
    },

    update_primary_color() {
      const data = { theme: { light: this.live_theme } };
      this.update(data);
    }
  }
};
</script>

<style>
.organization-settings .feature-description {
  padding-left: 16px;
  padding-right: 16px;
}

.organization-settings .v-card + .v-card {
  margin-top: 36px;
}
</style>
