import { http } from "@/vuex-bcore";

const url = "invoiceboost/payment-methods";

function fetch() {
  return http.get(`${url}/`);
}

export default {
  fetch
};
