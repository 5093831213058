<template>
  <v-card class="org-transactions" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <span>Transactions</span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder="customer, Invoice #, Etc."
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="items"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        @click:row="displayDetails"
        dense
        multi-sort
        disable-filtering
      >
        <!-- COLUMN: AMOUNT -->
        <template v-slot:item.amount="{ item }">{{ formatCurrency(item.amount, item.currency) }}</template>
        <!-- COLUMN: CREATED DATE -->
        <template v-slot:item.created_at="{ item }">{{ formatDate(item.created_at) }}</template>

        <template v-slot:item.actions="{ item }">
          <span class="" @click.prevent="">
            <v-icon left>mdi-page-next-outline</v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "vue-typed-mixins";
import dataTableMixin from "@/mixins/dataTable";
import permissionMixin from "@/mixins/permission";
import { ADD_MESSAGE } from "@/store/mutation-types";
import DateMixin from "@/mixins/date";
import PageTitle from "@/bcore/components/PageTitle";
import CurrencyFormatMixin from "@/mixins/currencyFormat";

import deep_copy from "@/utilities/deep_copy";
import eventHub from "@/eventHub";

const modelName = "transaction";

export default mixins(dataTableMixin, DateMixin, CurrencyFormatMixin, permissionMixin).extend({
  name: "Transactions",

  components: {
    PageTitle
  },

  data() {
    return {
      headers: [
        { text: "Customer", value: "target_entity.name", sortable: true },
        { text: "Reference no", value: "reference_no", sortable: true },
        // { text: "Related", value: "assignees", sortable: true },
        { text: "Amount", value: "amount", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Type", value: "type", sortable: false },
        { text: "Gateway", value: "gateway", sortable: true },
        { text: "Date Created", value: "created_at", sortable: true },
        { text: "", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    pageTitle() {
      return this.$store.state.theme.pageTitle;
    },

    items() {
      return this.$store.state[modelName].list;
    },

    isLoading() {
      return this.$store.getters[`${modelName}/loading`]("list");
    }
  },

  methods: {
    async fetchItems() {
      let response;
      try {
        response = await this.$store.dispatch("transaction/list");
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Failed to get results" });
        return;
      }
    },

    displayDetails(transaction) {
      // Send a copy of the invoice object to prevent edit propagation
      eventHub.trigger("TransactionsDetails.open", deep_copy(transaction));
    },

    formatStatus(status) {
      return status.replace("_", " ");
    }
  }
});
</script>
<style>
.org-transactions .search-field .v-input__slot {
  margin: 0;
}

.org-transactions .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-transactions table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-transactions table thead tr {
  background-color: #eeeeee;
}

.org-transactions table thead tr th {
  color: #000 !important;
}
</style>
