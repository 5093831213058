import { getActions, getState, getMutations, getGetters } from "@/vuex-bcore";
import { RootState } from "@/vuex-bcore";
import { Module } from "vuex";
import { ModelState } from "@/vuex-bcore";
import { Model } from "./types";

const actions = getActions<ModelState<Model>>("users");
const state = getState<Model>();
const getters = getGetters<Model>();
const mutations = getMutations<Model>();

const userAdmin: Module<ModelState<Model>, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default userAdmin;
