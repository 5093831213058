<template>
  <v-card class="customer-price-overrides" elevation="0">
    <v-card-title class="display-1 font-weight-thin mb-3">
      <v-menu nudge-bottom="38" bottom right tile>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" class="px-0 mr-3 align-center" outlined icon @click.native.stop>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="display_add_customer_form">
            <v-icon left>mdi-plus</v-icon> Add this customer to a Price Override
          </v-list-item>
        </v-list>
      </v-menu>

      <span class="">Price Overrides</span>
      <span v-if="customerName" class="ml-1 text-capitalize">({{ customerName }})</span>
    </v-card-title>

    <!--    v-if="hasPermission('add_customer')"-->
    <!-- <v-card-text>
      <v-btn
        v-show="!displayForm"
        title="Add Customer"
        color="primary"
        class=""
        ref="display_form_button"
        @click="display_add_customer_form"
        outlined
      >
        <v-icon>mdi-plus</v-icon> Add customer to a Price Override
      </v-btn>
    </v-card-text> -->

    <v-card v-if="displayForm" class="mx-4">
      <v-card-text>
        <v-autocomplete
          label="Price Overrides"
          placeholder="Start typing to Search"
          hide-details="auto"
          v-model="form.selected"
          :items="form.options"
          :loading="isLoading"
          :search-input="form.itemSearch"
          item-value="id"
          item-text="name"
          return-object
          hide-no-data
          clearable
          outlined
          dense
          solo
        />
      </v-card-text>

      <v-card-actions>
        <v-btn v-show="!isLoading" text color="error" @click.prevent="closeForm">Cancel</v-btn>

        <v-spacer />

        <v-btn color="primary" :loading="isLoading" :disabled="disableSave" @click="onFormSubmit" text>
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card-text>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="">Name</th>
            <th class="">Type</th>
            <th class="">Start date</th>
            <th class="">End Date</th>
            <th class="">Status</th>
            <th class=""></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="price_override in active_price_overrides" :key="price_override.id">
            <td>{{ price_override.name }}</td>
            <td>{{ getTypeText(price_override.type) }}</td>
            <td>{{ price_override.from_date }}</td>
            <td>{{ price_override.to_date }}</td>
            <td>
              <v-chip class="" text-color="white" :color="getStatusColor(price_override.is_active)" small>
                {{ getStatusText(price_override.is_active) }}
              </v-chip>
            </td>
            <td>
              <router-link :to="{ name: 'price-override-details', params: { id: price_override.id } }">
                View details
              </router-link>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import PriceOverrideTable from "@/point-of-sale/components/price-override/PriceOverrideTable.vue";

import PriceOverrideStatusMixin from "@/mixins/priceOverrideStatus";
import PriceOverrideTypeMixin from "@/mixins/priceOverrideType";

import { ADD_MESSAGE } from "@/store/mutation-types";

import api from "@/api";

function create_form_model() {
  return {
    id: null,
    options: [],
    itemSearch: "",
    selected: null
  };
}

export default {
  name: "CustomerPriceOverrides",

  components: { ResponsiveDialog, PriceOverrideTable },

  mixins: [PriceOverrideStatusMixin, PriceOverrideTypeMixin],

  data() {
    return {
      isLoading: false,
      displayForm: false,

      form: create_form_model(),

      customer: null,
      active_price_overrides: []
    };
  },

  computed: {
    disableSave() {
      return !this.form.selected || this.isLoading;
    },

    customerName() {
      return this.customer ? this.customer.name : "";
    }
  },

  mounted() {
    this.fetch_active_price_overrides();
  },

  methods: {
    fetch_active_price_overrides() {
      let customer_target_entity_id = this.$route.params.id;
      this.isLoading = true;

      api.price_override_customer
        .fetch_active_price_overrides(customer_target_entity_id)
        .then(response => {
          let data = response.data;
          this.customer = data.customer;
          this.active_price_overrides = data.price_overrides;
        })
        .catch(exception => {
          this.displayMessage({ text: "Could not fetch price override information. Please try again.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetch_all_price_overrides() {
      if (this.form.options && this.form.options.lenght) {
        return;
      }

      let exclude_customer_id = this.customer.id;

      this.isLoading = true;

      api.price_overrides
        .fetch()
        .then(response => {
          // exclude the overrides where the current customer is already part of
          let results = response.data.results.filter(po => {
            return !po.customers.find(customer => {
              return customer.id === exclude_customer_id;
            });
          });

          this.form.options = results;
        })
        .catch(exception => {
          this.displayMessage({ text: "Could not fetch price override information. Please try again.", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    filterPriceOverrides() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      return api.price_overrides
        .search(this.search)
        .then(response => {
          this.customers = response.data.results;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    debounceInput(func, timeout = 300) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        return func();
      }, timeout);
    },

    closeForm() {
      this.displayForm = false;
    },

    display_add_customer_form() {
      this.fetch_all_price_overrides();
      this.displayForm = true;
    },

    onFormSubmit() {
      let price_override = this.form.selected;
      let customers = price_override.customers;

      let ids = customers.map(customer => {
        return customer.id;
      });

      ids.push(this.customer.id);

      // TODO: this methods should be refactored so that it appends the specified customers, instead of setting the whole list
      api.price_overrides
        .add_customers(this.form.selected.id, ids)
        .then(response => {
          this.displayMessage({
            text: `Customer ${this.customer.name} successfully added to ${price_override.name}.`,
            type: "success"
          });
          this.fetch_active_price_overrides();
          this.displayForm = false;
        })
        .catch(exception => {
          this.displayMessage({ text: "Unable to add customer to Price Override", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style>
/* Specify a row height for the table header and body rows */
.customer-price-overrides table tr {
  line-height: 40px;
}

.customer-price-overrides table thead tr {
  background-color: #eeeeee;
}

.customer-price-overrides table thead tr th {
  background-color: transparent;
}

.customer-price-overrides table thead tr th {
  color: #000 !important;
}
</style>
