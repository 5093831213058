import { AuthState, RootState } from "../types";
import { GetterTree } from "vuex/types";

const getters: GetterTree<AuthState, RootState> = {
  /**
   * Check if a user is logged in
   * @param state
   */
  loggedIn: state => {
    return !state.user.anonymous;
  },

  /**
   * Check if the user has entity permissions granted on the django backend.
   * The permission string is of format "app_name.permission"
   * e.g this.$store.getters["auth/hasEntityPermission"]("yourapp.view_employees");
   * @param state
   */
  hasEntityPermission: state => (permission: string): boolean => {
    return state.permissions.includes(permission);
  }
};

export default getters;
