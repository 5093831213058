<template>
  <tr>
    <td>{{ item.item.name }}</td>
    <td>
      <v-text-field
        v-if="item"
        v-model="formItem.interval_qty"
        solo
        label="Quantity"
        type="number"
        :step="0.01"
        dense
        @input="checkMax"
      />
    </td>
    <td>
      /
      <span class="text--primary" v-if="item">{{ item.quantity }}</span>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";

function defaultObject() {
  return {};
}

export default Vue.extend({
  name: "DeliveryIntervalItemForm",

  props: {
    item: { type: Object, default: defaultObject }
  },

  data() {
    return {
      isOpen: false,
      formErrors: {},
      formItem: {}
    };
  },

  computed: {
    formHasErrors() {
      return Object.keys(this.formErrors).length > 0;
    }
  },

  created() {
    this.formItem = {
      item: this.item
      // orderItemId: this.item.id
      // name: this.item.item.name
    };
  },

  methods: {
    resetFormErrors() {
      this.formErrors = {};
    },

    checkMax() {
      if (Number(this.formItem.interval_qty) > Number(this.item.quantity)) {
        this.$nextTick(() => {
          this.formItem.interval_qty = this.item.quantity;
        });
      }
      this.$emit("updated", this.formItem);
    },

    closeForm() {
      this.isOpen = false;
      this.formItem = {};
      this.$emit("closed");
    }
  }
});
</script>

<style>
td .v-text-field__details {
  display: none;
}
</style>
