<template>
  <v-card class="forgot-password">
    <v-card-title class="d-block rounded-0">
      <div class="logo-wrapper">
        <img src="@/assets/accruvia-logo.png" alt="accruvia-logo" class="logo" />
      </div>

      <div v-if="active_step == 1" class="header">CREATE NEW PASSWORD</div>
      <div v-if="active_step == 2" class="header">NEW PASSWORD CONFIRMATION</div>
    </v-card-title>

    <v-card-text class="px-8 pt-5">
      <non-field-errors :errors="form.errors" />

      <!-- PASSWORD CHANGE -->
      <v-container v-if="active_step == 1" class="pa-0">
        <v-row>
          <v-col>
            <p class="instructions">Your new password must be different from your previously used passwords.</p>
          </v-col>
        </v-row>

        <v-form ref="reset_password_form" :lazy-validation="false">
          <v-row class="mt-4">
            <v-col>
              <label class="c-label">Password</label>
              <v-text-field
                v-model="form.fields.password"
                name="ForgotPassword[NewPassword]"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_password ? 'text' : 'password'"
                :error-messages="form.field_errors.password"
                :rules="form.rules.password"
                @click:append="show_password = !show_password"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label class="c-label">Confirm Password</label>
              <v-text-field
                v-model="form.fields.confirm_password"
                :type="show_password ? 'text' : 'password'"
                name="ForgotPassword[ConfirmNewPassword]"
                :rules="form.rules.confirm_password"
                outlined
              />
            </v-col>
          </v-row>
        </v-form>

        <v-row class="mt-2">
          <v-col>
            <v-btn
              color="primary"
              class="btn-submit"
              :disabled="context.is_loading"
              :loading="context.is_loading"
              @click="confirm_reset_password"
              x-large
            >
              RESET PASSWORD
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- CONFIRMATION -->
      <v-container v-if="active_step == 2" class="pa-0">
        <v-row class="my-10">
          <v-col>
            <p class="instructions">Your password has successfully been reset! Login to access your account.</p>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <v-btn
              color="primary"
              class="btn-submit"
              :disabled="context.is_loading"
              :loading="context.is_loading"
              @click="context.goto('login')"
              x-large
            >
              LOGIN
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "ResetPasswordConfirmMobile",

  components: {
    NonFieldErrors,
  },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {
      active_step: 1,
    };
  },

  computed: {
    form() {
      return this.context.form;
    },

    fields() {
      return this.context.form.fields;
    },

    show_password: {
      get() {
        return this.context.show_password;
      },

      set(value) {
        this.context.show_password = value;
      }
    }
  },

  methods: {
    confirm_reset_password() {
      if (this.$refs.reset_password_form.validate()) {
        this.context
            .confirm_reset_password()
            .then(() => {
              this.active_step = 2;
            })
            .catch(() => {
            });
      }
    }
  }
};
</script>

<style>
.is-mobile .forgot-password {
  height: 100%;
}

.is-mobile .forgot-password .v-card__title {
  background-size: cover;
  background-position: bottom;
  background-image: url("~@/assets/mobile/background-swoosh.svg");
  padding-bottom: 18%;
}

.is-mobile .forgot-password .logo-wrapper {
  line-height: 0;
  text-align: right;
}

.is-mobile .forgot-password .logo {
  height: 35px;
}

.is-mobile .forgot-password .v-card__title .header {
  font-size: 18px;
  color: #fff;
  text-align: center;
  word-break: break-word;

  margin-top: 28px;
  margin-bottom: 16px;
}

.is-mobile .forgot-password .c-label {
  line-height: 2.5;
  font-size: 18px;
  color: #000;
}

.is-mobile .forgot-password .go-back {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.is-mobile .forgot-password .instructions {
  color: #000;
  font-size: 19px;
  line-height: 1.5;
}

.is-mobile .forgot-password .btn-submit {
  width: 100%;
}
</style>
