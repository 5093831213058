<template>
  <v-card elevation="0">
    <v-card-title>
      <span>Quickbooks Online Settings</span>
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="tab" fixed-tabs background-color="primary" dark>

        <!-- TABS -->
        <v-tab href="#tab-1">Deposit Account</v-tab>
        <v-tab href="#tab-2">Items</v-tab>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab">

          <!-- PAYMENT METHOD - DEPOSIT ACCOUNT MATCH -->
          <v-tab-item value="tab-1" class="pa-lg-12 pa-4">
            <v-card flat>

              <v-card-title class="mb-8">
                <span>Assign a Bank Account where Quickbooks should record your deposits</span>
              </v-card-title>

              <v-card-text>

                <!-- MATCHING -->
                <v-row dense>
                  <v-col>Quickbooks Account:</v-col>
                  <v-col>
                    <v-select
                        v-model="account"
                        :items="accounts"
                        clearable
                        dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn @click="saveDepositAccounts" color="primary" :disabled="loading"> Save</v-btn>
              </v-card-actions>

            </v-card>
          </v-tab-item>

          <!-- ITEMS -->
          <v-tab-item value="tab-2">
            <span>WORK IN PROGRESS</span>
          </v-tab-item>

        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import {ADD_MESSAGE} from "@/store/mutation-types";

export default {
  name: "QuickbooksOnlineSettings",
  components: {},
  data() {
    return {
      tab: null,
      accounts: [],
      paymentMethods: [],
      account: {},
      loading: false
    };
  },

  mounted() {
    const vm = this;
    api.integrations.fetchQBOAccounts()
        .then(rs => {
          vm.accounts = rs.data.filter(acct => acct.account_type === "Bank").map(acct => {
            return {text: `${acct.name} - ${acct.account_subtype}`, value: acct.id};
          });
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Error loading Quickbooks accounts"});
        });

    api.integrations.fetchQBOPaymentDepositAccount()
        .then(rs => {
          const data = rs.data;
          vm.account = data.bank_account_id;
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Error loading current accounts"});
        });
  },

  methods: {

    saveDepositAccounts() {
      this.loading = true;

      api.integrations.saveQBOPaymentDepositAccount({bank_account_id: this.account})
          .then(() => {
            this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Accounts updated"});
          })
          .catch(() => {
            this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Error updating accounts"});
          })
          .finally(() => this.loading = false);

    }

  },

};
</script>
