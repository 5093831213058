<template>
  <v-card>
    <PageTitle />

    <v-row>
      <v-col cols="12">
        <v-card-text>
          <div>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="isLoading"
              :footer-props="{
                'items-per-page-options': itemsPerPageOptions,
              }"
              :options.sync="tableOptions"
              :server-items-length="totalItems"
              dense
              multi-sort
              disable-filtering
            >
              <template v-slot:item.actions="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click.native.stop>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-icon left>mdi-mail</v-icon>
                      Email
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="onClickDetailItem(item)">Summary</v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <!-- ORDER FORM -->
            <quote-details ref="quote_details" :is-customer="true" />
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PageTitle from "@/bcore/components/PageTitle.vue";
import DataTableMixin from "@/mixins/dataTable";
import DateMixin from "@/mixins/date";
import PermissionMixin from "@/mixins/permission";

import QuoteDetails from "@/point-of-sale/components/quote/QuoteDetails.vue";

export default {
  name: "CustomerQuotes",

  components: {
    QuoteDetails,
    PageTitle
  },

  mixins: [DataTableMixin, DateMixin, PermissionMixin],

  data() {
    return {
      formLoading: false,
      formOpened: false,
      displayFilters: false,

      deleteConfirmOpen: false,
      idDeleteItem: -1,

      currentItem: null,
      entity: {},
      headers: [
        { text: "Quote no", value: "reference_no", sortable: true },
        { text: "Supplier", value: "owner_entity.name", sortable: true },
        { text: "Status ", value: "status", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.$store.state.customerQuote.list;
    },

    isLoading() {
      return this.$store.getters["customerQuote/loading"]("list");
    }
  },

  methods: {
    fetchItems() {
      this.$store.dispatch("customerQuote/list");
    },

    onClickNewButton() {
      this.$refs.quote_form.open({});
    },

    onClickListItem(item) {
      this.$refs.quote_form.open(item);
    },

    onClickDetailItem(item) {
      this.$refs.quote_details.open(item);
    }
  }
};
</script>
