import { http } from "@/vuex-bcore";

const url = "pos/price-overrides";

// fetch all POs for the current org
function fetch() {
  return http.get(`${url}/`);
}

function fetch_details(price_override_id) {
  return http.get(`${url}/${price_override_id}/`);
}

function create(price_override) {
  return http.post(`${url}/`, price_override);
}

function update(price_override) {
  return http.patch(`${url}/${price_override.id}/`, price_override);
}

function remove(price_override_id) {
  return http.delete(`${url}/${price_override_id}/`);
}

function search(name) {
  const payload = {
    search: name
  };

  return http.get(`${url}/`, { params: payload });
}

function fetch_customers(price_override_id) {
  return http.get(`${url}/${price_override_id}/customers/`);
}

function add_customers(price_override_id, payload) {
  return http.patch(`${url}/${price_override_id}/customers/`, payload);
}

export default {
  fetch,
  create,
  update,
  remove,
  search,
  fetch_details,
  fetch_customers,
  add_customers
};
