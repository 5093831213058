import Orders from "@/point-of-sale/components/order/Orders.vue";
import Quotes from "@/point-of-sale/components/quote/Quotes.vue";
import CustomerQuotes from "@/point-of-sale/components/customer-quote/CustomerQuotes.vue";
import Deliveries from "@/point-of-sale/components/delivery/Deliveries.vue";
import Delivery from "@/point-of-sale/components/delivery/Delivery.vue";
import DeliverySummary from "@/point-of-sale/components/delivery/DeliverySummary.vue";
import DeliveryInterval from "@/point-of-sale/components/deliveryInterval/DeliveryInterval.vue";
import DeliveryDetails from "@/point-of-sale/components/delivery/DeliveryDetails.vue";
import PriceOverride from "@/point-of-sale/components/price-override/PriceOverrides.vue";
import PriceOverrideDetails from "@/point-of-sale/components/price-override/PriceOverrideDetails.vue";
import Truck from "@/point-of-sale/components/truck/Trucks.vue";
import Driver from "@/point-of-sale/components/driver/Drivers.vue";
import Tickets from "@/point-of-sale/components/ticket/Tickets.vue";
import TicketForm from "@/point-of-sale/components/ticket/TicketForm.vue";

const posRoutes = [
  {
    path: "/organization/orders",
    name: "orders",
    component: Orders,
    props: { modelName: "order" },
    meta: { requiresAuth: true, pageTitle: "Orders", noSuperuser: true }
  },
  {
    path: "/organization/quotes",
    name: "quotes",
    component: Quotes,
    props: { modelName: "quote" },
    meta: { requiresAuth: true, pageTitle: "Quotes", noSuperuser: true }
  },
  {
    path: "/organization/quote/:quoteId/delivery",
    name: "quote-delivery",
    component: Delivery,
    props: { modelName: "quote" },
    meta: { requiresAuth: true, pageTitle: "Quote Delivery", noSuperuser: true }
  },
  {
    path: "/organization/quotes/:quoteId/delivery",
    name: "quote-delivery-summary",
    component: DeliverySummary,
    props: { isQuote: true },
    meta: { requiresAuth: true, pageTitle: "Quote Delivery Summary", noSuperuser: true }
  },
  {
    path: "/organization/orders/:orderId/delivery",
    name: "order-delivery-summary",
    component: DeliverySummary,
    props: { isQuote: false },
    meta: { requiresAuth: true, pageTitle: "Order Delivery Summary", noSuperuser: true }
  },
  {
    path: "/organization/order/:orderId/delivery",
    name: "order-delivery",
    component: Delivery,
    props: { modelName: "order" },
    meta: { requiresAuth: true, pageTitle: "Order Delivery", noSuperuser: true }
  },
  {
    path: "/organization/customer-quotes",
    name: "customer-quotes",
    component: CustomerQuotes,
    props: { modelName: "customerQuote" },
    meta: { requiresAuth: true, pageTitle: "Quotes", noSuperuser: true }
  },
  {
    path: "/organization/deliveries",
    name: "deliveries",
    component: Deliveries,
    props: { modelName: "delivery" },
    meta: { requiresAuth: true, pageTitle: "Deliveries", noSuperuser: true }
  },
  {
    path: "/organization/deliveries/:id",
    name: "delivery-details",
    component: DeliveryDetails,
    meta: { requiresAuth: true, noSuperuser: true }
  },
  {
    path: "/organization/delivery/:deliveryId/intervals",
    name: "delivery-interval",
    component: DeliveryInterval,
    meta: { requiresAuth: true, pageTitle: "Delivery Interval", noSuperuser: true }
  },
  {
    path: "/organization/price-override",
    name: "price-override",
    component: PriceOverride,
    props: { modelName: "priceOverride" },
    meta: { requiresAuth: true, noSuperuser: true }
  },
  {
    path: "/organization/price-override/:id",
    name: "price-override-details",
    component: PriceOverrideDetails,
    meta: { requiresAuth: true, noSuperuser: true }
  },
  {
    path: "organization/trucks",
    name: "trucks",
    component: Truck,
    props: { modelName: "truck" },
    meta: { requiresAuth: true, pageTitle: "Trucks", noSuperuser: true }
  },
  {
    path: "organization/drivers",
    name: "drivers",
    component: Driver,
    props: { modelName: "driver" },
    meta: { requiresAuth: true, pageTitle: "Drivers", noSuperuser: true }
  },
  {
    path: "/organization/tickets",
    name: "tickets",
    component: Tickets,
    props: {},
    meta: { requiresAuth: true, noSuperuser: true },
  },
  {
    path: "/organization/tickets/new",
    name: "new-ticket-form",
    component: TicketForm,
    props: {},
    meta: { requiresAuth: true, noSuperuser: true },
  },
];

export default posRoutes;
