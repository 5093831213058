<template>
  <v-card class="app-integrations" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      {{ pageTitle }}
    </v-card-title>

    <quick-books-desktop form-model="webConnectorAccount" />

    <quick-books-online />

    <integration-xero />
  </v-card>
</template>

<script>
import QuickBooksDesktop from "@/integrations/components/quickbooks-desktop/QuickBooksDesktop.vue";
import QuickBooksOnline from "@/integrations/components/quickbooks-online/QuickBooksOnline.vue";
import IntegrationXero from "@/integrations/components/xero/Xero.vue";

import PageTitle from "@/mixins/pageTitle";

export default {
  name: "Integrations",

  components: {
    QuickBooksDesktop,
    QuickBooksOnline,
    IntegrationXero
  },

  mixins: [PageTitle]
};
</script>
