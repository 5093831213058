<template>
  <responsive-dialog
    id="item-editor"
    dialog-class="rounded-0"
    :display-dialog="display_dialog"
    :dialog-title="form_title"
    @closed="close"
  >
    <v-container>
      <v-row>
        <v-col class="pt-2 pb-2">
          <v-text-field
            name="InvoiceItem[Description]"
            label="Description"
            v-model="form.fields.description"
            hide-details
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="align-content-center">
          <v-text-field
            name="InvoiceItem[Name]"
            label="Price"
            type="number"
            prefix="$"
            v-model="form.fields.price"
            :step="0.01"
            :min="0"
            hide-details
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            name="InvoiceItem[Quantity]"
            label="Quantity"
            type="number"
            v-model="form.fields.qty"
            :step="0.01"
            :min="0"
            hide-details
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            name="InvoiceItem[Total]"
            label="Total"
            v-model="total_formatted"
            hide-details
            outlined
            readonly
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <v-btn color="primary" class="continue d-block" style="width: 100%" :loading="is_loading" @click="submit" large>
        ADD
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import currency from "@/utilities/currency.js";

export default {
  name: "ItemEditor",

  components: {
    ResponsiveDialog
  },

  props: {},

  data() {
    return {
      is_loading: false,
      display_dialog: false,

      form: {
        fields: {
          id: null,
          description: "",
          qty: 0.0,
          price: 0.0,
          total: 0.0
        }
      }
    };
  },

  computed: {
    form_title() {
      return "New item or service";
    },

    total_calculated() {
      let price = this.form.fields.price;
      let quantity = this.form.fields.qty;
      let amount = 0.0;

      if (price && quantity) {
        amount = Number(price) * Number(quantity);
      }

      return amount;
    },

    total_formatted() {
      return currency.format(this.total_calculated);
    },

    is_update() {
      return !!this.form.fields.id;
    }
  },

  methods: {
    new() {
      this.display_dialog = true;
      this.clear_form();
    },

    open(item) {
      this.display_dialog = true;
    },

    close() {
      this.display_dialog = false;
      this.$emit("closed");
    },

    clear_form() {
      let fields = this.form.fields;
      fields.description = "";
      fields.price = 0;
      fields.qty = 0;
    },

    submit() {
      let fields = this.form.fields;
      let is_update = this.is_update;
      let action = is_update ? "update_item" : "add_item";

      fields.total = this.total_calculated;

      this.$emit(action, Object.assign({}, fields));
      this.close();
    }
  }
};
</script>

<style></style>
