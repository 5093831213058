import Vue from "vue";
import VueGtm from "vue-gtm";
import Router from "vue-router";

import ServerError from "@/views/ServerError";
import Main from "@/Main.vue";

import router from "@/router.js";
import store from "@/store";
import vuetify from "@/plugins/vuetify.js";
import Bcore from "@/vuex-bcore/bcore.js";

import app from "@/app";

import "./register-service-worker.js";

Vue.config.productionTip = false;

Vue.use(Router);

Vue.use(Bcore, { store });

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID || [],
  defer: false,
  enabled: Boolean(process.env.VUE_APP_GTM_ID),
  debug: false,
  loadScript: Boolean(process.env.VUE_APP_GTM_ID),
  vueRouter: router,
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

function mount_app() {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(Main)
  }).$mount("#app");
}

function mount_server_error() {
  return new ServerError({ el: "#app" });
}

app.user
  .detect()
  .then(mount_app)
  .catch(exception => {
    let status_code = exception && exception.response ? exception.response.status : 0;

    if (status_code && [429, 502, 500].includes(status_code)) {
      let error_view = mount_server_error();
      return error_view.set_http_status(status_code);
    }

    mount_app();
  });
