<template>
  <v-card v-if="initialize" v-show="displayFilters" class="apr-filters elevation-0" outlined>
    <v-card-title class="d-flex">
      <span class="mr-auto">Filters</span>
      <v-btn text class="btn-close" @click.prevent="close" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-form @submit.prevent="applyFilters">
      <!-- FIRST ROW -->
      <v-row>
        <v-col cols="3">
          <v-text-field
            name="Filter[CompanyName]"
            v-model="form.data.company_name"
            label="Company"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            name="Filter[InvoiceNumber]"
            v-model.number="form.data.invoice_number"
            label="Invoice Number"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            name="Filter[PONumber]"
            v-model.number="form.data.po_number"
            label="PO Number"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            name="Filter[Terms]"
            v-model="form.data.terms"
            type="number"
            step="1"
            label="Terms (Days)"
            messages="Due on receipt = 0"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- SECOND ROW -->
      <div>Amount</div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            name="Filter[TotalMin]"
            v-model.number="form.data.total_min"
            type="number"
            label="From"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            name="Filter[TotalMax]"
            v-model.number="form.data.total_max"
            type="number"
            label="To"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <EmployeeSelector name="Filter[EmployeeName]" v-model="form.data.assignees" type="number" outlined dense />
        </v-col>

        <v-col cols="3">
          <v-select
            name="Filter[InvoiceStatus]"
            v-model="form.data.status_in"
            :items="allStatusOptions"
            label="Status"
            class="apr-filter-status"
            filled
            multiple
            clearable
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <div v-if="index === 0" class="v-select__selection v-select__selection--comma">{{ item.text }}</div>
              <div v-if="index === 1" class="v-select__selection grey--text caption">
                <!-- FORMAT -->
                (+{{ form.data.status_in.length - 1 }} others)
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- THIRD ROW -->
      <v-row class="third-row">
        <v-col cols="3">
          <div>Date Approved</div>
          <date-range-input
            :start-date="form.data.approved_at_min"
            :end-date="form.data.approved_at_max"
            dense
            @updated="updateApprovedAt"
          />
        </v-col>

        <v-col cols="3">
          <div>Date Received</div>
          <date-range-input
            :start-date="form.data.created_at_min"
            :end-date="form.data.created_at_max"
            dense
            @updated="updateCreatedAt"
          />
        </v-col>

        <v-col cols="3">
          <div>Last Updated</div>
          <date-range-input
            :start-date="form.data.modified_at_min"
            :end-date="form.data.modified_at_max"
            dense
            @updated="updateModifiedAt"
          />
        </v-col>

        <v-col cols="3" class="d-flex justify-end">
          <div class="mt-9">
            <v-btn class="btn-reset" @click.prevent="clearFilters">Clear</v-btn>
            <v-btn class="ml-2" color="secondary" :disabled="!isDirty" @click.prevent="applyFilters">Apply</v-btn>
          </div>
        </v-col>
      </v-row>

      <button v-show="false" type="submit" />
    </v-form>
  </v-card>
</template>

<script>
import DateRangeInput from "@/bcore/components/DateRangeInput.vue";
import EmployeeSelector from "@/invoiceboost/components/employee/EmployeeSelector.vue";

import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import invoiceStatusMixin from "@/mixins/invoiceStatus";
import filterCountMixin from "@/mixins/filterCount";

function createDataModel() {
  return {
    company_name: "",
    invoice_number: "",
    po_number: "",
    terms: "",

    total_min: "",
    total_max: "",

    assignees: [],
    status_in: [],

    approved_at_min: "",
    approved_at_max: "",

    created_at_min: "",
    created_at_max: "",

    modified_at_min: "",
    modified_at_max: ""
  };
}

export default {
  name: "InvoiceFilters",

  components: {
    DateRangeInput,
    EmployeeSelector
  },

  mixins: [trackDirtyFieldsMixin, invoiceStatusMixin, filterCountMixin],

  props: {
    account: { type: String, default: "payable" }
  },

  data() {
    return {
      initialize: false,
      displayFilters: false,

      form: {
        initialData: createDataModel(),
        data: createDataModel()
      }
    };
  },

  mounted() {
    this.trackDirtyFields();
  },

  methods: {
    open() {
      this.initialize = true;
      this.displayFilters = true;
      return this;
    },

    close() {
      this.displayFilters = false;
      this.$emit("closed");
      return this;
    },

    isOpen() {
      return this.displayFilters;
    },

    applyFilters() {
      const filters = this.form.data;
      const filterObject =
        this.account === "payable" ? { supplier_name: filters.company_name } : { customer_name: filters.company_name };

      this.$emit("change", Object.assign(filterObject, filters));

      //set initial data equal to the current data so that the form isn't dirty and the button is disabled
      this.form.initialData = Object.assign({}, filters);
    },

    clearFilters() {
      this.form.data = createDataModel();
    },

    updateApprovedAt(value) {
      const { startDate, endDate } = value;
      const formData = this.form.data;

      formData.approved_at_min = startDate;
      formData.approved_at_max = endDate;
    },

    updateCreatedAt(value) {
      const { startDate, endDate } = value;
      const formData = this.form.data;

      formData.created_at_min = startDate;
      formData.created_at_max = endDate;
    },

    updateModifiedAt(value) {
      const { startDate, endDate } = value;
      const formData = this.form.data;

      formData.modified_at_min = startDate;
      formData.modified_at_max = endDate;
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button

      this.trackDirtyField("form.data.company_name", "form.initialData.company_name");
      this.trackDirtyField("form.data.invoice_number", "form.initialData.invoice_number");
      this.trackDirtyField("form.data.po_number", "form.initialData.po_number");
      this.trackDirtyField("form.data.terms", "form.initialData.terms");

      this.trackDirtyField("form.data.total_min", "form.initialData.total_min");
      this.trackDirtyField("form.data.total_max", "form.initialData.total_max");

      this.trackDirtyField("form.data.assignees", "form.initialData.assignees");
      this.trackDirtyField("form.data.status_in", "form.initialData.status_in");

      this.trackDirtyField("form.data.approved_at_min", "form.initialData.approved_at_min");
      this.trackDirtyField("form.data.approved_at_max", "form.initialData.approved_at_max");

      this.trackDirtyField("form.data.created_at_min", "form.initialData.created_at_min");
      this.trackDirtyField("form.data.created_at_max", "form.initialData.created_at_max");

      this.trackDirtyField("form.data.modified_at_min", "form.initialData.modified_at_min");
      this.trackDirtyField("form.data.modified_at_max", "form.initialData.modified_at_max");
    }
  }
};
</script>

<style>
.apr-filters {
  padding-left: 16px;
  padding-right: 16px;

  background-color: #0001 !important;
}

.apr-filters .v-card__title {
  padding-left: 0;
  padding-right: 0;
}

.apr-filters form {
  margin-top: 16px;
}

.apr-filters form .v-input__control fieldset,
.apr-filters form .btn-reset {
  background-color: #ffffff !important;
}

.apr-filters .third-row .v-text-field__details {
  display: none;
}

.apr-filters .apr-filter-status .v-text-field__details {
  display: none;
}
</style>
