<template>
  <v-card class="bills-payment-methods" elevation="0">
    <!-- TITLE -->
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <span>Payment Methods</span>

      <v-spacer />
    </v-card-title>

    <v-tabs v-model="tab">
      <v-tab href="#tab-credit-cards">Credit Cards</v-tab>
      <v-tab href="#tab-ach-accounts">ACH Accounts</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <!-- CREDIT CARD MANAGER TAB -->
      <v-tab-item value="tab-credit-cards">
        <credit-card-manager />
      </v-tab-item>

      <!-- ACH ACCOUNT MANAGER TAB -->
      <v-tab-item value="tab-ach-accounts">
        <ach-account-form />
      </v-tab-item>

    </v-tabs-items>

  </v-card>
</template>

<script>
import CreditCardManager from "@/invoiceboost/components/payment-method/CreditCardManager.vue";
import AchAccountForm from "@/invoiceboost/components/payment-method/AchAccountForm";

export default {
  name: "APPaymentMethods",

  components: {
    CreditCardManager,
    AchAccountForm
  },


  data() {
    return {
      tab: "tab-credit-cards"
    };
  },

  computed: {},

  methods: {}
};
</script>
