<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="identicon" v-html="identicon"></div>
</template>

<script>
import Vue from "vue";
import { toSvg } from "jdenticon";

export default Vue.extend({
  name: "Identicon",

  props: { iconString: { type: String, default: "default" }, iconSize: { type: Number, default: 100 } },

  computed: {
    identicon() {
      return toSvg(this.iconString, this.iconSize);
    }
  }
});
</script>
