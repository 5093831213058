<template>
  <v-card tile outlined class="ma-5">
    <v-card-text>
      <v-row>
        <v-col cols="6" class="d-flex align-center justify-center flex-wrap">
          <v-img
              v-if="connected"
              class="white--text align-end"
              contain
              height="100px"
              :src="require('@/assets/integrations/quickbooks_online_logo.png')"
          />
          <v-img
              v-else
              class="white--text align-end"
              contain
              height="100px"
              :src="require('@/assets/integrations/quickbooks_online_logo_grayscale.png')"
          />
        </v-col>

        <v-col cols="6" class="d-flex align-center justify-center">
          <v-list dense>

            <v-list-item>

              <!-- MENU ITEM: CONNECT/DISCONNECT -->
              <v-menu v-if="!connected" bottom offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey" tile v-on="on">
                    NOT CONNECTED |
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="connect">
                    <v-icon color="green" right>mdi-power-plug</v-icon>
                    Connect
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu v-else bottom offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="green" tile v-on="on">
                    CONNECTED |
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="disconnect">
                    <v-icon color="red" right>mdi-power-plug-off</v-icon>
                    Disconnect
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>

            <v-list-item>

              <!-- MENU ITEM: SETTINGS -->
              <v-list-item v-if="connected">
                <v-list-item-title>
                  <v-btn class="mt-6" outlined color="danger" @click="navigateToSettings">
                    <v-icon left>mdi-tune</v-icon>
                    Settings
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import api from "@/api";
import settingsMixin from "@/mixins/settings";

export default Vue.extend({
  name: "QuickBooksOnline",

  mixins: [settingsMixin],

  data() {
    return {};
  },

  computed: {
    connected() {
      const accountingPackageConnected = this.mySettings.accounting_package_connected;
      return !!accountingPackageConnected.QBO;
    }
  },

  mounted() {
  },

  methods: {
    connect() {
      api.integrations.getAuthorizationURL().then(function (response) {
        window.location.href = response.data.url;
      });
    },

    disconnect() {
      // TODO: follow up on this
      alert("We're working on it...");
    },

    navigateToSettings() {
      this.$router.push({name: 'organization-qbo-settings'});
    }
  }
});
</script>
