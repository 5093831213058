<template>
  <component :is="autopay_component" :account="account" :target-id="targetId"/>
</template>

<script>
import AutoPayRestricted from "@/invoiceboost/components/auto-pay/AutoPayRestricted";
import AutoPayUnrestricted from "@/invoiceboost/components/auto-pay/AutoPayUnrestricted";

export default {
  name: "AutoPay",
  props: { targetId: { type: Number, default: 0 }, account: { type: String, default: "payable" } },
  computed: {
    has_premium_customer_restrictions() {
      return this.$store.state.auth.entity.has_premium_customer_restrictions;
    },
    autopay_component() {
      return this.has_premium_customer_restrictions ? AutoPayRestricted : AutoPayUnrestricted;
    }
  }
};
</script>

<style scoped>

</style>