var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"org-transactions",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"display-1 font-weight-thin justify-space-between"},[_c('span',[_vm._v("Transactions")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"search-field align-self-center mr-4",attrs:{"placeholder":"customer, Invoice #, Etc.","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.tableFilters.search),callback:function ($$v) {_vm.$set(_vm.tableFilters, "search", $$v)},expression:"tableFilters.search"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"options":_vm.tableOptions,"server-items-length":_vm.totalItems,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions,
      },"dense":"","multi-sort":"","disable-filtering":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.displayDetails},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.formatCurrency(item.amount, item.currency)))]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.created_at)))]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.preventDefault();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-page-next-outline")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }