<template>
  <!-- SEARCH ALL COUNTRIES -->
  <v-autocomplete
    v-model="value"
    :name="name"
    :label="label"
    :items="items"
    :loading="isLoading"
    :search-input.sync="itemSearch"
    :error-messages="errorMessages"
    :disabled="disabled"
    :required="required"
    :clearable="clearable"
    item-text="name"
    item-value="id"
    placeholder="Start typing to Search"
    outlined
    hide-no-data
    return-object
    autocomplete="off"
    @change="change"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.alpha3_code }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import api from "@/api";

const getDefaultFieldArray = () => {
  return [];
};

export default {
  name: "CountrySearch",

  props: {
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    fieldErrors: { type: Array, default: getDefaultFieldArray },
    initialValue: { type: Number, default: 0 },
    name: { type: String, default: "" },
    label: { type: String, default: "Country" }
  },

  data() {
    return {
      itemSearch: "",
      value: 0,

      isLoading: false,
      items: [],
      internalErrors: []
    };
  },

  computed: {
    errorMessages() {
      const fieldErrors = this.fieldErrors;

      if (fieldErrors && fieldErrors.length) {
        return fieldErrors;
      }

      return this.internalErrors;
    }
  },

  watch: {
    // TODO: verify usage of this component on the codebase and determine how initialValue is being used.
    // setting initial value on the fly would cause the user's current selection to be lost
    // if that's the case then move this to mounted event and/or add a specific method like .select( value );
    initialValue(value) {
      this.value = value;
    }
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      this.loading = true;

      return api.countries
        .fetch()
        .then(responde => {
          const countries = responde.data;

          this.items = countries;
        })
        .catch(error => {
          this.internalErrors = ["Unable to fetch Countries."];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    change(value) {
      this.$emit("change", value);
    },

    select(value) {
      this.value = value;
    }
  }
};
</script>
