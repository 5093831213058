import { ADD_MESSAGE } from "@/store/mutation-types";

const copyToClipBoardMixin = {
  methods: {
    async copyToClipBoard(data) {
      // TODO: Navigator is not well supported uncomment this and get it working for better browser support
      // const textarea = document.createElement("textarea");
      //
      // textarea.setAttribute("readonly", "");
      // textarea.style.position = "absolute";
      // textarea.style.left = "-9999px";
      // textarea.value = data;
      // document.body.appendChild(textarea);
      // textarea.select();
      // const success = document.execCommand("copy");
      // const message = success ? { text: "Copied!", type: "success" } : { text: "Copied Failed!", type: "error" };
      // document.body.removeChild(textarea);
      let message = { text: "Copied to clipboard!", type: "success" };
      try {
        await navigator.clipboard.writeText(data);
      } catch (e) {
        message = { text: "Copied Failed!", type: "error" };
      }
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
    }
  }
};

export default copyToClipBoardMixin;
