<template>
  <keep-alive>
    <component v-if="entity" :is="active_component" :context="context"></component>
  </keep-alive>
</template>

<script>
// import MainLayout from "@/views/layouts/MainLayout.vue";
import HomeMobile from "./HomeMobile.vue";
import viewport from "@/mixins/viewport";

export default {
  name: "Home",

  components: {
    HomeMobile
  },

  mixins: [viewport],

  computed: {
    context() {
      return this;
    },

    active_component() {
      // return this.viewport.is_desktop ? HomeDesktop : HomeMobile;
      return HomeMobile;
    },

    entity() {
      return this.$store.state.auth.entity;
    },

    // TODO: entity_first_name needs improvements
    entity_first_name() {
      let entity = this.entity;
      let name = entity.name;

      if (!entity || !name) {
        return "";
      }

      return name.split(" ")[0];
    },

    // TODO: entity_nitials needs improvements
    entity_nitials() {
      let entity = this.entity;
      let name = entity.name;

      if (!entity || !name) {
        return "";
      }

      let initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

      return initials.join("").toUpperCase();
    }
  }
};
</script>
