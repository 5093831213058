<template>
  <login-layout>
    <keep-alive>
      <component :is="active_component" :context="context"></component>
    </keep-alive>
  </login-layout>
</template>

<script>
import LoginLayout from "@/views/layouts/LoginLayout.vue";
import ResetPasswordConfirmDesktop from "./ResetPasswordConfirmDesktop";
import ResetPasswordConfirmMobile from "./ResetPasswordConfirmMobile";

import api from "@/api";
import utils from "@/utilities";
import viewport from "@/mixins/viewport";

const is_empty = utils.is_empty;

export default {
  name: "ResetPasswordConfirm",

  components: {
    LoginLayout
  },

  mixins: [viewport],

  data() {
    return {
      show_password: false,
      is_loading: false,
      token: this.$route.query.token,

      form: {
        fields: {
          password: "",
          confirm_password: ""
        },

        rules: {
          recovery_code: [value => !!value || "Reset code is required."],
          password: [value => {
            if (!value || value.length < 8) {
              return "Must be at least 8 characters";
            }

            return true;
          }],

          confirm_password: [value => {
            let fields = this.form.fields;

            if (fields.password !== fields.confirm_password) {
              return "Passwords do not match";
            }

            return true;
          }],
        },

        field_errors: {},
        errors: []
      },

      formErrors: {}
    };
  },

  computed: {
    context() {
      return this;
    },

    active_component() {
      return this.viewport.is_desktop ? ResetPasswordConfirmDesktop : ResetPasswordConfirmMobile;
    }
  },

  methods: {
    goto(route_name) {
      this.$router.push({ name: route_name });
    },

    confirm_reset_password() {
      this.is_loading = true;

      this.reset_errors();

      let fields = this.form.fields;
      let payload = {
        token: this.token,
        password: fields.password
      };

      return api.users
          .reset_password_confirm(payload)
          .catch(exception => {
            this.set_form_errors(exception);
            throw exception;
          })
          .finally(() => {
            this.is_loading = false;
          });
    },

    reset_forms() {
      this.form.fields = {
        password: "",
        confirm_password: ""
      };
    },

    reset_errors() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    set_form_errors(exception) {
      let form = this.form;
      let field_errors = utils.form.get_field_errors(exception);
      let errors = utils.form.get_errors(exception);

      if (is_empty(field_errors) && is_empty(errors)) {
        errors = [
          {
            message: "There was an issue performing this action, if this persist please contact us.",
            type: "error"
          }
        ];
      }

      form.field_errors = field_errors;
      form.errors = errors;
    }
  }
};
</script>

<style></style>
