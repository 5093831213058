import http from "./http.js";
import BcoreVuexPlugin from "./plugin.js";

const install = (vue, options) => {
  // Set resource to bcore axios
  vue.prototype.$http = http;

  if (!options) return;

  const { store } = options;

  // Register the Bcore Vuex plugin
  const bcoreVuexPlugin = BcoreVuexPlugin();
  bcoreVuexPlugin(store);
};

export default install;
