import { State } from "./types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
// Mutation types
import { CLEAR_DIALOGS, CLOSE_DIALOG, OPEN_DIALOG } from "@/store/mutation-types";
import { RootState } from "@/vuex-bcore";

const state = {
  open: []
};

const getters: GetterTree<State, RootState> = {
  isOpen: state => (name: string): boolean => {
    // Check if the dialog is open
    return !!state.open.find(item => {
      return item === name;
    });
  }
};

const mutations: MutationTree<State> = {
  [OPEN_DIALOG](state, name: string) {
    const set = new Set([...state.open, ...[name]]);
    state.open = Array.from(set);
  },
  [CLOSE_DIALOG](state, name: string) {
    let open = [...state.open];
    for (let i = 0; i < open.length; i++) {
      if (open[i] === name) {
        open.splice(i);
      }
    }
    state.open = open;
  },
  [CLEAR_DIALOGS](state) {
    state.open = [];
  }
};

const actions: ActionTree<State, RootState> = {};

const dialog: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default dialog;
