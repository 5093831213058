<template>
  <div>
    <PageLoad v-if="is_loading" />
    <slot v-else />
  </div>
</template>

<script>
import PageLoad from "@/bcore/components/PageLoad.vue";
import { ADD_MESSAGE, LOADED, LOADING, SET_CUSTOM_DOMAIN } from "@/store/mutation-types";
import api from "@/api";

export default {
  name: "Theme",

  components: { PageLoad },

  data() {
    return {
      is_loading: true
    };
  },

  computed: {
    theme() {
      return this.$store.state.theme;
    },

    host_name() {
      return window.location.hostname;
    },

    entity() {
      return this.$store.state.auth.entity;
    }
  },

  async mounted() {
    this.$store.commit(`theme/${LOADING}`);

    try {
      // Load custom domain theme
      await this.loadTheme();

      let colorScheme = this.theme.customDomain.colorScheme;
      if (typeof colorScheme === "string") {
        colorScheme = JSON.parse(colorScheme);
      }

      if (colorScheme) {
        colorScheme.light && (this.$vuetify.theme.themes.light = colorScheme.light);
        colorScheme.dark && (this.$vuetify.theme.themes.dark = colorScheme.dark);
      }
    } catch (e) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, {
        text: "An error occurred while loading the organization ",
        type: "error"
      });
    }

    this.$store.commit(`theme/${LOADED}`);
    this.is_loading = false;
  },

  methods: {
    async loadTheme() {
      const customDomain = this.theme.customDomain;

      if (customDomain.name !== this.host_name) {
        this.$store.commit(`theme/${SET_CUSTOM_DOMAIN}`, { ...customDomain, name: this.host_name });
        const domainTheme = await this.fetchDomainTheme();

        domainTheme &&
          this.$store.commit(`theme/${SET_CUSTOM_DOMAIN}`, {
            ...customDomain,
            ...domainTheme
          });
      }
    },

    async fetchDomainTheme() {
      try {
        const response = await api.premium.domain.fetch_theme(this.host_name);
        return response.data;
      } catch (e) {
        return null;
      }
    }
  }
};
</script>
