<template>
  <div>
    <v-form @submit.prevent="onFormSubmit">
      <responsive-dialog :display-dialog="formOpened" :dialog-title="formTitle" @closed="closeForm">
        <!-- DISPLAY GENERIC ERRORS -->
        <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>

        <v-row id="order-items">
          <v-col cols="12" v-if="itemsLoaded">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Picked</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <delivery-interval-item-form
                    v-for="item in orderItems"
                    :key="item.id"
                    :item="item"
                    @updated="onItemsUpdate"
                  />
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <template slot="actions">
          <v-spacer />
          <v-btn type="submit" color="primary" text :loading="formLoading" @click="onFormSubmit">
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
        </template>
      </responsive-dialog>
    </v-form>
  </div>
</template>

<script>
import mixins from "vue-typed-mixins";

import DeliveryIntervalItemForm from "@/point-of-sale/components/deliveryInterval/DeliveryIntervalItemForm";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import { ADD_MESSAGE } from "@/store/mutation-types";
import dateMixin from "@/mixins/date";
import { parseFormErrors } from "@/utilities/form";

export default mixins(dateMixin).extend({
  name: "DeliveryIntervalPickItems",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    DeliveryIntervalItemForm
  },

  data() {
    return {
      formOpened: false,
      itemsLoaded: false,
      orderItems: [],

      formErrors: {},
      formCleaned: {
        selectedItems: []
      },

      form: {
        selectedItems: []
      }
    };
  },

  computed: {
    formLoading() {
      const action = this.$store.getters["order/loading"];

      return action("create") || action("update") || action("detail");
    },

    formTitle() {
      return this.isItemUpdate ? `Update Item Selection` : `New Item Selection`;
    },

    formMessages() {
      let messages = [];

      if (this.formMessage) {
        messages.push(this.formMessage);
      }

      return messages;
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.form.id;
    },

    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    }
  },

  created() {
    this.form = { ...this.formCleaned };
  },

  watch: {
    items: function (newVal, oldVal) {
      this.items = newVal;
    }
  },

  methods: {
    open(items) {
      if (items) {
        this.orderItems = items;
        this.itemsLoaded = true;
        this.formOpened = !!items;
      }
    },
    resetFormErrors() {
      this.formErrors = {};
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async onFormSubmit() {
      this.resetFormErrors();

      this.$emit("updated", this.form);
      this.closeForm();
    },

    closeForm() {
      this.formOpened = false;
      this.resetFormErrors();
      this.$emit("closed");
    },

    onItemsUpdate(item) {
      const elementsIndex = this.form.selectedItems.findIndex(element => element.id === item.item.id);
      //if item.interval_qty > 0 add
      if (item.interval_qty > 0) {
        if (elementsIndex > -1) {
          this.form.selectedItems[elementsIndex].interval_qty = item.interval_qty;
        } else {
          this.form.selectedItems.push(item);
        }
      } else {
        // else remove it from selectedItems
        if (elementsIndex > -1) {
          this.form.selectedItems.splice(elementsIndex, 1);
        }
      }
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
});
</script>

<style scoped>
#order-items {
  min-height: 200px;
}
</style>
