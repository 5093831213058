<template>
  <responsive-dialog :display-dialog="displayDialog" :dialog-title="dialogTitle" @closed="close">
    <v-form ref="form" v-model="form.isValid" :lazy-validation="false" @submit.prevent="submitForm">
      <v-container class="pa-0">
        <!-- DISPLAY GENERIC ERRORS -->
        <non-field-errors :errors="form.errors.non_field_errors"></non-field-errors>

        <!-- DISPLAY FORM NOTIFICATIONS -->
        <v-row v-if="isClaimed || !hasEmail">
          <v-col cols="12">
            <v-alert type="info" border="left" elevation="2" class="mb-0" :icon="false" colored-border>
              <ul>
                <li v-if="isClaimed">
                  <!-- NOTIFY WHEN THE ACCOUNT HAS BEEN CLAIMED -->
                  <strong>You're viewing a claimed account</strong>
                </li>

                <li v-if="!hasEmail">
                  <!-- eslint-disable-next-line -->
                  <strong>E-mail invitation</strong>
                  requires an email address to send the invite.
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>

        <!-- FIELD: NAME -->
        <v-row>
          <v-col cols="12">
            <v-text-field
              name="Employee[Name]"
              label="Name*"
              hide-details="auto"
              v-model="form.data.name"
              :disabled="isLoading"
              :error-messages="form.errors.name"
              outlined
              required
            />
          </v-col>
        </v-row>

        <!-- FIELD: EMAIL -->
        <v-row>
          <v-col>
            <v-text-field
              name="Employee[Email]"
              label="Email"
              hide-details="auto"
              v-model="form.data.email"
              :rules="form.rules.email"
              :error-messages="form.errors.email"
              :disabled="isLoading"
              :messages="emailFieldMessage"
              @keyup="checkEmailEdition"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="isSuperuser">
          <v-col cols="12">
            <organization-search
              label="Owner*"
              :field-errors="form.errors.owner_entity"
              @updated="selectExisitingOwnerEntity"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <!-- SEND EMAIL INVITATION -->
      <v-col v-if="!isClaimed" cols="6">
        <v-switch
          class="mt-0"
          hide-details="auto"
          v-if="!isUpdate"
          v-model="switch_enable_send_invite"
          :label="inviteLabel"
          :loading="isSendingInvite"
          :disabled="!hasEmail"
        />

        <!-- <v-btn
              v-if="isUpdate && !isClaimed"
              color="secondary"
              :loading="isSendingInvite"
              :disabled="!hasEmail || !enableSendInvite"
              @click="sendInvite()"
              ountlined
              text
            >
              <v-icon left>mdi-send</v-icon>
              {{ inviteLabel }}
            </v-btn> -->
      </v-col>

      <v-spacer />
      <v-btn
        type="button"
        color="primary"
        :loading="isLoading"
        :disabled="isLoading || !isDirty"
        @click.prevent="submitForm"
        text
      >
        <v-icon>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";

import { ADD_MESSAGE } from "@/store/mutation-types";
import { parseFormErrors } from "@/utilities/form";

import api from "@/api";

function createDataModel(context) {
  const owner_entity = context || context.$store ? context.$store.state.auth.entity.id : 0;

  return {
    id: null,
    name: "",
    owner_entity: owner_entity,

    employee: {
      name: "",
      email: null,
      is_claimed: false
    }
  };
}

function createModelRules() {
  return {
    name: [value => !!value || "Name is required."],

    email: [
      value => {
        if (value && value.length > 0) {
          return /.+@.+\..+/.test(value) || "E-mail must be valid.";
        }

        return true;
      }
    ]
  };
}

export default Vue.extend({
  name: "EmployeeFormDialog",

  components: {
    NonFieldErrors,
    ResponsiveDialog
  },

  mixins: [trackDirtyFieldsMixin],

  data() {
    return {
      displayDialog: false,

      isLoading: false,

      inviteLabel: `Email invitation to join ${process.env.VUE_APP_NAME}`,
      isSendingInvite: false,
      enableSendInvite: true,

      emailFieldMessage: "",

      form: {
        isValid: false,

        data: createDataModel(this),
        initialData: createDataModel(this),

        rules: createModelRules(),
        errors: {}
      }
    };
  },

  computed: {
    /* COMPUTED MODELS/VALUES */

    switch_enable_send_invite: {
      get() {
        // the computed value should return false when the user doesn't have a valid email
        if (!this.hasEmail) {
          return false;
        }

        return this.enableSendInvite;
      },

      set(value) {
        this.enableSendInvite = !!value;
      }
    },

    dialogTitle() {
      return this.isUpdate ? `Update Employee` : `New Employee`;
    },

    /* VALIDATORS */

    isUpdate() {
      return !!this.form.data.id;
    },

    isClaimed() {
      return this.form.data.is_claimed;
    },

    formHasErrors() {
      return Object.keys(this.form.errors).length;
    },

    // TODO: this doesn't make sense, super users are logged out right after authenticating
    isSuperuser() {
      return this.$store.state.auth.user.is_superuser;
    },

    hasEmail() {
      return /.+@.+\..+/.test(this.form.data.email);
    },

    /* SHORTCUTS */
    settings() {
      return this.$store.state.entitySettings.detail;
    }
  },

  mounted() {
    this.trackDirtyFields();
  },

  methods: {
    new() {
      this.resetStates();
      this.resetValidation();

      // Reset form data
      this.form.initialData = createDataModel(this);
      this.form.data = createDataModel(this);

      this.displayDialog = true;
    },

    open(employee) {
      if (!employee) {
        return;
      }

      this.resetStates();
      this.resetValidation();

      // Clone data to prevent sharing state
      this.form.data = { ...employee };
      this.form.initialData = { ...employee };

      this.displayDialog = true;

      return this;
    },

    close() {
      this.displayDialog = false;
      this.$emit("closed");
      return this;
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetStates() {
      // Reset form states
      this.isSendingInvite = false;
      this.enableSendInvite = true;
      this.emailFieldMessage = "";

      return this;
    },

    resetForm() {
      // Reset form fields/values
      const $form = this.$refs.form;

      if ($form) {
        $form.reset();
      }

      return this;
    },

    resetValidation() {
      this.form.errors = {};

      const $form = this.$refs.form;
      if ($form) {
        $form.resetValidation();
      }

      return this;
    },

    submitForm() {
      const isUpdate = this.isUpdate;
      const action = isUpdate ? "updated" : "created";
      const promise = isUpdate ? this.updateEmployee() : this.createEmployee();

      this.isLoading = true;

      promise
        .then(employee => {
          const message = { text: `The employee information was ${action} successfully.`, type: "success" };

          // Update local data with the server response
          this.form.data = { ...employee };
          this.form.initialData = { ...employee };

          this.displayMessage(message);
          this.$emit(action, { ...employee });
        })
        .then(() => {
          // only send email invite through this step when creating an entity
          if (!isUpdate && this.hasEmail && this.enableSendInvite) {
            return this.sendInvite();
          }
        })
        .catch(error => {
          const errors = error && error.response ? error.response.data : {};
          this.setFormErrors(errors);

          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setFormErrors(errors) {
      this.form.errors = parseFormErrors(errors.field_errors || []);
      this.form.errors.non_field_errors = errors.errors || [];
    },

    createEmployee() {
      const employee = this.form.data;

      return api.employees.create(employee).then(response => {
        this.close();
        return response.data;
      });
    },

    updateEmployee() {
      const employee = this.form.data;

      return api.employees.update(employee).then(response => {
        return response.data;
      });
    },

    sendInvite() {
      this.isSendingInvite = true;

      return api.invitations
        .send(this.form.data)
        .then(() => {
          this.displayMessage({ text: "Invitation sent", type: "info" });
        })
        .catch(error => {
          this.displayMessage({ text: "Invitation failed to send", type: "error" });
        })
        .finally(() => {
          this.isSendingInvite = false;
        });
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    // Helpers
    checkEmailEdition() {
      const isDirty = this.dirtyFields.has("form.data.email");
      const message = "Employee's e-mail was edited, you need to save first in order to email invitation.";

      this.emailFieldMessage = this.isUpdate && this.hasEmail && isDirty ? message : "";
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField("form.data.name", "form.initialData.name");
      this.trackDirtyField("form.data.email", "form.initialData.email");
    }
  }
});
</script>
