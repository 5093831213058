export default {
  data() {
    return {
      panels: [],
      items: []
    };
  },
  computed: {
    panelCount() {
      return this.items.length;
    },
    allPanelsOpen() {
      return this.items.length === this.panels.length;
    }
  },
  methods: {
    showAllPanels() {
      this.panels = [...Array(this.panelCount).keys()].map((k, i) => i);
    },
    closeAllPanels() {
      this.panels = [];
    }
  }
};
