import { http } from "@/vuex-bcore";

const url = "invoiceboost/bills";

function fetch_payments(bill_id) {
  return http.get(`${url}/${bill_id}/payments/`);
}

function fetch(bill_id) {
  return http.get(`${url}/${bill_id}/`);
}

function create(bill) {
  return http.post(`${url}/`, bill);
}

function update(bill) {
  return http.patch(`${url}/${bill.id}/`, bill);
}

function remove(bill_id) {
  return http.delete(`${url}/${bill_id}/`);
}

function overdue() {
  return http.get(`${url}/overdue/`);
}

function outstanding() {
  return http.get(`${url}/outstanding/`);
}

export default {
  fetch,
  fetch_payments,
  create,
  update,
  delete: remove,
  outstanding,
  overdue
};
