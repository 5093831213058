<template>
  <div class="invoice-assignees-editor">
    <!-- SKELETON LOADER -->
    <span v-show="isLoading">Loading...</span>

    <!-- ASSIGNEE -->
    <div v-if="!isLoading" class="assignee-info">
      <span v-if="firstAssignee" class="assignee-name">{{ firstAssignee.assignee }}</span>
      <span v-else class="assignee-name">None</span>

      <span v-if="countLabel" class="assignees-count mr-1">
        {{ countLabel }}
      </span>

      <span v-if="canChangeInvoice">
        <span>|</span>
        <a @click.prevent="openEditor" class="underline ml-1">Edit</a>
      </span>
    </div>

    <responsive-dialog
      dialog-title="Invoice Assignees"
      :display-dialog="displayEditor"
      :show-actions="false"
      @closed="closeEditor"
    >
      <v-card-text>
        <v-switch
          v-for="(employee, index) in selection"
          :input-value="employee.selected"
          :false-value="false"
          :true-value="true"
          :key="index"
          :loading="employee.isLoading"
          :label="`${employee.name}`"
          @change="change(employee, $event)"
        ></v-switch>
      </v-card-text>
    </responsive-dialog>
  </div>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";

import api from "@/api";

export default {
  name: "InvoiceAssigneesEditor",

  components: {
    ResponsiveDialog
  },

  props: {
    invoicePayable: { type: Boolean, default: false },
    invoiceReceivable: { type: Boolean, default: false },
    canChangeInvoice: { type: Boolean, default: false },
    invoiceId: { type: Number, default: 0 }
  },

  data() {
    return {
      isLoadingEmployees: false,
      isLoadingAssignees: false,
      displayEditor: false,

      employees: [],
      assignees: [],
      selection: []
    };
  },

  computed: {
    isLoading() {
      return this.isLoadingEmployees || this.isLoadingAssignees;
    },

    firstAssignee() {
      return this.assignees[0];
    },

    countLabel() {
      const length = this.assignees.length;

      if (length > 1) {
        return `+${length - 1}`;
      }

      return "";
    }
  },

  watch: {
    // Watch for prop changes to automate fetching assignees based on invoiceId set by parent
    invoiceId(value) {
      this.fetch_assignees();
    }
  },

  mounted() {
    this.fetch_employees().then(() => {
      this.fetch_assignees();
    });
  },

  methods: {
    fetch_employees() {
      this.isLoadingEmployees = true;

      return api.employees
        .fetch_all()
        .then(response => {
          const results = response.data.results;
          const employees = [];

          this.isLoadingEmployees = false;
          this.employees = results;

          for (let i = 0; i < results.length; i++) {
            let employee = results[i];

            this.$set(employees, i, {
              id: 0,
              target_entity: employee.target_entity,
              name: employee.name,
              isLoading: false,
              selected: false
            });
          }

          this.selection = employees;
        })
        .catch(error => {
          this.isLoadingEmployees = false;
        });
    },

    fetch_assignees() {
      this.isLoadingAssignees = true;
      this.assignees = []; // reset selection

      const endpoint = this.invoicePayable ? api.invoice_payable : api.invoice_receivable;

      return endpoint
        .fetch_assignees(this.invoiceId)
        .then(response => {
          this.clearSelection();

          const selection = this.selection;
          const assignees = response.data;
          const selectedEntities = [];

          this.isLoadingAssignees = false;
          this.assignees = assignees;

          for (let i = 0; i < assignees.length; i++) {
            let assignee = assignees[i];
            let found =
              selection.find(item => {
                return item.target_entity == assignee.target_entity;
              }) || {};

            found.id = assignee.id;
            found.selected = true;
          }
        })
        .catch(error => {
          this.isLoadingAssignees = false;
        });
    },

    openEditor() {
      this.displayEditor = true;
    },

    closeEditor() {
      this.displayEditor = false;
    },

    clearSelection() {
      const selection = this.selection;

      for (let i = 0; i < selection.length; i++) {
        selection[i].selected = false;
      }
    },

    change(employee, selected) {
      employee.isLoading = true;

      const endpoint = this.invoicePayable ? api.invoice_payable : api.invoice_receivable;

      if (selected) {
        endpoint
          .add_assignee(this.invoiceId, {
            target_entity: employee.target_entity,
            invoice: this.invoiceId,
            type: this.invoicePayable ? "payable" : "receivable"
          })
          .finally(() => {
            employee.isLoading = false;
            this.fetch_assignees();
          });
      } else {
        endpoint
          .remove_assignee(this.invoiceId, employee.id)
          .then(response => {
            employee.selected = selected;
          })
          .finally(() => {
            employee.isLoading = false;
            this.fetch_assignees();
          });
      }
    }
  }
};
</script>

<style>
.invoice-assignees-editor .assignee-info {
  display: flex;
  min-width: 0;
}

.invoice-assignees-editor .assignee-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 130px;
  margin-right: 5px;
}

.invoice-assignees-editor a.underline {
  text-decoration: underline;
}
</style>
