import { http } from "@/vuex-bcore";

const url = "/internationalization";

function update(language_code) {
  const payload = { language: language_code };

  return http.put(`${url}/`, payload);
}

export default {
  update
};
