import { http } from "@/vuex-bcore";

const url = "users";

function whoami() {
  return http.get(`${url}/whoami/`);
}

function login(credentials) {
  return http.post(`/login/`, credentials);
}

function logout() {
  return http.get(`/logout/`);
}

function fetch_organizations(user_id) {
  return http.get(`/invoiceboost/users/${user_id}/organizations/`);
}

function fetch_permissions() {
  return http.get(`${url}/entity_permissions/`);
}

function reset_password_confirm(payload) {
  return http.post(`${url}/reset-password-confirm/`, payload);
}

function request_password_reset(payload) {
  return http.post(`${url}/reset-password-request/`, payload);
}

export default {
  whoami,
  login,
  logout,
  fetch_organizations,
  fetch_permissions,
  request_password_reset,
  reset_password_confirm
};
