import Vue from "vue";
import { auth } from "@/vuex-bcore";
import Vuex from "vuex";
import theme from "@/store/theme";
import user from "@/store/models/user";
import routerEntity from "@/store/router";
import dialog from "@/store/dialog";
import userAdmin from "@/bcore/store/models/user";
import customer from "@/invoiceboost/store/models/customer";
import customDomain from "@/premium/store/models/custom-domain";
import country from "@/bcore/store/models/country";
import supplier from "@/invoiceboost/store/models/supplier";
import employee from "@/invoiceboost/store/models/employee";
import entity from "@/bcore/store/models/entity";
import entityAddress from "@/bcore/store/models/entity-address";
import entitySettings from "@/invoiceboost/store/models/entity-settings";
import media from "@/bcore/store/models/media";
import organization from "@/invoiceboost/store/models/organization";
import payment from "@/invoiceboost/store/models/payment";
import paymentMethod from "@/invoiceboost/store/models/payment-method";
import paymentSchedule from "@/invoiceboost/store/models/payment-schedule";
import person from "@/invoiceboost/store/models/person";
import invite from "@/bcore/store/models/invite";
import invoice from "@/invoiceboost/store/models/invoice";
import invoiceAssignee from "@/invoiceboost/store/models/invoice-assignee";
import item from "@/invoiceboost/store/models/item";
import invoiceItem from "@/invoiceboost/store/models/invoice-item";
import invoiceMedia from "@/invoiceboost/store/models/invoice-media";
import bill from "@/invoiceboost/store/models/bill";
import transaction from "@/invoiceboost/store/models/transaction";
import webConnectorAccount from "@/integrations/store/models/web-connector-account";
import order from "@/point-of-sale/store/models/order";
import orderItem from "@/point-of-sale/store/models/order-item";
import quote from "@/point-of-sale/store/models/quote";
import quoteItem from "@/point-of-sale/store/models/quote-item";
import customerQuote from "@/point-of-sale/store/models/customer-quote";
import delivery from "@/point-of-sale/store/models/delivery";
import priceOverride from "@/point-of-sale/store/models/price-override";
import truck from "@/point-of-sale/store/models/truck";
import driver from "@/point-of-sale/store/models/driver";
import ticket from "@/point-of-sale/store/models/ticket";
import ticketItem from "@/point-of-sale/store/models/ticket-item";

Vue.use(Vuex);

const store = {
  state: {
    version: "1.0.0"
  },
  modules: {
    auth,
    theme,
    dialog,
    user,
    routerEntity,
    entity,
    entityAddress,
    entitySettings,
    media,
    organization,
    payment,
    paymentMethod,
    paymentSchedule,
    person,
    customer,
    customDomain,
    country,
    supplier,
    employee,
    userAdmin,
    invite,
    item,
    invoice,
    invoiceAssignee,
    invoiceItem,
    invoiceMedia,
    bill,
    transaction,
    webConnectorAccount,
    order,
    orderItem,
    quote,
    quoteItem,
    customerQuote,
    delivery,
    priceOverride,
    truck,
    driver,
    ticket,
    ticketItem
  }
};

export default new Vuex.Store(store);
