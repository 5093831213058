import { http } from "@/vuex-bcore";

const url = "invoiceboost/suppliers";

function fetch(supplier_id) {
  return http.get(`${url}/${supplier_id}/`);
}

function fetch_all() {
  return http.get(`${url}/`);
}

function create(supplier) {
  return http.post(`${url}/`, supplier);
}

function update(supplier) {
  return http.patch(`${url}/${supplier.id}/`, supplier);
}

export default {
  fetch,
  fetch_all,
  create,
  update
};