// import { parseISO, differenceInSeconds } from "date-fns";

// function autoLogout(store) {
//   const sessionExpiry = store.state.auth.user.session_expiry_date;
//   if (sessionExpiry) {
//     const timeDifference = differenceInSeconds(parseISO(sessionExpiry), new Date());

//     if (timeDifference < 30) {
//       store.dispatch("auth/logout");
//     }
//   }
//   return;
// }

export default function BcoreVuexPlugin() {
  return (store) => {
    // NOTE: this is now handled at app/user.js

    // Initialize state from server
    // store.dispatch("auth/whoami").then(() => {
    //   // Automatically logout an expiring session
    //   window.setInterval(autoLogout, 30000, store); // 30 seconds
    // });

    // window.addEventListener("", () => {
    //   store.dispatch("auth/logout");
    // });
  };
}
