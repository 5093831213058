<template>
  <v-container>

    <v-row>
      <v-col><h3>New Ticket</h3></v-col>

      <v-col class="text-right">
        <v-btn class="error" @click="cancel" :disabled="loading">CANCEL</v-btn>
      </v-col>
    </v-row>

    <v-divider class="mb-6 mt-3"/>

    <!-- DISPLAY GENERIC ERRORS -->
    <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>

    <!-- DISPLAY FORM NOTIFICATIONS -->
    <v-row v-if="notifications.length">
      <v-col>
        <v-alert
            v-for="(alert, index) in notifications"
            border="left"
            elevation="2"
            class="mb-0"
            :type="alert.type"
            :key="index"
            colored-border
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="alert.message"></span>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <!-- FIELD: CUSTOMER -->
      <v-col>
        <customer-autocomplete
            ref="customer_autocomplete"
            :form-loading="loading"
            :rules="rules.customer"
            @change="onCustomerChange"
        />
      </v-col>

      <!-- FIELD: DATE -->
      <v-col>
        <v-text-field type="date" v-model="form.deliveryDate"/>
      </v-col>

      <!-- FIELD: TIME -->
      <v-col>
        <v-text-field type="time" v-model="form.deliveryTime"/>
      </v-col>

    </v-row>

    <v-row v-if="form.customer">

      <!-- ORDERS TABLE -->
      <v-col>

        <div v-if="tables.orders.items">
          <span>Orders</span>

          <v-data-table
              v-model="form.order"
              :headers="tables.orders.headers"
              :items="tables.orders.items"
              :single-select="true"
              disable-pagination
              hide-default-footer
              class="elevation-1 table-striped table-hover"
              show-select
              fixed-header
          />
        </div>

      </v-col>

      <!-- AVAILABLE ITEMS TABLE -->
      <v-col>

        <span>Items</span>

        <v-data-table
            v-model="form.items"
            :headers="tables.items.headers"
            :items="orderItems"
            :single-select="false"
            class="elevation-1 table-striped table-hover"
            disable-pagination
            hide-default-footer
            show-select
            fixed-header
        >

          <!-- COLUMN: QUANTITY TO DISPATCH -->
          <template v-slot:item.toDispatch="{ item }">
            <div>
              <v-text-field
                  v-model="item.toDispatch"
                  :rules="isValidItemValue(item)"
                  type="number"
                  dense
              />
            </div>

          </template>

        </v-data-table>
      </v-col>

    </v-row>

    <div v-if="selectedOrder && form.items.length > 0">
      <v-divider class="mt-8 mb-2"/>

      <!-- SUMMARY -->
      <h3>Summary</h3>
      <v-row>
        <!-- TICKET INFO -->
        <v-col>
          <strong>{{ form.customer.name }} / Order {{ selectedOrder.po_number }}</strong> <br/>
          <span v-if="form.deliveryDate && form.deliveryTime">
            Expected delivery: {{ form.deliveryDate }} @ {{ form.deliveryTime }} <br/>
          </span>
          <div v-for="item in form.items" :key="item.id">
            <i>{{ item.item.description }} </i> — {{ item.toDispatch }}
            {{ item.item.unit_of_measure || `[uom]` }}
          </div>

        </v-col>

        <!-- DELIVERY INFO -->
        <v-col v-if="deliveryAddress">
          <strong>Delivery Address</strong>
          <address>
            {{ deliveryAddress.address_line1 }}<br/>
            <span v-if="deliveryAddress.address_line2">{{ deliveryAddress.address_line2 }}<br/></span>
            {{ deliveryAddress.city }}, {{ deliveryAddress.code }}<br/>
            {{ deliveryAddress.country_code }}
          </address>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-divider class="my-3"/>

          <v-btn color="error" @click="cancel" :disabled="loading">CANCEL</v-btn> &nbsp;
          <v-btn color="success" @click="createTicket" :disabled="loading">CREATE TICKET</v-btn>
        </v-col>
      </v-row>

    </div>

  </v-container>
</template>

<script>
import api from "@/api";
import NonFieldErrors from "@/bcore/components/NonFieldErrors";
import trans from "@/utilities/translations";
import CustomerAutocomplete from "@/invoiceboost/components/CustomerAutocomplete";
import {ADD_MESSAGE} from "@/store/mutation-types";

export default {
  name: "TicketForm",
  components: {
    NonFieldErrors,
    CustomerAutocomplete
  },
  data() {

    return {
      form: {
        customer: null,
        order: [],
        items: [],
        deliveryDate: null,
        deliveryTime: null,
      },
      tables: {
        orders: {
          headers: [
            {text: trans.gettext("PO Number"), value: "po_number", sortable: false},
            {text: trans.gettext("Status"), value: "status", sortable: false},
            {text: trans.gettext("Date"), value: "expected_delivery_date", sortable: true},
            {text: trans.gettext("Total"), value: "total", sortable: false},
          ],
          items: []
        },
        items: {
          headers: [
            {text: trans.gettext("Item"), value: "description", sortable: false},
            {text: trans.gettext("Ordered"), value: "quantity", sortable: false},
            {text: trans.gettext("Remaining"), value: "quantity", sortable: false},
            {text: trans.gettext("To Dispatch"), value: "toDispatch", sortable: false},
          ],
        },
      },
      rules: {
        customer: [value => !!value || false],
      },
      loading: false,
      formErrors: {non_field_errors: null},
    };

  },

  computed: {

    notifications() {
      const alerts = [];
      const customer = this.form.customer;

      if (!customer) {
        const msg = `<strong>Customer field is required</strong>, you can't create an invoice without a customer.`;
        alerts.push({type: "warning", message: trans.gettext(msg)});
      }

      return alerts;
    },

    selectedOrder() {
      // v-model holds the selected items
      return ((this.form.order && this.form.order.length)) ? this.form.order[0] : null;
    },

    orderItems() {
      if ((this.form.order && this.form.order.length))
          // v-model holds the selected items
        return this.form.order[0].items;

      return [];
    },

    deliveryAddress() {
      if (this.selectedOrder && this.selectedOrder.delivery_to_order && this.selectedOrder.delivery_to_order) {
        const delivery = this.selectedOrder.delivery_to_order[0].delivery;
        return delivery.address[0];
      }

      return null;
    }

  },

  watch: {
    "form.order": function () {
      this.form.items = [];
    },
  },

  methods: {

    fetchOrders() {

      const vm = this;
      vm.setLoading(true);

      api.order.fetch_for_customer(this.form.customer.id)
          .then(function (rs) {
            vm.tables.orders.items = rs.data.results;
          })
          .catch(() => {
            vm.displayMessage({text: trans.gettext("An error occurred while searching for orders"), type: "error"});
          })
          .finally(function () {
            vm.setLoading(false);
          });
    },

    setLoading(loading) {
      this.loading = loading;
    },

    onCustomerChange(customer) {
      this.tables.orders.items = [];
      this.form.customer = customer;
      this.form.order = [];
      this.form.items = [];
      this.fetchOrders();
    },

    cancel() {
      this.$router.go(-1);
    },

    isValidItemValue(item) {
      const errorMsg = trans.gettext("Incorrect value");

      return [
        val => val >= 0 || errorMsg,
        val => val <= item.quantity || errorMsg,
      ];

    },

    createTicket() {

      const data = {
        target_entity: this.form.customer.target_entity,
        delivery_date: new Date(this.form.deliveryDate + " " + this.form.deliveryTime),
        order: this.selectedOrder.id,
        items: this.form.items.map(item => {
          return {order_item: item.id, item_qty: item.toDispatch};
        })
      };

      this.setLoading(true);
      const vm = this;
      api.tickets.create(data)
          .then(() => {
            vm.displayMessage({text: trans.gettext("Ticket successfully created"), type: "success"});
            vm.cancel();
          })
          .catch(() => {
            vm.displayMessage({text: trans.gettext("Error creating ticket"), type: "error"});
          })
          .finally(() => this.setLoading(false));
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

  }

};
</script>

<style scoped>

</style>