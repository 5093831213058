<template>
  <v-card class="first-visit">
    <v-card-title>
      <div class="header">
        <div class="logo-wrapper">
          <img src="@/assets/accruvia-cloud-logo.png" alt="Accruvia Cloud Logo" class="logo" />
        </div>

        <h2>WELCOME TO</h2>
        <p>A better way of managing bills and invoices.</p>
      </div>

      <img src="@/assets/mobile/background-swoosh.svg" class="background-swoosh" />
    </v-card-title>

    <v-card-text class="pa-6">
      <div class="title">PLEASE SELECT A LANGUAGE</div>

      <div class="text-center mt-6">
        <span class="languages rounded-1">
          <v-btn
            class="option"
            :class="{ selected: selected_english }"
            :elevation="selected_english ? 1 : 0"
            @click="select('en-us')"
            x-large
          >
            ENGLISH
          </v-btn>
          <v-btn
            class="option"
            :class="{ selected: selected_spanish }"
            :elevation="selected_spanish ? 1 : 0"
            @click="select('es')"
            x-large
          >
            ESPAÑOL
          </v-btn>
        </span>
      </div>

      <div class="text-center pt-7">
        <v-btn color="primary" class="submit" @click="context.submit" large>CONTINUE</v-btn>
      </div>

      <div class="text-center pt-7">
        <p class="got-account ma-0">
          Already have an account?
          <router-link :to="{ name: 'login' }">Sign in</router-link>
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "FirstVisit",

  components: {
    // NonFieldErrors
  },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {};
  },

  computed: {
    // using iso 639-1 codes
    // https://www.andiamo.co.uk/resources/iso-language-codes/
    // https://www.science.co.il/language/Locale-codes.php

    selected_english() {
      return this.context.form.language === "en-us";
    },

    selected_spanish() {
      return this.context.form.language === "es";
    },
  },

  methods: {
    select(language) {
      this.context.form.language = language;
    }
  }
};
</script>

<style>
.first-visit {
  height: 100%;
}

.first-visit .v-card__title {
  position: relative;
  display: block;
  padding: 0 0 15.5%;
  z-index: 0;
}

.first-visit .v-card__title .background-swoosh {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.first-visit .logo-wrapper {
  line-height: 0;
}

.first-visit .logo {
  height: 85px;
}

.first-visit .v-card__title .header {
  background-color: #000;
  padding: 35px 45px 35px 45px;
}

.first-visit .v-card__title h2 {
  font-size: 26px;
  word-break: break-word;
  color: #186734;
  font-weight: bold;
  word-break: break-word;

  margin-top: 40px;
  margin-bottom: 28px;
}

.first-visit .v-card__title p {
  color: #fff;
  font-weight: 200;
  font-size: 38px;
  word-break: break-word;
  line-height: 1.2;
  margin-bottom: 0;
}

.first-visit .v-card__text .title {
  font-size: 16px !important;
  text-align: center;
  color: #000;
}

.first-visit .languages {
  background-color: #f5f5f5;
  display: inline-flex;
  padding: 2px;
  border-radius: 5px;
}

.first-visit .option + .option {
  margin-left: 5px;
}

.first-visit .option.selected {
  background-color: #fff;
}

.first-visit button.submit {
  width: 260px;
}

.first-visit .got-account {
  font-size: 15px;
}
</style>
