<template>
  <v-card class="org-orders" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="hasPermission('add_order', 'point_of_sale')"
        title="New Order"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newOrder"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder="customer, Order #, Etc."
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        dense
        multi-sort
        disable-filtering
      >
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canEditOrder(item)" @click="onClickCancelOrder(item)">
                <v-icon left>mdi-cancel</v-icon>
                cancel
              </v-list-item>
              <v-list-item v-if="canEditOrder(item)" @click="editOrder(item)">
                <v-icon left>mdi-pencil</v-icon>
                Edit
              </v-list-item>
              <v-list-item @click="sendEmail(item)">
                <!--  -->
                <v-icon left>mdi-mail</v-icon>
                Email
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item v-if="isDeliveryRequired(item)" @click="onClickDeliverySummary(item)">
                Delivery summary
              </v-list-item>
              <v-list-item v-if="canEditOrder(item)" @click="onClickCloseOrder(item)">Close Order</v-list-item>
              <v-list-item @click.prevent="previewOrder(item)">
                <v-icon small>mdi-open-in-new</v-icon>
                Preview
              </v-list-item>
              <v-list-item @click="onClickDetailItem(item)">Summary</v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- ORDER FORM -->
    <order-form ref="order_form" @updated="onOrderCreated" @requiresDelivery="navToOrderDelivery" />
    <order-details ref="order_details" @edit_order="editOrder" @updated="fetchItems" @paymentProcessed="fetchItems" />
    <v-dialog v-model="confirmOpen" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Confirm action</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onConfirmButton">ACCEPT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import OrderForm from "@/point-of-sale/components/order/OrderForm.vue";
import OrderDetails from "@/point-of-sale/components/order/OrderDetails.vue";

import { ADD_MESSAGE } from "@/store/mutation-types";
import PageTitle from "@/mixins/pageTitle";
import DataTableMixin from "@/mixins/dataTable";
import DateMixin from "@/mixins/date";
import PermissionMixin from "@/mixins/permission";
import PreviewMixin from "@/mixins/preview";
import api from "@/api";

export default {
  name: "Orders",

  components: {
    OrderForm,
    OrderDetails,
    PageTitle
  },

  mixins: [PageTitle, DataTableMixin, DateMixin, PermissionMixin, PreviewMixin],

  data() {
    return {
      paymentsOpened: false,
      paymentsItem: null,

      formOpened: false,
      displayFilters: false,

      confirmOpen: false,
      dialogText: null,

      currentItem: null,
      newStatus: null,
      entity: {},
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "Order no", value: "reference_no", sortable: true },
        { text: "Customer", value: "target_entity.name", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.$store.state.order.list;
    },

    isLoading() {
      return this.$store.getters["order/loading"]("list");
    }
  },

  beforeDestroy() {
    this.clearItems();
  },

  methods: {
    isDeliveryRequired(item) {
      return item.requires_delivery;
    },

    fetchItems() {
      this.$store.dispatch("order/list");
    },

    clearItems() {
      this.$store.dispatch(`order/clearState`, "list");
    },

    onOrderCreated(orderCreated) {
      this.$refs.order_details.open(orderCreated);
      this.fetchItems();
    },

    newOrder() {
      this.$refs.order_form.new();
    },

    editOrder(item) {
      this.$refs.order_form.open(item);
    },

    onClickCancelOrder(item) {
      this.confirmOpen = true;
      this.currentItem = item;
      this.dialogText = "Are you sure? Once you cancel this order you will no longer be able to invoice it.";
      this.newStatus = "cancelled";
    },

    onClickCloseOrder(item) {
      this.confirmOpen = true;
      this.currentItem = item;
      this.dialogText = "Are you sure? Once you close this order you will no longer be able to add or edit items.";
      this.newStatus = "closed";
    },

    onClickPayOrder(item) {
      this.$router.push({ name: "order-payment", params: { orderId: item.id } });
    },

    onClickDetailItem(item) {
      this.$refs.order_details.open(item);
    },

    canEditOrder(item) {
      return item.status === "open" && this.hasPermission("change_order", "point_of_sale");
    },

    async onConfirmButton() {
      await this.updateOrderStatus();
      this.confirmOpen = false;
      this.fetchItems();
    },

    async updateOrderStatus() {
      try {
        let payload = { status: this.newStatus };
        await api.order.update(this.currentItem.id, payload);
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Order successfully updated" });
        this.clearItem();
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Failed to update order status" });
      }
    },

    clearItem() {
      this.newStatus = null;
      this.currentItem = null;
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    async sendEmail(order) {
      if (order.id) {
        try {
          await api.order.send(order.id);
          this.displayMessage({ text: "Email Sent", type: "primary" });
        } catch (error) {
          this.displayMessage({ text: "Email failed to send", type: "error" });
        }
      }
    },

    previewOrder(order) {
      this.previewTitle = `order: ${order.reference_no}`;
      this.previewUrl = api.order.previewUrl(order.id);
      this.showPreview();
    },

    navToOrderDelivery(order) {
      this.$router.push({ name: "order-delivery", params: { orderId: order.id } });
    },

    onClickDeliverySummary(order) {
      this.$router.push({ name: "order-delivery-summary", params: { orderId: order.id } });
    }
  }
};
</script>

<style>
.org-orders .search-field .v-input__slot {
  margin: 0;
}

.org-orders .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-orders table tr {
  line-height: 40px;
  cursor: pointer;
}

.org-orders table thead tr {
  background-color: #eeeeee;
}

.org-orders table thead tr th {
  color: #000 !important;
}
</style>
