import { AuthState } from "../types";

const auth: AuthState = {
  entity: {},
  permissions: [],
  loading: false,
  errors: {},
  user: {}
};

export default auth;
