<template>
  <v-autocomplete
    v-model="item"
    label="Customer*"
    item-value="target_entity"
    item-text="name"
    placeholder="Start typing to Search"
    :name="name"
    :items="items"
    :loading="customersLoading"
    :rules="rules"
    :search-input.sync="search"
    :disabled="isLoading"
    @change="onCustomerChange"
    return-object
    hide-no-data
    outlined
    no-filter
  />
</template>

<script>
import api from "@/api";

function emptyRules() {
  return [];
}

export default {
  name: "CustomerAutocomplete",

  props: {
    name: { type: String, default: "" },
    isLoading: { type: Boolean, default: false },
    rules: { type: Array, default: emptyRules }
  },

  data() {
    return {
      customersLoading: false,
      search: "",
      items: [],
      item: null
    };
  },

  watch: {
    search() {
      this.debounceInput(this.filterCustomers);
    }
  },

  methods: {
    onCustomerChange(value) {
      this.$emit("change", value);
    },

    filterCustomers() {
      if (this.customersLoading) {
        return;
      }

      this.customersLoading = true;

      return api.customers
        .search(this.search)
        .then(response => {
          this.items = response.data.results;
        })
        .finally(() => {
          this.customersLoading = false;
        });
    },

    /**
     * Debounce a function
     * @param func
     * @param timeout
     * TODO: Make this re-usable in other components ?
     */
    debounceInput(func, timeout = 300) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        return func();
      }, timeout);
    },

    clear() {
      this.item = null;
      this.items = [];
      this.search = "";
    },

    select(customer) {
      this.item = customer;
    }
  }
};
</script>
