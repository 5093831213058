<template>
  <v-card>
    <v-card-title class="display-1 font-weight-thin">Delivery Form</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="onFormSubmit">
        <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>
        <v-row>
          <!-- Form fields -->
          <v-col cols="6" md="6">
            <v-checkbox v-model="form.is_waiver_required" label="Waiver Required?"></v-checkbox>
            <v-text-field v-model="form.delivery_address.address_line1" label="Address" outlined></v-text-field>
            <v-text-field
              v-model="form.delivery_address.address_line2"
              label="Address complement"
              outlined
            ></v-text-field>
            <v-text-field v-model="form.delivery_address.code" label="Zip Code" outlined></v-text-field>
            <v-text-field v-model="form.delivery_address.city" label="City" outlined></v-text-field>
            <v-text-field v-model="form.delivery_address.state" label="State" outlined></v-text-field>
            <v-text-field v-model="form.comments" label="Comments" outlined></v-text-field>
          </v-col>
          <!-- GOOGLE MAPS -->
          <v-col cols="6" md="6">
            <google-map-loader :map-config="mapConfig" :api-key="getMapsAPIKey()" @updated="onCoordUpdate" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field v-model="form.delivery_fee" label="Delivery Fee*" prefix="$"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn type="submit" color="primary" text :loading="formLoading" @click="onFormSubmit">
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import GoogleMapLoader from "@/point-of-sale/components/googleMaps/GoogleMapLoader.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import permissionMixin from "@/mixins/permission";
import { ADD_MESSAGE } from "@/store/mutation-types";
import { parseFormErrors } from "@/utilities/form";

function defaultObject() {
  return {
    target_entity: { name: "" },
    delivery_to_quote: [],
    delivery_to_order: []
  };
}

export default {
  name: "DeliveryForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    GoogleMapLoader
  },

  mixins: [permissionMixin],

  props: {
    isQuote: { type: Boolean, required: false },
    item: { type: Object, default: defaultObject() },
    deliveryAddress: { type: Object, default: null },
    url: { type: String, default: "" },
    redirectTo: { type: String, default: "" },
    isItemUpdate: { type: Boolean, default: false }
  },

  data() {
    return {
      formLoading: false,
      formOpened: true,
      mapConfig: {
        center: { lat: 32.79199, lng: -97.527637 },
        zoom: 12,
        maxZoom: 15,
        minZoom: 3,
        streetViewControl: false
      },

      form: {
        delivery_address: {}
      },
      formErrors: {}
    };
  },

  computed: {
    formTitle() {
      return `Delivery Form`;
    },

    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    },

    itemName() {
      return this.isQuote ? `Quote` : `Order`;
    }
  },

  mounted() {
    if (this.isItemUpdate) {
      this.loadDeliveryData();
    }
  },

  methods: {
    getMapsAPIKey() {
      // todo define what's the proper way to get this key ( Store it in our server ??)
      return "";
    },

    getMapConfig() {
      let mapOptions = { zoom: 4, center: { lat: -34.397, lng: 150.644 } };

      return mapOptions;
    },

    closeForm() {},

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async onFormSubmit(send = false) {
      if (this.formHasErrors) {
        return;
      }
      if (this.item) {
        this.form.owner_entity = this.item.owner_entity;
        this.form.target_entity = this.item.target_entity;
        this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();
      }
    },

    sendUpdate() {
      if (this.url) {
        try {
          this.$http.put(this.url, this.form).then(response => {
            this.form = response.data;
            this.$store.commit(`theme/${ADD_MESSAGE}`, {
              type: "success",
              text: "Delivery information updated successfully"
            });
            if (this.redirectTo) {
              this.$router.push({ name: this.redirectTo });
            } else {
              this.$emit("updated");
            }
          });
        } catch (error) {
          this.setFormErrors(error.response.data);
        }
      }
    },

    sendCreate() {
      if (this.url) {
        try {
          this.$http.post(this.url, this.form).then(response => {
            this.form = response.data;
            this.$store.commit(`theme/${ADD_MESSAGE}`, {
              type: "success",
              text: "Delivery information stored successfully"
            });
            if (this.redirectTo) {
              this.$router.push({ name: this.redirectTo });
            }
          });
        } catch (error) {
          this.setFormErrors(error.response.data);
        }
      }
    },

    loadDeliveryData() {
      this.form.delivery_address = this.deliveryAddress;
      delete this.form.delivery_address.country_code;
    },

    onCoordUpdate(coordinates) {
      this.form.delivery_address.latitude = coordinates.lat;
      this.form.delivery_address.longitude = coordinates.lng;
    }
  }
};
</script>
