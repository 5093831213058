<template>
  <div class="spinner-center">
    <svg class="spinner" viewBox="0 0 100 100" width="50" height="50">
      <circle cx="50" cy="50" r="45" transform="rotate(-90,50,50)" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PageLoad",

  computed: {}
};
</script>

<style>
/* The styling for this element is provided in public/index.html */
</style>