<template>
  <responsive-dialog
    content-class="order-form-dialog"
    :display-dialog="displayDialog"
    :dialog-title="formTitle"
    @closed="close"
  >
    <v-form ref="form" v-model="isFormValid" :lazy-validation="false" @submit.prevent="submitForm">
      <!-- DISPLAY GENERIC ERRORS -->
      <non-field-errors :errors="formErrors.non_field_errors" />

      <!-- DISPLAY FORM NOTIFICATIONS -->
      <v-row v-if="notifications.length">
        <v-col>
          <v-alert
            v-for="(alert, index) in notifications"
            border="left"
            elevation="2"
            class="mb-0"
            :type="alert.type"
            :key="index"
            colored-border
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="alert.message"></span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="hasPermission('add_customer')">
        <v-col>
          <v-btn title="New Customer" color="primary" @click="newCustomer" outlined>
            <v-icon left>mdi-plus</v-icon>
            Customer
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- FIELD: CUSTOMER -->
        <v-col cols="12" md="6">
          <!-- DISABLED TEXT FIELD IS USED ON UPDATE MODE -->
          <v-text-field
            label="Customer"
            v-if="populateCustomer"
            :value="form.target_entity.name"
            :disabled="true"
            outlined
            required
          ></v-text-field>

          <!-- DROPDOWN/AUTOCOMPLETE IS USED WHEN CREATING A NEW ORDER -->
          <customer-autocomplete
            v-if="!populateCustomer"
            ref="customer_autocomplete"
            :customer-search="customersFilter"
            :form-loading="formLoading"
            :rules="rules.customer"
            @change="onCustomerChange"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox v-model="form.requires_delivery" label="Delivery Required?"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="form.description" label="Description" outlined></v-text-field>
        </v-col>
        <!-- FIELD: PO # -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.po_number"
            outlined
            label="PO No."
            :error-messages="formErrors.po_number"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="order-items">
        <v-col cols="12">
          <order-items
            ref="order_items"
            @updated="onItemsUpdate"
            :loading="formLoading"
            :price-overrides="customerPriceOverrides"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6"></v-col>
        <v-col cols="12" md="6">
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>Subtotal</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                {{ formatCurrency(form.subtotal, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="taxPercentage">
              <v-list-item-content>{{ taxTitle }} ({{ taxPercentage }}%)</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                {{ formatCurrency(form.tax, form.currency) }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Total</v-list-item-content>
              <v-list-item-content class="align-end" text-align="end">
                {{ formatCurrency(form.total, form.currency) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-form>

    <template slot="actions">
      <v-spacer />

      <v-btn
        type="submit"
        color="primary"
        :loading="formLoading"
        :disabled="disableSave"
        @click="performSaveOption('save')"
        text
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>

    <customer-form-dialog ref="customer_editor" @created="customerCreated" />
  </responsive-dialog>
</template>

<script>
import Vue from "vue";
import { ADD_FILTERS, ADD_MESSAGE, CLEAR_FILTERS, SET_LIST } from "@/store/mutation-types";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";

import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import OrderItems from "@/point-of-sale/components/order/OrderItems.vue";

import currencyFormatMixin from "@/mixins/currencyFormat";
import sumColumnMixin from "@/mixins/sumColumn";
import PreviewMixin from "@/mixins/preview";
import dateMixin from "@/mixins/date";
import CustomerAutocomplete from "@/invoiceboost/components/CustomerAutocomplete";
import CustomerFormDialog from "@/invoiceboost/components/customer/CustomerFormDialog";
import permissionMixin from "@/mixins/permission";
import api from "@/api";
import { parseFormErrors } from "@/utilities/form";

function createOrderModel() {
  return {
    owner_entity: null,
    target_entity: {},
    customer: null,
    items: [],
    subtotal: 0,
    tax: 0,
    total: 0
  };
}

function createModelRules() {
  return {
    customer: [value => !!value || false]
  };
}

export default {
  name: "OrderForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    OrderItems,
    CustomerAutocomplete,
    CustomerFormDialog
  },

  mixins: [sumColumnMixin, currencyFormatMixin, PreviewMixin, dateMixin, permissionMixin],

  props: {
    formMessage: { type: String, default: null },
    isCreateFromQuote: { type: Boolean, default: false }
  },

  data() {
    return {
      displayDialog: false,

      sendCopy: false,

      form: createOrderModel(),
      rules: createModelRules(),
      formErrors: {},
      isFormValid: false,

      customer: {},
      customers: [],
      customersFilter: "",
      customerPriceOverrides: [],

      quoteId: null
    };
  },

  computed: {
    entitySettings() {
      return this.$store.state.entitySettings.detail;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    isCustomerSelected() {
      return !!this.form.customer;
    },

    hasItems() {
      return this.form.items.length > 0;
    },

    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.form.id;
    },

    formLoading() {
      const action = this.$store.getters["order/loading"];

      return action("create") || action("update") || action("detail");
    },

    formTitle() {
      return this.isItemUpdate ? `Update Order` : `New Order`;
    },

    currency() {
      const entitySettings = this.entitySettings || {};
      return this.form.currency || entitySettings.default_currency || "";
    },

    entity() {
      return this.$store.state.auth.entity;
    },

    formHasErrors() {
      return !!Object.keys(this.formErrors).length;
    },

    taxTitle() {
      return this.form.tax_title || "Taxes";
    },

    taxPercentage() {
      const entitySettings = this.entitySettings || {};
      return entitySettings.default_tax_percentage || this.form.tax_percentage;
    },

    populateCustomer() {
      if (this.isCreateFromQuote) {
        return true;
      }
      return this.isItemUpdate;
    },

    notifications() {
      const alerts = [];
      const customer = this.form.customer;
      if (!customer) {
        alerts.push({
          type: "warning",
          message: `<strong>Customer field is required</strong>, you can't create an order without a customer.`
        });
      }
      return alerts;
    },

    disableSave() {
      return !!(!this.isCustomerSelected || !this.hasItems || this.formLoading);
    }
  },

  beforeDestroy() {
    this.$store.commit(`customer/${CLEAR_FILTERS}`);

    this.$store.dispatch(`order/clearState`, "detail");
    this.$store.dispatch(`order/clearState`, "list");
    this.form = createOrderModel();
  },

  methods: {
    new() {
      this.resetValidation();

      const order = createOrderModel();

      this.form = order;

      this.form.owner_entity = this.ownerEntity.id;
      this.customer = {};

      this.$nextTick(() => {
        this.$refs.customer_autocomplete.clear();
        this.$refs.order_items.update(order.items);
      });

      this.displayDialog = true;
    },

    open(item) {
      if (item) {
        if (item.id) {
          this.form = { ...item };
          this.form.customer = this.form.target_entity;

          // Back-end expects ids instead of objects
          this.form.target_entity = this.form.target_entity.id;
          this.form.owner_entity = this.form.owner_entity.id;

          // the item passed if prop isCreateFromQuote = true, then its id is the quote's id
          if (this.isCreateFromQuote) {
            this.quoteId = item.id;

            //  transform quote structure to order's structure
            item.items = this.transformQuoteData(item.items);

            this.form.items = item.items;

            //having an id will trigger an update
            this.form.id = null;

            // we don't want to use the quotes reference_no, we want the system generate this for us
            this.form.reference_no = null;
          }

          // Add selected customer to customer select
          this.$store.commit(`customer/${SET_LIST}`, [
            { target_entity: { id: item.target_entity.id, name: item.target_entity.name } }
          ]);

          this.customersFilter = item.target_entity.name;
        }

        this.customer = { target_entity: { ...item.target_entity } };
        this.displayDialog = !!item;
        Vue.nextTick(() => {
          if (this.$refs.customer_autocomplete !== undefined) {
            this.$refs.customer_autocomplete.select(item.target_entity);
          }
          this.displayDialog && this.$refs.order_items.update(item.items);
        });

        this.displayDialog = true;
      }
    },

    resetValidation() {
      this.formErrors = {};
    },

    newCustomer() {
      this.$refs.customer_editor.new();
    },

    customerCreated() {
      this.$refs.customer_editor.close();
    },

    performSaveOption(saveOption) {
      switch (saveOption.value) {
        case "saveAndSend":
          this.submitForm(true);
          break;

        case "send":
          this.sendEmail();
          break;

        default:
          this.submitForm();
      }
    },

    async submitForm(send = false) {
      this.resetValidation();

      this.form.owner_entity = this.entity;
      this.form.tax = this.computeTax;
      this.form.tax_percentage = this.taxPercentage;

      if (this.isCreateFromQuote) {
        this.form.quotes = [this.quoteId];
      }

      if (typeof this.customer.target_entity === "object" && typeof this.form.target_entity === "object") {
        this.form.target_entity = { ...this.customer.target_entity, ...this.form.target_entity };
      }

      this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      if (this.formHasErrors) {
        return;
      }

      if (this.form.requires_delivery) {
        this.$emit("requiresDelivery", this.form);
      }

      this.$emit("updated", this.form);

      if (send) {
        // this.sendEmail();
      }

      this.close();
    },

    close() {
      this.displayDialog = false;
    },

    closeForm() {
      this.resetValidation();
      this.$emit("closed");
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async fetchCustomers() {
      this.$store.commit(`customer/${ADD_FILTERS}`);
      await this.$store.dispatch("customer/list");
    },

    async sendUpdate() {
      try {
        await this.$store.dispatch("order/update", { id: this.form.id, data: this.form });
        this.displayMessage({ text: "Order updated successfully.", type: "success" });
      } catch (error) {
        this.setFormErrors(error.response.data);
        this.displayMessage({ text: "Unable to update the order.", type: "error" });
      }
    },

    onCurrencyChange(value) {
      this.form.currency = value;
    },

    onCustomerChange(value) {
      this.form.customer = value;
      this.form.target_entity = value.target_entity;
      this.debounceInput(this.fetchCustomerPriceOverride);
    },

    async sendCreate() {
      try {
        const response = await this.$store.dispatch("order/create", this.form);
        this.form = response.data;
        this.displayMessage({ text: "Order created successfully.", type: "success" });
      } catch (error) {
        this.setFormErrors(error.response.data);
        this.displayMessage({ text: "Unable to update the quote.", type: "error" });
      }
    },

    transformQuoteData(items) {
      let new_data = [];

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        let data = {
          currency: item.currency,
          quantity: item.quantity,
          total: item.total,
          description: item.description
        };

        if (item.item) {
          data.item = Object.assign({}, item.item);
          new_data.push(data);
        }
      }

      return new_data;

      /*
      // TODO: remove implementation
      return items.map(function(item) {
        let data = {
          currency: item.currency,
          item: item.item == null ? null : { ...item.item },
          quantity: item.quantity,
          total: item.total,
          description: item.description
        };
 
        if (item.item == null) delete data.item;
 
        return data;
      });
      */
    },

    onItemsUpdate(items) {
      this.form.items = items;
      this.form.subtotal = this.calculateSubtotal();
      this.form.tax = this.calculateTax();
      this.form.total = this.calculateTotal();
    },

    calculateSubtotal() {
      let subtotal = this.sumColumn("total", this.form.items);
      return Math.round((Number(subtotal) + Number.EPSILON) * 100) / 100;
    },

    calculateTax() {
      let tax = this.sumColumn("total", this.form.items) * this.taxPercentage;
      return Math.round((tax + Number.EPSILON) * 100) / 10000;
    },

    calculateTotal() {
      let total = this.calculateSubtotal() + (this.calculateTax() || 0);
      return Math.round((total + Number.EPSILON) * 100) / 100;
    },

    fetchCustomerPriceOverride() {
      return api.price_override_customer
        .fetch_active_price_overrides(this.form.customer.target_entity)
        .then(response => {
          this.customerPriceOverrides = response.data;
        });
    },

    debounceInput(func, timeout = 300) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        return func();
      }, timeout);
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>

<style>
.order-form-dialog .v-alert + .v-alert {
  margin-top: 16px;
}

.order-form-dialog .order-items {
  min-height: 200px;
}
</style>
