import { http } from "@/vuex-bcore";

const url = "pos/orders";

function fetch_for_customer(customerId) {
  return http.get(`${url}/?customer=${customerId}`);
}

function fetch_delivery(order_id) {
  return http.get(`${url}/${order_id}/delivery/`);
}

function fetch_invoices(order_id) {
  return http.get(`${url}/${order_id}/invoices/`);
}

function is_invoiced(order_id) {
  return http.get(`${url}/${order_id}/is-invoiced/`);
}

function update(order_id, payload) {
  return http.patch(`${url}/${order_id}/`, payload);
}

function send(order_id) {
  return http.post(`${url}/${order_id}/send/`);
}

function add_quote_items(order_id, payload) {
  return http.patch(`${url}/${order_id}/add_quote_items/`, payload);
}

function previewUrl(quote_id) {
  return `/${url}/${quote_id}/preview/`;
}

export default {
  fetch_for_customer,
  fetch_delivery,
  fetch_invoices,
  is_invoiced,
  update,
  send,
  add_quote_items,
  previewUrl
};
