import {
  ADD_ERRORS,
  ADD_FILTERS,
  ADD_REQUEST,
  CLEAR_ERRORS,
  CLEAR_FILTERS,
  REMOVE_REQUEST,
  SET_DETAIL,
  SET_LIST,
  SET_META
} from "../mutation-types";
import { MutationTree } from "vuex";
import { ErrorObject, ModelState } from "../types";
import axios from "axios";

const getMutations = <S>(): MutationTree<ModelState<S>> => {
  return {
    [SET_LIST](state, list): void {
      state.list = list;
    },
    [SET_DETAIL](state, detail): void {
      state.detail = detail;
    },
    [SET_META](state, meta): void {
      state.meta = { ...state.meta, ...meta };
    },
    [ADD_REQUEST](state, action): void {
      const CancelToken = axios.CancelToken;
      state.requests[action] = CancelToken.source();
      state.requests = { ...state.requests };
    },
    [REMOVE_REQUEST](state, action): void {
      delete state.requests[action];
      state.requests = { ...state.requests };
    },
    [ADD_FILTERS](state, filters): void {
      // parse filter values
      for (const i in filters) {
        if (filters.hasOwnProperty(i)) {
          if (Array.isArray(filters[i])) {
            filters[i] = filters[i].join();
          }
          if (filters[i] === null) {
            filters[i] = "";
          }
        }
      }

      state.filters = { ...state.filters, ...filters };
    },
    [CLEAR_FILTERS](state): void {
      state.filters = {};
    },
    [ADD_ERRORS](state, errorObject: ErrorObject): void {
      state.errors = { ...errorObject, ...state.errors };
    },
    [CLEAR_ERRORS](state): void {
      state.errors = {};
    }
  };
};

export default getMutations;
