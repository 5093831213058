import {
  ADD_ERRORS,
  CLEAR_ERRORS,
  LOADED,
  LOADING,
  SET_ENTITY,
  SET_ENTITY_PERMISSIONS,
  SET_USER
} from "../mutation-types";

import { AuthState, ErrorObject } from "../types";
import { MutationTree } from "vuex";

const mutations: MutationTree<AuthState> = {
  [LOADING](state) {
    state.loading = true;
  },
  [LOADED](state) {
    state.loading = false;
  },
  [ADD_ERRORS](state, errorObject: ErrorObject) {
    state.errors = { ...errorObject, ...state.errors };
  },
  [CLEAR_ERRORS](state) {
    state.errors = {};
  },
  [SET_ENTITY](state, entity) {
    state.entity = entity;
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_ENTITY_PERMISSIONS](state, permissions: Array<string>) {
    state.permissions = permissions;
  }
};

export default mutations;
