const permissionMixin = {
  methods: {
    /**
     *Determine if the current user has the required permissions for the organization
     * @param action (django permission as string eg: change_invoice)
     * @param backendPackage (django package default 'invoiceboost')
     * https://docs.djangoproject.com/en/3.0/topics/auth/default/#permissions-and-authorization)
     */
    hasPermission(action, backendPackage = "invoiceboost") {
      const permissions = this.$store.getters["auth/hasEntityPermission"];
      const permission = `${backendPackage}.${action}`;
      return permissions(permission);
    }
  }
};

export default permissionMixin;
