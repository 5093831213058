<template>
  <v-card class="invoice-editor" elevation="0">
    <!-- <v-card-title class="display-1"> -->
    <v-card-title class="">
      <span>{{ context.page_title }}</span>

      <v-spacer />
      <span>Step {{ active_step }} of 4</span>
    </v-card-title>

    <v-divider />

    <v-card-text class="mt-2">
      <!-- DISPLAY GENERIC ERRORS -->
      <non-field-errors :errors="context.form.errors"></non-field-errors>

      <!-- DISPLAY FORM NOTIFICATIONS -->
      <v-row v-if="context.notifications.length">
        <v-col>
          <v-alert
            v-for="(alert, index) in context.notifications"
            border="left"
            elevation="0"
            class="mb-0 pl-6"
            :type="alert.type"
            :key="index"
            :icon="false"
            colored-border
            dense
            tile
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="alert.message"></span>
          </v-alert>
        </v-col>
      </v-row>

      <v-stepper v-model="active_step" vertical>
        <!-- STEP 1 -->
        <v-stepper-step step="1" :complete="active_step > 1" :rules="[() => is_step1_valid]" editable>
          Bill to
        </v-stepper-step>

        <v-stepper-content step="1">
          <div>
            <v-text-field
              name="InvoiceEditor[SearchCustomer]"
              class="search-field"
              placeholder="Type customer name..."
              append-icon="mdi-magnify"
              hide-details="auto"
              v-model="form.search_customer"
              :error-messages="form.field_errors.target_entity"
              :loading="context.form.is_loading"
              @keyup="context.search_customers"
              outlined
              dense
            />
            <a class="text-decoration-none mt-2 d-inline-block" @click.prevent="new_customer">
              <v-icon>mdi-plus</v-icon>
              Add new customer
            </a>
          </div>

          <v-card class="search-results" flat>
            <v-card-title class="pa-0">Search result</v-card-title>
            <v-divider />

            <v-card-text class="pa-0 mt-4 rounded-0">
              <v-radio-group v-model="form.fields.target_entity" class="pa-0 ma-0" hide-details="true">
                <v-radio
                  v-for="customer in form.customer_search_results"
                  class="py-1"
                  hide-details="auto"
                  @click="set_customer(customer)"
                  :key="customer.id"
                  :label="customer.name"
                  :value="customer.target_entity"
                />
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-stepper-content>

        <!-- STEP 2 -->
        <v-stepper-step step="2" :complete="active_step > 2" :rules="[() => is_step2_valid]" editable>
          Edit Header Details
        </v-stepper-step>

        <v-stepper-content step="2">
          <!-- FIELD: INVOICE DATE -->
          <v-row>
            <v-col>
              <label class="c-label">Invoice Date</label>

              <v-dialog
                ref="invoice_date_dialog"
                v-model="form.display_date_calendar"
                :return-value.sync="form.fields.date"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    name="InvoiceEditor[InvoiceDate]"
                    hide-details="auto"
                    append-icon="mdi-calendar"
                    v-model="form.fields.date"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="context.is_loading"
                    :error-messages="form.field_errors.date"
                    @click:append="on.click"
                    outlined
                    readonly
                  />
                </template>

                <v-date-picker v-model="form.fields.date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="form.display_date_calendar = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.invoice_date_dialog.save(form.fields.date)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <!-- FIELD: DUE DATE -->
          <v-row>
            <v-col>
              <label class="c-label">Due Date</label>

              <v-dialog
                ref="due_date_dialog"
                v-model="form.display_due_date_calendar"
                :return-value.sync="form.fields.due_date"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    name="InvoiceEditor[InvoiceDate]"
                    hide-details="auto"
                    append-icon="mdi-calendar"
                    v-model="form.fields.due_date"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="context.is_loading"
                    :error-messages="form.field_errors.due_date"
                    @click:append="on.click"
                    outlined
                    readonly
                  />
                </template>
                <v-date-picker v-model="form.fields.due_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="form.display_due_date_calendar = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.due_date_dialog.save(form.fields.due_date)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <!-- FIELD: REFERENCE -->
          <v-row>
            <v-col>
              <label class="c-label">
                Invoice No.
                <div style="font-size: 14px; color: gray; margin-left: 5px"></div>
              </label>

              <v-text-field
                name="InvoiceEditor[ReferenceNumber]"
                hide-details="auto"
                v-model="form.fields.reference_no"
                :error-messages="form.field_errors.reference_no"
                :messages="['(Auto generated if left blank.)']"
                outlined
              />
            </v-col>
          </v-row>

          <!-- FIELD: INVOICE NO # -->
          <v-row>
            <v-col>
              <label class="c-label">PO No.</label>
              <div class="d-flex align-baseline">
                <v-text-field
                  name="InvoiceEditor[PoNumber]"
                  hide-details="auto"
                  v-model="form.po_number"
                  append-icon="mdi-information"
                  :error-messages="form.field_errors.po_number"
                  :disabled="context.is_loading"
                  @click:append="context.toggle_po_info"
                  outlined
                />
                <!-- append-outer-icon="mdi-information" -->
              </div>

              <div v-if="form.display_po_info" class="purchace-order-info mt-8">
                {{ $store.state.theme.appName }} automatically generates purchase order numbers when left blank to
                maximize your chance of qualifying for level 2 and level 3 pricing if a customer pays by credit card.
              </div>
            </v-col>
          </v-row>

          <!-- CUSTOMER MESSAGE -->
          <v-row>
            <v-col>
              <label class="c-label">Notes</label>
              <v-textarea
                v-model="form.fields.customer_message"
                :error-messages="form.field_errors.customer_message"
                outlined
              />
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- STEP 3 -->
        <v-stepper-step step="3" :complete="active_step > 3" :rules="[() => is_step3_valid]" editable>
          Add Invoice Items
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-alert
            v-show="!context.has_items"
            class=""
            border="left"
            type="warning"
            elevation="0"
            :icon="false"
            colored-border
            tile
          >
            <strong>Invoice Items are required.</strong>
          </v-alert>

          <div class="mt-2">
            <a class="text-decoration-none d-inline-block" @click.prevent="new_item">
              <v-icon>mdi-plus</v-icon>
              Add item or service
            </a>
          </div>

          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">Item description</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ item.description }}</td>
                <td class="text-right">
                  {{ `${item.qty} x ${format_currency(item.price)} = ${format_currency(item.total)}` }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Subtotal</td>
                <td class="text-right">
                  {{ `${format_currency(context.subtotal)}` }}
                </td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td class="text-right">
                  {{ `${format_currency(context.grand_total)}` }}
                </td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-stepper-content>

        <!-- STEP 4 -->
        <v-stepper-step step="4" :complete="active_step > 4" :rules="[() => is_step4_valid]" editable>
          E-mail Invoice
        </v-stepper-step>

        <v-stepper-content step="4">
          <div class="mt-4">
            <v-checkbox
              v-model="context.form.email_self"
              label="Send me a copy"
              hide-details="auto"
              class="ma-0 pa-0 d-inline-block"
              :disabled="context.disable_save_and_send"
            />
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>

    <v-card-actions class="justify-center flex-column">
      <template v-if="active_step < 4">
        <v-btn
          color="primary"
          class="continue d-block"
          style="width: 100%"
          @click="context.next_step"
          :loading="context.is_loading"
          large
        >
          CONTINUE
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          color="primary"
          class="continue d-block"
          style="width: 100%"
          @click="context.confirm_send_invoice"
          :disabled="context.disable_save_and_send"
          large
        >
          SEND INVOICE
        </v-btn>

        <v-btn
          class="save-draft d-block mt-8"
          style="width: 100%"
          @click="context.confirm_save"
          :disabled="context.disable_save"
          large
          outlined
        >
          SAVE INVOICE
        </v-btn>
      </template>
    </v-card-actions>

    <ItemEditor ref="item_editor" @add_item="on_add_item" />

    <!-- DIALOG: ERROR -->
    <v-dialog v-model="form.display_dialog_error" max-width="330" :overlay-opacity="0.95">
      <v-card class="invoice-editor-confirm-dialog pb-6">
        <div class="pa-2">
          <v-btn class="close-dialog" @click="context.close_dialogs" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title class="headline">UH OH!</v-card-title>

        <v-card-text class="content text-center black--text">
          <p>Something went wrong when trying to submit your invoice.</p>
          <p>
            Please verify and try again. If the issue persist, send us an email at
            <a href="mailto:help@accruvia.com">help@accruvia.com</a>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG: CONFIRM SEND -->
    <v-dialog v-model="form.display_dialog_send" max-width="320" :overlay-opacity="0.95">
      <v-card class="invoice-editor-confirm-dialog">
        <div class="pa-2">
          <v-btn class="close-dialog" @click="context.close_dialogs" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title class="headline">CONFIRM SEND</v-card-title>

        <v-card-text class="content text-center">
          <p>You're about to save and email an Invoice!</p>
          <p>Double check to make sure everything looks good or push send now.</p>
        </v-card-text>

        <v-card-actions class="justify-center flex-column">
          <v-btn
            class="action send-now"
            color="primary"
            large
            :loading="context.is_loading"
            :disabled="context.disable_save_and_send"
            @click="context.send_invoice"
          >
            SEND NOW
          </v-btn>
          <v-btn
            class="action edit-invoice mt-6 ma-0"
            :disabled="context.is_loading"
            large
            outlined
            @click="edit_invoice"
          >
            EDIT INVOICE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG: CONFIRM SAVE  -->
    <v-dialog v-model="form.display_dialog_save" max-width="320" :overlay-opacity="0.95">
      <v-card class="invoice-editor-confirm-dialog">
        <div class="pa-2">
          <v-btn class="close-dialog" @click="context.close_dialogs" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title class="headline">CONFIRM SAVE</v-card-title>

        <v-card-text class="content text-center">
          <p>You're about to save an Invoice!</p>
          <p>Double check to make sure everything looks good or push save.</p>
        </v-card-text>

        <v-card-actions class="justify-center flex-column">
          <v-btn
            class="action send-now"
            color="primary"
            large
            :loading="context.is_loading"
            :disabled="context.disable_save"
            @click="context.save_invoice(false)"
          >
            SAVE
          </v-btn>
          <v-btn
            class="action edit-invoice mt-6 ma-0"
            large
            :disabled="context.is_loading"
            outlined
            @click="edit_invoice"
          >
            EDIT INVOICE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG: CONFIRM SUCCESS -->
    <v-dialog v-model="form.display_dialog_success" max-width="360" :overlay-opacity="0.95">
      <v-card class="invoice-editor-confirm-dialog">
        <div class="pa-2">
          <v-btn class="close-dialog" @click="context.close_dialogs" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title class="headline">SUCCESS</v-card-title>

        <v-card-text class="content text-center">
          <p>Your invoice has successfully been submitted</p>
          <p>Sit tight while we work our magic to get you paid, or create another invoice in the meantime.</p>
        </v-card-text>

        <v-card-actions class="justify-center flex-column">
          <v-btn class="action send-now" color="primary" link :to="{ name: 'org-invoices' }" large>VIEW INVOICES</v-btn>
          <v-btn class="action edit-invoice mt-6 ma-0" link @click.prevent="context.new" large outlined>
            CREATE NEW INVOICE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CustomerEditorMobile ref="customer_editor_mobile" @created="on_customer_create"></CustomerEditorMobile>
  </v-card>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import InvoicePayments from "@/invoiceboost/components/invoice/InvoicePayments.vue";

import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import InvoiceItems from "@/invoiceboost/components/invoice/InvoiceItems.vue";
import InvoiceMedia from "@/invoiceboost/components/invoice/InvoiceMedia.vue";
import CurrencySelect from "@/invoiceboost/components/CurrencySelect.vue";

import CustomerEditorMobile from "@/invoiceboost/components/customer/CustomerEditorMobile.vue";

import ItemEditor from "./ItemEditor.vue";
import sumColumnMixin from "@/mixins/sumColumn";

import currency from "@/utilities/currency.js";


const fieldStepMap = {
  1: ["target_entity"],
  2: ["date", "due_date", "reference_no", "po_number", "customer_message"],
  3: [],
  4: [],
};

export default {
  name: "InvoiceEditorMobile",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    InvoiceItems,
    InvoiceMedia,
    InvoicePayments,
    CurrencySelect,
    ItemEditor,
    CustomerEditorMobile
  },

  mixins: [sumColumnMixin],

  props: {
    context: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {};
  },

  computed: {
    form: {
      get() {
        return this.context.form;
      },
      set(value) {
        this.context.form.fields = value;
      }
    },

    items() {
      return this.context.form.fields.items;
    },

    active_step: {
      get() {
        return this.context.form.active_step;
      },

      set(value) {
        return (this.context.form.active_step = value);
      }
    },

    is_step1_valid() {
      return !this.step_has_errors(1);
    },

    is_step2_valid() {
      return !this.step_has_errors(2);
    },

    is_step3_valid() {
      return !this.step_has_errors(3);
    },

    is_step4_valid() {
      return !this.step_has_errors(4);
    },


  },

  methods: {
    new_customer() {
      this.$refs.customer_editor_mobile.new();
    },

    new_item(item) {
      this.$refs.item_editor.new();
    },

    edit_item(item) {
      this.$refs.item_editor.open(item);
    },

    on_add_item(item) {
      this.items.push(item);
    },

    on_update_item() {
    },

    on_customer_create(customer) {
      this.$refs.customer_editor_mobile.close();
      this.set_customer(customer);
      this.form.customer_search_results.push(customer);
      this.context.form.fields.target_entity = customer.target_entity;
    },

    update_form(form) {
      // This method is currently only used in the desktop version
    },

    set_customer(value) {
      this.context.form.fields.customer = value;
    },

    edit_invoice() {
      this.context.form.active_step = 1;
      this.context.close_dialogs();
    },

    format_currency: currency.format,

    step_has_errors(step) {
      const fields = Object.keys(this.form.field_errors);

      for (let j = 0; j < fields.length; j++) {
        // If a field error is found, let the user know that there's something wrong
        if (fieldStepMap[step].some(f => f === fields[j])) {
          return true;
        }
      }

      return false;
    },

  }
};
</script>

<style scoped>
.invoice-editor > .v-card__title {
  justify-content: space-between;
  flex-grow: 1;
  padding: 8px 8px;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.2;
}

.invoice-editor > .v-divider {
  margin: 0 8px;
}

.invoice-editor > .v-card__text {
  padding: 0 8px;
}

.invoice-editor > .v-card__actions {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  padding: 10px 36px 10px 56px;
  background-color: #fff;
}

.invoice-editor .v-stepper {
  box-shadow: none;
}

.invoice-editor .v-stepper .v-stepper__step {
  padding: 16px 0;
}

.invoice-editor .v-stepper .v-stepper__content {
  margin: 0 0 16px 0 !important;
  padding: 0 16px 0 36px;
}

.invoice-editor .v-stepper .v-stepper__wrapper {
  /* overflow: visible; */
}

.invoice-editor .search-results {
  margin-top: 16px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px !important;
  padding: 5px 16px 16px !important;
}

.invoice-editor .search-results .v-card__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 2.5 !important;
}

.invoice-editor .v-stepper .c-label {
  line-height: 2.5;
  font-size: 18px;
  color: #000;
}

.invoice-editor .purchace-order-info {
  padding: 25px;
  border: 1px solid gray;
  color: #000;
}

.invoice-editor .v-alert + .v-alert {
  margin-top: 20px;
}

.invoice-editor #invoice-items {
  min-height: 200px;
}

.invoice-editor .v-alert .v-icon {
  margin-right: 8px;
}

.v-dialog .invoice-editor-confirm-dialog .headline {
  text-align: center;
  color: #437b56;
  font-weight: bold;
  word-break: break-word;

  display: block;
  padding: 0;
  margin: 8px 24px 24px;
}

.v-dialog .invoice-editor-confirm-dialog .close-dialog {
  min-width: auto !important;
  padding: 0 8px !important;
}

.v-dialog .invoice-editor-confirm-dialog .v-card__actions {
  padding-bottom: 42px;
}

.v-dialog .invoice-editor-confirm-dialog .v-card__actions .action {
  width: 180px;
}
</style>