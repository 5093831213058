/*
"translations" is a dummy implementation of Django's translation module.
TODO: Implement Django Translations
 */

function gettext(str) {
  return str;
}

function ngettext(str, str2, num) {
  // The ngettext function provides an interface to pluralize words and phrases
  return num === 1 ? str : str2;
}

function interpolate(formats, nums, bool) {
  /*
  const formats = ngettext(
  'There is %s object. Remaining: %s',
  'There are %s objects. Remaining: %s',
  11
  );
  const string = interpolate(formats, [11, 20]);
  // string is 'There are 11 objects. Remaining: 20'
   */
  return formats.join(" ");
}

function get_format(str) {
  return str;
}

function gettext_noop(str) {
  // This emulates the gettext function but does nothing, returning whatever is passed to it

  return str;
}

function pgettext(str) {
  // https://docs.djangoproject.com/en/3.1/topics/i18n/translation/#contextual-markers
  return str;
}

function npgettext(str) {
  // https://docs.djangoproject.com/en/3.1/ref/utils/#django.utils.translation.npgettext
  return str;
}

function pluralidx(str) {
  // https://docs.djangoproject.com/en/3.1/ref/templates/builtins/#std:templatefilter-pluralize
  return str;
}

export default {
  gettext,
  ngettext,
  interpolate,
  get_format,
  gettext_noop,
  pgettext,
  npgettext,
  pluralidx,
};