<template>
  <Theme>
    <v-app id="inspire" :class="[viewport_class]">
      <slot v-if="is_ready" />

      <GlobalSnackbarMessages v-if="is_ready" />
    </v-app>
  </Theme>
</template>

<script>
import Theme from "@/views/layouts/Theme.vue";
import GlobalSnackbarMessages from "@/bcore/components/GlobalSnackbarMessages.vue";

import viewport from "@/mixins/viewport";

export default {
  name: "BaseLayout",

  components: {
    Theme,
    GlobalSnackbarMessages,
  },

  mixins: [viewport],

  data() {
    return {};
  },

  computed: {
    is_ready() {
      let anonymous_route_names = [
        "first-visit",
        "invite-redemption",
        "login",
        "login",
        "signup",
        "forgot-password",
        "reset-password-confirm"
      ];
      let is_anon_view = anonymous_route_names.includes(this.$route.name);
      let state = this.$store.state;
      let auth = state.auth;

      // console.log("state", state);
      // console.log("state.theme", state.theme);
      // console.log("state.auth", state.auth);
      // console.log("state.entitySettings", state.entitySettings);
      // console.log("$route.name", this.$route.name, "path", this.$route.path);

      // console.log("is_anon_view", is_anon_view);
      // console.log("is_ready", !!(state.theme && state.auth && state.entitySettings && state.entitySettings.detail));

      // By the time this layout is rendered, whoami has populated an anonymous user, a super user or a regular user
      // and the organization entity settings are loaded
      return is_anon_view || (auth && auth.user && auth.entity && state.entitySettings.detail && auth.user.user_id && !auth.user.is_superuser);
    },

    viewport_class() {
      return this.viewport.is_desktop ? "is-desktop" : "is-mobile";
    }
  },

  methods: {}
};
</script>
