import axios from "axios";
import { forceLogout, getEntity } from "./utils.js";

/**
 * Axios request interceptors
 * https://github.com/axios/axios#interceptors
 */
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken"
});

http.interceptors.request.use(
  async (reqConfig) => {
    const entity = getEntity();

    reqConfig.headers = reqConfig.headers || {};

    // TODO: move entity-id from local storage into a cookie
    /*
    On user login the backend should use the same logic as in app/user.js get_default_organization
    in order to set the entity-id cookie at session start
    this might as well be an https cookie and an endpoint would allow to change it
    while returning the corresponding entity settings and permissions in one go
    */
    if (entity && entity.id) {
      // Set the entity.id X-ENTITY-ID url param for multi-tenancy
      reqConfig.headers["X-ENTITY-ID"] = entity.id;
    }

    return reqConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      return forceLogout();
    }

    return Promise.reject(error);
  }
);

export default http;
