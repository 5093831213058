<template>
  <v-card class="supplier-viewer" elevation="0">
    <!-- TITLE -->
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn class="px-0 mr-3 align-center" title="Go back" @click="$router.go(-1)" outlined icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ supplier.name }}</span>

      <v-spacer />
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#tab-general">General</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- GENERAL supplier INFO FORM -->
        <v-tab-item value="tab-general">
          <v-container class="pa-0 px-4 mt-6 mb-4" fluid>
            <v-row>
              <v-col class="col-2">Name</v-col>
              <v-col>
                <strong>{{ supplier.name }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Company</v-col>
              <v-col>
                <strong>{{ supplier.company }}</strong>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-2">Email</v-col>
              <v-col>
                <strong>{{ supplier.email }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Address Line 1</v-col>
              <v-col>
                <strong>{{ supplier.address.address_line1 }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Address Line 2</v-col>
              <v-col>
                <strong>{{ supplier.address.address_line2 }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">City</v-col>
              <v-col>
                <strong>{{ supplier.address.city }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">State</v-col>
              <v-col>
                <strong>{{ supplier.address.state }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Zip code</v-col>
              <v-col>
                <strong>{{ supplier.address.code }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">Country</v-col>
              <v-col>
                <strong>{{ supplier.address.country_name }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2"></v-col>
              <v-col class="">
                <v-btn color="primary" @click.stop="edit_supplier" outlined>Edit</v-btn>
              </v-col>
            </v-row>

            <!-- INVITE DETAILS -->
            <v-row v-if="!supplier.is_claimed">
              <v-col class="col-12">
                <v-divider />

                <InviteLink :entity="supplier" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- PAYMENT METHOD FORMS -->
        <v-tab-item value="tab-payment-methods">
          <div class="pt-10">
            <ach-account-form :supplier-id="supplier.id" />

            <credit-card-manager :supplier-id="supplier.target_entity" account="receivable"></credit-card-manager>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <supplier-form-dialog ref="supplier_editor" @updated="supplier_updated" />
  </v-card>
</template>

<script>
import SupplierFormDialog from "@/invoiceboost/components/supplier/SupplierFormDialog.vue";
import InviteLink from "@/invoiceboost/components/InviteLink.vue";

import api from "@/api";
// import utils from "@/utilities";

function create_model() {
  return {
    id: null,
    name: "",
    email: "",
    company: null,
    is_claimed: false,
    address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      code: "",
      country: 0
    }
  };
}

export default {
  name: "SupplierDetails",

  components: {
    SupplierFormDialog,
    InviteLink
  },

  data() {
    return {
      tab: "tab-general",
      supplier: create_model()
    };
  },

  computed: {
    supplier_id() {
      return this.$route.params.id || null;
    }
  },

  mounted() {
    this.fetch_details(this.supplier_id);
  },

  methods: {
    fetch_details(supplier_id) {

      api.suppliers.fetch(supplier_id)
        .then(response => {
          this.supplier = Object.assign(create_model(), response.data);
        });
    },

    edit_supplier() {
      // create a copy of the given data object to prevent live editing of the data displayed on the current list
      const data = Object.assign(create_model(), this.supplier);
      this.$refs.supplier_editor.open(data);
    },

    supplier_updated(supplier_data) {
      this.supplier = supplier_data;
    }
  }
};
</script>

<style>
</style>