<template>
  <div class="creditcard-manager">
    <v-list flat subheader>
      <!-- SECTION: ADD PAYMENT METHOD  -->
      <v-subheader v-if="hasPermission('add_cardprofile', 'pci_vault')">
        <v-btn text @click.stop="newCreditCard">
          <!-- FORCE NEW LINE -->
          <v-icon left>mdi-credit-card-plus-outline</v-icon>
          Add card
        </v-btn>
        <add-card-dialog
          ref="payment_methods_form"
          @CreditCardAdded="fetchPaymentMethods"
          @CreditCardUpdated="fetchPaymentMethods"
          @CardPaymentSubmitted="processPayment"
          :customer-id="customerId"
          :allow-direct-payment="allowDirectPayment"
        ></add-card-dialog>
      </v-subheader>

      <!-- MESSAGE TO DISPLAY WHEN THERE ARE NO CARDS REGISTERED -->
      <v-list-item v-if="creditCards.length === 0">
        <v-list-item-content>
          <v-card outlined :loading="isLoading" class="payment-method">
            <v-card-text class="payment-method__header">
              <v-row dense>
                <v-col>
                  <span v-if="isLoading" class="text--primary">Loading...</span>
                  <span v-if="!isLoading" class="text--primary">There are no cards registered yet.</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>

      <!-- LIST OF CREDIT CARDS -->
      <v-list-item v-for="(cc, index) in creditCards" :key="index">
        <v-list-item-content>
          <!-- PAYMENT METHOD -->

          <v-card
            outlined
            class="payment-method"
            :class="{ 'payment-method--selected': cc.state.is_selected && allowSelectors }"
          >
            <v-card-text class="payment-method__header">
              <v-row dense>
                <v-col cols="5">
                  <!-- CC SELECT MODE -->
                  <v-icon
                    v-if="allowSelectors"
                    left
                    @click="onSelectedCreditCard(cc)"
                    class="creditcard-manager__selector black-text"
                  >
                    <!-- FORCE NEW LINE -->
                    {{ cc.state.is_selected ? "mdi-radiobox-marked" : "mdi-radiobox-blank" }}
                  </v-icon>

                  <!-- CC TYPE -->
                  <v-icon left>mdi-credit-card-outline</v-icon>
                  <span class="text--primary font-weight-bold">{{ cc.card_type }}&nbsp;</span>
                  <!-- CC LAST DIGITS -->
                  <span class="text--primary">ending in&nbsp;</span>
                  <span class="text--primary font-weight-bold">{{ cc.card_number }}</span>
                </v-col>

                <v-col cols="3">Exp. {{ cc.exp_month }}/{{ cc.exp_year }}</v-col>

                <v-col cols="2">
                  <v-chip v-if="cc.is_default" small>Default card</v-chip>
                </v-col>

                <v-col>
                  <v-btn
                    v-if="allowExpand"
                    icon
                    class="expand-button"
                    @click="cc.state.is_expanded = !cc.state.is_expanded"
                  >
                    <v-icon>{{ cc.state.is_expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-show="cc.state.is_expanded" class="payment-method__details">
              <v-expand-transition>
                <div>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-row>
                      <v-col cols="6" no-gutters>
                        <v-switch
                          label="Set as default globally"
                          v-model="cc.is_default"
                          :disabled="cc.state.is_loading"
                          :loading="cc.state.is_toggling_default"
                          @change="toggleGlobalDefault(cc, $event)"
                        ></v-switch>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col class="text-right">
                        <v-btn
                          v-if="hasPermission('delete_cardprofile', 'pci_vault')"
                          text
                          small
                          :disabled="cc.state.is_loading"
                          @click="deleteCreditCard(cc)"
                          class="justify-end"
                          color="red"
                        >
                          <!-- just for format -->
                          Remove
                        </v-btn>
                        <v-btn
                          v-if="hasPermission('change_cardprofile', 'pci_vault')"
                          text
                          small
                          :disabled="cc.state.is_loading"
                          @click="editCreditCard(cc)"
                          color="success"
                        >
                          <!-- just for format -->
                          Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="displayAutoPay">
        <v-list-item-content>
          <auto-pay :target-id="supplierId || customerId" :account="account"></auto-pay>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- PAYMENT METHOD: DELETE DIALOG -->
    <delete-dialog
      :object-name="deleteObjectText"
      :open="displayDeleteDialog"
      @cancelled="closeDeleteDialog"
      @deleted="onDeleteDialogConfirmed"
    />
  </div>
</template>

<script>
import Vue from "vue";

import { ADD_MESSAGE } from "@/store/mutation-types";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";

import AddCardDialog from "@/invoiceboost/views/bill/AddCardDialog.vue";
import AutoPay from "@/invoiceboost/components/auto-pay/AutoPay";
import permissionMixin from "@/mixins/permission";
import uuidv4 from "uuid/v4";

const defaultObject = () => {
  return {};
};

export default Vue.extend({
  name: "CreditCardManager",

  components: {
    AddCardDialog,
    DeleteDialog,
    AutoPay
  },

  mixins: [permissionMixin],

  props: {
    allowExpand: { type: Boolean, default: true },
    allowSelectors: { type: Boolean, default: false },
    allowDirectPayment: { type: Boolean, default: false },
    customerId: { type: Number, default: 0 },
    supplierId: { type: Number, default: 0 },
    account: { type: String, default: "payable" },
    displayAutoPay: { type: Boolean, default: true }
  },

  data() {
    return {
      isLoading: false,
      displayDialog: false,
      displayDeleteDialog: false,

      deleteCard: null,
      selectedCard: null,
      cardStates: {},

      creditCards: []
    };
  },

  computed: {
    deleteObjectText() {
      const deleteCard = this.deleteCard;
      if (!this.deleteCard) {
        return "";
      }

      return `Payment Method ending in ${deleteCard.card_number}`;
    },

    ownerEntity() {
      return this.customerId ? { id: this.customerId } : this.$store.state.auth.entity.id;
    },

    isCustomerManager() {
      return !!(this.customerId && this.customerId !== this.$store.state.auth.entity.id);
    },

    baseUrl() {
      return !this.isCustomerManager ? "pci-vault/card-profile/" : "invoiceboost/customer-card-profile/";
    }
  },

  mounted() {
    this.fetchPaymentMethods();
  },

  methods: {
    fetchPaymentMethods() {
      this.isLoading = true;

      const cardFilter = this.customerId ? `?owner_entity=${this.customerId}` : "";

      this.$http
        .get(this.baseUrl + cardFilter)
        .then(response => {
          const creditCards = response.data.results;

          // Sort cards by id to keep the list consistent from old to new
          creditCards.sort(function (a, b) {
            return a.id - b.id;
          });

          for (let card of creditCards) {
            card.state = this.getCardState(card.id);
            card.state.is_selected = false;

            // Identify the default card and mark it as selected
            if (card.is_default) {
              card.state.is_selected = true;
              this.$emit("CreditCardSelected", card);
            }
          }

          this.creditCards = creditCards;
        })
        .catch(error => {
          this.displayMessage({
            text:
              "There was an error while fetching the data. Please try refreshing the page and contact us if this problem persist.",
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    newCreditCard() {
      this.$refs.payment_methods_form.new();
    },

    editCreditCard(creditCard) {
      this.$refs.payment_methods_form.edit(creditCard);
    },

    deleteCreditCard(creditCard) {
      this.deleteCard = creditCard;
      this.displayDeleteDialog = true;
    },

    toggleGlobalDefault(card, value) {
      const state = card.state;

      // Disables Remove and Update buttons while a server update is being performed
      state.is_loading = true;

      // Displays loading icon on switch component
      state.is_toggling_default = true;

      const url = `${this.baseUrl}${card.id}/`;
      const dataCopy = {
        is_default: card.is_default,
        owner_entity: this.ownerEntity.id
      };

      this.$http
        .patch(url, dataCopy)
        .catch(error => {
          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
        })
        .then(() => {
          this.fetchPaymentMethods();
        })
        .finally(() => {
          state.is_loading = false;
          state.is_toggling_default = false;
        });
    },

    // EVENT HANDLERS

    onDeleteDialogConfirmed() {
      const deleteCard = this.deleteCard;

      this.$http
        .delete(`${this.baseUrl}${this.deleteCard.id}`)
        .then(response => {
          const creditCards = this.creditCards;
          const foundCard = creditCards.find(card => {
            return card.id === deleteCard.id;
          });

          if (foundCard) {
            creditCards.splice(creditCards.indexOf(foundCard), 1);
          }

          this.closeDeleteDialog();
        })
        .catch(error => {
          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
        });
    },

    closeDeleteDialog() {
      this.deleteCard = null;
      this.displayDeleteDialog = false;
    },

    onSelectedCreditCard(card) {
      const creditCards = this.creditCards;

      for (let i = 0; i < creditCards.length; i++) {
        let item = creditCards[i];
        item.state.is_selected = item.id === card.id;
      }

      this.$emit("CreditCardSelected", card);
    },

    // UTILITIES
    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    getCardState(cardId) {
      const cardStates = this.cardStates;
      let state = cardStates[cardId];

      if (!state) {
        state = {
          is_selected: false,
          is_expanded: false,
          is_loading: false,
          is_toggling_default: false
        };

        Vue.set(cardStates, cardId, state);
      }

      return state;
    },

    processPayment(paymentInfo) {
      const temporary_card = {
        ...paymentInfo.card_profile,
        id: uuidv4(),
        temporary: true,
        state: { is_selected: true }
      };
      this.creditCards.push(temporary_card);
      this.onSelectedCreditCard(temporary_card);
    }
  }
});
</script>

<style>
.creditcard-manager {
  padding-bottom: 48px;
}

.creditcard-manager .v-list {
  padding-top: 10px;
  padding-bottom: 0;
}

.creditcard-manager .v-subheader {
  padding-left: 24px;
  padding-right: 24px;
}

.creditcard-manager .v-list-item__content {
  overflow: visible;
}

.creditcard-manager .payment-method__details {
  padding-top: 0;
}

.creditcard-manager .v-input--switch {
  margin: 0;
  padding: 0;
}

/*.creditcard-manager .v-input--switch .v-input__slot {*/
/*  margin: 0;*/
/*}*/

.creditcard-manager .payment-method.payment-method--selected {
  /* for consistency, using vuetify's success color */
  border: 1px solid #4caf50;
}

.creditcard-manager__selector {
  cursor: pointer;
}

.creditcard-manager .expand-button {
  position: absolute;
  right: 14px;
  top: 14px;
}

.creditcard-manager .v-card__actions {
  padding-left: 0;
  padding-right: 0;
}
</style>
