export default {
  computed: {
    viewport() {
      const breakpoint = this.$vuetify.breakpoint;
      const mdAndUp = breakpoint.mdAndUp;
      const smAndDown = breakpoint.smAndDown;

      return {
        is_desktop: mdAndUp,
        is_mobile: smAndDown,

        isLarge: breakpoint.lgAndUp,
        isMedium: breakpoint.md,
        isSmall: smAndDown
      };
    }
  }
};