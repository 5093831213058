import Home from "@/views/home/Home.vue";

import Bills from "@/invoiceboost/views/Bills.vue";
import BillDetails from "@/invoiceboost/components/bill/BillDetails.vue";

import Invoices from "@/invoiceboost/views/invoice/Invoices.vue";
import InvoiceDetails from "@/invoiceboost/components/invoice/InvoiceDetails.vue";

import InvoiceEditor from "@/invoiceboost/components/invoice-editor/InvoiceEditor.vue";

import Transactions from "@/invoiceboost/views/Transactions.vue";
import TransactionsDetails from "@/invoiceboost/components/transaction/TransactionDetails.vue";

const invoiceRoutes = [
  {
    name: "home",
    path: "/home",
    meta: { requiresAuth: true, noSuperuser: true },
    component: Home
  },

  {
    name: "org-bills",
    path: "/organization/bill/:id?",
    meta: { requiresAuth: true, noSuperuser: true },
    components: {
      default: Bills,
      side_panel: BillDetails
    },
    props: {
      // These props must match the components name
      default: {
        modelName: "bill"
      },

      side_panel: {}
    }
  },

  {
    name: "org-invoices-new",
    path: "/organization/invoices/new",
    meta: { requiresAuth: true, noSuperuser: true },
    component: InvoiceEditor
  },

  {
    name: "org-invoices-edit",
    path: "/organization/invoices/edit/:id", //TODO: EVENTUALLY THIS SHOULD SHARE A PAGE WITH DESKTOP EDIT WHEN WE REMOVE IT FROM THE MODAL.
    meta: { requiresAuth: true, noSuperuser: true },
    component: InvoiceEditor
  },

  {
    name: "org-invoices",
    path: "/organization/invoices/:id?",
    meta: { requiresAuth: true, noSuperuser: true },
    components: {
      default: Invoices,
      side_panel: InvoiceDetails
    },
    props: {
      default: {
        modelName: "invoice"
      },

      side_panel: {}
    }
  },

  {
    name: "org-transactions",
    path: "/organization/transactions",
    meta: { requiresAuth: true, noSuperuser: true },
    components: {
      default: Transactions,
      side_panel: TransactionsDetails
    },
    props: {
      default: {
        modelName: "transaction"
      },
      side_panel: {}
    }
  }
];

export default invoiceRoutes;
