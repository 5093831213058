<template>
  <div>
    <responsive-dialog
      :display-dialog="dialogOpened"
      :dialog-title="dialogTitle"
      :show-actions="false"
      @closed="closeDialog"
    >
      <v-row class="pt-4">
        <v-col cols="6">
          <p>
            Quote #:
            <b>{{ quote.reference_no }}</b>
          </p>
          <p>
            Customer:
            <b>{{ customer.target_entity.name }}</b>
          </p>
        </v-col>
        <v-col cols="6">
          <p>
            Valid until:
            <b>{{ expirationDate }}</b>
          </p>
          <p v-if="quote.requires_delivery">
            <b>Requires delivery</b>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Description</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Quantity</th>
                  <th class="text-left">Sub Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="quoteItem in items" :key="quoteItem.id">
                  <td>{{ quoteItem.description }}</td>
                  <td>{{ formatCurrency(quoteItem.price) }}</td>
                  <td>{{ quoteItem.quantity }}</td>
                  <td>{{ quoteItem.total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <template>
            <v-row>
              <v-col cols="12" md="8">
                <div v-if="!showAddToOrderExpansion">
                  <v-btn class="ma-2" small v-if="canEditQuote" text outlined @click="onClickEditItem(quote)">
                    Edit Quote
                  </v-btn>
                  <v-btn class="ma-2" small v-if="canAcceptQuote" text outlined @click="onClickAcceptQuote()">
                    {{ acceptQuoteText }}
                  </v-btn>
                  <v-btn class="ma-2" small v-if="canAcceptQuote" text outlined @click="onClickAddToOrder()">
                    Add to Order
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    small
                    v-if="isDeliveryRequired"
                    text
                    outlined
                    @click="onClickShowDeliveryDetails(quote)"
                  >
                    Delivery Details
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      Subtotal
                    </v-list-item-content>
                    <v-list-item-content class="align-end" text-align="end">
                      {{ formatCurrency(quote.subtotal, quote.currency) }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="quote.tax_percentage">
                    <v-list-item-content>{{ taxTitle }} ({{ quote.tax_percentage }}%)</v-list-item-content>
                    <v-list-item-content class="align-end" text-align="end">
                      {{ formatCurrency(quote.tax, quote.currency) }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      Total
                    </v-list-item-content>
                    <v-list-item-content class="align-end" text-align="end">
                      {{ formatCurrency(grandTotal) }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <quote-into-order
        ref="quote_into_order"
        v-if="showAddToOrderExpansion"
        @orderPicked="mergeQuote"
        @closed="closeDialog"
      />
    </responsive-dialog>
    <quote-form ref="quote_form" @updated="fetchItems" />
    <order-form ref="order_form" :is-create-from-quote="isCreateFromQuote" @updated="fetchItems" />
  </div>
</template>

<script>
import mixins from "vue-typed-mixins";
import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";
import { ADD_MESSAGE, SET_LIST } from "@/store/mutation-types";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import QuoteForm from "@/point-of-sale/components/quote/QuoteForm.vue";
import OrderForm from "@/point-of-sale/components/order/OrderForm.vue";
import QuoteIntoOrder from "@/point-of-sale/components/quote/QuoteIntoOrder.vue";
import permissionMixin from "@/mixins/permission";
import api from "@/api";

export default mixins(sumColumnMixin, currencyFormatMixin, permissionMixin).extend({
  name: "QuoteDetails",
  props: { isCustomer: { type: Boolean, default: false } },
  components: {
    ResponsiveDialog,
    OrderForm,
    QuoteForm,
    QuoteIntoOrder
  },
  data() {
    return {
      dialogOpened: false,
      items: [],
      quote: {},
      isCreateFromQuote: true,
      showAddToOrderExpansion: false,
      customer: {
        target_entity: {}
      },
      expirationDate: null,
      tax_percentage: 8.5
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["quote/loading"]("list");
    },

    dialogTitle() {
      return `Quote Summary`;
    },

    grandTotal() {
      let tax = Math.round((Number(this.quote.tax) + Number.EPSILON) * 100) / 100;
      let total = Math.round((Number(this.quote.subtotal) + Number.EPSILON) * 100) / 100;
      return total + (tax || 0);
    },

    taxTitle() {
      return this.tax_title || "Taxes";
    },

    acceptQuoteText() {
      return this.isCustomer ? "Accept Quote" : "Generate Order";
    },

    canAcceptQuote() {
      return this.quote.status === "valid" && this.hasPermission("change_quote", "point_of_sale");
    },

    canEditQuote() {
      return this.quote.status !== "ordered" && !this.isCustomer && this.hasPermission("change_quote", "point_of_sale");
    },

    isDeliveryRequired() {
      return this.quote.requires_delivery;
    }
  },

  methods: {
    open(item, displayAddToOrder = false) {
      this.showAddToOrderExpansion = displayAddToOrder;
      if (item) {
        this.quote = item;
        this.expirationDate = this.calculateExpirationDate(this.quote);
        this.items = item.items;
        if (item.id) {
          // Add selected customer to customer select
          this.$store.commit(`customer/${SET_LIST}`, [
            { target_entity: { id: item.target_entity.id, name: item.target_entity.name } }
          ]);
        }
        this.customer = { target_entity: { ...item.target_entity } };
        this.dialogOpened = !!item;
      }
    },

    calculateExpirationDate(quote) {
      let date = new Date(quote.created_at);
      date.setDate(date.getDate() + parseInt(quote.expiration_days));
      return date.toLocaleDateString();
    },

    fetchItems() {
      this.dialogOpened = false;
      this.$emit("updated");
    },

    mergeQuote(pickedOrder) {
      this.dialogOpened = false;
      this.mergeIntoOrder(pickedOrder);
      this.$emit("updated");
    },

    closeDialog() {
      this.showAddToOrderExpansion = false;
      this.dialogOpened = false;
      this.$emit("closed");
    },

    onClickAcceptQuote() {
      this.dialogOpened = false;
      let payload = { ...this.quote };
      delete payload.status;
      delete payload.expiration_days;
      this.$refs.order_form.open(payload);
    },

    onClickEditItem(item) {
      this.dialogOpened = false;
      this.$refs.quote_form.open(item);
    },

    onClickAddToOrder() {
      this.showAddToOrderExpansion = true;
    },

    onClickShowDeliveryDetails(item) {
      this.$router.push({ name: "quote-delivery", params: { quoteId: item.id } });
    },

    itemDescription(quoteItem) {
      return quoteItem.item === null ? quoteItem.description : quoteItem.item.description;
    },

    itemPrice(quoteItem) {
      return quoteItem.item === null ? quoteItem.total : quoteItem.item.price;
    },

    async mergeIntoOrder(pickedOrder) {
      try {
        let payload = {
          quotes_to_merge: [this.quote]
        };
        const response = await api.order.add_quote_items(pickedOrder.id, payload);
        this.form = response.data;
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Could not update order", type: "error" });
      }
    }
  }
});
</script>

<style scoped></style>
