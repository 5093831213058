<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
        name="DateRange[From]"
        label="From"
        v-model="dateRange.startDate"
        append-icon="mdi-calendar"
        :dense="dense"
        @click:append="showStartDateCalendar = true"
        outlined
      />

      <v-dialog v-model="showStartDateCalendar" max-width="300px">
        <v-date-picker v-model="dateRange.startDate"></v-date-picker>
      </v-dialog>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        name="DateRange[To]"
        label="To"
        v-model="dateRange.endDate"
        append-icon="mdi-calendar"
        :dense="dense"
        @click:append="showEndDateCalendar = true"
        outlined
      />

      <v-dialog v-model="showEndDateCalendar" max-width="300px">
        <v-date-picker v-model="dateRange.endDate"></v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DateRangeInput",

  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    dense: { type: Boolean, default: false }
  },

  data() {
    return {
      showStartDateCalendar: false,
      showEndDateCalendar: false,
      dateRange: {
        startDate: null,
        endDate: null
      }
    };
  },

  watch: {
    dateRange: {
      handler(value) {
        this.$emit("updated", value);
      },
      deep: true
    },

    startDate: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.dateRange.startDate = value;
        }
      }
    },

    endDate: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.dateRange.endDate = value;
        }
      }
    }
  }
};
</script>
