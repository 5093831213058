import get_type from "./object_type.js";

/**
 * Deep copy all properties from the source object into the target object. This modifies the target object and returns it.
 * In order to preserve the values already defined on the target object, then set undefined_only as true
 *
 * @param {Object} source
 * @param {Object} target
 * @param {Boolean} undefined_only
 */
function deep_copy(source, target, undefined_only) {
  let new_value;
  let current_value;
  let source_type = get_type(source);

  undefined_only = undefined_only === true;

  // HANDLE DATE
  if (source_type === "date") {
    target = new Date();
    target.setTime(source.getTime());

    return target;
  }

  // HANDLE ARRAY
  if (source_type === "array" && undefined_only === false) {
    let index = source.length;

    target = target === undefined ? [] : target;

    while (index--) {
      target[index] = deep_copy(source[index], target[index], undefined_only);
    }

    return target;
  }

  // HANDLE OBJECTS
  if (source_type === "object") {
    target = target === undefined ? {} : target;

    for (let attribute in source) {
      if (source.hasOwnProperty(attribute)) {
        new_value = source[attribute];
        current_value = target[attribute];

        target[attribute] = deep_copy(new_value, current_value, undefined_only);
      }
    }

    return target;
  }

  // HANDLE PRIMITIVE TYPES: boolean, number, string, function, error
  return undefined_only ? (target !== undefined ? target : source) : source;
}

export default deep_copy;
