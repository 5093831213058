import { RouterEntityState, RouterEntity } from "./types";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { GenericObject, RootState } from "@/vuex-bcore";
import { SET_ROUTER_ENTITY } from "../mutation-types";

const state = {
  entity: {
    id: 0,
    user_id: 0,
    name: "",
    is_default: true,
    avatar: "",
    landing_page: ""
  }
};

// Getters
const getters: GetterTree<RouterEntityState, RootState> = {
  landingPage(state) {
    return function () {
      return state.entity.landing_page;
    };
  }
};

const mutations: MutationTree<RouterEntityState> = {
  [SET_ROUTER_ENTITY](state, entity: RouterEntity) {
    state.entity = entity;
  }
};

// Actions
const actions: ActionTree<RouterEntityState, RootState> = {
  setEntity({ commit }, entity: GenericObject) {
    commit(SET_ROUTER_ENTITY, entity);
  }
};

const index: Module<RouterEntityState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default index;
