import { http } from "@/vuex-bcore";

const url = "invoiceboost/customers";

function fetch() {
  return http.get(`${url}/`);
}

function fetch_customer(id) {
  return http.get(`${url}/${id}/`);
}

function create(customer) {
  return http.post(`${url}/`, customer);
}

function update(customer) {
  return http.patch(`${url}/${customer.id}/`, customer);
}

function search(name) {
  const payload = {
    search: name
  };

  return http.get(`${url}/`, { params: payload });
}

export default {
  fetch,
  fetch_customer,
  create,
  update,
  search
};
