var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"org-invoices",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"display-1 font-weight-thin justify-space-between"},[(_vm.context.hasPermission('add_invoice'))?_c('v-btn',{staticClass:"px-0 mr-3 align-center",attrs:{"title":"New Invoice","color":"primary","to":{name: 'org-invoices-new'},"outlined":"","icon":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('span',[_vm._v("Invoices")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"search-field align-self-center mr-4",attrs:{"placeholder":"customer, Invoice #, Etc.","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.context.tableFilters.search),callback:function ($$v) {_vm.$set(_vm.context.tableFilters, "search", $$v)},expression:"context.tableFilters.search"}}),_c('v-btn',{staticClass:"px-0 align-center",attrs:{"title":"Filters","color":"secondary","outlined":"","icon":""},on:{"click":_vm.toggleFiltersDisplay}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1)],1),_c('invoice-filters',{ref:"invoice_filters",staticClass:"justify-center ma-4",attrs:{"account":"receivable"},on:{"change":_vm.context.updateFilters,"closed":function($event){_vm.context.displayFilters = false}}}),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.context.isLoading,"headers":_vm.context.headers,"items":_vm.context.items,"options":_vm.context.tableOptions,"server-items-length":_vm.context.totalItems,"footer-props":{
        'items-per-page-options': _vm.context.itemsPerPageOptions
      },"dense":"","multi-sort":"","disable-filtering":""},on:{"update:options":function($event){return _vm.$set(_vm.context, "tableOptions", $event)},"click:row":_vm.context.displayDetails},scopedSlots:_vm._u([{key:"item.customer_name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.name)+" ")]}},{key:"item.reference_no",fn:function(ref){
      var item = ref.item;
return [(item.sync_status)?_c('v-badge',{attrs:{"title":_vm.context.getInvoiceSyncStatusText(item),"color":_vm.context.getInvoiceSyncStatusColor(item),"inline":"","left":"","dot":""}}):_vm._e(),_vm._v(" "+_vm._s(item.reference_no)+" ")]}},{key:"item.balance_due",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.context.formatCurrency(item.balance_due, item.currency))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.context.getStatusText(item.status))+" ")]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.context.formatDate(item.created_at)))]}},{key:"item.modified_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.context.formatDate(item.modified_at)))]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.preventDefault();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-page-next-outline")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }