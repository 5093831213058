import { http } from "@/vuex-bcore";

const url = "invoiceboost/entity-settings";

function fetch(id) {
  return http.get(`${url}/${id}/`);
}

function update(id, payload) {
  return http.patch(`${url}/${id}/`, payload);
}

export default {
  fetch,
  update
};
