<template>
  <div>
    <div class="google-map" ref="googleMap" id="map" @drag="updateCoordinates"></div>
    <!--    <template v-if="Boolean(this.google) && Boolean(this.map)">-->
    <!--      <slot-->
    <!--        :google="google"-->
    <!--        :map="map"-->
    <!--      />-->
    <!--    </template>-->
  </div>
</template>

<script>
// import GoogleMapsApiLoader from "google-maps-api-loader";
import gmapsInit from "./gmaps.js";

function defaultObject() {
  return {};
}

export default {
  name: "GoogleMapLoader",

  props: { mapConfig: { type: Object, default: defaultObject() }, apiKey: { type: String, default: null } },

  data() {
    return {
      google: null,
      map: null,
      marker: null,
      coordinates: {}
    };
  },
  // async mounted() {
  //   this.google = await GoogleMapsApiLoader({
  //     apiKey: this.apiKey
  //   });
  //   await this.initializeMap();
  // },
  async mounted() {
    try {
      this.google = await gmapsInit();
      // const geocoder = new google.maps.Geocoder();
      const mapContainer = this.$refs.googleMap;
      // creates map
      this.map = new google.maps.Map(mapContainer, this.mapConfig);
      // creates marker
      this.marker = new google.maps.Marker({
        position: this.mapConfig.center,
        map: this.map,
        draggable: true,
        title: "Hello!"
      });
      this.google.maps.event.addListener(this.marker, "dragend", this.markDraggedEvent);

      // geocoder.geocode({ address: 'Austria' }, (results, status) => {
      //   if (status !== 'OK' || !results[0]) {
      //     throw new Error(status);
      //   }
      //
      //   this.map.setCenter(results[0].geometry.location);
      //   this.map.fitBounds(results[0].geometry.viewport);
      // });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
    },
    updateCoordinates(location) {
      this.coordinates = { ...location };
      this.$emit("updated", this.coordinates);
    },
    markDraggedEvent(evt) {
      let lat = evt.latLng.lat().toFixed(6);
      let lng = evt.latLng.lng().toFixed(6);
      this.updateCoordinates({ lat: lat, lng: lng });
    }
  }
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 500px;
}
</style>
