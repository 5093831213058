<template>
  <v-row>
    <v-col cols="12">
      <delivery-interval-details></delivery-interval-details>
      <delivery-interval-form v-if="delivery" :delivery="delivery" :ordered-items="orderedItems" />
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import DeliveryIntervalForm from "@/point-of-sale/components/deliveryInterval/DeliveryIntervalForm.vue";
import DeliveryIntervalDetails from "@/point-of-sale/components/deliveryInterval/DeliveryIntervalDetails.vue";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default Vue.extend({
  name: "DeliveryInterval",

  components: {
    DeliveryIntervalForm,
    DeliveryIntervalDetails
  },

  data() {
    return {
      delivery: null,
      deliveryIntervals: null,
      orderedItems: []
    };
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    async fetchItem() {
      try {
        if (this.$route.params.deliveryId !== undefined) {
          let url = `pos/delivery/${this.$route.params.deliveryId}/intervals`;
          await this.$http.get(url).then(response => {
            this.deliveryIntervals = response.data.intervals;
            this.orderedItems = response.data.ordered_items;
            this.delivery = response.data.delivery;
          });
        }
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Could not fetch delivery information. Please try again.",
          type: "error"
        });
      }
    }
  }
});
</script>

<style scoped></style>
