import { http } from "@/vuex-bcore";

const url = "premium";

function fetch_theme(host_name) {
  return http.get(`${url}/custom-domains/${host_name}/theme/`);
}

function fetch_settings(organization_id) {
  return http.get(`${url}/settings/${organization_id}/`);
}

function update_settings(organization_id, data) {
  return http.patch(`${url}/settings/${organization_id}/`, data);
}

export default {
  domain: {
    fetch_theme
  },
  settings: {
    fetch: fetch_settings,
    update: update_settings
  }
};
