<template>
  <!--
    Search all organizations as per https://gitlab.com/soverton/invoice-boost/issues/88
    -->
  <v-autocomplete
    v-model="item"
    :items="items"
    :loading="loading"
    :search-input.sync="itemSearch"
    outlined
    :disabled="disabled"
    hide-no-data
    item-text="name"
    return-object
    :label="label"
    placeholder="Start typing to Search"
    :error-messages="fieldErrors"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar tile>
        <entity-avatar :entity="item" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from "vue";

import { ADD_FILTERS, CLEAR_FILTERS, SET_LIST } from "@/store/mutation-types";

import EntityAvatar from "@/bcore/components/entity/EntityAvatar.vue";
import debounce from "lodash/debounce";

const getDefaultFieldArray = () => {
  return [];
};

export default Vue.extend({
  name: "OrganizationSearch",
  components: {
    EntityAvatar
  },
  props: {
    fieldErrors: { type: Array, default: getDefaultFieldArray },
    initialValue: { type: Object, default: null },
    label: { type: String, default: "Organization Name*" },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      itemSearch: "",
      item: null
    };
  },

  computed: {
    loading() {
      return !!this.$store.state.organization.requests.list;
    },
    items() {
      return this.$store.state.organization.list;
    }
  },

  watch: {
    item: {
      handler(value) {
        if (value) {
          this.$emit("updated", value);
        }
      },
      immediate: true,
      deep: true
    },

    mounted() {
      if (this.initialValue) {
        this.item = this.initialValue;
        this.$store.commit(`organization/${SET_LIST}`, [this.initialValue]);
      }
    },

    itemSearch: {
      handler: debounce(($this, val) => {
        if (val && val !== "") {
          $this.$store.commit(`organization/${ADD_FILTERS}`, { name__icontains: this.itemSearch });
          $this.fetchItems();
        }
      }, 500)
    }
  },

  beforeDestroy() {
    this.$store.commit(`organization/${CLEAR_FILTERS}`);
  },

  methods: {
    async fetchItems() {
      await this.$store.dispatch("organization/list");
    }
  }
});
</script>

<style></style>
