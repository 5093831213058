import { getActions, getState, getMutations, getGetters, ModelState, RootState } from "@/vuex-bcore";
import { Module } from "vuex";
import { Model } from "./types";

let actions = getActions<ModelState<Model>>("invoiceboost/entity-addresses");
let state = getState<Model>();
const getters = getGetters<Model>();
const mutations = getMutations<Model>();

const entityAddress: Module<ModelState<Model>, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default entityAddress;
