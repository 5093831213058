import uuidv4 from "uuid/v4";
// Mutation types
import {
  ADD_DIALOG_MESSAGE,
  ADD_MESSAGE,
  CLEAR_MESSAGES,
  LOADED,
  LOADING,
  REMOVE_DIALOG_MESSAGE,
  REMOVE_MESSAGE,
  SET_CUSTOM_DOMAIN,
  SET_DRAWER,
  SET_PAGE_TITLE,
  SET_PROGRESS,
  TOGGLE_MINI_MENU,
  TOGGLE_THEME
} from "@/store/mutation-types";

// ThemeState
const state = {
  customDomain: {
    name: "",
    organization: process.env.VUE_APP_NAME || "",
    logo: require("@/assets/logo-login.png"),
    colorScheme: null
  },
  dark: false,
  drawer: false,
  miniMenu: false,
  mediaOpen: false,
  loading: false,
  messages: [],
  pageTitle: "",
  lastMessageId: null,
  progress: 0,
  language: "en-us",
  dialogMessages: [],
  appName: process.env.VUE_APP_NAME
};

// Getters
const getters = {};

const mutations = {
  [TOGGLE_THEME](state) {
    state.dark = !state.dark;
  },
  [TOGGLE_MINI_MENU](state) {
    state.miniMenu = !state.miniMenu;
  },
  [SET_DRAWER](state, open = false) {
    state.drawer = open;
  },
  [LOADING](state) {
    state.loading = true;
  },
  [LOADED](state) {
    state.loading = false;
  },
  [SET_PROGRESS](state, progress) {
    state.progress = progress;
  },
  [SET_PAGE_TITLE](state, title) {
    state.pageTitle = title;
  },
  [ADD_MESSAGE](state, message) {
    message.key = uuidv4();
    message.timeout = message.timeout || -1;
    state.messages.push(message);
    state.lastMessageId = message.key;
  },
  [REMOVE_MESSAGE](state, key) {
    state.messages = state.messages.filter(obj => obj.key !== key);
  },
  [CLEAR_MESSAGES](state) {
    state.messages = [];
  },
  [SET_CUSTOM_DOMAIN](state, customDomain) {
    state.customDomain = customDomain;
  },
  [ADD_DIALOG_MESSAGE](state, message) {
    message.key = uuidv4();
    state.dialogMessages.push(message);
    state.lastMessageId = message.key;
  },
  [REMOVE_DIALOG_MESSAGE](state, key) {
    state.dialogMessages = state.dialogMessages.filter(obj => obj.key !== key);
  }
};

// Actions
const actions = {
  toggle({ commit }) {
    commit(TOGGLE_THEME);
  },
  toggleMiniMenu({ commit }) {
    commit(TOGGLE_MINI_MENU);
  }
};

const index = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default index;
