import axios from "axios";
import { Commit } from "vuex";
import { ADD_REQUEST, REMOVE_REQUEST } from "./mutation-types";
import { GenericObject, ModelState } from "./types";

const logout_event = new CustomEvent("User.logout");

/**
 * Register an http request with the vuex store
 * @param commit {Commit}
 * @param state {ModelState}
 * @param action {string}
 */
export const registerHTTPRequest = async (
  commit,
  state,
  action
) => {
  if (state.requests[action]) {
    // Cancel any existing requests
    try {
      await state.requests[action].cancel();
      commit(REMOVE_REQUEST, action);
    } catch (error) {
      if (!axios.isCancel(error)) {
        return await Promise.reject(error);
      }
    }
  }

  commit(ADD_REQUEST, action);

  return Promise.resolve(state.requests[action]);
};

/**
 * Get the entity from localStorage
 * @param { GenericObject } entity
 * @return void
 */
export const storeEntity = (entity) => {
  entity = entity || {};
  return localStorage.setItem("entity", JSON.stringify(entity));
};

/**
 * Get the Entity from localStorage
 * @return { GenericObject }
 */
export const getEntity = () => {
  const entity = localStorage.getItem("entity");

  if (!entity || entity == "null") {
    return null;
  }

  return JSON.parse(entity);
};

/**
 * Force a logout event
 */
export const forceLogout = () => {
  window.dispatchEvent(logout_event);
};
