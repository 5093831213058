import {http} from "@/vuex-bcore";

const url = "pos/tickets";

const fakeData = {
  data: {
    count: 4,
    next: null,
    previous: null,
    results: [
      {
        id: 1,
        order: 1010,
        customer: "Jon Doe",
        delivery_date: new Date(),
        date_placed: new Date(),
        status: "Pending",
        requires_delivery: false,
      },
      {
        id: 2,
        order: 1010,
        customer: "Jon Doe",
        delivery_date: new Date(),
        date_placed: new Date(),
        status: "Pending",
        requires_delivery: true,
      },
      {
        id: 3,
        order: 1010,
        customer: "Jon Doe",
        delivery_date: new Date(),
        date_placed: new Date(),
        status: "Pending",
        requires_delivery: true,
      },
      {
        id: 4,
        order: 1010,
        customer: "Jon Doe",
        delivery_date: new Date(),
        date_placed: new Date(),
        status: "Pending",
        requires_delivery: true,
      },
    ]
  }
};

function fetch() {
  // return http.get(url);
  return new Promise((resolve, reject) => {
    resolve(fakeData);
  });
}


function search(params) {
  return new Promise((resolve, reject) => {
    resolve(fakeData);
  });
}


function create(data) {
  return http.post(`${url}/`, data);
}

export default {
  fetch,
  search,
  create,
};
