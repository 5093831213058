<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      :options.sync="tableOptions"
      :server-items-length="totalItems"
      :class="getClass"
      @click:row="show_price_override_details"
      dense
      multi-sort
      disable-filtering
    >
      <!-- COLUMN: TYPE -->
      <template v-slot:item.type="{ item }">
        {{ getTypeText(item.type) }}
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-chip class="ma-2" text-color="white" :color="getStatusColor(item.is_active)" small>
          {{ getStatusText(item.is_active) }}
        </v-chip>
      </template>

      <template v-if="showActions" v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.native.stop>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click.prevent="deletePriceOverride(item)"
              v-if="hasPermission('delete_priceoverride', 'point_of_sale')"
            >
              <v-icon left>mdi-delete</v-icon> Delete
              <!--  -->
            </v-list-item>

            <v-list-item @click="editPriceOverride(item)" v-if="hasPermission('change_priceoverride', 'point_of_sale')">
              <v-icon left>mdi-pencil</v-icon> Edit
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="show_price_override_details(item)">
              <v-icon left>mdi-more</v-icon> View Details
              <!--  -->
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <delete-dialog
      :object-name="deleteItemName"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
    <!-- PRICE OVERRIDE FORM -->
    <price-override-form ref="price_override_form" @updated="fetchItems" />
  </div>
</template>

<script>
import DataTableMixin from "@/mixins/dataTable";
import PermissionMixin from "@/mixins/permission";
import priceOverrideStatusMixin from "@/mixins/priceOverrideStatus";
import priceOverrideTypeMixin from "@/mixins/priceOverrideType";

import DeleteDialog from "@/bcore/components/DeleteDialog";
import PriceOverrideForm from "@/point-of-sale/components/price-override/PriceOverrideForm.vue";

import api from "@/api";

export default {
  name: "PriceOverrideTable",

  components: { DeleteDialog, PriceOverrideForm },

  mixins: [DataTableMixin, PermissionMixin, priceOverrideStatusMixin, priceOverrideTypeMixin],

  props: {
    showActions: { type: Boolean, default: true },
    isElevation: { type: Boolean, default: false },
    priceOverrides: { type: Array, default: () => [] },
    fetchItemsFromAPI: { type: Boolean, default: true }
  },

  data() {
    return {
      isLoading: false,

      deleteItemName: null,
      deleteConfirmOpen: false,

      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Type", value: "type", sortable: true },
        { text: "Start Date", value: "from_date", sortable: true },
        { text: "End Date", value: "to_date", sortable: true },
        { text: "Status ", value: "is_active", sortable: true },
        { text: "", value: "actions" }
      ]
    };
  },

  computed: {
    items() {
      return this.fetchItemsFromAPI ? this.$store.state.priceOverride.list : this.priceOverrides;
    },

    getClass() {
      return this.isElevation ? "elevation-1" : "elevation-0";
    }
  },

  methods: {
    async fetchItems() {
      await this.$store.dispatch("priceOverride/list");
    },

    newPriceOverride() {
      this.$refs.price_override_form.new();
    },

    editPriceOverride(item) {
      this.$refs.price_override_form.open(item);
    },

    show_price_override_details(price_override) {
      this.$router.push({ name: "price-override-details", params: { id: price_override.id } });
    },

    deletePriceOverride(priceOverride) {
      this.deleteConfirmOpen = true;
      this.deleteItemName = priceOverride.name;

      if (typeof priceOverride.id == "number") {
        this.idDeleteItem = priceOverride.id;
      }
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async sendDelete(id) {
      return api.price_overrides.remove(id);
    }
  }
};
</script>

<style></style>
