import { http } from "@/vuex-bcore";

const url = "invoiceboost/invoices";

function fetch_payments(invoice_id) {
  return http.get(`${url}/${invoice_id}/payments/`);
}

function fetch(invoice_id) {
  return http.get(`${url}/${invoice_id}/`);
}

function create(invoice) {
  return http.post(`${url}/`, invoice);
}

function update(invoice) {
  return http.patch(`${url}/${invoice.id}/`, invoice);
}

function remove(invoice_id) {
  return http.delete(`${url}/${invoice_id}/`);
}

export default {
  fetch,
  fetch_payments,
  create,
  update,
  delete: remove
};
