import Vue from "vue";
import api from "@/api";

/**
 * Injects the settings for the current organization into the component
 */
const settingsMixin = Vue.extend({
  computed: {
    /**
     * Use this in any component to get the current entity settings from state
     * @return {*}
     */
    mySettings() {
      return this.$store.state.entitySettings.detail;
    },

    isLoadingSettings() {
      return this.$store.getters["entitySettings/loading"]("detail");
    },

    hasAccountingPackageConnected() {
      const settings = this.mySettings || {};
      const connected = settings.accounting_package_connected || {};
      return connected.QBD || connected.QBO || connected.XERO;
    }
  },
  methods: {
    /**
     * Use this method to get the settings for the current entity
     * @return {Promise<any>}
     */
    fetchMySettings() {
      return this.$store.dispatch("entitySettings/detail", this.$store.state.auth.entity.id);
    },

    /**
     * Use this method to get the settings for a third party entity
     * @return {Promise<any>}
     */
    fetchSettings(entityID) {
      return api.organizations.settings.fetch(entityID);
    },

    updateSettings(entityID, payload) {
      return api.organizations.settings.update(entityID, payload);
    }
  }
});

export default settingsMixin;
