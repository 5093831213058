<template>
  <v-btn icon @click="dialog = true">
    <v-icon>mdi-help-circle-outline</v-icon>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Get Help</v-card-title>
        <v-card-text v-if="display_custom_help_message">
          {{ custom_help_message }}
        </v-card-text>
        <v-card-text v-else>
          Contact +1 (817) 756-6796 or
          <a href="mailto:help@roidevs.com">help@roidevs.com</a>
          for assistance
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import api from "@/api";
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "HelpButton",

  data() {
    return {
      dialog: false,
      custom_help_message: ""
    };
  },

  computed: {
    entity() {
      return this.$store.state.auth.entity;
    },

    display_custom_help_message() {
      return (
        !!this.custom_help_message &&
        (this.entity.is_premium_organization || this.entity.has_premium_customer_restrictions)
      );
    },

    premium_entity_id() {
      if (this.entity.is_premium_organization) {
        return this.entity.id;
      }

      if (this.entity.has_premium_customer_restrictions) {
        return this.entity.premium_organization_sponsor.id;
      }

      return null;
    }
  },

  watch: {
    dialog(value){
      if(value && this.premium_entity_id){
        this.fetch_message();
      }
    }
  },

  methods: {
    fetch_message() {
      this.is_loading = true;
      api.premium.settings
        .fetch(this.premium_entity_id)
        .then(response => {
          this.custom_help_message = response.data.help_message;
        })
        .catch(e => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch premium settings" });
        })
        .finally(() => {
          this.is_loading = false;
        });
    }
  }
};
</script>
