<template>
  <login-layout>
    <keep-alive>
      <component :is="active_component" :context="context"></component>
    </keep-alive>
  </login-layout>
</template>

<script>
import LoginLayout from "@/views/layouts/LoginLayout.vue";
import SignupMobile from "./SignupMobile.vue";
// import signupDesktop from "./signupDesktop.vue";

import app from "@/app";
import api from "@/api";
import utils from "@/utilities";

import viewport from "@/mixins/viewport";

const is_empty = utils.is_empty;

export default {
  name: "Signup",

  components: {
    LoginLayout,
    SignupMobile
  },

  mixins: [viewport],

  data() {
    return {
      isLoading: false,

      form: {
        is_valid: false,

        fields: {
          is_organization: false,
          company_name: "",

          first_name: "",
          last_name: "",
          email: this.$route.query.email || "",
          password: "",
          username: "",
          landing_page: ""
        },

        rules: {
          first_name: [value => !!value || "First Name is required."],
          last_name: [value => !!value || "Last Name is required."],
          password: [value => !!value || "Password is required."],

          email: [
            value => {
              if (!value) {
                return "E-mail is required.";
              }

              if (value.length > 0) {
                return /.+@.+\..+/.test(value) || "E-mail must be in a valid format.";
              }

              return true;
            }
          ]
        },

        field_errors: {},
        errors: []
      }
    };
  },

  computed: {
    context() {
      return this;
    },

    active_component() {
      // return this.viewport.is_desktop ? "SignupDesktop" : "SignupMobile";
      return SignupMobile;
    },

    is_organization() {
      const account = this.form.fields;
      const company_name = account.company_name.trim();
      return !!company_name;
    },

    company_name() {
      const account = this.form.fields;
      return this.is_organization ? account.company_name : account.first_name + " " + account.last_name;
    }
  },

  methods: {
    account_type_changed() {},

    reset_errors() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit_form() {
      this.reset_errors();
      this.isLoading = true;

      let account = Object.assign({}, this.form.fields);

      account.username = account.email;
      account.company_name = this.company_name;

      api.signup
        .create(account)
        .then(response => {
          this.auto_sign_in();
        })
        .catch(exception => {
          let form = this.form;
          let field_errors = utils.form.get_field_errors(exception);
          let errors = utils.form.get_errors(exception);

          if (is_empty(field_errors) && is_empty(errors)) {
            errors = [
              {
                message: "There was an issue performing this action, if this persist please contact us.",
                type: "error"
              }
            ];
          }

          form.field_errors = field_errors;
          form.errors = errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    auto_sign_in() {
      app.user
        .login({
          username: this.form.fields.email,
          password: this.form.fields.password
        })
        .then(() => {
          this.$router.push({ name: "welcome-slides", query: { option: this.selected_landing_page_option() } });
        });
    },

    selected_landing_page_option() {
      return this.form.fields.landing_page === "org-bills" ? 1 : 2;
    },

  }
};
</script>

<style></style>
