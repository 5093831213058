<template>
  <responsive-dialog :display-dialog="displayDialog" :dialog-title="formTitle" @closed="close">
    <!-- SUPPLIER FORM -->
    <v-form ref="form" v-model="form.isValid" :lazy-validation="false" @submit.prevent="submitForm">
      <v-container class="pa-0">
        <!-- DISPLAY GENERIC ERRORS -->
        <non-field-errors :errors="form.errors.non_field_errors"></non-field-errors>

        <!-- DISPLAY FORM NOTIFICATIONS -->
        <v-row v-if="isClaimed || !hasEmail">
          <v-col cols="12">
            <v-alert type="info" border="left" elevation="2" :icon="false" colored-border>
              <ul>
                <li v-if="isClaimed">
                  <!-- NOTIFY WHEN THE ACCOUNT HAS BEEN CLAIMED -->
                  <strong>You're viewing a claimed account</strong>
                </li>

                <li v-if="!hasEmail">
                  <!-- eslint-disable-next-line -->
                  <strong>E-mail invitation not available</strong>
                  , an email address is required to send the invite.
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>

        <!-- FIELD: NAME -->
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              label="Name*"
              v-model="form.supplier.name"
              :rules="form.rules.name"
              :error-messages="form.errors.name"
              :disabled="isLoading"
              outlined
              required
            />
          </v-col>

          <!-- FIELD: COMPANY -->
          <v-col cols="12" md="6">
            <v-text-field
              name="Supplier[Company]"
              label="Company"
              v-model="form.supplier.company"
              :rules="form.rules.company"
              :error-messages="form.errors.company"
              :disabled="isLoading"
              outlined
              :messages="['If different from Name']"
              required
            />
          </v-col>
        </v-row>

        <!-- FIELD: EMAIL -->
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Email"
              v-model="form.supplier.email"
              :rules="form.rules.email"
              :error-messages="form.errors.email"
              :disabled="isLoading"
              outlined
            />
          </v-col>
        </v-row>

        <!-- ADDRESS -->
        <v-row>
          <v-col cols="12">
            <!-- FIELD: ADDRESS LINE 1 -->
            <v-text-field
              label="Address Line 1"
              v-model="form.supplier.address.address_line1"
              :error-messages="form.errors['address.address_line1']"
              :disabled="isLoading"
              outlined
              required
            ></v-text-field>

            <!-- FIELD: ADDRESS LINE 2 -->
            <v-text-field
              label="Address Line 2"
              v-model="form.supplier.address.address_line2"
              :error-messages="form.errors['address.address_line2']"
              :disabled="isLoading"
              outlined
            ></v-text-field>

            <!-- FIELD: CITY -->
            <v-text-field
              label="City*"
              v-model="form.supplier.address.city"
              :error-messages="form.errors['address.city']"
              :disabled="isLoading"
              outlined
              required
            ></v-text-field>

            <!-- FIELD: ADDRESS STATE -->
            <v-text-field
              label="State*"
              v-model="form.supplier.address.state"
              :error-messages="form.errors['address.state']"
              :disabled="isLoading"
              outlined
              required
            ></v-text-field>

            <!-- FIELD: ZIP CODE -->
            <v-text-field
              label="Zip code*"
              v-model="form.supplier.address.code"
              :error-messages="form.errors['address.code']"
              :disabled="isLoading"
              outlined
              required
            ></v-text-field>

            <!-- FIELD: COUNTRY -->
            <country-search
              ref="country_search"
              :initial-value="form.supplier.address.country"
              :field-errors="form.errors['address.country']"
              :disabled="isLoading"
              @change="onCountrySelected"
            ></country-search>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- DIALOG ACTIONS -->
    <template v-slot:actions>
      <!-- SEND EMAIL INVITATION -->
      <v-col v-if="!isClaimed" cols="6">
        <v-switch
          v-if="!isUpdate"
          v-model="switch_enable_send_invite"
          :label="inviteLabel"
          :loading="isSendingInvite"
          :disabled="!hasEmail"
        />

        <!-- <v-btn
          v-if="isUpdate"
          color="secondary"
          :loading="isSendingInvite"
          :disabled="!isSendInviteAllowed || !hasEmail"
          @click="emailInvitation"
          ountlined
          text
        >
          <v-icon left>mdi-send</v-icon>
          {{ inviteLabel }}
        </v-btn> -->
        <small v-if="!hasEmail && isSendInviteAllowed">You need to enter an email address to send the invite</small>
      </v-col>

      <v-spacer />

      <v-btn
        type="button"
        color="primary"
        :loading="isLoading"
        :disabled="isLoading || !isDirty || !hasWritePermission"
        @click.prevent="submitForm"
        text
      >
        <v-icon>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";

import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import CountrySearch from "@/bcore/components/country/CountrySearch.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import { ADD_MESSAGE } from "@/store/mutation-types";

import api from "@/api";

import deep_copy from "../../../utilities/deep_copy";
import settingsMixin from "@/mixins/settings";
import permissionMixin from "@/mixins/permission";
import { isEqual } from "lodash";
import { parseFormErrors } from "@/utilities/form";

function createSupplierModel() {
  return {
    id: null,
    name: "",
    email: "",
    company: null,
    is_claimed: false,
    address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      code: "",
      country: 0
    }
  };
}

function createModelRules() {
  return {
    name: [value => !!value || "Name is required."],

    email: [
      value => {
        if (value && value.length > 0) {
          return /.+@.+\..+/.test(value) || "E-mail must be valid.";
        }

        return true;
      }
    ]
  };
}

function cloneSupplierModel(data) {
  const dataCopy = deep_copy(data);

  // ensure address object is defined
  // when creating or editing a customer without an address the object will be null
  dataCopy.address = Object.assign({}, data.address);

  return dataCopy;
}

export default Vue.extend({
  name: "SupplierFormDialog",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    CountrySearch
  },

  mixins: [trackDirtyFieldsMixin, settingsMixin, permissionMixin],

  props: {},

  data() {
    return {
      displayDialog: false,

      isLoading: false,

      inviteLabel: `Email invitation to join ${process.env.VUE_APP_NAME}`,
      enableSendInvite: true,
      isSendingInvite: false,

      defaultCountryId: null,

      form: {
        isValid: false,

        supplier: createSupplierModel(),
        supplierInitialData: createSupplierModel(),

        rules: createModelRules(),
        errors: {}
      }
    };
  },

  computed: {
    /* COMPUTED MODELS/VALUES */
    hasWritePermission() {
      return this.hasPermission("change_supplier");
    },

    switch_enable_send_invite: {
      get() {
        // the computed value should return false when:
        // - the user doesn't have a valid email or the field is empty
        if (!this.hasEmail) {
          return false;
        }

        return this.enableSendInvite;
      },

      set(value) {
        this.enableSendInvite = value;
      }
    },

    formTitle() {
      return this.isUpdate ? "Update Supplier" : "New Supplier";
    },

    isSendInviteAllowed() {
      return this.isGlobalEmailCommunicationEnabled;
    },

    isGlobalEmailCommunicationEnabled() {
      return !!this.$store.state.entitySettings.detail.enable_customers_email_communication;
    },

    /* VALIDATORS */

    hasEmail() {
      const email = this.form.supplier.email || "";
      return /.+@.+\..+/.test(email);
    },

    isUpdate() {
      return !!this.form.supplier.id;
    },

    isClaimed() {
      return this.form.supplier.is_claimed;
    },

    /* SHORTCUTS */
    entitySettings() {
      return this.$store.state.entitySettings.detail;
    }
  },

  created() {
    // INTENTIONALLY EXECUTING THIS FETCH EARLY AS POSSIBLE
    this.fetchDefaultCountry();
  },

  mounted() {
    this.trackDirtyFields();
  },

  methods: {
    new() {
      this.resetValidation();

      this.form.supplier = createSupplierModel();
      this.form.supplierInitialData = createSupplierModel();

      this.$nextTick(() => {
        // set default country selection
        this.form.supplier.address.country = this.defaultCountryId;
        this.$refs.country_search.select(this.defaultCountryId);
      });

      this.displayDialog = true;
    },

    open(data) {
      if (!data) {
        return;
      }

      this.resetValidation();

      // Copy the supplier data
      this.form.supplier = cloneSupplierModel(data);
      this.form.supplierInitialData = cloneSupplierModel(data);

      this.$nextTick(() => {
        // update country search selection
        this.$refs.country_search.select(this.form.supplier.address.country);
      });

      this.displayDialog = true;

      return this;
    },

    close() {
      this.displayDialog = false;
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetStates() {
      // Reset form states
      this.isSendingInvite = false;
      this.enableSendInvite = true;

      return this;
    },

    resetValidation() {
      this.form.errors = {};

      const $form = this.$refs.form;
      if ($form) {
        $form.resetValidation();
      }

      return this;
    },

    setFormErrors(errors) {
      this.form.errors = parseFormErrors(errors.field_errors || []);
      this.form.errors.non_field_errors = errors.errors || [];
    },

    submitForm() {
      if (!this.validate()) {
        return;
      }

      const isUpdate = this.isUpdate;
      const action = isUpdate ? "updated" : "created";
      const promise = isUpdate ? this.updateSupplier() : this.createSupplier();
      const form = this.form;

      this.isLoading = true;

      return promise
        .then(response => {
          const responseData = response.data;
          const message = { text: `The supplier has been ${action} successfully.`, type: "success" };

          // update local supplier data with the server response
          form.supplier = cloneSupplierModel(responseData);
          form.supplierInitialData = cloneSupplierModel(responseData);

          this.displayMessage(message);
          this.$emit(action, cloneSupplierModel(responseData));
        })
        .then(() => {
          // only send email invite through this step when creating an entity
          if (!isUpdate && this.hasEmail && this.enableSendInvite) {
            this.sendInvite();
          }
          return Promise.resolve();
        })
        .catch(error => {
          const errors = error && error.response ? error.response.data : {};
          this.setFormErrors(errors);

          this.displayMessage({
            text: "There was an error performing this action, if this persist please contact us.",
            type: "error"
          });
          return Promise.reject();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createSupplier() {
      const url = "invoiceboost/suppliers/";
      const supplier = { ...this.form.supplier };

      if (isEqual(supplier.address, this.form.supplierInitialData.address)) {
        delete supplier.address;
      }

      return this.$http.post(url, supplier);
    },

    updateSupplier() {
      const supplier = { ...this.form.supplier };

      if (isEqual(supplier.address, this.form.supplierInitialData.address)) {
        delete supplier.address;
      }

      const url = `invoiceboost/suppliers/${supplier.id}/`;

      return this.$http.patch(url, supplier);
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    },

    onCountrySelected(country) {
      // Check selected data (country) is not null and id is not zero or empty value
      this.form.supplier.address.country = country && country.id ? country.id : null;
    },

    emailInvitation() {
      // If the email field was changed, use the submitForm workflow
      // to ensure the new data is saved first
      if (this.dirtyFields.has("form.supplier.email")) {
        this.submitForm().then(() => {
          this.sendInvite();
        });
      } else {
        this.sendInvite();
      }
    },

    sendInvite() {
      this.isSendingInvite = true;

      return api.invitations
        .send(this.form.supplier)
        .then(() => {
          this.displayMessage({ text: "Invitation sent", type: "info" });
        })
        .catch(error => {
          this.displayMessage({ text: "Invitation failed to send", type: "error" });
        })
        .finally(() => {
          this.isSendingInvite = false;
        });
    },

    fetchDefaultCountry() {
      const organization_id = this.entitySettings.id;

      api.organizations
        .fetch_address(organization_id)
        .then(response => {
          this.defaultCountryId = response.data.country;
        })
        .catch(error => {
          // this.displayMessage({ text: "Unable to load default country.", type: "error" });
        });
    },

    /* HELPERS */

    trackDirtyFields() {
      const address = "form.supplier.address";
      const address_initial_data = "form.supplierInitialData.address";

      // Register all data fields that should be tracked in order to disable/enable the save button
      this.trackDirtyField("form.supplier.name", "form.supplierInitialData.name");
      this.trackDirtyField("form.supplier.email", "form.supplierInitialData.email");
      this.trackDirtyField("form.supplier.company", "form.supplierInitialData.company");

      this.trackDirtyField(`${address}.address_line1`, `${address_initial_data}.address_line1`);
      this.trackDirtyField(`${address}.address_line2`, `${address_initial_data}.address_line2`);
      this.trackDirtyField(`${address}.city`, `${address_initial_data}.city`);
      this.trackDirtyField(`${address}.state`, `${address_initial_data}.state`);
      this.trackDirtyField(`${address}.code`, `${address_initial_data}.code`);
      this.trackDirtyField(`${address}.country`, `${address_initial_data}.country`);
    }
  }
});
</script>
