<template>
  <v-card class="delivery-summary" outlined>
    <v-card-title class="display-1 font-weight-thin">{{ pageTitle }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <p>
            Customer:
            <b>{{ customer }}</b>
          </p>
        </v-col>
        <v-col>
          <p>
            {{ itemName }} #:
            <b>{{ item.reference_no }}</b>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn v-if="allPanelsOpen" text @click="closeAllPanels">
            <v-icon left>mdi-chevron-up</v-icon>
            Collapse all
          </v-btn>
          <v-btn v-else text @click="showAllPanels">
            <v-icon left>mdi-chevron-down</v-icon>
            Expand all
          </v-btn>
        </v-col>
      </v-row>
      <v-skeleton-loader v-if="loading" class="mt-7" type="paragraph"></v-skeleton-loader>
      <!-- EXPANSION PANELS -->
      <v-expansion-panels v-show="!loading" v-model="panels" multiple hover class="pt-0 pb-4" flat focusable accordion>
        <v-expansion-panel v-for="item in deliveries" :key="item.id" class="delivery-detail">
          <v-expansion-panel-header>
            Delivery #:
            <strong>{{ item.reference_no }}</strong>
            <v-spacer />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="white-space: pre-line; margin-top: 30px">
              {{ item.firstAddress.address_line1 }}, {{ item.firstAddress.city }}, {{ item.firstAddress.state }}
            </div>
            <div style="white-space: pre-line; margin-top: 10px">
              Waiver required:
              <b>{{ waiverRequiredString }}</b>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click.stop="onClickEditButton(item)">Edit</v-btn>
              <v-btn text @click.stop="onClickDetailsButton(item)">Details</v-btn>
            </v-card-actions>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-form @submit.prevent="onFormSubmit">
      <responsive-dialog :display-dialog="formOpened" :dialog-title="formTitle" @closed="closeForm">
        <!-- DISPLAY GENERIC ERRORS -->
        <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>

        <delivery-form
          v-if="formOpened"
          :is-quote="isQuote"
          :is-item-update="true"
          :url="formObject.url"
          :item="formObject"
          :delivery-address="formObject.firstAddress"
          @updated="onFormUpdate"
        />
      </responsive-dialog>
    </v-form>
  </v-card>
</template>

<script>
import DeliveryForm from "./DeliveryForm.vue";
import { ADD_MESSAGE } from "@/store/mutation-types";
import mixins from "vue-typed-mixins";
import expansionPanel from "@/mixins/expansionPanel";
import permissionMixin from "@/mixins/permission";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog";
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default mixins(expansionPanel, permissionMixin).extend({
  name: "DeliverySummary",

  components: {
    ResponsiveDialog,
    DeliveryForm,
    NonFieldErrors
  },

  props: {
    isQuote: { type: Boolean, required: true }
  },

  data() {
    return {
      loading: false,
      formOpened: false,
      formErrors: {},
      formObject: {},
      customer: null,
      displayDetails: false,
      item: {}, // this will be either an Order or Quote
      itemType: null,
      deliveries: []
    };
  },

  computed: {
    pageTitle() {
      return this.isQuote ? `Quote Delivery Summary` : `Order Delivery Summary`;
    },

    itemName() {
      return this.isQuote ? `Quote` : `Order`;
    },

    waiverRequiredString() {
      return this.item.is_waiver_required ? `Yes` : `No`;
    },

    formTitle() {
      return `Update Delivery`;
    }
  },

  watch: {
    item: function (newVal, oldVal) {
      this.item = newVal;
      this.customer = this.item.target_entity.name;
    }
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    async fetchItem() {
      this.loading = true;

      try {
        if (this.$route.params.quoteId !== undefined) {
          let id = this.$route.params.quoteId;
          this.itemUrl = `pos/quotes/${id}/delivery/`;
          this.itemType = "quote";
        }
        if (this.$route.params.orderId !== undefined) {
          let id = this.$route.params.orderId;
          this.itemUrl = `pos/orders/${id}/delivery/`;
          this.itemType = "order";
        }
        if (this.itemUrl) {
          await this.$http
            .get(this.itemUrl)
            .then(response => {
              this.item = response.data[this.itemType];
              let delivery = response.data["delivery"];
              this.item.url = this.itemUrl + "delivery/";
              this.getDeliveryInfo(delivery, this.isQuote);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Could not fetch delivery information. Please try again.",
          type: "error"
        });
      }
    },

    getDeliveryInfo(item, isQuote) {
      if (item && item.length > 0) {
        item.forEach(element => {
          const [firstAddress] = element.delivery.address;
          this.deliveries.push({
            url: item.url,
            firstAddress: firstAddress,
            ...element.delivery
          });
        });
      }
    },

    onClickDetailsButton(item) {
      this.$router.push({ name: "delivery-details", params: { id: item.id } });
    },

    onClickEditButton(item) {
      this.formObject = item;
      this.formOpened = true;
    },

    closeForm() {
      this.formOpened = false;
      this.formObject = {};
      this.resetFormErrors();
    },

    resetFormErrors() {
      this.formErrors = {};
    },

    onFormUpdate() {
      this.deliveries = [];
      this.closeForm();
      this.fetchItem();
    }
  }
});
</script>

<style>
.delivery-summary .delivery-detail .v-expansion-panel-header {
  background-color: #eeeeee;
  margin-bottom: 2px;
  border-radius: 0;
}
</style>
