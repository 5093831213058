const priceOverrideStatusMixin = {
  data() {
    const statusItems = {
      true: { text: "Active", color: "green", icon: "mdi-timer-sand" },
      false: { text: "Inactive", color: "red", icon: "mdi-cash-check" }
    };

    return {
      statusItems: statusItems
    };
  },

  methods: {
    getStatusText(status) {
      return status !== undefined ? this.statusItems[status].text : "";
    },

    getStatusColor(status) {
      return status !== undefined ? this.statusItems[status].color : "";
    },

    getStatusIcon(status) {
      return status !== undefined ? this.statusItems[status].icon : "";
    }
  }
};

export default priceOverrideStatusMixin;
