<template>
  <v-switch
    v-model="value"
    @change="saveValue"
    :label="inputLabel"
    :messages="inputMessages"
    :disabled="isDisabled"
    :loading="isLoading"
  ></v-switch>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

export default {
  name: "AutoPayUnrestricted",
  props: { targetId: { type: Number, default: 0 }, account: { type: String, default: "payable" } },

  data() {
    return {
      isLoading: false,
      isDisabled: false,
      value: false,
      settings: {},
      targetMap: {
        payable: "supplier",
        receivable: "customer"
      },
      actionMap: {
        payable: "Pay",
        receivable: "Charge"
      }
    };
  },

  computed: {
    isGlobal() {
      return !this.targetId;
    },

    targetUrl() {
      return this.isGlobal
        ? this.settingsUrl
        : `/invoiceboost/${this.targetType}s/${this.targetId}/auto-${this.targetAction.toLowerCase()}/`;
    },

    settingsUrl() {
      return `/invoiceboost/entity-settings/${this.currentEntity.id}/`;
    },

    targetType() {
      return this.targetMap[this.account];
    },

    currentEntity() {
      return this.$store.state.auth.entity;
    },

    targetField() {
      return `enable_auto_${this.targetAction.toLowerCase()}`;
    },

    targetScope() {
      return this.isGlobal ? "ALL" : "THIS";
    },

    targetAction() {
      return this.actionMap[this.account];
    },

    inputMessages() {
      const pronoun = {
        payable: "you",
        receivable: "they"
      };

      const plural = this.targetId ? "" : "s";

      return this.isDisabled
        ? [
            `You have enabled Auto-${this.targetAction} globally for ${this.targetType}s, and ${
              pronoun[this.account]
            } will be automatically charged for all future Invoices regardless of this setting. please disable the global setting to make changes at individual ${
              this.targetType
            } level.`
          ]
        : [
            `Automatically ${this.targetAction.toLowerCase()}s all future invoices for ${this.targetScope} ${
              this.targetType
            }${plural} if ${pronoun[this.account]} have a default credit card set.`
          ];
    },

    inputLabel() {
      let labelString = `Auto-${this.targetAction}`;
      labelString += this.isGlobal ? " (Global)" : "";
      return labelString;
    }
  },

  mounted() {
    this.fetchValue();

    if (!this.isGlobal) {
      // Disable target level settings if global setting is enabled
      this.$http.get(this.settingsUrl).then(response => {
        this.isDisabled = response.data[this.targetField];
      });
    }
  },

  methods: {
    async fetchValue() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(this.targetUrl);
        this.value = response.data[this.targetField];
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: `Could not get Auto-${this.targetAction} setting`,
          type: "error"
        });
      }
      this.isLoading = false;
    },

    async saveValue() {
      this.isLoading = true;
      try {
        let payload = {};
        payload[`enable_auto_${this.targetAction.toLowerCase()}`] = this.value;

        const response = await this.$http.patch(this.targetUrl, payload);
        this.value = response.data[this.targetField];

        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: `Auto-${this.targetAction} status updated`,
          type: "success"
        });
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: `Could not update Auto ${this.targetAction} setting`,
          type: "error"
        });

        this.value = !this.value;
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped></style>
