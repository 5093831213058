import form from "./form";
import type from "./object_type";
import clipboard from "./clipboard.js";

export function resolve_namespace(context, field) {
  const parts = field.split(".");
  let reference = context;

  for (let i = 0; i < parts.length; i++) {
    reference = reference[parts[i]];
  }

  return reference;
}

// Compare two items
export function compare(item1, item2) {
  // Get the object type
  const itemType = Object.prototype.toString.call(item1);

  // If an object or array, compare recursively
  if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
    if (!is_equal(item1, item2)) {
      return false;
    }
  } else {
    // Otherwise, do a simple comparison
    // If the two items are not the same type, return false
    if (itemType !== Object.prototype.toString.call(item2)) {
      return false;
    }

    // Else if it is a function, convert to a string and compare
    if (itemType === "[object Function]") {
      if (item1.toString() !== item2.toString()) {
        return false;
      }
    } else {
      // Otherwise, just compare
      if (item1 !== item2) {
        return false;
      }
    }
  }
}

export function is_equal(value, other) {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLength = type === "[object Array]" ? value.length : Object.keys(value).length;
  const otherLength = type === "[object Array]" ? other.length : Object.keys(other).length;

  if (valueLength !== otherLength) return false;

  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLength; i++) {
      if (compare(value[i], other[i]) === false) {
        return false;
      }
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) {
          return false;
        }
      }
    }
  }

  // If nothing failed, return true
  return true;
}

export function is_empty(object) {
  if (!object) {
    return true;
  }

  if (type(object) === "array") {
    return !object.length;
  }

  for (let property in object) {
    if (object.hasOwnProperty(property)) {
      return false;
    }
  }

  return true;
}

export default {
  resolve_namespace,
  compare,
  is_equal,
  form,
  type,
  is_empty,
  clipboard
};
