var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{class:_vm.getClass,attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"footer-props":{ 'items-per-page-options': _vm.itemsPerPageOptions },"options":_vm.tableOptions,"server-items-length":_vm.totalItems,"dense":"","multi-sort":"","disable-filtering":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.show_price_override_details},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeText(item.type))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white","color":_vm.getStatusColor(item.is_active),"small":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.is_active))+" ")])]}},(_vm.showActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},nativeOn:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(_vm.hasPermission('delete_priceoverride', 'point_of_sale'))?_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.deletePriceOverride(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e(),(_vm.hasPermission('change_priceoverride', 'point_of_sale'))?_c('v-list-item',{on:{"click":function($event){return _vm.editPriceOverride(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.show_price_override_details(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-more")]),_vm._v(" View Details ")],1)],1)],1)]}}:null],null,true)}),_c('delete-dialog',{attrs:{"object-name":_vm.deleteItemName,"open":_vm.deleteConfirmOpen},on:{"cancelled":_vm.onConfirmCancelled,"deleted":_vm.onConfirmDeleteDeleted}}),_c('price-override-form',{ref:"price_override_form",on:{"updated":_vm.fetchItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }