<template>
  <responsive-dialog :show-actions="false" :display-dialog="formOpened" :dialog-title="formTitle" @closed="closeForm">
    <form ref="payment_form" v-if="formItem" @submit.prevent="onFormSubmit()">
      <v-card-text>
        <v-container>
          <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formItem.amount"
                outlined
                label="Amount*"
                type="number"
                :step="0.0001"
                :error-messages="formErrors.amount"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formItem.date"
                label="Payment Date"
                outlined
                append-icon="mdi-calendar"
                @click:append="showDateCalendar = true"
              ></v-text-field>
              <v-dialog v-model="showDateCalendar" max-width="300px">
                <v-date-picker v-model="formItem.date"></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isItemUpdate"
          ref="delete_button"
          type="submit"
          color="error"
          @click.prevent="onClickDeleteButton(formItem)"
          text
          :loading="formLoading"
          x-large
        >
          DELETE
        </v-btn>
        <v-spacer />
        <v-btn type="submit" ref="save_button" color="primary" text :loading="formLoading" x-large>SAVE</v-btn>
      </v-card-actions>
    </form>
    <delete-dialog
      object-name="payment"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </responsive-dialog>
</template>

<script>
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";
import DateMixin from "@/mixins/date";
import DeleteMixin from "@/mixins/delete";
import DeleteDialog from "@/bcore/components/DeleteDialog";
import { parseFormErrors } from "@/utilities/form";

export default {
  name: "InvoicePaymentForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors,
    DeleteDialog
  },

  mixins: [DateMixin, DeleteMixin],

  data() {
    return {
      formLoading: false,

      formOpened: false,
      formErrors: {},
      formItem: {},
      invoice: {},
      showDateCalendar: false
    };
  },

  computed: {
    isItemUpdate() {
      return !!this.formItem.id;
    },

    ownerEntity() {
      return this.$store.state.entity;
    },

    formTitle() {
      return this.isItemUpdate ? `Payment ${this.formItem.reference_no}` : "New Payment";
    },

    formHasErrors() {
      return Object.keys(this.formErrors).length;
    }
  },

  methods: {
    resetFormErrors() {
      this.formErrors = {};
    },

    async onFormSubmit() {
      this.resetFormErrors();

      const responseData = this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      if (this.formHasErrors) {
        return;
      }

      this.$emit("updated", responseData);
      this.closeForm();
    },

    closeForm() {
      this.formOpened = false;
      this.formItem = {};
      this.$emit("closed");
    },

    open(payment) {
      let { invoice, ...form } = payment;
      this.invoice = invoice;
      this.formItem = form;
      this.formOpened = true;
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async sendUpdate() {
      this.formLoading = true;

      try {
        const form = Object.assign({}, this.formItem);
        form.payment_invoices = [{ invoice: this.invoice.id, amount: Number(form.amount) }];
        const response = await this.$store.dispatch("payment/update", { id: form.id, data: form });
        return response ? response.data : response;
      } catch (error) {
        this.setFormErrors(error.response.data);
      }

      this.formLoading = false;
    },

    async sendCreate() {
      this.formLoading = true;

      try {
        let form = Object.assign({}, this.formItem);
        form.owner_entity = this.invoice.owner_entity;
        form.target_entity = this.invoice.target_entity;
        form.payment_invoices = [];
        form.payment_invoices.push({ invoice: this.invoice.id, amount: Number(form.amount) });

        const response = await this.$store.dispatch("payment/create", form);
        return response ? response.data : response;
      } catch (error) {
        this.setFormErrors(error.response.data);
      }
      
      this.formLoading = false;
    },

    async sendDelete(id) {
      await this.$store.dispatch("payment/delete", id);
    }
  }
};
</script>
