<template>
  <v-card class="forgot-password">
    <v-card-title class="d-block rounded-0">
      <div class="logo-wrapper">
        <img src="@/assets/accruvia-logo.png" alt="accruvia-logo" class="logo" />
      </div>

      <div v-if="context.active_step == 1" class="header">RESET PASSWORD</div>
      <div v-if="context.active_step == 2" class="header">CHECK YOUR EMAIL</div>
    </v-card-title>

    <v-card-text class="px-8 pt-5">
      <non-field-errors :errors="form.errors" />

      <!-- REQUEST INSTRUCTIONS -->
      <v-container v-if="context.active_step == 1" class="pa-0">
        <v-row>
          <v-col>
            <router-link class="go-back" :to="{ name: 'login' }" text>
              <v-icon>mdi-chevron-left</v-icon>
              BACK
            </router-link>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="instructions">
              Enter the username or e-mail associated with your account and we'll send you instructions to reset your
              password.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <label class="c-label">Username or E-mail</label>
            <v-text-field
              name="ForgotPassword[Username]"
              type="text"
              v-model="form.fields.username"
              :error-messages="form.field_errors.username"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <v-btn
              color="primary"
              class="btn-submit"
              :disabled="context.is_loading"
              :loading="context.is_loading"
              @click="context.submit_step_one"
              x-large
            >
              SEND INSTRUCTIONS
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- STEP TWO: ENTER RECOVERY CODE -->
      <v-container v-if="context.active_step == 2" class="pa-0">
        <v-row>
          <v-col>
            <a class="go-back" @click="context.resend" text>
              <v-icon>mdi-chevron-left</v-icon>
              BACK
            </a>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="instructions">We have sent you an email with a link to reset your password.</p>
            <p class="instructions">
              Didn't receive the email yet? Please check your spam folder, or click here to
              <a @click.prevent="context.active_step = 1" style="text-decoration: underline">Resend instructions.</a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import NonFieldErrors from "@/bcore/components/NonFieldErrors";

export default {
  name: "ForgotPasswordMobile",

  components: {
    NonFieldErrors,
  },

  props: {
    context: { type: Object, default: () => { return {}; } }
  },

  data() {
    return {};
  },

  computed: {
    form() {
      return this.context.form;
    },

    fields() {
      return this.context.form.fields;
    },

    show_password: {
      get() {
        return this.context.show_password;
      },

      set(value) {
        this.context.show_password = value;
      }
    }
  }
};
</script>

<style>
.is-mobile .forgot-password {
  height: 100%;
}

.is-mobile .forgot-password .v-card__title {
  background-size: cover;
  background-position: bottom;
  background-image: url("~@/assets/mobile/background-swoosh.svg");
  padding-bottom: 18%;
}

.is-mobile .forgot-password .logo-wrapper {
  line-height: 0;
  text-align: right;
}

.is-mobile .forgot-password .logo {
  height: 35px;
}

.is-mobile .forgot-password .v-card__title .header {
  font-size: 18px;
  color: #fff;
  text-align: center;
  word-break: break-word;

  margin-top: 28px;
  margin-bottom: 16px;
}

.is-mobile .forgot-password .c-label {
  line-height: 2.5;
  font-size: 18px;
  color: #000;
}

.is-mobile .forgot-password .go-back {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.is-mobile .forgot-password .instructions {
  color: #000;
  font-size: 19px;
  line-height: 1.5;
}

.is-mobile .forgot-password .btn-submit {
  width: 100%;
}
</style>
