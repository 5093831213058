let determineItemPrice = function (formItem, priceOverride) {
  const item = formItem.item;
  let price = item.price;
  if (priceOverride !== null) {
    price =
      priceOverride.type === "fixed_percentage"
        ? applyPriceOverrideFixedPct(priceOverride, item)
        : applyPriceOverridePerItem(priceOverride, item);
    formItem.custom_price = price;
  } else {
    formItem.custom_price = undefined;
    formItem.price = price;
  }
  return formItem;
};

let calculateCustomPrice = function (priceOverride, item) {
  let result = 0;
  let price = Math.round((Number(item.price) + Number.EPSILON) * 100) / 100;

  if (priceOverride.percentage_amount !== null) {
    let amount = Math.round((Number(priceOverride.percentage_amount) + Number.EPSILON) * 100) / 100;
    let overrideAmount = amount;
    overrideAmount = (price * amount) / 100;
    result = price + overrideAmount;
  }
  if (priceOverride.custom_price !== null) {
    let amount = Math.round((Number(priceOverride.custom_price) + Number.EPSILON) * 100) / 100;
    result = price + amount;
  }

  return result;
};

let applyPriceOverrideFixedPct = function (priceOverride, item) {
  let result = calculateCustomPrice(priceOverride, item);
  return Math.round((Number(result) + Number.EPSILON) * 100) / 100;
};

let applyPriceOverridePerItem = function (priceOverride, item) {
  let poItemList = priceOverride.items;
  let item_found = poItemList.find(function (element, index) {
    if (element.id === item.id) return true;
  });
  if (item_found !== undefined) {
    return formatCalculatedPrice(priceOverride, item);
  }
  return item.price;
};

function formatCalculatedPrice(priceOverride, item) {
  let result = calculateCustomPrice(priceOverride, item);
  return Math.round((Number(result) + Number.EPSILON) * 100) / 100;
}

export { determineItemPrice, calculateCustomPrice, applyPriceOverridePerItem };
