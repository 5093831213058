import { http } from "@/vuex-bcore";

const url = "pos/quotes";

function fetch_delivery(quote_id) {
  return http.get(`${url}/${quote_id}/delivery/`);
}

function send(quote_id) {
  return http.post(`${url}/${quote_id}/send/`);
}

function cancel(quote_id) {
  return http.post(`${url}/${quote_id}/cancel/`);
}

function previewUrl(quote_id) {
  return `/${url}/${quote_id}/preview/`;
}

export default {
  fetch_delivery,
  send,
  cancel,
  previewUrl
};
