import { http } from "@/vuex-bcore";

const url = "invoiceboost/invitations";

function send(entity) {
  const payload = { email: entity.email };

  return http.post(`${url}/${entity.target_entity}/send/`, payload);
}

function get_link(target_entity_id) {
  return http.get(`${url}/${target_entity_id}/link/`);
}

function validate(token) {
  return http.get(`/invitations/${token}/validate/`);
}

function redeem(token, user, phone_number) {
  return http.post(`/invitations/${token}/redeem/`, { user, phone_number });
}

export default {
  send,
  get_link,
  validate,
  redeem
};
