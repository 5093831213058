<template>
  <v-navigation-drawer
    ref="drawer"
    class="main-menu"
    height="auto"
    v-model="drawerState.isExpanded"
    :width="drawerState.width"
    :mini-variant="drawerState.isMini"
    :expand-on-hover="drawerState.isExpandOnHover"
    :temporary="drawerState.isTemporary"
    :absolute="drawerState.isAbsolute"
    :bottom="drawerState.isBottom"
    :stateless="drawerState.isStateless"
    :app="drawerState.isApp"
    floating
  >
    <template v-slot:prepend>
      <ul v-if="entity" class="d-flex align-center flex-grow-1" dense>
        <li class="d-flex">
          <entity-avatar :entity="entity" />
        </li>
        <li class="entity-name mr-auto">
          {{ entity.name }}
        </li>
        <li class="organization-select mr-1">
          <organization-select />
        </li>
      </ul>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <slot name="items">
        <!-- MENU ITEM: ACCOUNT PAYABLE -->
        <v-list-item v-if="menuItemVisible('bill')" router :to="{ name: 'org-bills' }">
          <v-list-item-icon>
            <v-icon>mdi-cash-minus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Bills</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: INVOICES -->
        <v-list-item v-if="menuItemVisible('invoice')" router :to="{ name: 'org-invoices' }">
          <v-list-item-icon>
            <v-icon>mdi-cash-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Invoices</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: TRANSACTIONS-REFUNDS -->
        <v-list-item v-if="menuItemVisible('transactionsRefunds')" router :to="{ name: 'org-transactions' }">
          <v-list-item-icon>
            <v-icon>mdi-wallet-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Transactions</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: CUSTOMERS -->
        <v-list-item v-if="menuItemVisible('customers')" router :to="{ name: 'org-customers' }">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Customers</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: SUPPLIERS -->
        <v-list-item v-if="menuItemVisible('suppliers')" router :to="{ name: 'org-suppliers' }">
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Suppliers</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: QUOTES -->
        <v-list-item v-if="menuItemVisible('quote')" :to="{ name: 'quotes' }">
          <v-list-item-icon>
            <v-icon>mdi-basket</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Quotes</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: ORDERS -->
        <v-list-item v-if="menuItemVisible('order')" router :to="{ name: 'orders' }">
          <v-list-item-icon>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: DELIVERIES -->
        <v-list-item v-if="menuItemVisible('delivery')" link to="/organization/deliveries">
          <v-list-item-icon>
            <v-icon>mdi-truck-fast</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Delivery</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: DISPATCH REQUESTS -->
        <v-list-item v-if="menuItemVisible('dispatch')" router :to="{ name: 'tickets' }">
          <v-list-item-icon>
            <v-icon>mdi-dump-truck</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Tickets</v-list-item-title>
        </v-list-item>

        <!-- MENU ITEM: EMPLOYEES -->
        <v-list-item v-if="menuItemVisible('employees')" router :to="{ name: 'org-employees' }">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Employees</v-list-item-title>
        </v-list-item>

        <!-- MENU: ORGANIZATION -->
        <v-list-group v-if="menuItemVisible('organizations')" no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Organization</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item router :to="{ name: 'org-profile' }">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="menuItemVisible('paymentMethods')" link @click.stop="$refs.payment_methods.open(entity)">
            <v-list-item-title>AR | Payment Methods</v-list-item-title>
            <payment-methods ref="payment_methods" account="receivable" editable></payment-methods>
          </v-list-item>

          <v-list-item router :to="{ name: 'organization-ap-payment-methods' }">
            <v-list-item-title>AP | Payment Methods</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="menuItemVisible('priceOverride')" router :to="{ name: 'price-override' }">
            <v-list-item-title>Price Override</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="menuItemVisible('paymentSchedules')"
            link
            @click.stop="$refs.payment_schedules.open(entity)"
          >
            <v-list-item-title>Payment Schedules</v-list-item-title>
            <payment-schedules ref="payment_schedules" editable></payment-schedules>
          </v-list-item>

          <!-- MENU ITEM: SETTINGS  -->
          <v-list-item v-if="menuItemVisible('entitySettings')" router :to="{ name: 'organization-settings' }">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>

          <!-- MENU ITEM: MEDIA  -->
          <v-list-item link @click="openMediaDialog">
            <v-list-item-title>Media</v-list-item-title>
            <media-popup v-if="drawerState.isExpanded" model-name="media"></media-popup>
          </v-list-item>

          <!-- MENU ITEM: INTEGRATIONS  -->
          <v-list-item router :to="{ name: 'organization-integrations' }">
            <v-list-item-title>Integrations</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </slot>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { OPEN_DIALOG } from "@/store/mutation-types";

import OrganizationSelect from "@/bcore/components/organization/OrganizationSelect.vue";
import EntityAvatar from "@/bcore/components/entity/EntityAvatar.vue";
import MediaPopup from "@/bcore/components/media/MediaPopup.vue";

import PaymentMethods from "@/invoiceboost/components/payment-method/PaymentMethods.vue";
import PaymentSchedules from "@/invoiceboost/components/payment-schedule/PaymentSchedules.vue";

import permissionMixin from "@/mixins/permission";
import eventHub from "@/eventHub";

const command = {
  toggle: "MainMenu.toggle",
  expand: "MainMenu.expand",
  collapse: "MainMenu.collapse"
};

const event = {
  expanded: "MainMenu.EXPANDED",
  collapsed: "MainMenu.COLLAPSED"
};

export default {
  name: "MainMenu",

  components: {
    OrganizationSelect,
    EntityAvatar,
    PaymentMethods,
    PaymentSchedules,
    MediaPopup
  },

  mixins: [permissionMixin],

  data() {
    return {
      drawerBreakpoint: {
        large: {
          width: 250,

          isExpanded: true,
          isMini: false,
          isExpandOnHover: false,

          isTemporary: false,
          isAbsolute: false,
          isBottom: false,
          isStateless: true,

          isApp: false
        },

        medium: {
          width: 250,

          isExpanded: true,
          isMini: true,
          isExpandOnHover: true,

          isTemporary: false,
          isAbsolute: true,
          isBottom: false,
          isStateless: true,

          isApp: false
        },

        small: {
          isExpanded: false,
          isMini: false,
          isExpandOnHover: false,

          isTemporary: true,
          isAbsolute: true,
          isBottom: true,
          isStateless: false,

          isApp: true
        }
      },

      settingsEntity: {}
    };
  },

  computed: {
    /* SHORTCUTS */

    drawerState() {
      const drawerBreakpoint = this.drawerBreakpoint;
      const viewPort = this.viewPort;

      if (viewPort.isLarge) {
        return drawerBreakpoint.large;
      }

      if (viewPort.isMedium) {
        return drawerBreakpoint.medium;
      }

      return drawerBreakpoint.small;
    },

    user() {
      return this.$store.state.auth.user;
    },

    entity() {
      return this.$store.state.auth.entity;
    },

    /* VALIDATORS */

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    isExpanded: {
      get() {
        const drawerState = this.drawerState;
        const viewPort = this.viewPort;

        if (viewPort.isLarge) {
          return !drawerState.isMini;
        }

        // Medium and small screens toggle on/off the menu
        return drawerState.isExpanded;
      },

      set(value) {
        const viewPort = this.viewPort;
        let BoolValue = !!value;

        if (viewPort.isLarge) {
          this.drawerState.isMini = !BoolValue;
          this.drawerState.isExpandOnHover = !BoolValue;
        } else {
          this.drawerState.isExpanded = BoolValue;
        }
      }
    },

    viewPort() {
      const breakpoint = this.$vuetify.breakpoint;

      return {
        isLarge: breakpoint.lgAndUp,
        isMedium: breakpoint.md,
        isSmall: breakpoint.smAndDown
      };
    }
  },

  mounted() {
    eventHub.listen(command.toggle, this.toggle);
  },

  methods: {
    expand() {
      this.isExpanded = true;
      this.$emit("change", true);
      eventHub.trigger(event.expanded);
    },

    collapse() {
      this.isExpanded = false;
      this.$emit("change", false);
      eventHub.trigger(event.collapsed);
    },

    toggle() {
      if (this.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },

    openMediaDialog() {
      this.$store.commit(`dialog/${OPEN_DIALOG}`, "media");
    },

    menuItemVisible(item) {
      // Menu item visibility logic
      switch (item) {
        case "employees":
          return this.hasPermission("view_employee");
        case "organizations":
          return this.hasPermission("view_entity", "bcore");
        case "paymentMethods":
          return this.hasPermission("view_paymentmethod");
        case "paymentSchedules":
          return this.hasPermission("view_paymentschedule");
        case "entitySettings":
          return this.hasPermission("view_entitysettings");
        case "customers":
          return this.hasPermission("view_customer");
        case "suppliers":
          return this.hasPermission("view_supplier");
        case "bill":
          return this.hasPermission("view_accounts_payable");
        case "invoice":
          return this.hasPermission("view_invoice");
        case "transactionsRefunds":
          return this.hasPermission("view_transaction", "payments");
        case "quote":
          return this.hasPermission("change_quote", "point_of_sale");
        case "order":
          return this.hasPermission("change_order", "point_of_sale");
        case "delivery":
          return this.hasPermission("change_delivery", "point_of_sale");
        case "dispatch":
          return this.hasPermission("change_ticket", "point_of_sale");
        case "priceOverride":
          return this.hasPermission("change_priceoverride", "point_of_sale");
        default:
          return false;
      }
    }
  }
};
</script>

<style>
.main-menu {
  border-radius: 5px;
}

.main-menu .v-navigation-drawer__prepend {
  display: flex;
  height: 64px;
}

.main-menu .v-navigation-drawer__prepend ul {
  list-style-type: none;
  padding: 0;
  transition: 0.75s;
}

.main-menu.v-navigation-drawer--mini-variant .v-navigation-drawer__prepend ul {
  padding: 0 8px;
}

.main-menu:not(.v-navigation-drawer--mini-variant) .v-navigation-drawer__prepend ul {
  padding: 0 6px;
}

.main-menu:not(.v-navigation-drawer--mini-variant) .v-navigation-drawer__prepend .entity-avatar {
  margin-right: 12px;
}

.main-menu .v-navigation-drawer__prepend .entity-avatar .identicon {
  display: flex;
}

.main-menu.v-navigation-drawer--mini-variant .v-navigation-drawer__prepend .entity-avatar .identicon svg {
  width: 100%;
}

.main-menu .v-navigation-drawer__prepend .entity-name {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-menu.v-navigation-drawer--mini-variant .v-navigation-drawer__prepend .entity-name,
.main-menu.v-navigation-drawer--mini-variant .v-navigation-drawer__prepend .organization-select {
  display: none;
}

.main-menu.v-navigation-drawer--is-mouseover {
  /* position: absolute; */
  max-width: none;
  /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.2);
}
</style>
