<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Invoice #</th>
            <th class="text-left">Balance due</th>
            <th class="text-left">Total</th>
            <th class="text-left">Terms</th>
            <th class="text-left">Created</th>
            <th class="text-left">Status</th>
            <th class="text-left">PDF</th>
            <th class="text-left">Preview</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.reference_no }}</td>
            <td>{{ item.balance_due }}</td>
            <td>{{ item.total }}</td>
            <td>{{ item.terms }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.status }}</td>
            <td>
              <v-icon small>
                {{ isLoading ? "mdi-download-outline" : "mdi-file-pdf-outline" }}
              </v-icon>
              <a :class="{ underline: !isLoading }" @click.prevent="downloadPdf(item)" :disabled="isLoading">
                <span v-if="isLoading">Downloading...</span>
                <span v-else>Download</span>
              </a>
            </td>
            <td>
              <span>
                <v-icon small>mdi-open-in-new</v-icon>
                <a class="underline" @click.prevent="previewInvoice(item)">Open</a>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import api from "@/api";
import { ADD_MESSAGE } from "@/store/mutation-types";
import InvoiceStatusMixin from "@/mixins/invoiceStatus";
import PreviewMixin from "@/mixins/preview";
import downloadMixin from "@/mixins/download";

export default {
  name: "OrderInvoicesList",

  mixins: [InvoiceStatusMixin, downloadMixin, PreviewMixin],

  data() {
    return {
      items: [],
      isLoading: false
    };
  },
  methods: {
    async fetchItems(order_id) {
      this.isLoading = true;
      api.order
        .fetch_invoices(order_id)
        .then(response => {
          this.items = response.data;
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "unable to fetch order's invoices.", type: "Error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    previewInvoice(invoice) {
      this.previewTitle = `Invoice: ${invoice.reference_no}`;
      this.previewUrl = `/invoiceboost/invoices/${invoice.id}/preview/`;
      this.showPreview();
    },

    downloadPdf(invoice) {
      const pdfUrl = `/invoiceboost/invoices/${invoice.id}/pdf/`;
      this.downloadResource(pdfUrl, `invoice-${invoice.reference_no}.pdf`);
    }
  }
};
</script>

<style scoped></style>
