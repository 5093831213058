<template>
  <v-card class="org-suppliers" elevation="0">
    <v-card-title class="display-1 font-weight-thin justify-space-between">
      <v-btn
        v-if="hasPermission('add_supplier')"
        title="New Supplier"
        color="primary"
        class="px-0 mr-3 align-center"
        @click="newSupplier"
        outlined
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <span>
        {{ pageTitle }}
      </span>

      <v-spacer />

      <!-- SEARCH & FILTER -->
      <div class="d-flex align-center">
        <v-text-field
          class="search-field align-self-center mr-4"
          placeholder="Search..."
          prepend-inner-icon="mdi-magnify"
          v-model="tableFilters.search"
          outlined
          dense
        />
      </div>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        class="pa-2"
        :headers="headers"
        :items="items"
        :loading="loadingTable"
        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        @click:row="view_supplier_details"
        disable-filtering
        disable-sort
      >
        <template v-slot:item.balance="{ item }">
          <div class="text-right">
            {{ formatCurrency(item.balance) }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link :href="item.file" target="_blank">
                <v-list-item-title @click.stop="editSupplier(item)">
                  <v-icon left>mdi-pencil</v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="openPaymentMethodsDialog(item)">
                <v-list-item-title>
                  <!--  -->
                  <v-icon left>mdi-credit-card-outline</v-icon>
                  Payment Methods
                </v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="item.is_claimed" @click.stop="openInvitationDialog(item)">
                <v-list-item-title>
                  <!--  -->
                  <v-icon left>mdi-send</v-icon>
                  Send Invite
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasPermission('delete_supplier') && !hasAccountingPackageConnected"
                @click.stop="onClickDeleteButton(item)"
              >
                <v-list-item-title>
                  <!--  -->
                  <v-icon left>mdi-delete</v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>

    <SupplierFormDialog ref="supplier_editor" @created="supplierCreated" @updated="supplierUpdated" />

    <invitation-dialog ref="invitation_dialog" />

    <payment-methods ref="payment_methods" />

    <delete-dialog
      :object-name="modelName"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </v-card>
</template>

<script>
import PageTitle from "@/mixins/pageTitle";

import SupplierFormDialog from "@/invoiceboost/components/supplier/SupplierFormDialog.vue";
import InvitationDialog from "@/invoiceboost/components/supplier/InvitationDialog.vue";
import PaymentMethods from "@/invoiceboost/components/payment-method/PaymentMethods.vue";

import currencyFormatMixin from "@/mixins/currencyFormat";
import relationshipViewMixin from "@/mixins/relationshipView";
import settingsMixin from "@/mixins/settings";

export default {
  name: "Suppliers",

  components: {
    SupplierFormDialog,
    InvitationDialog,
    PaymentMethods
  },

  mixins: [PageTitle, relationshipViewMixin, settingsMixin, currencyFormatMixin],

  props: {},

  computed: {
    headers() {
      let columns = [
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Balance", value: "balance", width: 120 },
        { text: "Actions", value: "action" }
      ];

      if (this.isSuperuser) {
        columns.splice(3, 0, { text: "Owner", value: "owner_entity.name" });
      }

      return columns;
    },
  },

  methods: {
    newSupplier() {
      this.$refs.supplier_editor.new();
    },

    editSupplier(supplier) {
      // create a copy of the given data object to prevent live editing of the data displayed on the current list
      // copy first level properties
      const dataCopy = Object.assign({}, supplier);

      // copy address
      dataCopy.address = Object.assign({}, supplier.address);

      this.$refs.supplier_editor.open(dataCopy);
    },

    supplierCreated() {
      this.fetchItems();
      this.$refs.supplier_editor.close();
    },

    supplierUpdated() {
      this.fetchItems();
      this.$refs.supplier_editor.close();
    },

    openPaymentMethodsDialog(supplier) {
      let item = {};
      item.id = supplier.target_entity;
      item.name = supplier.name;
      this.$refs.payment_methods.open(item);
    },

    openInvitationDialog(supplier) {
      this.$refs.invitation_dialog.open(supplier);
    },

    view_supplier_details(supplier) {
      this.$router.push({ name: "org-supplier-details", params: { id: supplier.id } });
    }
  }
};
</script>

<style>
.org-suppliers .search-field .v-input__slot {
  margin: 0;
}

.org-suppliers .search-field .v-text-field__details {
  position: absolute;
  bottom: -32px;
}

/* Specify a row height for the table header and body rows */
.org-suppliers table tr {
  line-height: 40px;
  cursor: pointer;
}
</style>
