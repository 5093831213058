import axios from "axios";

import invitations from "./invitations";
import organizations from "./organizations";
import premium from "@/api/premium";
import suppliers from "./suppliers";
import customers from "./customers";
import employees from "./employees";
import integrations from "./integrations";
import internationalization from "@/api/internationalization";
import invoices from "./invoices";
import invoice_payable from "./invoice-payable";
import invoice_receivable from "./invoice-receivable";
import invoice_payment from "./invoice-payment";
import bills from "./bills";
import currencies from "./currencies";
import countries from "./countries";
import price_overrides from "./price-overrides";
import price_override_customer from "./price-override-customer";
import order from "./order";
import organization_settings from "./organization-settings";
import quote from "./quote";
import truck from "./truck";
import driver from "./driver";
import transaction from "./transaction";
import platform_subscriptions from "./platform-subscriptions";
import signup from "./signup";
import tickets from "./tickets";
import payment_methods from "./payment-methods";
import users from "./users";

function get_cancel_token() {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
}

export default {
  invitations,
  organizations,
  organization_settings,
  suppliers,
  bills,
  customers,
  employees,
  premium,
  integrations,
  internationalization,
  invoices,
  invoice_payable,
  invoice_receivable,
  invoice_payment,
  currencies,
  countries,
  platform_subscriptions,
  price_overrides,
  price_override_customer,
  order,
  quote,
  truck,
  driver,
  transaction,
  signup,
  tickets,
  payment_methods,
  users,
};
