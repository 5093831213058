<template>
  <responsive-dialog :display-dialog="formOpened" :dialog-title="formTitle" @closed="closeForm">
    <form @submit.prevent="onFormSubmit()">
      <v-card-text>
        <v-container>
          <non-field-errors :errors="formErrors.non_field_errors"></non-field-errors>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formItem.name"
                outlined
                label="Name"
                :error-messages="formErrors.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="formItem.instructions"
                outlined
                label="Payment instructions"
                :error-messages="formErrors.instructions"
                wrap="hard"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn type="submit" color="primary" text :loading="formLoading" x-large>SAVE</v-btn>
      </v-card-actions>
    </form>
  </responsive-dialog>
</template>

<script>
import Vue from "vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import NonFieldErrors from "@/bcore/components/NonFieldErrors.vue";

import { parseFormErrors } from "@/utilities/form";

function defaultObject() {
  return {};
}

export default Vue.extend({
  name: "PaymentMethodForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors
  },

  props: {
    formObject: { type: Object, default: defaultObject() }
  },

  data() {
    return {
      formOpened: false,
      formErrors: {},
      formItem: {}
    };
  },

  computed: {
    formLoading() {
      return (
        this.$store.getters["paymentMethod/loading"]("create") || this.$store.getters["paymentMethod/loading"]("update")
      );
    },

    isItemUpdate() {
      return !!this.formItem.id;
    },

    formTitle() {
      return this.isItemUpdate ? `Update Payment Method` : `New Payment Method`;
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    formHasErrors() {
      return Object.keys(this.formErrors).length > 0;
    }
  },

  watch: {
    formObject: {
      handler(value) {
        if (value) {
          this.formItem = { ...value };
          this.formOpened = !!value;
        }
      },
      deep: true
    }
  },

  methods: {
    resetFormErrors() {
      this.formErrors = {};
    },

    async onFormSubmit() {
      this.resetFormErrors();
      this.formItem.owner_entity = this.ownerEntity.id;
      this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      if (this.formHasErrors) {
        return;
      }

      this.$emit("updated");
      this.closeForm();
    },

    closeForm() {
      this.formOpened = false;
      this.formItem = {};
      this.$emit("closed");
    },

    setFormErrors(errors) {
      const fieldErrors = errors.field_errors || [];
      this.formErrors = parseFormErrors(fieldErrors);
      this.formErrors.non_field_errors = errors.errors || [];
    },

    async sendUpdate() {
      try {
        await this.$store.dispatch("paymentMethod/update", { id: this.formItem.id, data: this.formItem });
      } catch (error) {
        this.setFormErrors(error.response.data);
      }
    },

    async sendCreate() {
      try {
        await this.$store.dispatch("paymentMethod/create", this.formItem);
      } catch (error) {
        this.setFormErrors(error.response.data);
      }
    }
  }
});
</script>
