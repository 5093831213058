<template>
  <v-dialog v-model="display_dialog" fullscreen>
    <v-card class="invoice-filters-mobile rounded-0">
      <v-card-title class="d-flex">
        <span class="headline pl-3">Filter Settings</span>

        <v-spacer></v-spacer>

        <v-btn class="close-dialog" @click.prevent="close" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="content">
        <v-form @submit.prevent="applyFilters">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    class="search-field"
                    append-icon="mdi-magnify"
                    v-model="form.data.company_name"
                    hide-details="auto"
                    :label="companyFieldTitle"
                    :loading="form.is_loading"
                    @keyup="search_customers"
                    outlined
                />
              </v-col>
            </v-row>

            <v-row v-show="form.customer_search_results.length > 0">
              <v-col>
                <v-card class="search-results" flat>
                  <v-card-title class="pa-0">
                    Search result

                    <v-btn @click.prevent="clearSearchResults" text>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-divider/>

                  <v-card-text class="pa-0 mt-4 rounded-0">
                    <v-radio-group class="pa-0 ma-0" hide-details="true">
                      <v-radio
                          v-for="customer in form.customer_search_results"
                          class="py-1"
                          hide-details="auto"
                          @click="setCompanyName(customer)"
                          :key="customer.id"
                          :label="customer.name"
                          :value="customer.name"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-8">
              <v-col cols="6">
                <v-menu
                    v-model="showStartDateCalendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateRange.startDate"
                        name="DateRange[From]"
                        label="Date From"
                        hide-details="auto"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateRange.startDate"
                      no-title
                      @input="showStartDateCalendar = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-menu
                    v-model="showEndDateCalendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateRange.endDate"
                        name="DateRange[To]"
                        label="Date To"
                        hide-details="auto"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateRange.endDate"
                      no-title
                      @input="showEndDateCalendar = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    name="Filter[TotalMax]"
                    v-model.number="form.data.total_max"
                    type="number"
                    label="Amount"
                    outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    name="Filter[InvoiceNumber]"
                    label="Invoice No"
                    v-model.number="form.data.invoice_number"
                    outlined
                />
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center flex-column">
        <v-btn class="action btn-reset" @click.prevent="clearFilters" large>Clear</v-btn>

        <br />

        <v-btn
            class="action"
            color="primary"
            ref="submit_button"
            :disabled="!isDirty"
            @click.prevent="applyFilters"
            large
        >
          APPLY
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import trackDirtyFieldsMixin from "@/mixins/trackDirtyFields";
import invoiceStatusMixin from "@/mixins/invoiceStatus";
import filterCountMixin from "@/mixins/filterCount";

import api from "@/api";
import debounce from "lodash/debounce";

function createDataModel() {
  return {
    company_name: "",
    invoice_number: "",
    po_number: "",
    terms: "",

    total_min: "",
    total_max: "",

    assignees: [],
    status_in: [],

    approved_at_min: "",
    approved_at_max: "",

    created_at_min: "",
    created_at_max: "",

    modified_at_min: "",
    modified_at_max: ""
  };
}

export default {
  name: "InvoiceFiltersMobile",
  
  mixins: [trackDirtyFieldsMixin, invoiceStatusMixin, filterCountMixin],

  props: {
    account: { type: String, default: "payable" },
  },

  data() {
    return {
      display_dialog: false,
      showStartDateCalendar: false,
      showEndDateCalendar: false,
      dateRange: {
        startDate: null,
        endDate: null
      },

      form: {
        is_loading: false,
        search_customer: "",
        customer_search_results: [],        
        initialData: createDataModel(),
        data: createDataModel()
      }
    };
  },

  computed: {
    companyFieldTitle() {
      return this.account === "payable" ? "Supplier" : "Customer";
    }
  },
  
  watch: {
    dateRange: {
      handler(value) {
        this.updateCreatedAt(value);
      },
      deep: true
    },
  },

  mounted() {
    this.trackDirtyFields();
  },

  methods: {
    open() {
      this.display_dialog = true;
      this.form.search_customer = "";
      this.form.customer_search_results = [];
    },

    close() {
      this.display_dialog = false;
      this.$emit("closed");
    },

    applyFilters() {
      const filters = this.form.data;
      const filterObject =
        this.account === "payable" ? { supplier_name: filters.company_name } : { customer_name: filters.company_name };

      this.$emit("change", Object.assign(filterObject, filters));

      //set initial data equal to the current data so that the form isn't dirty and the button is disabled
      this.form.initialData = Object.assign({}, filters);
      this.close();
    },

    clearFilters() {
      this.form.data = createDataModel();
    },

    updateCreatedAt(value) {
      const { startDate, endDate } = value;
      const formData = this.form.data;

      formData.created_at_min = startDate;
      formData.created_at_max = endDate;
    },

    clearSearchResults() {
       this.form.customer_search_results = [];
    },

    search_customers: debounce(function search_customers(val) {
      if (this.form.is_loading) {
        return;
      }

      if (val && val !== "") {
        this.form.is_loading = true;

        return api.customers
          .search(this.form.search_customer)
          .then(response => {
            this.form.customer_search_results = response.data.results;
          })
          .finally(() => {
            this.form.is_loading = false;
          });
      }
    }, 500),

    setCompanyName(value) {
      this.form.data.company_name = value.name;
      this.form.customer_search_results = [];
    },

    trackDirtyFields() {
      // Register all data fields that should be tracked in order to disable/enable the save button

      this.trackDirtyField("form.data.company_name", "form.initialData.company_name");
      this.trackDirtyField("form.data.invoice_number", "form.initialData.invoice_number");

      this.trackDirtyField("form.data.total_min", "form.initialData.total_min");
      this.trackDirtyField("form.data.total_max", "form.initialData.total_max");

      this.trackDirtyField("form.data.created_at_min", "form.initialData.created_at_min");
      this.trackDirtyField("form.data.created_at_max", "form.initialData.created_at_max");
    }
  }
};
</script>

<style>
.v-dialog .invoice-filters-mobile > .v-card__title {
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 24px;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.2;
}

.v-dialog .invoice-filters-mobile .close-dialog {
  min-width: auto !important;
  padding: 0 8px !important;
}

.v-dialog .invoice-filters-mobile .v-card__actions {
  padding: 8px 24px;
}

.v-dialog .invoice-filters-mobile .v-card__actions .action {
  width: 100%;
}

.invoice-filters-mobile .v-input--is-disabled input {
  color: #757575;
}

.invoice-filters-mobile .v-input--is-disabled .v-text-field__details > .v-messages {
  color: #263238 !important;
}

.invoice-filters-mobile strong {
  color: #424242;
}
</style>