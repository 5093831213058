import { getActions, customAction, getState, getMutations, getGetters, ModelState, RootState } from "@/vuex-bcore";
import { Module } from "vuex";
import { Model } from "./types";

let actions = getActions<ModelState<Model>>("entities");
let state = getState<Model>();
const getters = getGetters<Model>();
const mutations = getMutations<Model>();

actions["types"] = customAction("types", "entities/types/", "list");

const entity: Module<ModelState<Model>, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default entity;
