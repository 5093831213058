import { ADD_ERRORS, CLEAR_ERRORS, REMOVE_REQUEST, SET_DETAIL, SET_LIST, SET_META } from "../mutation-types";
import http from "../http";
import { ActionMethod, ActionTree } from "vuex";
import { GenericObject, ModelState, RootState, UpdateRequestObject } from "../types";
import { registerHTTPRequest } from "../utils.js";
import axios from "axios";

/**
 * Return a list of CRUD actions
 * @param { string } endpoint
 * @param { Store } store
 */
// TODO: Preferably we don't want Generic type S extending any
const getActions = <S extends ModelState<any>>(endpoint: string): ActionTree<S, RootState> => {
  return {
    /**
     * CREATE a new model
     * @param commit
     * @param state
     * @param payload obj {email: '', password: ''}
     */
    async create({ commit, state }, payload) {
      const cancelToken = await registerHTTPRequest(commit, state, "create");
      commit(CLEAR_ERRORS);

      try {
        const response = await http.post(`${endpoint}/`, payload, {
          cancelToken: cancelToken.token
        });
        const object = response.data;
        commit(REMOVE_REQUEST, "create");
        commit(SET_DETAIL, object);
        return response;
      } catch (error) {
        if (!axios.isCancel(error)) {
          const status = error.response ? error.response.status : null;
          commit(REMOVE_REQUEST, "create");
          if (status === 400) {
            commit(ADD_ERRORS, error.response.data);
          }
          return await Promise.reject(error);
        }
      }
    },
    /**
     * LIST model objects
     * @param commit
     * @param state
     * @param getters
     */
    async list({ commit, state, getters }) {
      const cancelToken = await registerHTTPRequest(commit, state, "list");

      try {
        const response = await http.get(`${endpoint}/${getters.filterString}`, {
          cancelToken: cancelToken.token
        });

        // un-paginated results
        let results = response.data;

        // paginated results
        if (response.data.results) {
          results = response.data.results;
          const meta = { ...response.data };
          delete meta.results;
          commit(SET_META, meta); // meta (e.g count, pages etc)
        }

        commit(REMOVE_REQUEST, "list");
        commit(SET_LIST, results); // list of results
        return response;
      } catch (error) {
        if (!axios.isCancel(error)) {
          commit(REMOVE_REQUEST, "list");
          return await Promise.reject(error);
        }
      }
    },

    /**
     * Next model objects
     * @param commit
     * @param state
     */
    async next({ commit, state }) {
      if (state.meta.next) {
        const cancelToken = await registerHTTPRequest(commit, state, "list");
        try {
          const response = await http.get(state.meta.next, {
            cancelToken: cancelToken.token
          });
          const results = response.data.results;
          const meta = { ...response.data, results: null };
          delete meta.results;

          commit(REMOVE_REQUEST, "list");
          commit(SET_LIST, results); // list of results
          commit(SET_META, meta); // meta (e.g count, pages etc)
          return response;
        } catch (error) {
          if (!axios.isCancel(error)) {
            commit(REMOVE_REQUEST, "list");
            return await Promise.reject(error);
          }
        }
      }
    },
    /**
     * GET a model DETAIL object
     * @param commit
     * @param state
     * @param id Model ID
     */
    async detail({ commit, state }, id: string) {
      const cancelToken = await registerHTTPRequest(commit, state, "detail");
      try {
        const response = await http.get(`${endpoint}/${id}/`, {
          cancelToken: cancelToken.token
        });
        const result = response.data;
        commit(REMOVE_REQUEST, "detail");
        commit(SET_DETAIL, result);
        return Promise.resolve(response);
      } catch (error) {
        if (!axios.isCancel(error)) {
          commit(REMOVE_REQUEST, "detail");
          return await Promise.reject(error);
        }
      }
    },
    /**
     * UPDATE a model object
     * @param commit
     * @param state
     * @param data
     */
    async update({ commit, state }, data: UpdateRequestObject) {
      const cancelToken = await registerHTTPRequest(commit, state, "update");

      try {
        const response = await http.patch(`${endpoint}/${data.id}/`, data.data, {
          cancelToken: cancelToken.token
        });
        const result = response.data;
        commit(REMOVE_REQUEST, "update");
        commit(SET_DETAIL, result);
        commit(CLEAR_ERRORS);
        return response;
      } catch (error) {
        if (!axios.isCancel(error)) {
          const status = error.response ? error.response.status : null;

          commit(REMOVE_REQUEST, "update");
          if (status === 400) {
            commit(ADD_ERRORS, error.response.data);
          }
          return await Promise.reject(error);
        }
      }
    },
    /**
     *
     * DELETE a model object
     * @param commit
     * @param state
     * @param id Model ID
     */
    async delete({ commit, state }, id) {
      const cancelToken = await registerHTTPRequest(commit, state, "delete");

      try {
        const response = await http.delete(`${endpoint}/${id}/`, {
          cancelToken: cancelToken.token
        });
        const result = response.data;
        commit(REMOVE_REQUEST, "delete");
        commit(SET_DETAIL, result);
        return response;
      } catch (error) {
        if (!axios.isCancel(error)) {
          commit(REMOVE_REQUEST, "delete");
          return await Promise.reject(error);
        }
      }
    },
    /**
     * Clear CRUD State
     * @param commit
     * @param dispatch
     * @param action
     */
    clearState({ commit, dispatch }, action = "all"): void {
      switch (action) {
        case "list":
          dispatch("cancel", "list");
          commit(SET_LIST, []);
          commit(SET_META, {});
          break;
        case "all":
          dispatch("cancel", "list");
          dispatch("cancel", "detail");
          dispatch("cancel", "create");
          dispatch("cancel", "update");
          dispatch("cancel", "delete");
          commit(SET_LIST, []);
          commit(SET_META, {});
          commit(SET_DETAIL, {});
          break;
        default:
          dispatch("cancel", action);
          commit(SET_DETAIL, {});
          break;
      }

      commit(CLEAR_ERRORS);
      return;
    },

    async cancel({ commit, state }, action: string) {
      if (state.requests[action]) {
        try {
          await state.requests[action].cancel("Previous request cancelled");
          commit(`${REMOVE_REQUEST}`, action);
        } catch (error) {
          return await Promise.reject(error);
        }
      }
    }
  };
};

export const customAction = (action: string, endpoint: string, type: string): ActionMethod => {
  switch (type) {
    case "list":
      return async ({ commit, state }) => {
        const cancelToken = await registerHTTPRequest(commit, state, action);

        try {
          const response = await http.get(endpoint, {
            cancelToken: cancelToken.token
          });
          commit(REMOVE_REQUEST, action);
          return response;
        } catch (error) {
          if (!axios.isCancel(error)) {
            commit(REMOVE_REQUEST, action);
            return await Promise.reject(error);
          }
        }
      };
    case "create":
      return async ({ commit, state }, payload) => {
        const cancelToken = await registerHTTPRequest(commit, state, action);
        commit(CLEAR_ERRORS);

        try {
          const response = await http.post(endpoint, payload, {
            cancelToken: cancelToken.token
          });
          commit(REMOVE_REQUEST, action);
          return response;
        } catch (error) {
          if (!axios.isCancel(error)) {
            const status = error.response ? error.response.status : null;
            commit(REMOVE_REQUEST, action);
            if (status === 400) {
              commit(ADD_ERRORS, error.response.data);
            }
            return await Promise.reject(error);
          }
        }
      };
    case "detail":
      return async ({ commit, state }, id: string) => {
        const cancelToken = await registerHTTPRequest(commit, state, action);
        try {
          const response = await http.get(`${endpoint}/${id}/`, {
            cancelToken: cancelToken.token
          });
          commit(REMOVE_REQUEST, action);
          return Promise.resolve(response);
        } catch (error) {
          if (!axios.isCancel(error)) {
            commit(REMOVE_REQUEST, action);
            return await Promise.reject(error);
          }
        }
      };
    case "update":
      return async ({ commit, state }, data: UpdateRequestObject) => {
        const cancelToken = await registerHTTPRequest(commit, state, "update");

        try {
          const response = await http.patch(`${endpoint}/${data.id}/`, data.data, {
            cancelToken: cancelToken.token
          });
          commit(REMOVE_REQUEST, action);
          commit(CLEAR_ERRORS);
          return response;
        } catch (error) {
          if (!axios.isCancel(error)) {
            const status = error.response ? error.response.status : null;

            commit(REMOVE_REQUEST, action);
            if (status === 400) {
              commit(ADD_ERRORS, error.response.data);
            }
            return await Promise.reject(error);
          }
        }
      };
    case "delete":
      return async ({ commit, state }, id) => {
        const cancelToken = await registerHTTPRequest(commit, state, action);

        try {
          const response = await http.delete(`${endpoint}/${id}/`, {
            cancelToken: cancelToken.token
          });
          const result = response.data;
          commit(REMOVE_REQUEST, action);
          commit(SET_DETAIL, result);
          return response;
        } catch (error) {
          if (!axios.isCancel(error)) {
            commit(REMOVE_REQUEST, action);
            return await Promise.reject(error);
          }
        }
      };
  }

  return async ({ commit, state }, id) => {
    return Promise.reject();
  };
};

export default getActions;
