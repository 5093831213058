<template>
  <responsive-dialog :display-dialog="displayDialog" :dialog-title="dialogTitle" @closed="close">
    <!-- BUTTON: ADD SCHEDULE -->
    <template slot="app-bar-right">
      <v-btn v-if="hasPermission('add')" color="success" text tile @click="newSchedule()">
        <v-icon left>mdi-plus</v-icon>
        ADD
      </v-btn>
    </template>

    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card-title>{{ entity.name }}</v-card-title>
        </v-col>
        <v-col cols="4">
          <v-switch
            v-if="canEditPaymentSchedules"
            v-model="settings.enable_payment_schedule"
            label="Enable Payment Schedules"
            :loading="settingsLoading"
            @change="updateSettings"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-btn v-if="allPanelsOpen" text @click="closeAllPanels">
            <!-- just for format -->
            <v-icon left>mdi-chevron-up</v-icon>
            Collapse all
          </v-btn>
          <v-btn v-else text @click="showAllPanels">
            <!-- just for format -->
            <v-icon left>mdi-chevron-down</v-icon>
            Expand all
          </v-btn>
        </v-col>
      </v-row>

      <v-skeleton-loader v-if="loading" class="mt-7" type="paragraph"></v-skeleton-loader>

      <v-expansion-panels v-show="!loading" v-model="panels" multiple hover focusable>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>{{ item.text }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="white-space: pre-line; margin-top: 30px">{{ item.text }}</div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.stop="copyToClipBoard(item.text)">Copy</v-btn>
              <v-btn v-if="hasPermission('change')" text color="primary" @click="editSchedule(item)">Edit</v-btn>
              <v-btn v-if="hasPermission('delete')" text color="error" @click="onClickDeleteButton(item)">Delete</v-btn>
            </v-card-actions>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <!-- PAYMENT SCHEDULE FORM -->
    <payment-schedule-form
      ref="payment_schedule_form"
      :form-object="formObject"
      @updated="onItemsUpdated"
    ></payment-schedule-form>

    <delete-dialog
      object-name="payment schedule"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </responsive-dialog>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";

import PaymentScheduleForm from "@/invoiceboost/components/payment-schedule/PaymentScheduleForm.vue";
import ResponsiveDialog from "@/bcore/components/ResponsiveDialog.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import expansionPanel from "@/mixins/expansionPanel";
import mixins from "vue-typed-mixins";

import copyToClipBoardMixin from "@/mixins/copyToClipBoard";
import settingsMixin from "@/mixins/settings";

export default mixins(expansionPanel, copyToClipBoardMixin, settingsMixin).extend({
  name: "PaymentSchedules",

  components: { PaymentScheduleForm, DeleteDialog, ResponsiveDialog },

  props: {
    editable: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      displayDialog: false,
      deleteConfirmOpen: false,

      idDeleteItem: -1,
      dialogTitle: "Payment Schedules",

      items: [],

      entity: {},
      formNew: {},
      formObject: null,
      settings: {}
    };
  },

  computed: {
    canEditPaymentSchedules() {
      return this.editable && this.$store.getters["auth/hasEntityPermission"]("invoiceboost.change_entitysettings");
    },

    settingsLoading() {
      return (
        this.$store.getters["entitySettings/loading"]("detail") ||
        this.$store.getters["entitySettings/loading"]("update")
      );
    },

    ownerEntity() {
      return this.$store.state.auth.entity;
    },

    idIsSet() {
      return this.entity && this.entity.id;
    }
  },

  watch: {},

  mounted() {
    if (this.idIsSet) {
      this.fetchItems();
    }
    this.settings = { ...this.mySettings };
  },

  methods: {
    open(entity) {
      this.displayDialog = true;
      this.entity = entity;
      this.fetchItems().then(response => {
        this.showAllPanels();
      });
      this.settings = { ...this.mySettings };
      return this;
    },

    close() {
      this.displayDialog = false;
      this.entity = {};
      this.items = [];

      return this;
    },

    async fetchItems() {
      this.loading = true;

      try {
        const response = await this.$http.get(`invoiceboost/payment-schedules`);
        this.items = response.data;
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch schedules." });
      }

      this.loading = false;
      return;
    },

    async onItemsUpdated() {
      await this.fetchItems();
      this.formObject = null;
    },

    onClickAddItem() {
      this.formObject = {
        ...this.formNew,
        ...{
          owner_entity: this.ownerEntity.id
        }
      };
    },

    onItemClick(item) {
      this.formObject = { ...item };
    },

    editSchedule(data) {
      this.$refs.payment_schedule_form.edit(data);
    },

    newSchedule() {
      this.$refs.payment_schedule_form.open();
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;

      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();

      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.showSnackbarSuccess();
    },

    async sendDelete(id) {
      await this.$store.dispatch("paymentSchedule/delete", id);
    },

    hasPermission(action) {
      return this.editable && this.$store.getters["auth/hasEntityPermission"](`invoiceboost.${action}_paymentschedule`);
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    },

    async updateSettings() {
      try {
        await this.$store.dispatch("entitySettings/update", { id: this.entity.id, data: this.settings });
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "Settings saved", type: "success" });
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { text: "There was an error saving your settings", type: "error" });
      }
    }
  }
});
</script>
