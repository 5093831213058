import { getActions, getGetters, getMutations, getState } from "@/vuex-bcore";

const actions = getActions("invoiceboost/payments");
const state = getState();
const getters = getGetters();
const mutations = getMutations();

const payment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default payment;
