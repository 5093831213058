<template>
  <div>
    <v-btn
      v-show="invoiceIdIsSet && hasPermission('add_payment') && !hasAccountingPackageConnected"
      small
      text
      color="success"
      @click.stop="onClickAddItem"
    >
      <v-icon left>mdi-plus</v-icon>
      Add payment
    </v-btn>

    <v-skeleton-loader v-if="loading" type="table" />

    <v-simple-table v-if="showTable" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Amount</th>
            <th class="text-left">Reference</th>
            <th class="text-left">Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" @click="onItemClick(item)">
            <td>{{ formatCurrency(item.amount, item.amount_currency) }}</td>
            <td>{{ item.reference_no }}</td>
            <td>{{ formatDate(item.date) }}</td>
            <td>
              <v-btn
                v-if="hasPermission('add_payment') && !mySettings.accounting_package_connected"
                text
                color="error"
                @click.stop="onClickDeleteButton(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <invoice-payment-form ref="payment_form" @updated="onItemsUpdated"></invoice-payment-form>

    <delete-dialog
      object-name="payment"
      :open="deleteConfirmOpen"
      @cancelled="onConfirmCancelled"
      @deleted="onConfirmDeleteDeleted"
    />
  </div>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import InvoicePaymentForm from "@/invoiceboost/components/invoice/InvoicePaymentForm.vue";
import DeleteDialog from "@/bcore/components/DeleteDialog.vue";
import sumColumnMixin from "@/mixins/sumColumn";
import currencyFormatMixin from "@/mixins/currencyFormat";
import dateMixin from "@/mixins/date";
import permissionMixin from "@/mixins/permission";
import settingsMixin from "@/mixins/settings";

const defaultObject = () => {
  return {};
};

export default {
  name: "InvoicePayments",

  components: { InvoicePaymentForm, DeleteDialog },

  mixins: [sumColumnMixin, currencyFormatMixin, dateMixin, permissionMixin, settingsMixin],

  props: {
    invoice: { type: Object, default: defaultObject() }
  },

  data() {
    return {
      loading: false,
      idDeleteItem: -1,
      deleteConfirmOpen: false,
      items: [],
      formNew: {
        amount: 0.0
      }
    };
  },

  computed: {
    invoiceIdIsSet() {
      return !!this.invoice && !!this.invoice.id;
    },
    showTable() {
      return !this.loading && this.items.length > 0;
    },
    ownerEntity() {
      return this.$store.state.auth.entity;
    },
    grandTotal() {
      return this.sumColumn("amount", this.items);
    }
  },

  watch: {
    invoice: {
      handler(value) {
        if (value && value.id) {
          this.fetchItems();
        } else {
          this.items = [];
        }
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    if (this.invoiceIdIsSet) {
      this.fetchItems();
    }
  },

  methods: {
    async fetchItems() {
      this.loading = true;
      try {
        const response = await this.$http.get(`invoiceboost/invoices/${this.invoice.id}/payments/`);
        this.items = response.data;
        this.$emit("updated", this.grandTotal);
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "error", text: "Unable to fetch payments." });
      }
      this.loading = false;
    },

    async onItemsUpdated() {
      await this.fetchItems();
    },

    onClickAddItem() {
      this.$refs.payment_form.open({
        ...this.formNew,
        ...{
          invoice: this.invoice.id,
          date: this.toDjangoDateField(new Date())
        }
      });
    },

    onItemClick(item) {
      if (this.hasPermission("change_payment") && !mySettings.accounting_package_connected)
        this.$refs.payment_form.open({ ...item });
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },

    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },

    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.showSnackbarSuccess();
    },

    async sendDelete(id) {
      await this.$store.dispatch("payment/delete", id);
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, { type: "success", text: "Success" });
    }
  }
};
</script>

<style scoped></style>
